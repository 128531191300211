"use strict";

var _interopRequireDefault = require("@babel/runtime/helpers/interopRequireDefault");
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.availableToAdministration = void 0;
Object.defineProperty(exports, "component", {
  enumerable: true,
  get: function get() {
    return _Line["default"];
  }
});
exports.name = exports.id = exports.description = void 0;
var _Line = _interopRequireDefault(require("./Line"));
var name = exports.name = 'Line';
var id = exports.id = 'Line';
var availableToAdministration = exports.availableToAdministration = false;
var description = exports.description = 'Line';