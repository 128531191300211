"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.getVehicleStatePath = exports.getShortList = exports.getCompareList = void 0;
var vehicleStoreSelector = function vehicleStoreSelector(featureFlags) {
  return featureFlags.vehicleStoreSelector || {};
};
var urlMatchValue = function urlMatchValue(config) {
  return window.location.pathname.match(new RegExp(config.regex))[config.matchIndex];
};
var getVehicleStatePath = exports.getVehicleStatePath = function getVehicleStatePath(featureFlags) {
  return urlMatchValue(vehicleStoreSelector(featureFlags)) || 'vehicles';
};
var getVehicles = function getVehicles(vehicleStateContainer, featureFlags) {
  return vehicleStateContainer[getVehicleStatePath(featureFlags)] || [];
};
var getShortList = exports.getShortList = function getShortList(featureFlags) {
  return function (shared) {
    return getVehicles(shared.shortlist, featureFlags);
  };
};
var getCompareList = exports.getCompareList = function getCompareList(featureFlags) {
  return function (shared) {
    return getVehicles(shared.compare, featureFlags);
  };
};