"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.constants = exports.actions = void 0;
var constants = exports.constants = {
  changeTerritory: 'CHANGE_TERRITORY'
};
var actions = exports.actions = {
  changeTerritory: function changeTerritory(territory) {
    return {
      type: constants.changeTerritory,
      payload: territory
    };
  }
};