"use strict";

var _interopRequireDefault = require("@babel/runtime/helpers/interopRequireDefault");
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.initialState = exports.id = exports.constants = exports.actions = void 0;
exports.reducer = reducer;
var _toConsumableArray2 = _interopRequireDefault(require("@babel/runtime/helpers/toConsumableArray"));
var _defineProperty2 = _interopRequireDefault(require("@babel/runtime/helpers/defineProperty"));
var _reducerMacros = require("../../utilities/reducerMacros");
function ownKeys(e, r) { var t = Object.keys(e); if (Object.getOwnPropertySymbols) { var o = Object.getOwnPropertySymbols(e); r && (o = o.filter(function (r) { return Object.getOwnPropertyDescriptor(e, r).enumerable; })), t.push.apply(t, o); } return t; }
function _objectSpread(e) { for (var r = 1; r < arguments.length; r++) { var t = null != arguments[r] ? arguments[r] : {}; r % 2 ? ownKeys(Object(t), !0).forEach(function (r) { (0, _defineProperty2["default"])(e, r, t[r]); }) : Object.getOwnPropertyDescriptors ? Object.defineProperties(e, Object.getOwnPropertyDescriptors(t)) : ownKeys(Object(t)).forEach(function (r) { Object.defineProperty(e, r, Object.getOwnPropertyDescriptor(t, r)); }); } return e; }
var modulePrefix = 'MOD_MY_ACCOUNT';
var constants = exports.constants = (0, _reducerMacros.moduleConstants)(modulePrefix, ['INIT', 'CHANGE_ACCOUNT_VIEW', 'SAVE_USER_DETAILS', 'LOAD_TEST_DRIVES', 'LOAD_TEST_DRIVES_SUCCESS', 'LOAD_TEST_DRIVES_FAILURE', 'DELETE_TEST_DRIVE', 'DELETE_TEST_DRIVE_SUCCESS', 'DELETE_TEST_DRIVE_FAILURE', 'LOAD_MY_DEALS', 'LOAD_MY_DEALS_SUCCESS', 'LOAD_MY_DEALS_FAILURE', 'DELETE_MY_DEAL', 'DELETE_MY_DEAL_SUCCESS', 'DELETE_MY_DEAL_FAILURE', 'PRINT_MY_DEAL', 'PRINT_MY_DEAL_SUCCESS', 'CHANGE_PASSWORD', 'DELETE_ACCOUNT', 'SET_FEEDBACK', 'SIGN_OUT', 'RESET']);
var id = exports.id = 'MyAccount';
var actions = exports.actions = {
  init: (0, _reducerMacros.moduleAction)(constants.INIT, id),
  changeAccountView: (0, _reducerMacros.moduleActionWithArgs)(constants.CHANGE_ACCOUNT_VIEW, id, function (context) {
    return {
      context: context
    };
  }),
  saveUserDetails: (0, _reducerMacros.moduleActionWithArgs)(constants.SAVE_USER_DETAILS, id, function (details) {
    return _objectSpread({}, details);
  }),
  loadTestDrives: (0, _reducerMacros.moduleAction)(constants.LOAD_TEST_DRIVES, id),
  loadTestDrivesSuccess: (0, _reducerMacros.moduleActionWithArgs)(constants.LOAD_TEST_DRIVES_SUCCESS, id, function (testDrives) {
    return _objectSpread({}, testDrives);
  }),
  loadTestDrivesFailure: (0, _reducerMacros.moduleActionWithArgs)(constants.LOAD_TEST_DRIVES_FAILURE, id, function (error) {
    return error;
  }),
  deleteTestDrive: (0, _reducerMacros.moduleActionWithArgs)(constants.DELETE_TEST_DRIVE, id, function (vin) {
    return {
      vin: vin
    };
  }),
  deleteTestDriveSuccess: (0, _reducerMacros.moduleActionWithArgs)(constants.DELETE_TEST_DRIVE_SUCCESS, id, function (vin) {
    return {
      vin: vin
    };
  }),
  deleteTestDriveFailure: (0, _reducerMacros.moduleActionWithArgs)(constants.DELETE_TEST_DRIVE_FAILURE, id, function (error) {
    return error;
  }),
  loadMyDeals: (0, _reducerMacros.moduleAction)(constants.LOAD_MY_DEALS, id),
  loadMyDealsSuccess: (0, _reducerMacros.moduleActionWithArgs)(constants.LOAD_MY_DEALS_SUCCESS, id, function (myDeals) {
    return _objectSpread({}, myDeals);
  }),
  loadMyDealsFailure: (0, _reducerMacros.moduleActionWithArgs)(constants.LOAD_MY_DEALS_FAILURE, id, function (error) {
    return error;
  }),
  deleteMyDeal: (0, _reducerMacros.moduleActionWithArgs)(constants.DELETE_MY_DEAL, id, function (vin) {
    return {
      id: vin
    };
  }),
  deleteMyDealSuccess: (0, _reducerMacros.moduleActionWithArgs)(constants.DELETE_MY_DEAL_SUCCESS, id, function (vin) {
    return {
      id: vin
    };
  }),
  deleteMyDealFailure: (0, _reducerMacros.moduleActionWithArgs)(constants.DELETE_MY_DEAL_FAILURE, function (error) {
    return error;
  }),
  printMyDeal: (0, _reducerMacros.moduleActionWithArgs)(constants.PRINT_MY_DEAL, id, function (payload) {
    return payload;
  }),
  printMyDealSuccess: (0, _reducerMacros.moduleActionWithArgs)(constants.PRINT_MY_DEAL_SUCCESS, id, function (vehicle) {
    return vehicle;
  }),
  changePassword: (0, _reducerMacros.moduleActionWithArgs)(constants.CHANGE_PASSWORD, id, function (passwords) {
    return _objectSpread({}, passwords);
  }),
  deleteAccount: (0, _reducerMacros.moduleActionWithArgs)(constants.DELETE_ACCOUNT, id, function (password) {
    return _objectSpread({}, password);
  }),
  setFeedback: (0, _reducerMacros.moduleActionWithArgs)(constants.SET_FEEDBACK, id, function (payload) {
    return _objectSpread({}, payload);
  }),
  signOut: (0, _reducerMacros.moduleAction)(constants.SIGN_OUT, id),
  reset: (0, _reducerMacros.moduleAction)(constants.RESET, id)
};
var initialState = exports.initialState = {
  error: null,
  context: 'Account',
  testDrives: [],
  myDeals: [],
  isLoading: false,
  detailsFeedback: {
    saveDetails: null,
    changePassword: null,
    deleteAccount: null
  },
  printing: []
};
function reducer() {
  var state = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : initialState;
  var action = arguments.length > 1 ? arguments[1] : undefined;
  switch (action.type) {
    case constants.RESET:
      return _objectSpread({}, initialState);
    case constants.CHANGE_ACCOUNT_VIEW:
      return _objectSpread(_objectSpread({}, state), {}, {
        context: action.payload.context
      });
    case constants.LOAD_TEST_DRIVES:
      return _objectSpread(_objectSpread({}, state), {}, {
        isLoading: true
      });
    case constants.LOAD_TEST_DRIVES_SUCCESS:
      return _objectSpread(_objectSpread({}, state), {}, {
        testDrives: action.payload.testDrives,
        isLoading: false,
        error: null
      });
    case constants.LOAD_MY_DEALS:
      return _objectSpread(_objectSpread({}, state), {}, {
        isLoading: true
      });
    case constants.LOAD_MY_DEALS_SUCCESS:
      return _objectSpread(_objectSpread({}, state), {}, {
        myDeals: action.payload.myDeals,
        isLoading: false,
        error: null
      });
    case constants.DELETE_MY_DEAL_SUCCESS:
      {
        var vin = action.payload.id;
        var index = state.myDeals.findIndex(function (myDeal) {
          return myDeal.vehicle.id === vin;
        });
        return _objectSpread(_objectSpread({}, state), {}, {
          isLoading: false,
          myDeals: [].concat((0, _toConsumableArray2["default"])(state.myDeals.slice(0, index)), (0, _toConsumableArray2["default"])(state.myDeals.slice(index + 1)))
        });
      }
    case constants.DELETE_TEST_DRIVE_SUCCESS:
      {
        var _vin = action.payload.vin;
        var _index = state.testDrives.findIndex(function (testDrive) {
          return testDrive.vehicle.vin === _vin;
        });
        return _objectSpread(_objectSpread({}, state), {}, {
          isLoading: false,
          testDrives: [].concat((0, _toConsumableArray2["default"])(state.testDrives.slice(0, _index)), (0, _toConsumableArray2["default"])(state.testDrives.slice(_index + 1)))
        });
      }
    case constants.SET_FEEDBACK:
      return _objectSpread(_objectSpread({}, state), {}, {
        detailsFeedback: _objectSpread(_objectSpread({}, state.detailsFeedback), action.payload)
      });
    case constants.LOAD_TEST_DRIVES_FAILURE:
    case constants.LOAD_MY_DEALS_FAILURE:
    case constants.DELETE_MY_DEAL_FAILURE:
    case constants.DELETE_TEST_DRIVE_FAILURE:
      return _objectSpread(_objectSpread({}, state), {}, {
        error: action.payload,
        isLoading: false
      });
    case constants.PRINT_MY_DEAL:
      {
        var vehicle = action.payload.vehicle;
        return _objectSpread(_objectSpread({}, state), {}, {
          printing: [].concat((0, _toConsumableArray2["default"])(state.printing), [vehicle.id])
        });
      }
    case constants.PRINT_MY_DEAL_SUCCESS:
      {
        return _objectSpread(_objectSpread({}, state), {}, {
          printing: (0, _toConsumableArray2["default"])(state.printing.filter(function (vin) {
            return vin !== action.payload.id;
          }))
        });
      }
    default:
      return state;
  }
}