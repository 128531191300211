"use strict";

var _interopRequireDefault = require("@babel/runtime/helpers/interopRequireDefault");
var _typeof = require("@babel/runtime/helpers/typeof");
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.useQuickContact = useQuickContact;
var _defineProperty2 = _interopRequireDefault(require("@babel/runtime/helpers/defineProperty"));
var _slicedToArray2 = _interopRequireDefault(require("@babel/runtime/helpers/slicedToArray"));
var _react = require("react");
var _validations = _interopRequireWildcard(require("./validations"));
function _getRequireWildcardCache(e) { if ("function" != typeof WeakMap) return null; var r = new WeakMap(), t = new WeakMap(); return (_getRequireWildcardCache = function _getRequireWildcardCache(e) { return e ? t : r; })(e); }
function _interopRequireWildcard(e, r) { if (!r && e && e.__esModule) return e; if (null === e || "object" != _typeof(e) && "function" != typeof e) return { "default": e }; var t = _getRequireWildcardCache(r); if (t && t.has(e)) return t.get(e); var n = { __proto__: null }, a = Object.defineProperty && Object.getOwnPropertyDescriptor; for (var u in e) if ("default" !== u && {}.hasOwnProperty.call(e, u)) { var i = a ? Object.getOwnPropertyDescriptor(e, u) : null; i && (i.get || i.set) ? Object.defineProperty(n, u, i) : n[u] = e[u]; } return n["default"] = e, t && t.set(e, n), n; }
function ownKeys(e, r) { var t = Object.keys(e); if (Object.getOwnPropertySymbols) { var o = Object.getOwnPropertySymbols(e); r && (o = o.filter(function (r) { return Object.getOwnPropertyDescriptor(e, r).enumerable; })), t.push.apply(t, o); } return t; }
function _objectSpread(e) { for (var r = 1; r < arguments.length; r++) { var t = null != arguments[r] ? arguments[r] : {}; r % 2 ? ownKeys(Object(t), !0).forEach(function (r) { (0, _defineProperty2["default"])(e, r, t[r]); }) : Object.getOwnPropertyDescriptors ? Object.defineProperties(e, Object.getOwnPropertyDescriptors(t)) : ownKeys(Object(t)).forEach(function (r) { Object.defineProperty(e, r, Object.getOwnPropertyDescriptor(t, r)); }); } return e; }
function useQuickContact(initialState, translations) {
  var _useState = (0, _react.useState)(initialState),
    _useState2 = (0, _slicedToArray2["default"])(_useState, 2),
    values = _useState2[0],
    setValues = _useState2[1];
  var _useState3 = (0, _react.useState)({}),
    _useState4 = (0, _slicedToArray2["default"])(_useState3, 2),
    errors = _useState4[0],
    setErrors = _useState4[1];
  var _useState5 = (0, _react.useState)('unsubmitted'),
    _useState6 = (0, _slicedToArray2["default"])(_useState5, 2),
    submissionStatus = _useState6[0],
    setSubmissionStatus = _useState6[1];
  (0, _react.useEffect)(function () {
    if (submissionStatus === 'isSubmitting') {
      var noErrors = Object.keys(errors).length === 0;
      if (noErrors) {
        setSubmissionStatus('submitted');
      } else {
        setSubmissionStatus('unsubmitted');
      }
    }
  }, [submissionStatus]);
  function handleChange(event) {
    setValues(_objectSpread(_objectSpread({}, values), {}, (0, _defineProperty2["default"])({}, event.target.name, _objectSpread(_objectSpread({}, values[event.target.name]), {}, {
      value: event.target.value
    }))));
  }
  function handleBlur(event) {
    var blurError = (0, _validations["default"])(values[event.target.name], translations);
    setErrors(_objectSpread(_objectSpread({}, errors), blurError));
  }
  function handleSubmit(event) {
    event.preventDefault();
    var submissionErrors = (0, _validations.validateAll)(values, translations);
    setErrors(submissionErrors);
    setSubmissionStatus('submitted');
  }
  return {
    handleChange: handleChange,
    handleSubmit: handleSubmit,
    handleBlur: handleBlur,
    values: values,
    errors: errors,
    submissionStatus: submissionStatus
  };
}