"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.required = exports.position = exports.name = exports.id = exports.global = exports.defaultConfig = exports.config = exports.availableToAdministration = void 0;
var config = exports.config = {};
var defaultConfig = exports.defaultConfig = function defaultConfig() {};
var name = exports.name = '$$BODY$$';
var id = exports.id = '$$BODY$$';
var availableToAdministration = exports.availableToAdministration = false;
var global = exports.global = true;
var required = exports.required = true;
var position = exports.position = 3;