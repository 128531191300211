"use strict";

var _interopRequireDefault = require("@babel/runtime/helpers/interopRequireDefault");
Object.defineProperty(exports, "__esModule", {
  value: true
});
Object.defineProperty(exports, "actions", {
  enumerable: true,
  get: function get() {
    return _reducer.actions;
  }
});
exports.availableToAdministration = void 0;
Object.defineProperty(exports, "component", {
  enumerable: true,
  get: function get() {
    return _AstonMartinVehicleSearchResults["default"];
  }
});
Object.defineProperty(exports, "constants", {
  enumerable: true,
  get: function get() {
    return _reducer.constants;
  }
});
exports.id = exports.description = void 0;
Object.defineProperty(exports, "initialState", {
  enumerable: true,
  get: function get() {
    return _reducer.initialState;
  }
});
exports.name = void 0;
Object.defineProperty(exports, "reducer", {
  enumerable: true,
  get: function get() {
    return _reducer.reducer;
  }
});
Object.defineProperty(exports, "reducerId", {
  enumerable: true,
  get: function get() {
    return _reducer.id;
  }
});
var _AstonMartinVehicleSearchResults = _interopRequireDefault(require("./AstonMartinVehicleSearchResults"));
var _reducer = require("../../VehicleSearchResults/reducer");
var name = exports.name = 'Aston Martin Vehicle Search Results';
var id = exports.id = 'AstonMartinVehicleSearchResults';
var availableToAdministration = exports.availableToAdministration = true;
var description = exports.description = 'Search Results Page';