"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.getSymbolFromCurrency = void 0;
var _currencySymbolMap = require("./currencySymbolMap");
/* eslint-disable import/prefer-default-export */
var getSymbolFromCurrency = exports.getSymbolFromCurrency = function getSymbolFromCurrency(currencyCode) {
  if (typeof currencyCode !== 'string') return undefined;
  var code = currencyCode.toUpperCase();
  if (!Object.keys(_currencySymbolMap.currencySymbolMap).find(function (c) {
    return c === code;
  })) return undefined;
  return _currencySymbolMap.currencySymbolMap[code];
};