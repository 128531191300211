"use strict";

var _interopRequireDefault = require("@babel/runtime/helpers/interopRequireDefault");
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.initialState = exports.constants = exports.actions = void 0;
exports.reducer = reducer;
var _defineProperty2 = _interopRequireDefault(require("@babel/runtime/helpers/defineProperty"));
var _reducerMacros = require("../../utilities/reducerMacros");
function ownKeys(e, r) { var t = Object.keys(e); if (Object.getOwnPropertySymbols) { var o = Object.getOwnPropertySymbols(e); r && (o = o.filter(function (r) { return Object.getOwnPropertyDescriptor(e, r).enumerable; })), t.push.apply(t, o); } return t; }
function _objectSpread(e) { for (var r = 1; r < arguments.length; r++) { var t = null != arguments[r] ? arguments[r] : {}; r % 2 ? ownKeys(Object(t), !0).forEach(function (r) { (0, _defineProperty2["default"])(e, r, t[r]); }) : Object.getOwnPropertyDescriptors ? Object.defineProperties(e, Object.getOwnPropertyDescriptors(t)) : ownKeys(Object(t)).forEach(function (r) { Object.defineProperty(e, r, Object.getOwnPropertyDescriptor(t, r)); }); } return e; }
var modulePrefix = 'MOD_VEHICLE_INFO_SHARED';
var constants = exports.constants = (0, _reducerMacros.moduleConstants)(modulePrefix, ['GET_VEHICLE_INFO', 'GET_VEHICLE_INFO_SUCCESS', 'CLEAR_VEHICLE_INFO', 'SET_VEHICLE']);
var actions = exports.actions = {
  getVehicleInfo: function getVehicleInfo(payload) {
    return {
      type: constants.GET_VEHICLE_INFO,
      payload: payload
    };
  },
  getVehicleInfoSuccess: function getVehicleInfoSuccess(payload) {
    return {
      type: constants.GET_VEHICLE_INFO_SUCCESS,
      payload: payload
    };
  },
  clearVehicleInfo: function clearVehicleInfo() {
    return {
      type: constants.CLEAR_VEHICLE_INFO
    };
  },
  setVehicle: function setVehicle(payload) {
    return {
      type: constants.SET_VEHICLE,
      payload: payload
    };
  }
};
var initialState = exports.initialState = {
  vehicle: null
};
function reducer() {
  var state = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : initialState;
  var action = arguments.length > 1 ? arguments[1] : undefined;
  switch (action.type) {
    case constants.GET_VEHICLE_INFO_SUCCESS:
      {
        return _objectSpread(_objectSpread({}, state), {}, {
          vehicle: action.payload
        });
      }
    case constants.SET_VEHICLE:
      {
        return _objectSpread(_objectSpread({}, state), {}, {
          vehicle: action.payload
        });
      }
    case constants.CLEAR_VEHICLE_INFO:
      {
        return _objectSpread(_objectSpread({}, state), {}, {
          vehicle: null
        });
      }
    default:
      return state;
  }
}