import React from 'react';
import 'url-polyfill';
import 'unorm';

import { Provider, connect } from 'react-redux';
import { renderer } from 'cms-modules';

import Startup from './startup';
import store from './state/store';

import { StyleSheetManager } from 'styled-components';

const { Site } = renderer;

const ConnectedStartup = connect(state => ({
  initialised: state.config.initialised,
}))(Startup);

const ConnectedSite = connect(state => ({
  globalStyling: state.config.config.global.globalStyling,
  path: state.router.path,
  hash: state.router.hash,
}))(Site);

export default (
  <StyleSheetManager enableVendorPrefixes>
    <Provider store={store}>
      <ConnectedStartup>
        <ConnectedSite />
      </ConnectedStartup>
    </Provider>
  </StyleSheetManager>
);
