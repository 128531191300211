"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.selectStyleOverride = exports.linkStyleOverride = exports.fontStyleOverride = exports.buttonStyleOverride = void 0;
var fontStyleOverride = exports.fontStyleOverride = function fontStyleOverride() {
  var _ref = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : {},
    typeface = _ref.typeface,
    fontSize = _ref.fontSize,
    kerning = _ref.kerning,
    transform = _ref.transform,
    colour = _ref.colour,
    alignment = _ref.alignment,
    padding = _ref.padding;
  return [typeface && "font-family: ".concat(typeface.value, ";"), fontSize && "font-size: ".concat(fontSize, "px;"), kerning !== undefined && "letter-spacing: ".concat(kerning, "px;"), transform && "text-transform: ".concat(transform, ";"), colour && "color: ".concat(colour.value, ";"), alignment && "text-align: ".concat(alignment, ";"), padding && padding.top && "padding-top: ".concat(padding.top, "px;"), padding && padding.bottom && "padding-bottom: ".concat(padding.bottom, "px;"), padding && padding.left && "padding-left: ".concat(padding.left, "px;"), padding && padding.right && "padding-right: ".concat(padding.right, "px;")].filter(Boolean).join('');
};
var buttonStyleOverride = exports.buttonStyleOverride = function buttonStyleOverride() {
  var _ref2 = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : {},
    font = _ref2.font,
    backgroundColour = _ref2.backgroundColour,
    hoverBackgroundColour = _ref2.hoverBackgroundColour,
    buttonSize = _ref2.buttonSize,
    hoverColour = _ref2.hoverColour;
  return [fontStyleOverride(font), backgroundColour && "background-color: ".concat(backgroundColour.value, ";"), hoverBackgroundColour && "background-color: ".concat(hoverBackgroundColour.value, ";"), buttonSize && buttonSize.width && "width: ".concat(buttonSize.width, ";"), buttonSize && buttonSize.height && "height: ".concat(buttonSize.height, ";"), hoverColour && "color: ".concat(hoverColour.value, ";")].filter(Boolean).join('');
};
var linkStyleOverride = exports.linkStyleOverride = function linkStyleOverride() {
  var _ref3 = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : {},
    borderColour = _ref3.borderColour,
    borderBottom = _ref3.borderBottom;
  return [borderColour && "border-color: ".concat(borderColour.value, ";"), borderBottom && "border-bottom: solid ".concat(borderBottom, "px;")].filter(Boolean).join('');
};
var selectStyleOverride = exports.selectStyleOverride = function selectStyleOverride() {
  var _ref4 = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : {},
    font = _ref4.font,
    borderColour = _ref4.borderColour,
    borderWidth = _ref4.borderWidth,
    width = _ref4.width,
    height = _ref4.height,
    margin = _ref4.margin;
  return [fontStyleOverride(font), borderColour && "border-color: ".concat(borderColour.value, ";"), borderWidth && "border-width: ".concat(borderWidth.value, ";"), width && "width: ".concat(width, ";"), height && "height: ".concat(height, ";"), margin && Object.keys(margin).map(function (k) {
    return "margin-".concat(k, ": ").concat(margin[k], ";");
  }).filter(Boolean)].filter(Boolean);
};