"use strict";

var _interopRequireDefault = require("@babel/runtime/helpers/interopRequireDefault");
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.useCarousel = useCarousel;
var _slicedToArray2 = _interopRequireDefault(require("@babel/runtime/helpers/slicedToArray"));
var _react = require("react");
function useCarousel(scrollerRef, slidesCount) {
  var _useState = (0, _react.useState)(0),
    _useState2 = (0, _slicedToArray2["default"])(_useState, 2),
    currentIndex = _useState2[0],
    setCurrentIndex = _useState2[1];
  var _useState3 = (0, _react.useState)(null),
    _useState4 = (0, _slicedToArray2["default"])(_useState3, 2),
    prevIndex = _useState4[0],
    setPrevIndex = _useState4[1];
  var onThumbnailClick = function onThumbnailClick(index) {
    setPrevIndex(currentIndex);
    setCurrentIndex(index);
  };
  var onButtonClicked = function onButtonClicked(direction) {
    var index = currentIndex + direction;
    var nextIndex = index;
    if (index < 0) {
      nextIndex = slidesCount - 1;
    }
    if (index >= slidesCount) {
      nextIndex = 0;
    }
    setPrevIndex(currentIndex);
    setCurrentIndex(nextIndex);
  };
  var clickLeft = function clickLeft() {
    return onButtonClicked(-1);
  };
  var clickRight = function clickRight() {
    return onButtonClicked(1);
  };
  (0, _react.useEffect)(function () {
    if (!scrollerRef || !scrollerRef.current) {
      return;
    }
    var _scrollerRef$current = scrollerRef.current,
      clientWidth = _scrollerRef$current.clientWidth,
      scrollWidth = _scrollerRef$current.scrollWidth;
    var singleScrollDist = (scrollWidth - clientWidth) / (slidesCount - 1);
    var scrollDist = singleScrollDist * currentIndex;

    // eslint-disable-next-line no-param-reassign
    scrollerRef.current.style.left = "".concat(scrollDist * -1, "px");
  }, [currentIndex]);
  return {
    currentIndex: currentIndex,
    prevIndex: prevIndex,
    clickLeft: clickLeft,
    clickRight: clickRight,
    onThumbnailClick: onThumbnailClick
  };
}