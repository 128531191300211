"use strict";

var _interopRequireDefault = require("@babel/runtime/helpers/interopRequireDefault");
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports["default"] = void 0;
var _taggedTemplateLiteral2 = _interopRequireDefault(require("@babel/runtime/helpers/taggedTemplateLiteral"));
var _styledComponents = _interopRequireDefault(require("styled-components"));
var _theme = _interopRequireDefault(require("../../../theme"));
var _templateObject;
var _default = exports["default"] = _styledComponents["default"].div.withConfig({
  displayName: "WidthWrapper",
  componentId: "sc-yjnuuq-0"
})(["width:100%;", ";"], _theme["default"].min.medium(_templateObject || (_templateObject = (0, _taggedTemplateLiteral2["default"])(["\n    width: 360px;\n  "]))));