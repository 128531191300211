"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.saveKey = exports.getKey = exports.clearKey = void 0;
// Contains all supported keys to be saved in session storage.

var getKey = exports.getKey = function getKey(key) {
  try {
    var value = sessionStorage.getItem(key);
    if (!value) return null;
    return JSON.parse(value);
  } catch (error) {
    return null;
  }
};
var saveKey = exports.saveKey = function saveKey(key, data) {
  try {
    sessionStorage.setItem(key, JSON.stringify(data));
  } catch (error) {
    console.error(' Error saving key in sessionStorage: ', error);
  }
};
var clearKey = exports.clearKey = function clearKey(key) {
  try {
    sessionStorage.removeItem(key);
  } catch (error) {
    console.error(' Error cleaning key in sessionStorage: ', error);
  }
};