/* allows customised storage handling when customised consent rules exist */

/* BMW ePaaS */
window.storageMiddleware = () => false;
const ePaaSManagedKeys = [
  'locale',
  'customerDealerShortlist',
  'customerDealerCompare',
];
const ePaaSLocalStorageProcessingName = 'RRMCpreowned';

function initializeEpaasStateManagement() {
  const epaas = window.epaas || null;
  // check whether locally stored keys have user consent to be persisted
  const consentGivenForLocalePersistence = epaas
    ? epaas.api.isUsageAllowed('locale')
    : false;

  if (!consentGivenForLocalePersistence ?? false) {
    // If consent not given, port the keys to session storage and use that to persist state
    const keysToMigrate = ['locale', 'customerShortlist', 'customerCompare'];

    keysToMigrate.forEach(key => {
      const currentValue = localStorage.getItem(key) ?? null;
      if (currentValue) {
        sessionStorage.setItem(key, currentValue);
        localStorage.removeItem(key);
      }
    });
  }

  window.storageMiddleware = (mode, k, v) => {
    if (!epaas || ePaaSManagedKeys.indexOf(k) < 0) {
      return { handled: false, value: null };
    }

    switch (mode) {
      case 'save':
        if (epaas.api.isUsageAllowed('locale')) {
          epaas.api.setPersistence(k, v);
        } else {
          localStorage.clear();
        }
        return { handled: true, value: null };
      case 'load':
        if (epaas.api.isUsageAllowed(ePaaSLocalStorageProcessingName)) {
          return { handled: true, value: epaas.api.getPersistence(k) };
        }
        return { handled: true, value: sessionStorage.getItem(k) };

      case 'clear':
        if (epaas.api.isUsageAllowed(ePaaSLocalStorageProcessingName)) {
          epaas.api.deletePersistence(k);
          return { handled: true, value: null };
        }
        sessionStorage.removeItem(k);
        return { handled: true, value: null };

      default:
        return { handled: false, value: null };
    }
  };
}
if (!window.epaas) {
  window.addEventListener(
    'consentcontroller.api.initialized',
    initializeEpaasStateManagement,
  );
}
export default {};
