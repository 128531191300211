/* eslint-disable import/no-unresolved, import/extensions  */
import { actions, shared } from 'cms-modules';
import { createBrowserHistory } from 'history';
import querystring from 'querystring-es3';
import { eventChannel } from 'redux-saga';
import {
  call,
  put,
  select,
  take,
  takeEvery,
  takeLatest,
} from 'redux-saga/effects';

import appSettings from '../../settings';
import {
  actions as configActions,
  constants as configConstants,
} from '../actions/config';

const {
  router: { constants, actions: routerActions },
} = actions;

let history;
let trackerName;

function* setTrackerName() {
  const trackingId = yield select(
    shared.selectors.getGoogleAnalyticsTrackingId,
  );

  const tracker = window.ga
    .getAll()
    .find(analyticsTracker =>
      RegExp(trackingId.replace(/-/g, '_')).test(analyticsTracker.get('name')),
    );

  if (tracker) {
    trackerName = tracker.get('name');
  }

  return trackerName;
}

function* trackNavigation(path) {
  const sendTracking = yield select(shared.selectors.usesGoogleAnalytics);
  try {
    if (sendTracking && window.ga) {
      if (!trackerName) {
        yield setTrackerName();
      }

      window.ga(`${trackerName}.set`, 'page', path);
      window.ga(`${trackerName}.send`, 'pageview', path);
    }
  } catch {
    // no-op
  }
}

const rewriteUrl = (url, rewrite) => {
  const match = (rewrite.includeBase
    ? `${window.location.pathname}${window.location.hash}`
    : url
  ).match(new RegExp(rewrite.regex));
  const { retailerId, ...query } = querystring.parse(url.split('?')[1]);
  return match && match[1]
    ? rewrite.redirectUrl
        .replace('$$MATCH$$', match[1])
        .concat(query ? `?${querystring.stringify(query)}` : '')
    : url;
};

const matchUrlRedirect = (url, rewriteRules = []) => {
  const rewrite = rewriteRules.find(r =>
    new RegExp(r.regex).test(
      r.includeBase
        ? `${window.location.pathname}${window.location.hash}`
        : url,
    ),
  );
  return rewrite ? rewriteUrl(url, rewrite) : url;
};

const getUrlSegments = (path, propNames) =>
  propNames.map(propName =>
    path.split('/').find(urlSegment => urlSegment.startsWith(propName)),
  );

const extractPathProps = (path, pathToMatch, mappings) => {
  const [dealer, group] = getUrlSegments(path, ['dealer', 'group']);
  const match = window.location.pathname.match(pathToMatch);
  const dealerIdFromPath = match && match[1];
  const mapping = mappings && mappings[dealerIdFromPath];

  const pathProps = {
    retailerId:
      (dealer && decodeURIComponent(dealer.split('_')[1])) ||
      (mapping && mapping.type === 'dealer' && mapping.id),
    retailerName:
      dealer &&
      decodeURIComponent(
        dealer
          .split('_')
          .slice(2)
          .join(' '),
      ),
    dealerGroup:
      (group && decodeURIComponent(group.split('_')[1])) ||
      (mapping && mapping.type === 'group' && mapping.id),
  };

  if (pathProps.retailerId === 'undefined') delete pathProps.retailerId;

  return pathProps;
};

function* prepareExtractProps(hashRouting) {
  let pathnameProp = window.location.pathname;
  let mappingsObj = {};
  let pathToMatchAgainst = '';
  if (hashRouting) {
    const {
      redirects = { rewriteRules: [], pathToMatch: '', mappings: {} },
    } = yield select(state => state.config.config.global);
    mappingsObj = redirects.mappings;
    const path = window.location.hash;
    const rewritePath = matchUrlRedirect(path, redirects.rewriteRules);
    pathToMatchAgainst = redirects.pathToMatch;
    const [hashPath] = path.split('?');
    pathnameProp = hashPath;
    if (path !== rewritePath) {
      const redirect = `${window.location.href.split('#')[0]}${rewritePath}`;
      // this is brute forcing an edge case
      window.location = redirect;
    }
  }
  return extractPathProps(pathnameProp, pathToMatchAgainst, mappingsObj);
}

function* getLanguagePath(path) {
  const { language } = yield select(state => state.shared.sessionPreferences);
  // pushing a query string onto history appends it to the path so we don't want to add language there either
  return path.startsWith('?')
    ? path
    : // on refresh, initial navigate will include the language code
      `/${language}${path.replace(/^\/[a-z]{2}_[a-z]{2}\//, '/')}`;
}

function* push(path, data) {
  const { noLanguageInUrl } = appSettings;
  const hashRouting = yield select(state => state.config.settings.hashRouting);
  const languagePath = noLanguageInUrl ? path : yield getLanguagePath(path);
  const hashPath =
    hashRouting &&
    !(languagePath.startsWith('#') || languagePath.startsWith('/#'))
      ? `#${languagePath}`
      : languagePath;
  history.push(hashPath, data);
  yield trackNavigation(hashPath);
}

const isExternalUrl = path => /^(http)(s*)(:*\/*\/*)/.test(path);

function* navigate(action) {
  let path = action.payload;

  const { redirects = { rewriteRules: [] } } = yield select(
    state => state.config.config.global,
  );

  if (isExternalUrl(action.payload)) {
    return window.open(action.payload);
  }

  if (!action.payload.startsWith('/')) {
    // this added to have access in subnav links from vdp
    // TODO THIS SHOULD BE REMOVED!!
    path = '/'.concat(path);
  }

  return yield push(matchUrlRedirect(path, redirects.rewriteRules));
}

function* urlChangeNavigate(action) {
  const location = action.payload;
  const hashRouting = yield select(state => state.config.settings.hashRouting);
  const pathProps = yield prepareExtractProps(hashRouting);

  const payload = { ...location, pathProps, hashRouting };

  if (payload.state) {
    yield put(routerActions.navigateWithPayloadSuccess(payload));
  } else {
    yield put(routerActions.navigateSuccess(payload));
  }
}

function createNavigationWatcher() {
  return eventChannel(emitter =>
    history.listen(location => {
      emitter(routerActions.urlChangeNavigate(location));

      if (!location.state || !location.state.disableScroll) window.scroll(0, 0);
    }),
  );
}

function* watchNavigations() {
  const hashRouting = yield select(state => state.config.settings.hashRouting);
  const pathProps = yield prepareExtractProps(hashRouting);

  yield put(
    routerActions.navigateSuccess({
      ...history.location,
      pathProps,
      hashRouting,
    }),
  );
  yield put(configActions.initialised());
  const navigationChannel = yield call(createNavigationWatcher);
  while (true) {
    const action = yield take(navigationChannel);
    yield put(action);
  }
}

const baseFromUrl = (path, selectors = []) => {
  const pathSections = path.split(/\//).filter(Boolean);
  const matcher = selectors.find(
    selector =>
      pathSections.length >= selector.sectionMatchers.length &&
      selector.sectionMatchers.every((sectionMatcher, index) =>
        RegExp(sectionMatcher).test(pathSections[index]),
      ),
  );

  return (
    matcher &&
    pathSections
      .filter((_, index) => index < matcher.sectionMatchers.length)
      .join('/')
  );
};

function* createHistory(action) {
  const { baseSelectors } = action.payload.global.routing || {};
  if (!history) {
    const hashRouting = yield select(
      state => state.config.settings.hashRouting,
    );

    const currentPath = window.location.pathname;
    const basename = hashRouting
      ? currentPath
      : baseFromUrl(currentPath, baseSelectors);
    history = createBrowserHistory({
      basename,
    });
    yield put(routerActions.historyCreated(history, `/${basename || ''}`));
    const { pathname, search, hash } = history.location;
    // for configMarketByUrl (sites that run on a base url of www.domain.com/countryCode)
    // we need to prevent the /[countryCode]
    // from being appended to the end of the initial path
    // in cases where for example pathname == '/at/' and base == '/at/something'
    const initialPath = `${
      appSettings.configMarketByUrl
        ? `/${pathname
            .split('/')
            .filter((section, index) => (index > 1 ? section : ''))
            .join('/')}`
        : pathname
    }${search}${hash}`;
    yield put(routerActions.navigate(initialPath));
    yield call(watchNavigations);
  } else {
    yield put(configActions.initialised());
  }
}

function* navigateWithPayload(action) {
  yield push(action.payload.path, action.payload.data);
  yield call(trackNavigation, action.payload.path);
}

function* navigateWithQuery(action) {
  const path = `${action.payload.path}?${querystring.stringify(
    action.payload.queryParams,
  )}`;
  yield push(path);
  yield call(trackNavigation, path);
}

function* navigateWithFilters(action) {
  const {
    payload: { path: actionPath },
  } = action;
  const hashRouting = yield select(state => state.config.settings.hashRouting);
  let path = actionPath;

  if (hashRouting) {
    const hash = actionPath.startsWith('?')
      ? window.location.hash.split('?')[0]
      : '#/';
    // brute force remove any double slashes as breaks navigation
    path = `${hash}${actionPath}`.replace(/\/\//g, '/');
  }
  // dont leave a trailing ? if no query
  // need to figure out why goes mental!
  // path = path.endsWith('?') ? path.substring(0, path.length - 1) : path;
  if (!hashRouting || path !== '?') {
    yield push(path, {
      disableScroll: action.payload.disableScroll,
    });

    yield call(trackNavigation, path);
  }
}

// TODO check when we use this!
function* replace(action) {
  const languagePath = yield getLanguagePath(action.payload);
  yield call(history.replace, languagePath);
}

function* routerSaga() {
  yield takeLatest(constants.navigate, navigate);
  yield takeLatest(constants.navigateWithPayload, navigateWithPayload);
  yield takeLatest(constants.navigateWithQuery, navigateWithQuery);
  yield takeLatest(constants.navigateWithFilters, navigateWithFilters);
  yield takeLatest(constants.replace, replace);
  yield takeLatest(constants.urlChangeNavigation, urlChangeNavigate);
  yield takeEvery(configConstants.CONFIG_LOAD, createHistory);
}

export default [routerSaga];
