"use strict";

var _interopRequireDefault = require("@babel/runtime/helpers/interopRequireDefault");
Object.defineProperty(exports, "__esModule", {
  value: true
});
Object.defineProperty(exports, "component", {
  enumerable: true,
  get: function get() {
    return _CertifiedProgramme["default"];
  }
});
exports.name = exports.id = exports.description = void 0;
var _CertifiedProgramme = _interopRequireDefault(require("./CertifiedProgramme"));
var name = exports.name = 'BentleyCertifiedProgramme';
var id = exports.id = 'BentleyCertifiedProgramme';
var description = exports.description = 'Certified Programme Overview';