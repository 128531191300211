"use strict";

var _interopRequireDefault = require("@babel/runtime/helpers/interopRequireDefault");
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports["default"] = void 0;
var _config = require("../../helpers/config");
var _SectionIcons = _interopRequireDefault(require("../../helpers/SectionIcons"));
var hintText = function hintText() {
  return "\n  Please note that all images for this Module will be transformed to a resolution of 1366 x 600 pixels.\n  To optimise the display of images they should be as close to this resolution as possible.\n  ";
};
var config = (0, _config.Config)('Icon grid', {
  id: 'icongrid'
}, (0, _config.Slice)('Heading', {
  id: 'heading'
}, (0, _config.Section)('Global', {}, (0, _config.TextArea)('Header', 'headLineText'), (0, _config.TextArea)('Description', 'subHeadlineText'), (0, _config.Toggle)('Background Image', 'backgroundImageActive'), (0, _config.Image)('Image', 'backgroundImage', {
  hintText: hintText
}))), (0, _config.ListSlice)('Grid Items', {
  id: 'items',
  max: 9,
  sectionLabel: 'Grid Item',
  buttonLabel: 'Add new grid item',
  icon: _SectionIcons["default"].GridItem
}, (0, _config.Image)('Image', 'image'), (0, _config.TextArea)('Title', 'titleItemText'), (0, _config.TextArea)('Description', 'descriptionItemText')), (0, _config.Slice)('Styling', {
  id: 'styling'
}, (0, _config.Section)('Global', {
  icon: _SectionIcons["default"].TopTier
}, (0, _config.Align)('Align', 'headLineAlignment'), (0, _config.Colour)('Background Colour', 'backgroundColour'), (0, _config.Font)('Header Text', 'headlineFont'), (0, _config.Font)('Grid Item Header', 'itemHeaderFont'), (0, _config.Font)('Description', 'descriptionFont'))));
var _default = exports["default"] = config;