"use strict";

var _interopRequireDefault = require("@babel/runtime/helpers/interopRequireDefault");
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.availableToAdministration = void 0;
Object.defineProperty(exports, "component", {
  enumerable: true,
  get: function get() {
    return _Header["default"];
  }
});
Object.defineProperty(exports, "config", {
  enumerable: true,
  get: function get() {
    return _config["default"];
  }
});
Object.defineProperty(exports, "defaultConfig", {
  enumerable: true,
  get: function get() {
    return _defaultConfig["default"];
  }
});
exports.required = exports.position = exports.name = exports.id = exports.global = void 0;
var _Header = _interopRequireDefault(require("./Header"));
var _config = _interopRequireDefault(require("./config"));
var _defaultConfig = _interopRequireDefault(require("./defaultConfig"));
var name = exports.name = 'Header';
var id = exports.id = 'Header';
var availableToAdministration = exports.availableToAdministration = false;
var global = exports.global = true;
var required = exports.required = true;
var position = exports.position = 2;