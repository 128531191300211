"use strict";

var _interopRequireDefault = require("@babel/runtime/helpers/interopRequireDefault");
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports["default"] = void 0;
var _config = require("../../helpers/config");
var _SectionIcons = _interopRequireDefault(require("../../helpers/SectionIcons"));
var config = (0, _config.Config)('Representative Example', {
  id: 'Repex'
}, (0, _config.Slice)('Styling', {
  id: 'styling'
}, (0, _config.Section)('Global', {
  icon: _SectionIcons["default"].TopTier
}, (0, _config.Colour)('Background Colour', 'backgroundColour'), (0, _config.Font)('Title Text', 'titleFont'), (0, _config.Toggle)('Horizontal Border', 'horizontalBorder'), (0, _config.Toggle)('Vertical Border', 'verticalBorder'), (0, _config.Colour)('Border Colour', 'borderColour'))));
var _default = exports["default"] = config;