"use strict";

var _interopRequireDefault = require("@babel/runtime/helpers/interopRequireDefault");
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.useVehicleRetailer = useVehicleRetailer;
var _slicedToArray2 = _interopRequireDefault(require("@babel/runtime/helpers/slicedToArray"));
var _react = require("react");
var _reactRedux = require("react-redux");
var _sessionPreferences = require("../../shared/sessionPreferences");
var _filters = require("../../shared/filters");
var _router = require("../../actions/router");
var _translateFromTemplate = require("../../shared/localisation/translateFromTemplate");
var _pathBuilders = require("../../path-builders");
function useVehicleRetailer(retailerLoc, translations) {
  var dispatch = (0, _reactRedux.useDispatch)();
  var _useState = (0, _react.useState)(null),
    _useState2 = (0, _slicedToArray2["default"])(_useState, 2),
    distanceFromRetailer = _useState2[0],
    setDistanceFromRetailer = _useState2[1];
  var _useState3 = (0, _react.useState)(null),
    _useState4 = (0, _slicedToArray2["default"])(_useState3, 2),
    position = _useState4[0],
    setPosition = _useState4[1];
  var viewRetailerStock = function viewRetailerStock(retailer) {
    dispatch(_sessionPreferences.actions.updateSessionPreferences('resetSearchResultsPageNumber', true));
    dispatch(_filters.actions.resetFilters());
    dispatch(_router.actions.navigate((0, _pathBuilders.pathWithDealerID)('/searchresults', retailer)));
  };
  var makeAnEnquiry = function makeAnEnquiry(vehicleId) {
    dispatch(_router.actions.navigate("/".concat(vehicleId, "/enquiry")));
  };
  (0, _react.useEffect)(function () {
    if (!position) {
      return;
    }
    var _position$coords = position.coords,
      userLat = _position$coords.latitude,
      userLong = _position$coords.longitude;
    var _retailerLoc$coordina = (0, _slicedToArray2["default"])(retailerLoc.coordinates, 2),
      lat = _retailerLoc$coordina[0],
      _long = _retailerLoc$coordina[1];
    var distanceService = new window.google.maps.DistanceMatrixService();
    distanceService.getDistanceMatrix({
      origins: [new window.google.maps.LatLng(userLat, userLong)],
      destinations: [new window.google.maps.LatLng(lat, _long)],
      travelMode: 'DRIVING'
    }, function (response, status) {
      if (status === 'OK') {
        var distanceText = response.rows[0].elements[0].distance && response.rows[0].elements[0].distance.text;
        if (distanceText) {
          setDistanceFromRetailer((0, _translateFromTemplate.translateFromTemplate)('vdpRetailerDistanceSuccess', {
            DISTANCE: distanceText
          }, translations));
        } else {
          setDistanceFromRetailer(translations.vdpRetailerDistanceFailed);
        }
      } else {
        setDistanceFromRetailer(null);
      }
    });
  }, [position]);
  return {
    distanceFromRetailer: distanceFromRetailer,
    setDistanceFromRetailer: setDistanceFromRetailer,
    setPosition: setPosition,
    viewRetailerStock: viewRetailerStock,
    makeAnEnquiry: makeAnEnquiry
  };
}