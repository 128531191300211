"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.pageEntity = exports.pageArray = exports.globalArray = void 0;
var _normalizr = require("normalizr");
var podEntity = new _normalizr.schema.Entity('pods', {}, {
  idAttribute: 'instanceId'
});
var moduleEntity = new _normalizr.schema.Entity('bands', {
  config: {
    pods: [podEntity]
  }
}, {
  idAttribute: 'instanceId'
});
var pageEntity = exports.pageEntity = new _normalizr.schema.Entity('pages', {
  modules: [moduleEntity]
}, {
  idAttribute: 'relativePath'
});
var pageArray = exports.pageArray = [pageEntity];
var globalArray = exports.globalArray = [moduleEntity];