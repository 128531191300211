"use strict";

var _interopRequireDefault = require("@babel/runtime/helpers/interopRequireDefault");
Object.defineProperty(exports, "__esModule", {
  value: true
});
Object.defineProperty(exports, "default", {
  enumerable: true,
  get: function get() {
    return _YouTubePlayer["default"];
  }
});
var _YouTubePlayer = _interopRequireDefault(require("./YouTubePlayer"));