"use strict";

var _interopRequireDefault = require("@babel/runtime/helpers/interopRequireDefault");
Object.defineProperty(exports, "__esModule", {
  value: true
});
Object.defineProperty(exports, "component", {
  enumerable: true,
  get: function get() {
    return _RollsRoyceFooter["default"];
  }
});
exports.id = exports.description = void 0;
var _RollsRoyceFooter = _interopRequireDefault(require("./RollsRoyceFooter"));
var id = exports.id = 'RollsRoyceFooter';
var description = exports.description = 'RollsRoyceFooter Module';