"use strict";

var _interopRequireDefault = require("@babel/runtime/helpers/interopRequireDefault");
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.useModelSearch = useModelSearch;
var _toConsumableArray2 = _interopRequireDefault(require("@babel/runtime/helpers/toConsumableArray"));
var _slicedToArray2 = _interopRequireDefault(require("@babel/runtime/helpers/slicedToArray"));
var _react = require("react");
var _reactRedux = require("react-redux");
var _reducer = require("../../../pods/ModelSearch/reducer");
var _filters = require("../../../shared/filters");
var _router = require("../../../actions/router");
function _createForOfIteratorHelper(r, e) { var t = "undefined" != typeof Symbol && r[Symbol.iterator] || r["@@iterator"]; if (!t) { if (Array.isArray(r) || (t = _unsupportedIterableToArray(r)) || e && r && "number" == typeof r.length) { t && (r = t); var _n = 0, F = function F() {}; return { s: F, n: function n() { return _n >= r.length ? { done: !0 } : { done: !1, value: r[_n++] }; }, e: function e(r) { throw r; }, f: F }; } throw new TypeError("Invalid attempt to iterate non-iterable instance.\nIn order to be iterable, non-array objects must have a [Symbol.iterator]() method."); } var o, a = !0, u = !1; return { s: function s() { t = t.call(r); }, n: function n() { var r = t.next(); return a = r.done, r; }, e: function e(r) { u = !0, o = r; }, f: function f() { try { a || null == t["return"] || t["return"](); } finally { if (u) throw o; } } }; }
function _unsupportedIterableToArray(r, a) { if (r) { if ("string" == typeof r) return _arrayLikeToArray(r, a); var t = {}.toString.call(r).slice(8, -1); return "Object" === t && r.constructor && (t = r.constructor.name), "Map" === t || "Set" === t ? Array.from(r) : "Arguments" === t || /^(?:Ui|I)nt(?:8|16|32)(?:Clamped)?Array$/.test(t) ? _arrayLikeToArray(r, a) : void 0; } }
function _arrayLikeToArray(r, a) { (null == a || a > r.length) && (a = r.length); for (var e = 0, n = Array(a); e < a; e++) n[e] = r[e]; return n; }
function useModelSearch(config) {
  var dispatch = (0, _reactRedux.useDispatch)();
  var _useState = (0, _react.useState)([]),
    _useState2 = (0, _slicedToArray2["default"])(_useState, 2),
    selectedTiles = _useState2[0],
    setSelectedTiles = _useState2[1];

  // Model groups retrieved from Model Explore (see config project).
  var _useSelector = (0, _reactRedux.useSelector)(function (state) {
      return state.modelSearch;
    }),
    modelGroups = _useSelector.modelGroups;
  var totalTiles = (0, _react.useMemo)(function () {
    var tilesList = [];
    var _iterator = _createForOfIteratorHelper(config),
      _step;
    try {
      for (_iterator.s(); !(_step = _iterator.n()).done;) {
        var section = _step.value;
        tilesList = tilesList.concat(section.tiles);
      }
    } catch (err) {
      _iterator.e(err);
    } finally {
      _iterator.f();
    }
    return tilesList.map(function (t) {
      return t.tileId;
    }).length;
  }, []);

  // Gets a model groups list from Model Explore.
  var getModelGroups = function getModelGroups() {
    return dispatch(_reducer.actions.getAstonModelGroups());
  };
  var initialiseFilters = function initialiseFilters() {
    return dispatch(_filters.actions.initialiseFilters());
  };
  var clearState = function clearState() {
    return dispatch(_reducer.actions.clearState());
  };
  var clearQueryParams = function clearQueryParams() {
    return dispatch(_router.actions.replace('/search'));
  };
  var getTilesFromConfig = function getTilesFromConfig() {
    var tilesList = [];
    var _iterator2 = _createForOfIteratorHelper(config),
      _step2;
    try {
      for (_iterator2.s(); !(_step2 = _iterator2.n()).done;) {
        var section = _step2.value;
        tilesList = tilesList.concat(section.tiles);
      }
    } catch (err) {
      _iterator2.e(err);
    } finally {
      _iterator2.f();
    }
    return tilesList;
  };

  /** Add or remove tile id from selection.
   * @param tileId Tile id.
   */
  var onTileSelected = function onTileSelected(tileId) {
    setSelectedTiles(function (state) {
      return state.includes(tileId) ? state.filter(function (id) {
        return id !== tileId;
      }) : [].concat((0, _toConsumableArray2["default"])(state), [tileId]);
    });
  };

  /** Checks whether a tile is selected or not.
   *
   * @param tileId Tile id.
   * @returns If tile is selected.
   */
  var isTileSelected = function isTileSelected(tileId) {
    return selectedTiles.includes(tileId);
  };

  /** Check "Select all" state.
   *
   * @returns If "Select all" must be checked.
   */
  var isSelectAllChecked = function isSelectAllChecked() {
    return selectedTiles.length === totalTiles;
  };

  /** Forces the select/deselect action of all tiles.
   *
   * @param checked
   */
  var selectAllTiles = function selectAllTiles(checked) {
    if (!checked) {
      setSelectedTiles([]);
      return;
    }
    setSelectedTiles(getTilesFromConfig().map(function (t) {
      return t.tileId;
    }));
  };

  /** Sets the SRP filters state for building querystring parameters.
   * Uses tile ids to get model groups and variants from config project.
   * @param selection Tile ids list.
   */
  var prepareSRPFilters = function prepareSRPFilters(selection) {
    var tilesList = getTilesFromConfig();
    var filteredTiles = tilesList.filter(function (tile) {
      return selection.includes(tile.tileId);
    });
    var groupModels = filteredTiles.map(function (tile) {
      return tile.groups;
    });
    var groupVariants = filteredTiles.map(function (tile) {
      return tile.models;
    });

    // Get model groups and create an unique array with unique values.
    var mergedGroups = (0, _toConsumableArray2["default"])(new Set(groupModels.flat(1)));
    var mergedVariants = (0, _toConsumableArray2["default"])(new Set(groupVariants.flat(1)));
    dispatch(_filters.actions.updateFilters([{
      key: 'model',
      value: mergedGroups
    }, {
      key: 'modelVariants',
      value: mergedVariants
    }], null, false));
    dispatch(_filters.actions.searchWithFilters());
  };
  return {
    selectedTiles: selectedTiles,
    getModelGroups: getModelGroups,
    initialiseFilters: initialiseFilters,
    clearState: clearState,
    modelGroups: modelGroups,
    prepareSRPFilters: prepareSRPFilters,
    onTileSelected: onTileSelected,
    clearQueryParams: clearQueryParams,
    isTileSelected: isTileSelected,
    selectAllTiles: selectAllTiles,
    isSelectAllChecked: isSelectAllChecked
  };
}