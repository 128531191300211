"use strict";

var _interopRequireDefault = require("@babel/runtime/helpers/interopRequireDefault");
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports["default"] = void 0;
var _styledComponents = _interopRequireDefault(require("styled-components"));
var TableData = _styledComponents["default"].td.withConfig({
  displayName: "TableData",
  componentId: "sc-1lmzaao-0"
})(["vertical-align:", ";border-bottom:", ";padding-left:10px;color:#444444;", ""], function (_ref) {
  var verticalAlign = _ref.verticalAlign;
  return verticalAlign ? verticalAlign : 'inherit';
}, function (_ref2) {
  var hideBottomBorder = _ref2.hideBottomBorder;
  return hideBottomBorder ? 'none' : '1px solid #d8d8d8';
}, function (_ref3) {
  var priceRow = _ref3.priceRow;
  return priceRow && "padding-right: 10px;";
});
var _default = exports["default"] = TableData;