export const constants = {
  SET_FLAGS: 'SET FLAGS',
};

export const actions = {
  setFlags: flags => ({
    type: constants.SET_FLAGS,
    payload: flags,
  }),
};
