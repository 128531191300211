"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.id = exports.constants = exports.actions = void 0;
var _reducerMacros = require("../../utilities/reducerMacros");
var modulePrefix = 'MOD_DATA_PROVIDER';
var constants = exports.constants = (0, _reducerMacros.moduleConstants)(modulePrefix, ['GET_VEHICLE_INFO', 'GET_DATA_SUCCESS', 'CLEAR_DATA_PROVIDER_STATE']);
var id = exports.id = 'DATA_PROVIDER';
var actions = exports.actions = {
  getVehicleInfo: function getVehicleInfo(dataKey) {
    return {
      type: constants.GET_VEHICLE_INFO,
      dataKey: dataKey
    };
  },
  getDataSuccess: function getDataSuccess(payload, dataKey) {
    return {
      type: constants.GET_DATA_SUCCESS,
      payload: payload,
      dataKey: dataKey
    };
  },
  clearDataProviderState: function clearDataProviderState(dataKey) {
    return {
      type: constants.CLEAR_DATA_PROVIDER_STATE,
      dataKey: dataKey
    };
  }
};