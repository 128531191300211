"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports["default"] = void 0;
var _default = exports["default"] = {
  translations: {
    header: 'search results',
    hints: 'Try different or fewer terms, Try more general terms, Check the spelling of your terms',
    noResultMessage: "We're sorry; we couldn't find any pages or documents containing all your search terms.",
    resultsMessage: 'results'
  }
};