"use strict";

var _interopRequireDefault = require("@babel/runtime/helpers/interopRequireDefault");
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.transformCompareVehicle = void 0;
var _defineProperty2 = _interopRequireDefault(require("@babel/runtime/helpers/defineProperty"));
function ownKeys(e, r) { var t = Object.keys(e); if (Object.getOwnPropertySymbols) { var o = Object.getOwnPropertySymbols(e); r && (o = o.filter(function (r) { return Object.getOwnPropertyDescriptor(e, r).enumerable; })), t.push.apply(t, o); } return t; }
function _objectSpread(e) { for (var r = 1; r < arguments.length; r++) { var t = null != arguments[r] ? arguments[r] : {}; r % 2 ? ownKeys(Object(t), !0).forEach(function (r) { (0, _defineProperty2["default"])(e, r, t[r]); }) : Object.getOwnPropertyDescriptors ? Object.defineProperties(e, Object.getOwnPropertyDescriptors(t)) : ownKeys(Object(t)).forEach(function (r) { Object.defineProperty(e, r, Object.getOwnPropertyDescriptor(t, r)); }); } return e; }
var transformCompareVehicle = exports.transformCompareVehicle = function transformCompareVehicle(vehicle) {
  return _objectSpread(_objectSpread({}, vehicle), {}, {
    specification: {
      transmission: vehicle.transmission,
      bodystyle: vehicle.bodystyle,
      engineDetails: {
        cylinders: vehicle.engineDetails.cylinders
      },
      fuelType: vehicle.fuel,
      interior: vehicle.interior,
      exterior: vehicle.exterior,
      odometer: vehicle.odometer,
      capacity: vehicle.engineCapacity,
      secondaryExterior: vehicle.secondaryExterior,
      secondaryInterior: vehicle.secondaryInterior,
      veneer: vehicle.veneer
    },
    retailerInformation: {
      name: vehicle.retailerName,
      id: vehicle.retailerId,
      phone: vehicle.retailerPhone,
      region: vehicle.retailerRegion,
      countryCode: vehicle.retailerCountryCode
    }
  });
};