"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.joinUrl = joinUrl;
var _normalisePath = require("./normalisePath.js");
function joinUrl(base) {
  var baseUrl = new URL(base);
  for (var _len = arguments.length, path = new Array(_len > 1 ? _len - 1 : 0), _key = 1; _key < _len; _key++) {
    path[_key - 1] = arguments[_key];
  }
  baseUrl.pathname = _normalisePath.normalisePath.apply(void 0, [true, baseUrl.pathname].concat(path));
  return baseUrl.href;
}