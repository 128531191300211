"use strict";

var _interopRequireDefault = require("@babel/runtime/helpers/interopRequireDefault");
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.validPhone = exports.validEmail = exports.validCheckboxes = exports.required = void 0;
var _validate = _interopRequireDefault(require("validate.js"));
/* eslint-disable import/extensions */

var required = exports.required = function required(value, translations) {
  return _validate["default"].single(value, {
    presence: {
      allowEmpty: false,
      message: translations.formValidationRequired
    }
  });
};
var validEmail = exports.validEmail = function validEmail(value, translations) {
  return _validate["default"].single(value, {
    presence: {
      allowEmpty: false,
      message: translations.formValidationRequired
    },
    email: {
      message: translations.formValidationEmail
    }
  });
};
var validPhone = exports.validPhone = function validPhone(value, translations) {
  return _validate["default"].single(value, {
    presence: {
      allowEmpty: false,
      message: translations.formValidationRequired
    },
    phone: {
      message: translations.formValidationPhone
    }
  });
};
_validate["default"].validators.isValidCheckboxes = function (value, options) {
  return Object.values(value).some(function (v) {
    return v;
  }) ? undefined : options.message;
};
var validCheckboxes = exports.validCheckboxes = function validCheckboxes(value, translations) {
  return _validate["default"].single(value, {
    isValidCheckboxes: {
      message: translations.formValidationCheckboxes
    }
  });
};