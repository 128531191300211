"use strict";

var _interopRequireDefault = require("@babel/runtime/helpers/interopRequireDefault");
Object.defineProperty(exports, "__esModule", {
  value: true
});
Object.defineProperty(exports, "component", {
  enumerable: true,
  get: function get() {
    return _DealerNotFound["default"];
  }
});
exports.id = exports.description = void 0;
var _DealerNotFound = _interopRequireDefault(require("./DealerNotFound"));
var id = exports.id = 'DealerNotFound';
var description = exports.description = 'Dealer Not Found Module';