"use strict";

var _interopRequireDefault = require("@babel/runtime/helpers/interopRequireDefault");
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports["default"] = void 0;
var _ModelVariantTile = _interopRequireDefault(require("./ModelVariantTile"));
var _default = exports["default"] = {
  ModelVariantTile: _ModelVariantTile["default"]
};