"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.useTimeout = useTimeout;
var _react = require("react");
function useTimeout(callback, delay) {
  var id;
  var clearTimer = function clearTimer() {
    clearTimeout(id);
  };
  (0, _react.useEffect)(function () {
    if (delay !== null) {
      id = setTimeout(callback, delay);
    }
    return function () {
      return clearTimer();
    };
  }, [delay]);
  return clearTimer;
}