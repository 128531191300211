"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports["default"] = void 0;
var _default = exports["default"] = {
  retailerInformation: {
    location: {
      coordinates: [48.186869, 11.5496183]
    },
    name: 'AVALON PREMIUM CARS GMBH',
    address: ['Moosacherstrasse', '58', '80809', 'Munich'],
    country: 'spain',
    phone: '57348573489',
    fax: '57348573489',
    email: 'email@retailerinfo.com',
    openClosingTimes: ['8am', '6pm']
  }
};