"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.config = void 0;
/* eslint-disable quote-props, quotes, import/prefer-default-export */

var config = exports.config = [{
  id: '53767',
  name: '2014 XF',
  odometer: {
    reading: 33120,
    units: 'Km'
  },
  engine: '2.2 i4 Diesel',
  transmission: '8 Speed Automatic',
  fuel: 'Diesel',
  price: {
    currency: 'INR',
    value: 4200000,
    monthly: 550,
    apr: 6.9
  },
  priceFull: 0,
  priceText: 'TODO',
  priceMonthly: 550,
  monthlyText: 'TODO',
  priceApr: 6.9,
  aprText: 'TODO',
  locale: 'de-DE',
  currencyType: 'INR',
  performance: {
    emissions: '0'
  },
  distanceFromRetailer: 0,
  retailerName: 'Marqland',
  images: ['http://images-imsuat.motortrak.com/S/SAJ/AC0/5V8/EVT/014/17/1024/SAJAC05V8EVT01417_1.jpg', 'http://images-imsuat.motortrak.com/S/SAJ/AC0/5V8/EVT/014/17/1024/SAJAC05V8EVT01417_2.jpg', 'http://images-imsuat.motortrak.com/S/SAJ/AC0/5V8/EVT/014/17/1024/SAJAC05V8EVT01417_0_9CBAA64481B9DFEC.jpg', 'http://images-imsuat.motortrak.com/S/SAJ/AC0/5V8/EVT/014/17/1024/SAJAC05V8EVT01417_0_784A90D1C17FA609.jpg', 'http://images-imsuat.motortrak.com/S/SAJ/AC0/5V8/EVT/014/17/1024/SAJAC05V8EVT01417_0_C04E62D16CBADC15.jpg', 'http://images-imsuat.motortrak.com/S/SAJ/AC0/5V8/EVT/014/17/1024/SAJAC05V8EVT01417_0_14E8C5EEC8BA2EFD.jpg', 'http://images-imsuat.motortrak.com/S/SAJ/AC0/5V8/EVT/014/17/1024/SAJAC05V8EVT01417_0_D9B7792A8ADAEDAE.jpg', 'http://images-imsuat.motortrak.com/S/SAJ/AC0/5V8/EVT/014/17/1024/SAJAC05V8EVT01417_0_BE04600ACA21E3A7.jpg', 'http://images-imsuat.motortrak.com/S/SAJ/AC0/5V8/EVT/014/17/1024/SAJAC05V8EVT01417_0_C064EF0073B8AF2F.jpg'],
  numberAvailable: 0,
  selected: false,
  modelTheme: {
    colour: '',
    textTransform: '',
    characterSpacing: '',
    fontSize: '',
    fontFamily: ''
  },
  priceTheme: {
    colour: '',
    textTransform: '',
    characterSpacing: '',
    fontSize: '',
    fontFamily: ''
  },
  numberAvailableTheme: {
    colour: '',
    textTransform: '',
    characterSpacing: '',
    fontSize: '',
    fontFamily: ''
  },
  financeTextTheme: {
    colour: '',
    textTransform: '',
    characterSpacing: '',
    fontSize: '',
    fontFamily: ''
  },
  align: 'left',
  views: 0
}];