"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports["default"] = void 0;
var _default = exports["default"] = {
  title: 'Finance',
  links: [{
    text: 'Finance Explained',
    path: 'FinanceExplained',
    availableOnMobile: true,
    active: true
  }, {
    text: 'Budget Search',
    path: 'BudgetSearch',
    availableOnMobile: true
  }]
};