"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.normalisePages = exports.normalisePage = exports.normaliseGlobal = void 0;
var _normalizr = require("normalizr");
var _configSchema = require("./configSchema");
var normalisePage = exports.normalisePage = function normalisePage(page) {
  var _normalize = (0, _normalizr.normalize)(page, _configSchema.pageEntity),
    pages = _normalize.entities.pages;
  return Object.values(pages)[0];
};
var normalisePages = exports.normalisePages = function normalisePages(pages) {
  return (0, _normalizr.normalize)(pages, _configSchema.pageArray);
};
var normaliseGlobal = exports.normaliseGlobal = function normaliseGlobal(modules) {
  return (0, _normalizr.normalize)(modules, _configSchema.globalArray);
};