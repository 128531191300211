"use strict";

var _interopRequireDefault = require("@babel/runtime/helpers/interopRequireDefault");
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.initialState = exports.constants = exports.actions = void 0;
exports.reducer = reducer;
var _defineProperty2 = _interopRequireDefault(require("@babel/runtime/helpers/defineProperty"));
var _isEmpty = _interopRequireDefault(require("ramda/src/isEmpty"));
var _omit = _interopRequireDefault(require("ramda/src/omit"));
function ownKeys(e, r) { var t = Object.keys(e); if (Object.getOwnPropertySymbols) { var o = Object.getOwnPropertySymbols(e); r && (o = o.filter(function (r) { return Object.getOwnPropertyDescriptor(e, r).enumerable; })), t.push.apply(t, o); } return t; }
function _objectSpread(e) { for (var r = 1; r < arguments.length; r++) { var t = null != arguments[r] ? arguments[r] : {}; r % 2 ? ownKeys(Object(t), !0).forEach(function (r) { (0, _defineProperty2["default"])(e, r, t[r]); }) : Object.getOwnPropertyDescriptors ? Object.defineProperties(e, Object.getOwnPropertyDescriptors(t)) : ownKeys(Object(t)).forEach(function (r) { Object.defineProperty(e, r, Object.getOwnPropertyDescriptor(t, r)); }); } return e; }
var constants = exports.constants = {
  INITIALISE_FILTERS: 'INITIALISE_FILTERS',
  LOAD_FILTERS_SUCCESS: 'LOAD_FILTERS_SUCCESS',
  UPDATE_FILTERS: 'UPDATE_FILTERS',
  UPDATE_FILTERS_STORE: 'UPDATE_FILTERS_STORE',
  RESET_FILTERS: 'RESET_FILTERS',
  SEARCH_WITH_FILTERS: 'SEARCH_WITH_FILTERS',
  GET_INVENTORY_SUCCESS: 'GET_INVENTORY_SUCCESS',
  GET_DEALER_SUCCESS: 'GET_DEALER_SUCCESS',
  CHANGE_LOCATION: 'CHANGE_LOCATION',
  RESET_PRICE_SLIDER_VALUES: 'RESET_PRICE_SLIDER_VALUES'
};
var actions = exports.actions = {
  getInventorySuccess: function getInventorySuccess(payload) {
    return {
      type: constants.GET_INVENTORY_SUCCESS,
      payload: payload
    };
  },
  getDealerSuccess: function getDealerSuccess(payload) {
    return {
      type: constants.GET_DEALER_SUCCESS,
      payload: payload
    };
  },
  initialiseFilters: function initialiseFilters(queryStringFromUrl, urlConfig) {
    return {
      type: constants.INITIALISE_FILTERS,
      payload: {
        queryStringFromUrl: queryStringFromUrl,
        urlConfig: urlConfig
      }
    };
  },
  loadFiltersSuccess: function loadFiltersSuccess(payload) {
    return {
      type: constants.LOAD_FILTERS_SUCCESS,
      payload: payload
    };
  },
  updateFilters: function updateFilters(filters, urlConfig) {
    var reloadData = arguments.length > 2 && arguments[2] !== undefined ? arguments[2] : true;
    return {
      type: constants.UPDATE_FILTERS,
      payload: {
        filters: filters,
        urlConfig: urlConfig,
        reloadData: reloadData
      }
    };
  },
  updateFiltersStore: function updateFiltersStore(payload) {
    return {
      type: constants.UPDATE_FILTERS_STORE,
      payload: payload
    };
  },
  resetFilters: function resetFilters(payload) {
    return {
      type: constants.RESET_FILTERS,
      payload: payload
    };
  },
  searchWithFilters: function searchWithFilters(payload) {
    return {
      type: constants.SEARCH_WITH_FILTERS,
      payload: payload
    };
  },
  changeLocation: function changeLocation(payload) {
    return {
      type: constants.CHANGE_LOCATION,
      payload: payload
    };
  },
  resetPriceSliderValues: function resetPriceSliderValues(payload) {
    return {
      type: constants.RESET_PRICE_SLIDER_VALUES,
      payload: payload
    };
  }
};
var arrayFilters = ['bodystyle', 'model', 'variant', 'vehicleTypes', 'engine', 'transmission', 'exteriorColours', 'interiorShades', 'features', 'fuelType', 'subregions', 'modelVariants'];
var countFilters = function countFilters(selectedFilters) {
  var exclude = ['yearRangeMin', 'minPriceRange', 'latitude', 'sortField', 'sortDirection', 'retailerId'];
  return Object.keys(selectedFilters).filter(function (f) {
    return !exclude.includes(f);
  }).length;
};
var transformFilters = function transformFilters(_ref) {
  var selectedFilters = _ref.selectedFilters,
    availableFilterValues = _ref.availableFilterValues;
  var transformedFilters = Object.entries(selectedFilters).map(function (entry) {
    if (arrayFilters.some(function (arrayFilter) {
      return arrayFilter === entry[0];
    })) {
      return typeof entry[1] === 'string' ? [entry[0], [entry[1]]] : entry;
    }
    return entry;
  }).reduce(function (acc, curr) {
    return _objectSpread(_objectSpread({}, acc), {}, (0, _defineProperty2["default"])({}, curr[0], curr[1]));
  }, {});
  return {
    availableFilterValues: availableFilterValues,
    selectedFilters: _objectSpread({}, transformedFilters),
    activeFiltersCount: countFilters(transformedFilters)
  };
};
var initialState = exports.initialState = {
  selectedFilters: null,
  availableFilterValues: null,
  activeFiltersCount: 0,
  subregionsChanged: false
};
function reducer() {
  var state = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : initialState;
  var action = arguments.length > 1 ? arguments[1] : undefined;
  var retailerId = state.shared && state.shared.filters.selectedFilters && state.shared.filters.selectedFilters.retailerId;
  var subregionsChanged = action.payload && action.payload.selectedFilters && !!action.payload.selectedFilters.subregions;
  var payload = action.payload,
    type = action.type;
  switch (type) {
    case constants.GET_DEALER_SUCCESS:
      return _objectSpread(_objectSpread({}, state), {}, {
        dealer: payload
      });
    case constants.LOAD_FILTERS_SUCCESS:
      return _objectSpread(_objectSpread(_objectSpread({}, state), transformFilters(payload)), {}, {
        total: payload.resultsCount,
        subregionsChanged: subregionsChanged
      });
    case constants.UPDATE_FILTERS_STORE:
      {
        var updatedState = state;
        for (var idx = 0; idx < payload.length; idx += 1) {
          var data = payload[idx];
          updatedState = data.value === undefined || data.value === null || (0, _isEmpty["default"])(data.value) ? _objectSpread(_objectSpread({}, updatedState), {}, {
            selectedFilters: (0, _omit["default"])([data.key], _objectSpread({}, updatedState.selectedFilters))
          }) : _objectSpread(_objectSpread({}, updatedState), {}, {
            selectedFilters: _objectSpread(_objectSpread({}, updatedState.selectedFilters), {}, (0, _defineProperty2["default"])({}, data.key, data.value))
          });
        }
        return _objectSpread(_objectSpread({}, updatedState), {}, {
          activeFiltersCount: countFilters(updatedState.selectedFilters),
          total: state.total,
          subregionsChanged: subregionsChanged
        });
      }
    case constants.RESET_FILTERS:
      {
        // All brands that handle subregions, will keep them as they are
        var keepRegions = state.selectedFilters && state.selectedFilters.subregions;
        return _objectSpread(_objectSpread({}, state), {}, {
          selectedFilters: retailerId ? _objectSpread({
            retailerId: retailerId
          }, payload) : keepRegions ? _objectSpread(_objectSpread({}, payload), {}, {
            subregions: state.selectedFilters.subregions
          }) : _objectSpread({}, payload),
          activeFiltersCount: 0,
          location: '',
          subregionsChanged: false
        });
      }
    case constants.CHANGE_LOCATION:
      return _objectSpread(_objectSpread({}, state), {}, {
        location: payload
      });
    default:
      return state;
  }
}