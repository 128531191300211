"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.useVehicleActions = exports["default"] = void 0;
var _reactRedux = require("react-redux");
var _router = require("../actions/router");
var useVehicleActions = exports.useVehicleActions = function useVehicleActions() {
  var dispatch = (0, _reactRedux.useDispatch)();
  var navigateTo = function navigateTo(path, vehicleId) {
    return dispatch(_router.actions.navigate("/".concat(path, "/").concat(vehicleId)));
  };
  var navigateToBookTestDrive = function navigateToBookTestDrive(vehicleId) {
    return navigateTo('testdrive', vehicleId);
  };
  var navigateToEnquiry = function navigateToEnquiry(vehicleId) {
    return navigateTo('enquiry', vehicleId);
  };
  return {
    navigateToBookTestDrive: navigateToBookTestDrive,
    navigateToEnquiry: navigateToEnquiry
  };
};
var _default = exports["default"] = useVehicleActions;