"use strict";

var _interopRequireDefault = require("@babel/runtime/helpers/interopRequireDefault");
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports["default"] = void 0;
exports.useWindowSize = useWindowSize;
var _slicedToArray2 = _interopRequireDefault(require("@babel/runtime/helpers/slicedToArray"));
var _react = require("react");
function useWindowSize() {
  var initState = {
    width: undefined,
    height: undefined
  };
  var _useState = (0, _react.useState)(initState),
    _useState2 = (0, _slicedToArray2["default"])(_useState, 2),
    windowSize = _useState2[0],
    setWindowSize = _useState2[1];
  (0, _react.useEffect)(function () {
    function handleResize() {
      setWindowSize({
        width: window.innerWidth,
        height: window.innerHeight
      });
    }
    window.addEventListener('resize', handleResize);
    handleResize();
    return function () {
      return window.removeEventListener('resize', handleResize);
    };
  }, []);
  return windowSize;
}
var _default = exports["default"] = useWindowSize;