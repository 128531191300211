"use strict";

var _interopRequireDefault = require("@babel/runtime/helpers/interopRequireDefault");
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.titleOptions = exports.buildTitleOptions = void 0;
var _toConsumableArray2 = _interopRequireDefault(require("@babel/runtime/helpers/toConsumableArray"));
var titleOption = function titleOption(value) {
  return {
    label: value,
    value: value
  };
};
var titleOptions = exports.titleOptions = function titleOptions(translations) {
  var formTitleSelect = translations.formTitleSelect,
    formTitleMr = translations.formTitleMr,
    formTitleMrs = translations.formTitleMrs,
    formTitleMs = translations.formTitleMs,
    formTitleMiss = translations.formTitleMiss,
    formTitleLady = translations.formTitleLady,
    formTitleLord = translations.formTitleLord,
    formTitleDr = translations.formTitleDr,
    formTitleProfessor = translations.formTitleProfessor,
    formTitleReverand = translations.formTitleReverand,
    formTitleSir = translations.formTitleSir,
    formTitleDame = translations.formTitleDame;
  return [{
    label: formTitleSelect,
    value: ''
  }, titleOption(formTitleMr), titleOption(formTitleMrs), titleOption(formTitleMiss), titleOption(formTitleMs), titleOption(formTitleLady), titleOption(formTitleLord), titleOption(formTitleDr), titleOption(formTitleProfessor), titleOption(formTitleReverand), titleOption(formTitleSir), titleOption(formTitleDame)].filter(function (_ref) {
    var label = _ref.label;
    return label && label.trim();
  });
};
var brandTitles = {
  astonmartin: ['formTitleMr', 'formTitleMrs', 'formTitleMs', 'formTitleMx', 'formTitleOther'],
  bentley: ['formTitleMr', 'formTitleMrs', 'formTitleMs', 'formTitleMiss', 'formTitleLady', 'formTitleLord', 'formTitleDr', 'formTitleProfessor', 'formTitleReverand', 'formTitleSir', 'formTitleDame']
};

/**
 * Title options per brand
 */
var buildTitleOptions = exports.buildTitleOptions = function buildTitleOptions(translations, brandName) {
  var titles = [];
  var brandTitlesList = brandTitles[brandName];
  brandTitlesList.forEach(function (title) {
    var translatedTitle = translations[title];
    if (translatedTitle) {
      titles.push({
        label: translatedTitle,
        value: brandName === 'astonmartin' ? title : translatedTitle
      });
    }
  });
  var options = (0, _toConsumableArray2["default"])(new Set(titles.map(function (t) {
    return t.value;
  }))); // Remove duplicates

  return options.map(function (value) {
    return titles.find(function (t) {
      return t.value === value;
    });
  }).filter(function (_ref2) {
    var label = _ref2.label;
    return label && label.trim();
  });
};