"use strict";

var _interopRequireDefault = require("@babel/runtime/helpers/interopRequireDefault");
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.initialState = exports.constants = exports.actions = void 0;
exports.reducer = reducer;
var _toConsumableArray2 = _interopRequireDefault(require("@babel/runtime/helpers/toConsumableArray"));
var _defineProperty2 = _interopRequireDefault(require("@babel/runtime/helpers/defineProperty"));
var _reducerMacros = require("../../utilities/reducerMacros");
var _numbers = require("../localisation/numbers");
var _helpers = require("../currencyConversion/helpers");
function ownKeys(e, r) { var t = Object.keys(e); if (Object.getOwnPropertySymbols) { var o = Object.getOwnPropertySymbols(e); r && (o = o.filter(function (r) { return Object.getOwnPropertyDescriptor(e, r).enumerable; })), t.push.apply(t, o); } return t; }
function _objectSpread(e) { for (var r = 1; r < arguments.length; r++) { var t = null != arguments[r] ? arguments[r] : {}; r % 2 ? ownKeys(Object(t), !0).forEach(function (r) { (0, _defineProperty2["default"])(e, r, t[r]); }) : Object.getOwnPropertyDescriptors ? Object.defineProperties(e, Object.getOwnPropertyDescriptors(t)) : ownKeys(Object(t)).forEach(function (r) { Object.defineProperty(e, r, Object.getOwnPropertyDescriptor(t, r)); }); } return e; }
var modulePrefix = 'MOD_COMPARE_SHARED';
var constants = exports.constants = (0, _reducerMacros.moduleConstants)(modulePrefix, ['TOGGLE_VEHICLE', 'TOGGLE_VEHICLE_SUCCESS', 'CHECK_VEHICLE_STATUS', 'CHECK_VEHICLE_STATUS_SUCCESS', 'UPDATE_VEHICLE_PRICES', 'GET_EQUIPMENT_LIST', 'GET_EQUIPMENT_LIST_SUCCESS']);
var actions = exports.actions = {
  toggleVehicle: function toggleVehicle(payload) {
    return {
      type: constants.TOGGLE_VEHICLE,
      payload: payload
    };
  },
  toggleVehicleSuccess: function toggleVehicleSuccess(payload) {
    return {
      type: constants.TOGGLE_VEHICLE_SUCCESS,
      payload: payload
    };
  },
  checkVehicleStatus: function checkVehicleStatus(locale, translations) {
    return {
      type: constants.CHECK_VEHICLE_STATUS,
      payload: {
        locale: locale,
        translations: translations
      }
    };
  },
  checkVehicleStatusSuccess: function checkVehicleStatusSuccess(payload) {
    return {
      type: constants.CHECK_VEHICLE_STATUS_SUCCESS,
      payload: payload
    };
  },
  updateVehiclePrices: function updateVehiclePrices(payload) {
    return {
      type: constants.UPDATE_VEHICLE_PRICES,
      payload: payload
    };
  },
  getEquipmentList: function getEquipmentList() {
    return {
      type: constants.GET_EQUIPMENT_LIST
    };
  },
  getEquipmentListSuccess: function getEquipmentListSuccess(payload) {
    return {
      type: constants.GET_EQUIPMENT_LIST_SUCCESS,
      payload: payload
    };
  }
};
var updateVehicleCurrencies = function updateVehicleCurrencies(state, action) {
  var payload = action.payload;
  var exchangeRates = payload.exchangeRates,
    statePath = payload.statePath,
    currency = payload.currency;
  if (!exchangeRates[currency]) {
    return state;
  }
  var vehiclesToUpdate = state[statePath] || state.vehicles;
  var vehicles = (0, _helpers.convertVehiclesPriceCurrency)(vehiclesToUpdate, exchangeRates[currency].rates, currency);
  return _objectSpread(_objectSpread({}, state), {}, {
    vehicles: vehicles
  });
};
var initialState = exports.initialState = {
  vehicles: [],
  equipmentList: []
};
var maxVehiclesToCompare = 3;
var take = function take(n) {
  return function (collection) {
    return collection.slice(0, n);
  };
};
var takeOnly = take(maxVehiclesToCompare);
var patch = function patch(vehicles, vehicle) {
  var formattedVehicle = _objectSpread(_objectSpread({}, vehicle), {}, {
    price: {
      value: (0, _numbers.localiseCurrency)(vehicle.price.value, vehicle.locale, vehicle.price.currency)
    }
  });
  return takeOnly((0, _toConsumableArray2["default"])(vehicles.find(function (v) {
    return v.id === vehicle.id;
  }) ? (0, _toConsumableArray2["default"])(vehicles.filter(function (v) {
    return v.id !== vehicle.id;
  })) : [].concat((0, _toConsumableArray2["default"])(vehicles), [formattedVehicle])));
};
function reducer() {
  var state = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : initialState;
  var action = arguments.length > 1 ? arguments[1] : undefined;
  var payload = action.payload,
    type = action.type;
  switch (type) {
    case constants.TOGGLE_VEHICLE_SUCCESS:
      return _objectSpread(_objectSpread({}, state), {}, (0, _defineProperty2["default"])({}, payload.statePath, patch(state[payload.statePath] || [], payload.vehicle)));
    case constants.CHECK_VEHICLE_STATUS_SUCCESS:
      return _objectSpread(_objectSpread({}, state), {}, (0, _defineProperty2["default"])({}, payload.statePath, (0, _toConsumableArray2["default"])(payload.vehicles)));
    case constants.UPDATE_VEHICLE_PRICES:
      return updateVehicleCurrencies(state, action);
    case constants.GET_EQUIPMENT_LIST_SUCCESS:
      return _objectSpread(_objectSpread({}, state), {}, {
        equipmentList: payload
      });
    default:
      return state;
  }
}