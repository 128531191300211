"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.rangePayloads = exports.generateRangeUrl = exports.generateMaxDistanceUrl = exports.generateLocationUrl = exports.generateCheckboxFilterUrl = exports.engineSizeValueDown = void 0;
var engineSizeValueUp = function engineSizeValueUp(engineSize) {
  return "".concat(engineSize.replace(/\D/g, ''), "00");
};
var engineSizeValueDown = exports.engineSizeValueDown = function engineSizeValueDown(engineSize, option) {
  return option ? "".concat(engineSize.toString()[0], ",").concat(engineSize.toString()[1], " L") : "".concat(engineSize.toString()[0], ".").concat(engineSize.toString()[1], "L");
};
var generateCheckboxFilterUrl = exports.generateCheckboxFilterUrl = function generateCheckboxFilterUrl(selectedCheckboxFilters) {
  var keys = Object.keys(selectedCheckboxFilters);
  return keys.map(function (key) {
    return selectedCheckboxFilters[key].map(function (filter) {
      return "".concat(key, "=").concat(key === 'engineSizes' ? engineSizeValueUp(filter) : filter, "&");
    }).join('');
  }).join('');
};
var rangePayloads = exports.rangePayloads = {
  mileageRange: ['MinMileage', 'MaxMileage'],
  yearRange: ['MinYear', 'MaxYear'],
  priceRange: ['MinPrice', 'MaxPrice'],
  monthlyPaymentRange: ['MinMonthlyPayment', 'MaxMonthlyPayment']
};
var generateRangeUrl = exports.generateRangeUrl = function generateRangeUrl(rangeFilters) {
  var keys = Object.keys(rangeFilters);
  return keys.filter(function (key) {
    return rangeFilters[key].active;
  }).map(function (key) {
    return rangePayloads[key].map(function (filterName, index) {
      return "".concat(filterName, "=").concat(!index ? rangeFilters[key].min : rangeFilters[key].max, "&");
    }).join('');
  }).join('');
};
var generateLocationUrl = exports.generateLocationUrl = function generateLocationUrl(location) {
  var lat = location.lat,
    _long = location["long"];
  return lat && _long ? "Latitude=".concat(lat, "&Longitude=").concat(_long, "&") : '';
};
var generateMaxDistanceUrl = exports.generateMaxDistanceUrl = function generateMaxDistanceUrl(maxDistance) {
  return maxDistance && maxDistance !== 'all' ? "MaxDistance=".concat(maxDistance, "&") : '';
};