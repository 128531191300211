"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.useChangeReleaseFlag = exports.getEnviromentFromUrl = void 0;
var _reactRedux = require("react-redux");
var getEnviromentFromUrl = exports.getEnviromentFromUrl = function getEnviromentFromUrl() {
  var authenticatedEnvs = ['qa', 'dev', 'demo', 'localhost'];
  return authenticatedEnvs.find(function (e) {
    return window.location.hostname.includes(e);
  }) || 'prod';
};
var useChangeReleaseFlag = exports.useChangeReleaseFlag = function useChangeReleaseFlag(id) {
  var _useSelector = (0, _reactRedux.useSelector)(function (state) {
      return state.flags;
    }),
    changeFlags = _useSelector.changeFlags;
  var feature = changeFlags && changeFlags[id];
  var env = getEnviromentFromUrl();
  return !!feature && feature[env === 'localhost' ? 'dev' : env];
};