"use strict";

var _interopRequireDefault = require("@babel/runtime/helpers/interopRequireDefault");
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.screenSizeOptions = exports.mixins = exports["default"] = void 0;
var _taggedTemplateLiteral2 = _interopRequireDefault(require("@babel/runtime/helpers/taggedTemplateLiteral"));
var _styledComponents = require("styled-components");
var _templateObject, _templateObject2, _templateObject3, _templateObject4, _templateObject5, _templateObject6, _templateObject7, _templateObject8, _templateObject9, _templateObject10;
/* eslint-disable */
var screenSizeOptions = exports.screenSizeOptions = {
  small: 767,
  medium: 1023,
  large: 1399,
  extraLarge: 1535,
  xxl: 1919
};
var generateMediaQueries = function generateMediaQueries(widthType) {
  return Object.keys(screenSizeOptions).reduce(function (acc, label) {
    acc[label] = function () {
      return (0, _styledComponents.css)(["@media screen and (", "-width:", "px ){", ";}"], widthType, screenSizeOptions[label], _styledComponents.css.apply(void 0, arguments));
    };
    return acc;
  }, {});
};
var media = {
  min: generateMediaQueries('min'),
  max: generateMediaQueries('max')
};
var pageGuttering = function pageGuttering() {
  return (0, _styledComponents.css)(["box-sizing:border-box;padding:0 20px;", ";", ";", ";", ";", ";"], media.min.small(_templateObject || (_templateObject = (0, _taggedTemplateLiteral2["default"])(["\n    padding: 0 40px;\n  "]))), media.min.medium(_templateObject2 || (_templateObject2 = (0, _taggedTemplateLiteral2["default"])(["\n    padding: 0 76px;\n  "]))), media.min.large(_templateObject3 || (_templateObject3 = (0, _taggedTemplateLiteral2["default"])(["\n    padding: 0 80px;\n  "]))), media.min.extraLarge(_templateObject4 || (_templateObject4 = (0, _taggedTemplateLiteral2["default"])(["\n    padding: 0 100px;\n  "]))), media.min.xxl(_templateObject5 || (_templateObject5 = (0, _taggedTemplateLiteral2["default"])(["\n    padding: 0 140px;\n  "]))));
};
var heroGuttering = function heroGuttering() {
  return (0, _styledComponents.css)(["box-sizing:border-box;padding:0 30px;", ";", ";", ";", ";", ";"], media.min.small(_templateObject6 || (_templateObject6 = (0, _taggedTemplateLiteral2["default"])(["\n    padding: 0 58px;\n  "]))), media.min.medium(_templateObject7 || (_templateObject7 = (0, _taggedTemplateLiteral2["default"])(["\n    padding: 0 76px;\n  "]))), media.min.large(_templateObject8 || (_templateObject8 = (0, _taggedTemplateLiteral2["default"])(["\n    padding: 0 224px;\n  "]))), media.min.extraLarge(_templateObject9 || (_templateObject9 = (0, _taggedTemplateLiteral2["default"])(["\n    padding: 0 250px;\n  "]))), media.min.xxl(_templateObject10 || (_templateObject10 = (0, _taggedTemplateLiteral2["default"])(["\n    padding: 0 338px;\n  "]))));
};
var mixins = exports.mixins = {
  pageGuttering: pageGuttering,
  heroGuttering: heroGuttering
};
var _default = exports["default"] = media;