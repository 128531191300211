export const constants = {
  CONFIG_LOAD: 'CONFIG_LOAD',
  SETTINGS_LOAD: 'SETTINGS_LOAD',
  INITIALISED: 'INITIALISED',
};

export const actions = {
  loadConfig: config => ({
    type: constants.CONFIG_LOAD,
    payload: config,
  }),
  loadSettings: settings => ({
    type: constants.SETTINGS_LOAD,
    payload: settings,
  }),
  initialised: () => ({
    type: constants.INITIALISED,
  }),
};
