"use strict";

var _interopRequireDefault = require("@babel/runtime/helpers/interopRequireDefault");
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.useDealerInfo = exports["default"] = void 0;
var _slicedToArray2 = _interopRequireDefault(require("@babel/runtime/helpers/slicedToArray"));
var _reactRedux = require("react-redux");
var _routing = require("../shared/routing");
var _react = require("react");
var useDealerInfo = exports.useDealerInfo = function useDealerInfo() {
  var _useState = (0, _react.useState)(null),
    _useState2 = (0, _slicedToArray2["default"])(_useState, 2),
    dealerInfo = _useState2[0],
    setDealerInfo = _useState2[1];
  var isDealer = (0, _routing.isDealerPage)();

  // Gets the Dealer's page state based on url.
  var page = (0, _reactRedux.useSelector)(function (state) {
    return state.pages["/".concat((0, _routing.getDealerNameFromUrl)())];
  });
  (0, _react.useEffect)(function () {
    if (isDealer && dealerInfo === null) {
      if (page) {
        var dealer = page[Object.keys(page)[0]].dealer;
        if (dealer) {
          //@ts-ignore: TODO, we need to support types in useState.
          setDealerInfo(dealer);
        }
      }
    }
  }, [dealerInfo, isDealer, page, setDealerInfo]);
  return {
    dealerInfo: dealerInfo,
    isDealer: isDealer
  };
};
var _default = exports["default"] = useDealerInfo;