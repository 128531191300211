"use strict";

var _interopRequireDefault = require("@babel/runtime/helpers/interopRequireDefault");
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.SeparatedListTitle = exports.SeparatedListItem = exports.SeparatedListContainer = exports.SeparatedList = void 0;
var _taggedTemplateLiteral2 = _interopRequireDefault(require("@babel/runtime/helpers/taggedTemplateLiteral"));
var _styledComponents = _interopRequireDefault(require("styled-components"));
var _theme = _interopRequireDefault(require("../theme"));
var _templateObject;
var SeparatedListContainer = exports.SeparatedListContainer = _styledComponents["default"].div.withConfig({
  displayName: 'ListContainer'
}).withConfig({
  displayName: "SeparatedList__SeparatedListContainer",
  componentId: "sc-smx607-0"
})(["font-size:16px;line-height:22px;color:#343434;break-inside:avoid;display:inline;"]);
var SeparatedListTitle = exports.SeparatedListTitle = _styledComponents["default"].div.withConfig({
  displayName: 'ListTitle'
}).withConfig({
  displayName: "SeparatedList__SeparatedListTitle",
  componentId: "sc-smx607-1"
})(["font-weight:600;font-size:16px;line-height:19px;color:#444444;margin-bottom:27px;"]);
var SeparatedList = exports.SeparatedList = _styledComponents["default"].ul.withConfig({
  displayName: 'List'
}).withConfig({
  displayName: "SeparatedList",
  componentId: "sc-smx607-2"
})(["list-style:none;padding:0;margin:0 0 21px 0;", ";"], _theme["default"].max.large(_templateObject || (_templateObject = (0, _taggedTemplateLiteral2["default"])(["\n    margin: 0;\n  "]))));
var SeparatedListItem = exports.SeparatedListItem = _styledComponents["default"].li.withConfig({
  displayName: 'ListItem'
}).withConfig({
  displayName: "SeparatedList__SeparatedListItem",
  componentId: "sc-smx607-3"
})(["padding:0 0 20px 10px;font-size:16px;", ";page-break-inside:avoid;break-inside:avoid;"], function (_ref) {
  var flex = _ref.flex;
  return flex && 'display: flex';
});