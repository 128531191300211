"use strict";

var _interopRequireDefault = require("@babel/runtime/helpers/interopRequireDefault");
Object.defineProperty(exports, "__esModule", {
  value: true
});
Object.defineProperty(exports, "component", {
  enumerable: true,
  get: function get() {
    return _Band["default"];
  }
});
exports.name = exports.id = exports.description = void 0;
var _Band = _interopRequireDefault(require("./Band"));
var name = exports.name = 'Band';
var id = exports.id = 'Band';
var description = exports.description = 'Band Module';