"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.pathWithDealerID = exports.pathWithDealerGroup = void 0;
var pathWithDealerID = exports.pathWithDealerID = function pathWithDealerID(path, retailerName, retailerId, language, configDealerUrl) {
  var defaultDealerUrl = "dealer_#retailerId#_#retailerName##path#";
  var dealerUrl = !configDealerUrl ? defaultDealerUrl : configDealerUrl;
  return dealerUrl.replace("#retailerId#", retailerId || '').replace("#retailerName#", retailerName.toLowerCase().replace(/ /g, '_') || '').replace("#language#", language || '').replace("#path#", path || '');
};
var pathWithDealerGroup = exports.pathWithDealerGroup = function pathWithDealerGroup(path, retailer) {
  return "group_".concat(encodeURI(retailer.group)).concat(path);
};