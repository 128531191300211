"use strict";

var _interopRequireDefault = require("@babel/runtime/helpers/interopRequireDefault");
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports["default"] = void 0;
var _DefaultTemplate = _interopRequireDefault(require("./DefaultTemplate"));
var _default = exports["default"] = {
  DefaultTemplate: _DefaultTemplate["default"]
};