"use strict";

var _interopRequireDefault = require("@babel/runtime/helpers/interopRequireDefault");
Object.defineProperty(exports, "__esModule", {
  value: true
});
Object.defineProperty(exports, "actions", {
  enumerable: true,
  get: function get() {
    return _reducer.actions;
  }
});
exports["default"] = void 0;
Object.defineProperty(exports, "effects", {
  enumerable: true,
  get: function get() {
    return _effects["default"];
  }
});
Object.defineProperty(exports, "reducer", {
  enumerable: true,
  get: function get() {
    return _reducer.reducer;
  }
});
var _DealerLocator = _interopRequireDefault(require("./DealerLocator"));
var _effects = _interopRequireDefault(require("./effects"));
var _reducer = require("./reducer");
var _default = exports["default"] = _DealerLocator["default"];