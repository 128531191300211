/* eslint-disable import/no-unresolved, import/extensions  */
import { actions } from 'cms-modules';

const {
  router: { constants },
} = actions;

const initialState = {
  path: '/',
  data: null,
  navigationHistory: [],
};

const paramsToObject = entries => {
  const result = {};
  // eslint-disable-next-line no-restricted-syntax
  for (const [key, value] of entries) {
    result[key] = value;
  }
  return result;
};

export default function reducer(state = initialState, action) {
  switch (action.type) {
    case constants.historyCreated: {
      const { history, basePath } = action.payload;
      return {
        ...state,
        history,
        basePath,
      };
    }
    case constants.navigateSuccess: {
      const {
        pathname,
        hash,
        key,
        search: queryParams,
        hashRouting,
        pathProps,
        ...rest
      } = action.payload;

      const [hashPath, hashQuery] = hash.split('?');

      const searchParams = new URLSearchParams(queryParams);
      const platform = searchParams.get('appPlatform');

      const entries = searchParams.entries();
      const newParams = paramsToObject(entries);

      return {
        ...state,
        ...rest,
        hash: hashPath,
        queryParams: hashRouting ? hashQuery : queryParams.substring(1),
        path: pathname,
        pathProps,
        platformMode: state.platformMode || platform,
        navigationHistory: [
          hashRouting ? hashPath : pathname,
          ...state.navigationHistory,
        ],
        params: { ...state.params, ...newParams },
      };
    }
    case constants.navigateWithPayloadSuccess: {
      const {
        pathname,
        hash,
        key,
        search: queryParams,
        hashRouting,
        pathProps,
        ...rest
      } = action.payload;
      const searchParams = new URLSearchParams(queryParams);
      const platform = searchParams.get('appPlatform');
      const [hashPath, hashQuery] = hash.split('?');
      return {
        ...state,
        ...rest,
        hash: hashPath,
        queryParams: hashRouting ? hashQuery : queryParams.substring(1),
        path: pathname,
        data: action.payload.state,
        platformMode: state.platform || platform,
        navigationHistory: [pathname, ...state.navigationHistory],
        pathProps,
      };
    }
    default:
      return state;
  }
}
