"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports["default"] = defaultConfig;
function defaultConfig(_ref) {
  var logos = _ref.logos;
  return {
    topHeight: 100,
    topComponents: ['FooterNavigation'],
    translations: {
      bottomText: 'PLACEHOLDER',
      bottomLinkText: 'PLACEHOLDER',
      'topItems-0': 'PLACEHOLDER',
      'topItems-1': 'PLACEHOLDER',
      'topItems-2': 'PLACEHOLDER'
    },
    topItems: {
      items: [{
        link: 'placeholder',
        id: '7fddea32-2145-11e8-9a9c-e901973ea139',
        navId: '7fddea33-2145-11e8-9a9c-e901973ea139'
      }, {
        link: 'placeholder',
        id: '7fddea34-2145-11e8-9a9c-e901973ea139',
        navId: '7fddea35-2145-11e8-9a9c-e901973ea140'
      }, {
        link: 'placeholder',
        id: 'finance-explained',
        navId: '7fddea38-2145-11e8-9a9c-e901973ea141'
      }]
    },
    topLinkFont: {
      fontSize: 11,
      kerning: 1,
      transform: 'uppercase'
    },
    topLinkHoverColour: {
      value: '#000000',
      label: '#000000'
    },
    midHeight: 64,
    midComponents: ['SocialMediaLinks'],
    urlFacebook: 'http://www.facebook.com',
    urlTwitter: 'http://www.twitter.com',
    urlInstagram: 'http://www.instagram.com',
    urlYouTube: 'http://www.youtube.com',
    urlLinkedIn: 'http://www.linkedin.com',
    socialIconsColour: {
      value: '#FFFFFF',
      label: '#FFFFFF'
    },
    bottomComponents: ['CustomText'],
    bottomHeight: 230,
    bottomFont: {
      fontSize: 10,
      kerning: 2,
      transform: 'uppercase'
    },
    bottomLinkFont: {
      fontSize: 10,
      kerning: 2,
      transform: 'uppercase'
    },
    bottomLinkText: {
      url: 'placeholder',
      label: 'PLACEHOLDER'
    },
    stickyFooter: false,
    topTierAlign: 'center',
    midTierAlign: 'center',
    bottomTierAlign: 'center',
    bottomImage: {
      height: 50,
      url: logos.footer,
      fallbackUrl: logos.fallback
    },
    showBottomImage: true,
    topTierBorderTopColour: {
      value: '#000000',
      label: '#000000'
    },
    topTierBorderTopHeight: 0,
    midTierBorderTopColour: {
      value: '#000000',
      label: '#000000'
    },
    midTierBorderTopHeight: 0,
    bottomTierBorderTopColour: {
      value: '#000000',
      label: '#000000'
    },
    bottomTierBorderTopHeight: 0
  };
}