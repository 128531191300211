"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.translateFinanceFromTemplate = void 0;
var _numbers = require("../shared/localisation/numbers");
/* eslint-disable import/prefer-default-export */

var financePlaceholdersAndPropNames = {
  FINANCEPRICE: {
    prop: 'monthlyPrice',
    formatter: function formatter(price, currency, locale) {
      return (0, _numbers.localiseCurrency)(parseFloat(price.replace('.', '').replace(',', '.')), locale, currency);
    }
  },
  APR: {
    prop: 'apr',
    formatter: function formatter(apr) {
      return apr;
    }
  },
  INTERESTRATE: {
    prop: 'interestRate',
    formatter: function formatter(interestRate) {
      return interestRate;
    }
  },
  DURATION: {
    prop: 'duration',
    formatter: function formatter(duration) {
      return duration;
    }
  }
};
var translateFinanceFromTemplate = exports.translateFinanceFromTemplate = function translateFinanceFromTemplate(template, finance, locale) {
  var replaceValue = function replaceValue(t, entry) {
    return t.replace("{".concat(entry[0], "}"), entry[1].formatter(finance[entry[1].prop], finance.currency, locale));
  };
  return template ? Object.entries(financePlaceholdersAndPropNames).reduce(replaceValue, template) : '';
};