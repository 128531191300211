"use strict";

var _interopRequireDefault = require("@babel/runtime/helpers/interopRequireDefault");
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports["default"] = void 0;
var _styledComponents = _interopRequireDefault(require("styled-components"));
var _default = exports["default"] = _styledComponents["default"].div.withConfig({
  displayName: "TextWrapper",
  componentId: "sc-p0p6bq-0"
})(["display:flex;flex-direction:column;flex:1 0 auto;padding-top:", "px;padding-right:", "px;padding-bottom:", "px;padding-left:", "px;", ";list-style:inside;word-break:break-word;"], function (_ref) {
  var padding = _ref.padding;
  return padding && padding.top || 16;
}, function (_ref2) {
  var padding = _ref2.padding;
  return padding && padding.right || 16;
}, function (_ref3) {
  var padding = _ref3.padding;
  return padding && padding.bottom || 16;
}, function (_ref4) {
  var padding = _ref4.padding;
  return padding && padding.left || 16;
}, function (_ref5) {
  var alignment = _ref5.alignment;
  return "".concat(alignment && "text-align: ".concat(alignment));
});