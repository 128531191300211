"use strict";

var _interopRequireDefault = require("@babel/runtime/helpers/interopRequireDefault");
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.SubLabel = exports.OptionalLabel = exports.Label = void 0;
var _styledComponents = _interopRequireDefault(require("styled-components"));
var Label = exports.Label = _styledComponents["default"].label.withConfig({
  displayName: "Labels__Label",
  componentId: "sc-j40kv1-0"
})(["color:#121212;font-size:16px;line-height:24px;font-family:'AstonMartinSans-Regular';pointer-events:none;margin-bottom:8px;input:focus ~ &,input:not(:placeholder-shown).input:not(:focus) ~ .label,input:disabled ~ &,input:valid ~ &{color:#000000;}", " ", ""], function (_ref) {
  var subLabel = _ref.subLabel;
  return subLabel && "margin-bottom: 0px;";
}, function (_ref2) {
  var showErrorMargin = _ref2.showErrorMargin;
  return showErrorMargin && "margin-bottom: 32px;";
});
var SubLabel = exports.SubLabel = _styledComponents["default"].p.withConfig({
  displayName: "Labels__SubLabel",
  componentId: "sc-j40kv1-1"
})(["font:16px/24px 'AstonMartinSans-Regular';color:#7d7e7e;margin:0 0 8px 0;"]);
var OptionalLabel = exports.OptionalLabel = _styledComponents["default"].span.withConfig({
  displayName: "Labels__OptionalLabel",
  componentId: "sc-j40kv1-2"
})(["color:#7d7e7e;font:16px/24px 'AstonMartinSans-Regular';margin:0;"]);