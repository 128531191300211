"use strict";

var _interopRequireDefault = require("@babel/runtime/helpers/interopRequireDefault");
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.constants = exports.actions = void 0;
exports.reducer = reducer;
var _defineProperty2 = _interopRequireDefault(require("@babel/runtime/helpers/defineProperty"));
function ownKeys(e, r) { var t = Object.keys(e); if (Object.getOwnPropertySymbols) { var o = Object.getOwnPropertySymbols(e); r && (o = o.filter(function (r) { return Object.getOwnPropertyDescriptor(e, r).enumerable; })), t.push.apply(t, o); } return t; }
function _objectSpread(e) { for (var r = 1; r < arguments.length; r++) { var t = null != arguments[r] ? arguments[r] : {}; r % 2 ? ownKeys(Object(t), !0).forEach(function (r) { (0, _defineProperty2["default"])(e, r, t[r]); }) : Object.getOwnPropertyDescriptors ? Object.defineProperties(e, Object.getOwnPropertyDescriptors(t)) : ownKeys(Object(t)).forEach(function (r) { Object.defineProperty(e, r, Object.getOwnPropertyDescriptor(t, r)); }); } return e; }
var constants = exports.constants = {
  getFeaturedVehicle: 'GET_FEATURED_VEHICLE',
  getFeaturedVehicleSuccess: 'GET_FEATURED_VEHICLE_SUCCESS',
  getFeaturedVehicleFailure: 'GET_FEATURED_VEHICLE_FAILURE'
};
var actions = exports.actions = {
  getFeaturedVehicle: function getFeaturedVehicle(vehicleId) {
    return {
      type: constants.getFeaturedVehicle,
      payload: vehicleId
    };
  },
  getFeaturedVehicleSuccess: function getFeaturedVehicleSuccess(payload) {
    return {
      type: constants.getFeaturedVehicleSuccess,
      payload: payload
    };
  },
  getFeaturedVehicleFailure: function getFeaturedVehicleFailure() {
    return {
      type: constants.getFeaturedVehicleFailure
    };
  }
};
function reducer() {
  var state = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : [];
  var action = arguments.length > 1 ? arguments[1] : undefined;
  var type = action.type,
    vehicle = action.payload;
  switch (type) {
    case constants.getFeaturedVehicleSuccess:
      return _objectSpread(_objectSpread({}, state), {}, (0, _defineProperty2["default"])({}, vehicle.id, vehicle));
    default:
      return state;
  }
}