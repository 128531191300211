"use strict";

var _interopRequireDefault = require("@babel/runtime/helpers/interopRequireDefault");
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.constants = exports.actions = void 0;
exports.reducer = reducer;
var _defineProperty2 = _interopRequireDefault(require("@babel/runtime/helpers/defineProperty"));
function ownKeys(e, r) { var t = Object.keys(e); if (Object.getOwnPropertySymbols) { var o = Object.getOwnPropertySymbols(e); r && (o = o.filter(function (r) { return Object.getOwnPropertyDescriptor(e, r).enumerable; })), t.push.apply(t, o); } return t; }
function _objectSpread(e) { for (var r = 1; r < arguments.length; r++) { var t = null != arguments[r] ? arguments[r] : {}; r % 2 ? ownKeys(Object(t), !0).forEach(function (r) { (0, _defineProperty2["default"])(e, r, t[r]); }) : Object.getOwnPropertyDescriptors ? Object.defineProperties(e, Object.getOwnPropertyDescriptors(t)) : ownKeys(Object(t)).forEach(function (r) { Object.defineProperty(e, r, Object.getOwnPropertyDescriptor(t, r)); }); } return e; }
var constants = exports.constants = {
  LAMBO_FINANCE_INIT: 'LAMBO_FINANCE_INIT',
  LAMBO_INIT_SUCCESS: 'LAMBO_INIT_SUCCESS',
  LAMBO_GET_MONTHLY_PAYMENT: 'LAMBO_GET_MONTHLY_PAYMENT',
  LAMBO_GET_MONTHLY_PAYMENT_SUCCESS: 'LAMBO_GET_MONTHLY_PAYMENT_SUCCESS',
  LAMBO_CLEAR: 'LAMBO_CLEAR',
  LAMBO_CLEAR_FINANCE: 'LAMBO_CLEAR_FINANCE',
  LAMBO_ERROR: 'LAMBO_ERROR'
};
var actions = exports.actions = {
  init: function init(payload) {
    return {
      type: constants.LAMBO_FINANCE_INIT,
      payload: payload
    };
  },
  setInitialValues: function setInitialValues(payload) {
    return {
      type: constants.LAMBO_INIT_SUCCESS,
      payload: payload
    };
  },
  getMonthlyPayment: function getMonthlyPayment(payload) {
    return {
      type: constants.LAMBO_GET_MONTHLY_PAYMENT,
      payload: payload
    };
  },
  getMonthlyPaymentSuccess: function getMonthlyPaymentSuccess(payload) {
    return {
      type: constants.LAMBO_GET_MONTHLY_PAYMENT_SUCCESS,
      payload: payload
    };
  },
  clear: function clear() {
    return {
      type: constants.LAMBO_CLEAR
    };
  },
  clearFinance: function clearFinance() {
    return {
      type: constants.LAMBO_CLEAR_FINANCE
    };
  },
  error: function error(payload) {
    return {
      type: constants.LAMBO_ERROR,
      origin: origin,
      payload: payload
    };
  }
};
var initialState = {};
function reducer() {
  var state = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : initialState;
  var action = arguments.length > 1 ? arguments[1] : undefined;
  var payload = action.payload,
    type = action.type;
  switch (type) {
    case constants.LAMBO_INIT_SUCCESS:
      return _objectSpread(_objectSpread({}, state), {}, {
        finance: _objectSpread({}, payload),
        error: null
      });
    case constants.LAMBO_GET_MONTHLY_PAYMENT_SUCCESS:
      return _objectSpread(_objectSpread({}, state), {}, {
        payments: _objectSpread({}, payload),
        error: null
      });
    case constants.LAMBO_CLEAR:
      return _objectSpread(_objectSpread({}, state), {}, {
        payments: null,
        finance: null,
        error: null
      });
    case constants.LAMBO_CLEAR_FINANCE:
      return _objectSpread(_objectSpread({}, state), {}, {
        finance: null,
        error: null
      });
    case constants.LAMBO_ERROR:
      return _objectSpread(_objectSpread({}, state), {}, {
        error: _objectSpread({}, payload)
      });
    default:
      return state;
  }
}