"use strict";

var _interopRequireDefault = require("@babel/runtime/helpers/interopRequireDefault");
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.availableToAdministration = void 0;
Object.defineProperty(exports, "component", {
  enumerable: true,
  get: function get() {
    return _VehicleDataGrid["default"];
  }
});
Object.defineProperty(exports, "config", {
  enumerable: true,
  get: function get() {
    return _config["default"];
  }
});
Object.defineProperty(exports, "defaultConfig", {
  enumerable: true,
  get: function get() {
    return _defaultConfig["default"];
  }
});
exports.name = exports.id = exports.icon = exports.description = void 0;
exports.template = template;
var _Icon = _interopRequireDefault(require("./Icon"));
var _VehicleDataGrid = _interopRequireDefault(require("./VehicleDataGrid"));
var _config = _interopRequireDefault(require("./config"));
var _defaultConfig = _interopRequireDefault(require("./defaultConfig"));
var name = exports.name = 'Vehicle Data Grid';
var id = exports.id = 'VehicleDataGrid';
var availableToAdministration = exports.availableToAdministration = true;
var description = exports.description = 'A grid layout for vehicle data';
var icon = exports.icon = _Icon["default"];
function template() {
  return {
    items: {
      image: {
        label: 'default_icongrid_icon',
        value: 'https://res.cloudinary.com/motortrak/image/upload/v1538484990/Placeholder%20Images/default-brick-icon.svg'
      },
      translations: {
        titleItemText: 'Title Placeholder',
        descriptionItemText: 'Description Placeholder'
      }
    }
  };
}