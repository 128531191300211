"use strict";

var _interopRequireDefault = require("@babel/runtime/helpers/interopRequireDefault");
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.initialState = exports.id = exports.constants = exports.actions = void 0;
exports.reducer = reducer;
var _defineProperty2 = _interopRequireDefault(require("@babel/runtime/helpers/defineProperty"));
var _reducerMacros = require("../../utilities/reducerMacros");
var _thirdPartyIntegrationReducer = require("./thirdPartyIntegrationReducer");
function ownKeys(e, r) { var t = Object.keys(e); if (Object.getOwnPropertySymbols) { var o = Object.getOwnPropertySymbols(e); r && (o = o.filter(function (r) { return Object.getOwnPropertyDescriptor(e, r).enumerable; })), t.push.apply(t, o); } return t; }
function _objectSpread(e) { for (var r = 1; r < arguments.length; r++) { var t = null != arguments[r] ? arguments[r] : {}; r % 2 ? ownKeys(Object(t), !0).forEach(function (r) { (0, _defineProperty2["default"])(e, r, t[r]); }) : Object.getOwnPropertyDescriptors ? Object.defineProperties(e, Object.getOwnPropertyDescriptors(t)) : ownKeys(Object(t)).forEach(function (r) { Object.defineProperty(e, r, Object.getOwnPropertyDescriptor(t, r)); }); } return e; }
var modulePrefix = 'MOD_ENQUIRY';
var constants = exports.constants = _objectSpread(_objectSpread({}, (0, _reducerMacros.moduleConstants)(modulePrefix, ['SUBMIT_MESSAGE', 'SUBMIT_MESSAGE_SUCCESSFUL', 'SUBMIT_MESSAGE_FAILURE', 'RESET_ENQUIRY', 'GET_LOCALE_SUCCESS', 'INIT', 'REQUEST_PRIVACY_POLICY', 'GET_LANGUAGE_OPTIONS', 'GET_LANGUAGE_OPTIONS_SUCCESS'])), _thirdPartyIntegrationReducer.constants);
var id = exports.id = 'Enquiry';
var actions = exports.actions = _objectSpread({
  submitMessage: (0, _reducerMacros.moduleActionWithArgs)(constants.SUBMIT_MESSAGE, id, function (enquiryForm) {
    var preview = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : false;
    return {
      enquiryForm: enquiryForm,
      preview: preview
    };
  }),
  submitMessageSuccessful: (0, _reducerMacros.moduleAction)(constants.SUBMIT_MESSAGE_SUCCESSFUL, id),
  submitMessageFailure: (0, _reducerMacros.moduleActionWithArgs)(constants.SUBMIT_MESSAGE_FAILURE, id, function (error) {
    return error;
  }),
  resetEnquiry: (0, _reducerMacros.moduleAction)(constants.RESET_ENQUIRY, id),
  init: (0, _reducerMacros.moduleAction)(constants.INIT, id),
  getLocaleSuccess: (0, _reducerMacros.moduleActionWithArgs)(constants.GET_LOCALE_SUCCESS, id, function (payload) {
    return payload;
  }),
  requestPrivacyPolicy: (0, _reducerMacros.moduleActionWithArgs)(constants.REQUEST_PRIVACY_POLICY, id, function (payload) {
    return payload;
  }),
  getLanguageOptions: (0, _reducerMacros.moduleAction)(constants.GET_LANGUAGE_OPTIONS, id),
  getLanguageOptionsSuccess: (0, _reducerMacros.moduleActionWithArgs)(constants.GET_LANGUAGE_OPTIONS_SUCCESS, id, function (languageOptions) {
    return languageOptions;
  })
}, _thirdPartyIntegrationReducer.actions);
var initialState = exports.initialState = {
  error: null,
  submissionStatus: 'NotRequested',
  locale: ''
};
function reducer() {
  var state = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : initialState;
  var action = arguments.length > 1 ? arguments[1] : undefined;
  var integratedState = (0, _thirdPartyIntegrationReducer.reducer)(state, action);
  switch (action.type) {
    case constants.SUBMIT_MESSAGE:
      return _objectSpread(_objectSpread({}, integratedState), {}, {
        error: null,
        submissionStatus: 'Requested'
      });
    case constants.SUBMIT_THIRD_PARTY_LEAD:
      return _objectSpread(_objectSpread({}, integratedState), {}, {
        error: null,
        submissionStatus: 'Requested'
      });
    case constants.SUBMIT_MESSAGE_SUCCESSFUL:
      return _objectSpread(_objectSpread({}, integratedState), {}, {
        submissionStatus: 'Successful'
      });
    case constants.SUBMIT_MESSAGE_FAILURE:
      return _objectSpread(_objectSpread({}, integratedState), {}, {
        error: action.payload,
        submissionStatus: 'Failed'
      });
    case constants.RESET_ENQUIRY:
      return _objectSpread(_objectSpread({}, integratedState), {}, {
        error: null,
        submissionStatus: 'NotRequested'
      });
    case constants.GET_LOCALE_SUCCESS:
      return _objectSpread(_objectSpread({}, integratedState), {}, {
        locale: action.payload
      });
    case constants.GET_LANGUAGE_OPTIONS_SUCCESS:
      return _objectSpread(_objectSpread({}, state), {}, {
        languageOptions: action.payload
      });
    default:
      return integratedState;
  }
}