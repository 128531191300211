"use strict";

var _interopRequireDefault = require("@babel/runtime/helpers/interopRequireDefault");
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports["default"] = void 0;
var _config = require("../../helpers/config");
var _SectionIcons = _interopRequireDefault(require("../../helpers/SectionIcons"));
var config = (0, _config.Config)('Header', {
  id: 'header'
}, (0, _config.Slice)('Layout', {
  id: 'layout'
}, (0, _config.Section)('Utility Bar', {
  icon: _SectionIcons["default"].TopTier
}, (0, _config.Pixels)('Height', 'utilityBarHeight'), (0, _config.Align)('Align', 'utilityBarAlign')), (0, _config.Section)('Actions Bar', {
  icon: _SectionIcons["default"].MidTier
}, (0, _config.Toggle)('Show', 'showActionsBar'), (0, _config.Pixels)('Height', 'actionsBarHeight')), (0, _config.Section)('Menu Bar', {
  icon: _SectionIcons["default"].BottomTier
}, (0, _config.Pixels)('Height', 'menuBarHeight')), (0, _config.Section)('Search Bar', {
  icon: _SectionIcons["default"].LetterS
}, (0, _config.AlignLeftRight)('Align', 'searchBarAlign'), (0, _config.AlignLeftRight)('Search icon align', 'searchIconAlign')), (0, _config.Section)('Brand Logo', {
  icon: _SectionIcons["default"].LetterB
}, (0, _config.AlignLeftRight)('Align', 'logoAlign')), (0, _config.Section)('Global', {}, (0, _config.Toggle)('Sticky Header?', 'stickyHeader'), (0, _config.Pixels)('Padding Vertical', 'paddingVertical'), (0, _config.Pixels)('Padding Horizontal', 'paddingHorizontal'))), (0, _config.Slice)('Content', {
  id: 'content'
}, (0, _config.Section)('Utility Bar', {
  icon: _SectionIcons["default"].TopTier
}, (0, _config.Toggle)('Language', 'utilityBarLanguageEnabled'), (0, _config.Toggle)('Currency', 'utilityBarCurrencyEnabled'), (0, _config.Items)('PagesAndLinks', 'utilityBarItems')), (0, _config.Section)('Actions Bar', {
  icon: _SectionIcons["default"].MidTier
}, (0, _config.EditTextWithToggle)('Page Title', 'actionsBarPageTitle'), (0, _config.EditTextWithToggle)('Account', 'actionsBarsAccountTitle'), (0, _config.EditTextWithToggle)('Social Share', 'socialShareTitle')), (0, _config.Section)('Menu Bar', {
  icon: _SectionIcons["default"].BottomTier
}, (0, _config.Items)('PagesAndLinks', 'menuBarItems')), (0, _config.Section)('Search bar', {
  icon: _SectionIcons["default"].LetterS
}, (0, _config.Toggle)('Show search bar', 'showSearchBar')), (0, _config.Section)('Brand Logo', {
  icon: _SectionIcons["default"].LetterB
}, (0, _config.Page)('Page link', 'brandLogoLink'), (0, _config.Toggle)('Visible on desktop', 'brandLogoVisibleDesktop'))), (0, _config.Slice)('Styling', {
  id: 'styling'
}, (0, _config.Section)('Utility Bar', {
  icon: _SectionIcons["default"].TopTier
}, (0, _config.Colour)('Background', 'utilityBarBGColour'), (0, _config.Font)('Link', 'utilityBarLinkFont'), (0, _config.Colour)('Link Hover', 'utilityBarLinkHoverColour'), (0, _config.Colour)('Dropdown Hover Colour', 'utilityBarDropdownHoverColour'), (0, _config.Colour)('Dropdown Menu', 'utilityBarDropdownColour'), (0, _config.Font)('Dropdown Link', 'utilityBarDropdownFont'), (0, _config.Pixels)('Bottom Border Height', 'utilityBarBorderBottomHeight', {
  max: 10
}), (0, _config.Colour)('Bottom Border Colour', 'utilityBarBorderBottomColour')), (0, _config.Section)('Actions Bar', {
  icon: _SectionIcons["default"].MidTier
}, (0, _config.Colour)('Background', 'actionsBarBGColour'), (0, _config.Font)('Page title', 'actionsBarPageFont'), (0, _config.Font)('Account', 'actionsBarAccountFont'), (0, _config.Pixels)('Bottom Border Height', 'actionsBarBorderBottomHeight', {
  max: 10
}), (0, _config.Colour)('Bottom Border Colour', 'actionsBarBorderBottomColour')), (0, _config.Section)('Menu Bar', {
  icon: _SectionIcons["default"].BottomTier
}, (0, _config.Colour)('Background', 'menuBarBGColour'), (0, _config.Colour)('Border Colour', 'menuBarBorderColour'), (0, _config.FontWithAlignment)('Link', 'menuBarLinkFont'), (0, _config.Colour)('Link Hover', 'menuBarLinkHoverColour'), (0, _config.Colour)('Link Background Hover', 'menuBarLinkBgHoverColour'), (0, _config.Pixels)('Bottom Border Height', 'menuBarBorderBottomHeight', {
  max: 10
}), (0, _config.Colour)('Bottom Border Colour', 'menuBarBorderBottomColour')), (0, _config.Section)('Search Bar', {
  icon: _SectionIcons["default"].LetterS
}, (0, _config.Colour)('Background', 'searchBGColour'), (0, _config.Colour)('Icon', 'searchIconColour'), (0, _config.Font)('Search text', 'searchTextFont'), (0, _config.Toggle)('Show search input border', 'showSearchInputBorder')), (0, _config.Section)('Brand Logo', {
  icon: _SectionIcons["default"].LetterB
}, (0, _config.Colour)('Background', 'brandLogoBGColour'), (0, _config.Colour)('Border', 'brandLogoBorderColour'))));
var _default = exports["default"] = config;