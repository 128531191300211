"use strict";

var _interopRequireDefault = require("@babel/runtime/helpers/interopRequireDefault");
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports["default"] = void 0;
var _styledComponents = _interopRequireDefault(require("styled-components"));
var TableData = _styledComponents["default"].td.withConfig({
  displayName: "TableData",
  componentId: "sc-1wqchsw-0"
})(["vertical-align:top;border-bottom:", ";padding-left:10px;color:#151515;", ""], function (_ref) {
  var hideBottomBorder = _ref.hideBottomBorder;
  return hideBottomBorder ? 'none' : '0.5px solid #151515';
}, function (_ref2) {
  var priceRow = _ref2.priceRow;
  return priceRow && "padding-right: 10px;";
});
var _default = exports["default"] = TableData;