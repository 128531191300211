/* eslint-disable import/no-unresolved, import/extensions  */
import { modules } from 'cms-modules';

const getPageState = (state, id) => state[id];
const getInstanceState = (state, instance) =>
  state ? state[instance] : undefined;

export default function reducer(state = {}, action) {
  if (!action.forModuleInstance) return state;

  const { id, page, instance } = action.module;
  const namedModule =
    Object.values(modules).find(mod => (mod.reducerId || mod.id) === id) || {};
  const reduce = namedModule.reducer;

  // Module has no reducer attached
  if (!reduce) return state;

  const pageState = getPageState(state, page);

  return {
    ...state,
    [page]: {
      ...pageState,
      [instance]: reduce(getInstanceState(pageState, instance), action),
    },
  };
}
