"use strict";

var _interopRequireDefault = require("@babel/runtime/helpers/interopRequireDefault");
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.availableToAdministration = void 0;
Object.defineProperty(exports, "component", {
  enumerable: true,
  get: function get() {
    return _ModelGrid["default"];
  }
});
Object.defineProperty(exports, "config", {
  enumerable: true,
  get: function get() {
    return _config["default"];
  }
});
Object.defineProperty(exports, "defaultConfig", {
  enumerable: true,
  get: function get() {
    return _defaultConfig["default"];
  }
});
exports.searchable = exports.name = exports.id = exports.icon = exports.getIndexContent = exports.description = void 0;
var _toConsumableArray2 = _interopRequireDefault(require("@babel/runtime/helpers/toConsumableArray"));
var _Icon = _interopRequireDefault(require("./Icon"));
var _ModelGrid = _interopRequireDefault(require("./ModelGrid"));
var _config = _interopRequireDefault(require("./config"));
var _defaultConfig = _interopRequireDefault(require("./defaultConfig"));
var name = exports.name = 'ModelGrid';
var id = exports.id = 'ModelGrid';
var availableToAdministration = exports.availableToAdministration = false;
var description = exports.description = 'A grid view of car model items with image, text and button';
var icon = exports.icon = _Icon["default"];
var searchable = exports.searchable = true;
var getIndexContent = exports.getIndexContent = function getIndexContent(_ref) {
  var _ref$config = _ref.config,
    _ref$config2 = _ref$config === void 0 ? {} : _ref$config,
    _ref$config2$items = _ref$config2.items,
    items = _ref$config2$items === void 0 ? [] : _ref$config2$items,
    relativePath = _ref.relativePath,
    instanceId = _ref.instanceId;
  return [].concat((0, _toConsumableArray2["default"])(items.map(function (_ref2) {
    var _ref2$translations = _ref2.translations,
      headline = _ref2$translations.headline,
      body = _ref2$translations.body;
    return {
      title: headline,
      content: body
    };
  })), (0, _toConsumableArray2["default"])(items.map(function (_ref3) {
    var _ref3$translations = _ref3.translations,
      headline = _ref3$translations.headline,
      link = _ref3$translations.link;
    return {
      title: headline,
      content: link
    };
  }))).map(function (_ref4, index) {
    var title = _ref4.title,
      content = _ref4.content;
    return {
      key: "".concat(instanceId, "-").concat(index),
      title: title,
      content: content,
      link: relativePath
    };
  }).filter(function (_ref5) {
    var title = _ref5.title,
      content = _ref5.content;
    return !!title || !!content;
  });
};