"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports["default"] = void 0;
var _default = exports["default"] = {
  translations: {
    searchHeader: 'SEARCH JAGUAR APPROVED CARS',
    searchHeaderLocationDistance: 'LOCATION & DISTANCE',
    searchHeaderPriceRange: 'PRICE RANGE',
    searchHeaderModelVariant: 'MODEL & VARIANT',
    searchHeaderEngineTransmission: 'ENGINE & TRANSMISSION',
    searchHeaderBodyStyle: 'BODY STYLE',
    searchHeaderAgeMileage: 'AGE & ODOMETER',
    searchHeaderColour: 'COLOUR',
    searchHeaderFeatures: 'FEATURES',
    searchHeaderAdvancedSearch: 'ADVANCED SEARCH',
    searchSubHeaderLocation: 'Location',
    searchLocationInputPlaceholder: 'Postcode/Town/Place',
    searchDistanceNationwide: 'Nationwide',
    checkboxAnySelection: 'Any',
    searchCarFilterFromValue: 'From {VALUE}',
    searchCarFilterNumberAvailable: '{COUNT} available',
    searchCarNumberAvailable: 'Available',
    searchSubHeaderDistance: 'Distance',
    searchSubHeaderPrice: 'Price',
    searchSubHeaderModel: 'Model',
    searchSubHeaderVariant: 'Variant',
    searchSubHeaderEngine: 'Engine',
    searchSubHeaderTransmission: 'Transmission',
    searchSubHeaderAge: 'Age',
    searchSubHeaderMileage: 'Odometer',
    searchSubHeaderExteriorColours: 'Exterior Colours',
    searchSubHeaderInteriorShades: 'Interior Colours',
    searchPriceByCash: 'cash price',
    searchPriceByMonth: 'monthly budget',
    searchSubHeaderPriceMonthly: 'Monthly',
    searchPriceByMonthText: 'Search for vehicles that match your monthly budget. All initial results are calculated over a {DURATION}-month term with {DEPOSIT}€ deposit.',
    unitPrice: '€',
    unitAge: 'years',
    unitDistant: 'kilometres',
    searchHeaderMobile: 'SEARCH APPROVED',
    searchHeadingFilters: 'How would you like to filter your search',
    searchCTAReset: 'RESET',
    searchCTADone: 'DONE',
    searchCTASearch: 'SEARCH NOW',
    searchCTAResetSearch: 'RESET SEARCH',
    monthlyPayment: 'Monthly payment',
    finalPayment: 'Final payment',
    numberOfPayments: 'Number of payments',
    totalCost: 'Total Cost',
    deposit: 'Deposit',
    apr: 'APR',
    vehiclePrice: 'Vehicle Price',
    interestRate: 'Interest Rate',
    respresentativeExampleHeading: 'Representative Hire Purchase Finance Example',
    noResultsText: 'No models found with that criteria',
    representativeExampleBody: 'Representative example for XE subject to 36 month, 60,000 mile agreement',
    representativeExampleTermsAndConditions: 'Ejemplo de arrendamiento no vinculante con facturación de millaje dispuesto para Jaguar Bank, sucursal de FCA Bank Spain en condiciones válidas y actualizadas. Más. Costos de transferencia y admisión. Las cuotas mensuales y el monto total incluyen el seguro GAP Plus + de AXA (colisión diferencial). Esto no está sujeto al IVA. Todos los demás precios incluyen 21'
  }
};