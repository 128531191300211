"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.preferredTimeOptions = void 0;
/* eslint-disable import/prefer-default-export */
var preferredTimeOptions = exports.preferredTimeOptions = function preferredTimeOptions() {
  return [{
    label: '09:00',
    value: '09:00'
  }, {
    label: '10:00',
    value: '10:00'
  }, {
    label: '11:00',
    value: '11:00'
  }, {
    label: '12:00',
    value: '12:00'
  }, {
    label: '13:00',
    value: '13:00'
  }, {
    label: '14:00',
    value: '14:00'
  }, {
    label: '15:00',
    value: '15:00'
  }, {
    label: '16:00',
    value: '16:00'
  }, {
    label: '17:00',
    value: '17:00'
  }, {
    label: '18:00',
    value: '18:00'
  }, {
    label: '19:00',
    value: '19:00'
  }, {
    label: '20:00',
    value: '20:00'
  }, {
    label: '21:00',
    value: '21:00'
  }];
};