"use strict";

var _interopRequireDefault = require("@babel/runtime/helpers/interopRequireDefault");
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports["default"] = void 0;
var _defineProperty2 = _interopRequireDefault(require("@babel/runtime/helpers/defineProperty"));
function ownKeys(e, r) { var t = Object.keys(e); if (Object.getOwnPropertySymbols) { var o = Object.getOwnPropertySymbols(e); r && (o = o.filter(function (r) { return Object.getOwnPropertyDescriptor(e, r).enumerable; })), t.push.apply(t, o); } return t; }
function _objectSpread(e) { for (var r = 1; r < arguments.length; r++) { var t = null != arguments[r] ? arguments[r] : {}; r % 2 ? ownKeys(Object(t), !0).forEach(function (r) { (0, _defineProperty2["default"])(e, r, t[r]); }) : Object.getOwnPropertyDescriptors ? Object.defineProperties(e, Object.getOwnPropertyDescriptors(t)) : ownKeys(Object(t)).forEach(function (r) { Object.defineProperty(e, r, Object.getOwnPropertyDescriptor(t, r)); }); } return e; }
var emptyTheme = {
  colour: '',
  textTransform: '',
  characterSpacing: '',
  fontSize: '',
  fontFamily: ''
};
var vehicleToCarResult = function vehicleToCarResult(v) {
  return _objectSpread(_objectSpread({}, v), {}, {
    odometer: v.specification && v.specification.odometer || v.odometer,
    engine: v.specification && v.specification.engine || v.engine,
    transmission: v.specification && v.specification.transmission || v.transmission,
    fuel: v.specification && v.specification.fuelType || v.fuel,
    price: v.price || v.price.originalValue || v.price.value,
    priceFull: 0,
    priceText: 'TODO',
    priceMonthly: v.price && v.price.monthly,
    monthlyText: 'TODO',
    priceApr: v.price && v.price.apr,
    aprText: 'TODO',
    locale: v.locale,
    currencyType: v.price && v.price.originalCurrency || v.price.currency,
    originalCurrency: v.price && v.price.originalCurrency,
    performance: v.performance,
    powerOutput: v.powerOutput,
    distanceFromRetailer: undefined,
    retailerName: v.retailerInformation && v.retailerInformation.name || v.retailerName,
    numberAvailable: 0,
    selected: false,
    modelTheme: emptyTheme,
    priceTheme: emptyTheme,
    numberAvailableTheme: emptyTheme,
    financeTextTheme: emptyTheme,
    align: 'left',
    equipmentMetaData: v.equipmentMetaData,
    exterior: v.specification && v.specification.exterior || v.exterior,
    interior: v.specification && v.specification.interior || v.interior,
    bodystyle: v.specification && v.specification.bodystyle,
    retailerPhone: v.retailerInformation && v.retailerInformation.phone,
    colourWithTrim: v.colourWithTrim,
    veneer: v.specification && v.specification.veneer,
    warranty: v.specification && v.specification.warranty,
    engineDetails: v.specification && v.specification.engineDetails || v.engineDetails,
    oemId: v.retailerInformation && v.retailerInformation.oemId
  });
};
var _default = exports["default"] = vehicleToCarResult;