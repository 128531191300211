"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.screenSizeOptions = exports.mixins = exports["default"] = void 0;
var _styledComponents = require("styled-components");
/* eslint-disable */var screenSizeOptions = exports.screenSizeOptions = {
  small: 576,
  medium: 769,
  large: 992,
  extraLarge: 1200,
  xxl: 1536
};
var generateMediaQueries = function generateMediaQueries(widthType) {
  return Object.keys(screenSizeOptions).reduce(function (acc, label) {
    acc[label] = function () {
      return (0, _styledComponents.css)(["@media screen and (", "-width:", "px){", ";}"], widthType, screenSizeOptions[label], _styledComponents.css.apply(void 0, arguments));
    };
    return acc;
  }, {});
};
var media = {
  min: generateMediaQueries('min'),
  max: generateMediaQueries('max')
};
var arrowComtentTemplate = function arrowComtentTemplate() {
  return "\n  :after {\n    content: '';\n    border-style: solid;\n    border-width: 0.15em 0.15em 0 0;\n    display: inline-block;\n    height: 0.45em;\n    left: 0;\n    position: relative;\n    top: 0.4em;\n    transform: rotate(45deg);\n    vertical-align: top;\n    width: 0.45em;\n    margin: -1px 0 0 5px;\n  }\n";
};
var themeTemplate = function themeTemplate(theme) {
  return "\ncolor: ".concat(theme.colour, ";\ntext-transform: ").concat(theme.textTransform, ";\nletter-spacing: ").concat(theme.characterSpacing, ";\nfont-size: ").concat(theme.fontSize, ";\nfont-family: ").concat(theme.fontFamily, ";\n");
};
var mixins = exports.mixins = {
  arrowComtentTemplate: arrowComtentTemplate,
  themeTemplate: themeTemplate
};
var _default = exports["default"] = media;