"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports["default"] = _default;
function _default() {
  return {
    translations: {
      similarVehiclesTitle: 'Title Placeholder'
    },
    headlineFont: {
      fontSize: 24,
      transform: 'uppercase',
      kerning: 1
    }
  };
}