"use strict";

var _interopRequireDefault = require("@babel/runtime/helpers/interopRequireDefault");
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports["default"] = validate;
exports.validateAll = validateAll;
var _defineProperty2 = _interopRequireDefault(require("@babel/runtime/helpers/defineProperty"));
var _slicedToArray2 = _interopRequireDefault(require("@babel/runtime/helpers/slicedToArray"));
var _validationFunctions = require("./validationFunctions");
function ownKeys(e, r) { var t = Object.keys(e); if (Object.getOwnPropertySymbols) { var o = Object.getOwnPropertySymbols(e); r && (o = o.filter(function (r) { return Object.getOwnPropertyDescriptor(e, r).enumerable; })), t.push.apply(t, o); } return t; }
function _objectSpread(e) { for (var r = 1; r < arguments.length; r++) { var t = null != arguments[r] ? arguments[r] : {}; r % 2 ? ownKeys(Object(t), !0).forEach(function (r) { (0, _defineProperty2["default"])(e, r, t[r]); }) : Object.getOwnPropertyDescriptors ? Object.defineProperties(e, Object.getOwnPropertyDescriptors(t)) : ownKeys(Object(t)).forEach(function (r) { Object.defineProperty(e, r, Object.getOwnPropertyDescriptor(t, r)); }); } return e; }
var validations = {
  required: _validationFunctions.required,
  email: _validationFunctions.validEmail,
  phone: _validationFunctions.validPhone,
  checkboxes: _validationFunctions.validCheckboxes
};
function validate(field, translations) {
  if (!field.validation) {
    return undefined;
  }
  var result = validations[field.validation](field.value, translations);
  var returnValue;
  if (result && result.length > 0) {
    var _result = (0, _slicedToArray2["default"])(result, 1);
    returnValue = _result[0];
  }
  return returnValue;
}
function validateAll(fields, translations) {
  var errors = Object.values(fields).reduce(function (acc, field) {
    return _objectSpread(_objectSpread({}, acc), {}, (0, _defineProperty2["default"])({}, field.name, validate(field, translations)));
  }, {});
  return errors;
}