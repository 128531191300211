"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports["default"] = void 0;
var _default = exports["default"] = {
  translations: {
    searchResultsAddToCompare: 'Add to compare',
    searchResultsSaveVehicle: 'Save Vehicle',
    compareLink: 'compare',
    shortlistLink: 'shortlist',
    shortlistHeader: 'shortlist',
    shortlistSubheader: 'vehicles in your shortlist',
    searchResultTileMoreInfo: 'More Info',
    footer: 'MPG figures are obtained from laboratory testing, in accordance with 2004/3/EC and intended for comparisons between vehicles and may not reflect real driving results.(Optional equipment, maintenance, driving behaviour, road and weather conditions may affect the official results)',
    searchResultsAprLabel: 'APR',
    searchResultsMonthlyLabel: 'Monthly',
    searchResultsPriceLabel: 'Price',
    searchResultsEmissionsLabel: 'CO2',
    searchResultsEmissionsCombinedLabel: 'Emissions Combined',
    searchResultsNotAvailableLabel: 'N/A',
    searchResultsOdometerLabel: 'Odometer',
    searchResultsTransmissionLabel: 'Transmission',
    searchResultsFuelLabel: 'Fuel Type',
    searchResultsPowerLabel: 'Power',
    searchResultsSortPriceAscLabel: 'Price Low to High',
    searchResultsSortPriceDescLabel: 'Price High to Low',
    searchResultsSortDistanceLabel: 'Distance - closest',
    monthlyPayment: 'Monthly payment',
    finalPayment: 'Final payment',
    numberOfPayments: 'Number of payments',
    totalCost: 'Total Cost',
    deposit: 'Deposit',
    apr: 'APR',
    vehiclePrice: 'Vehicle Price',
    interestRate: 'Interest Rate',
    respresentativeExampleHeading: 'Representative Hire Purchase Finance Example',
    representativeExampleBody: 'Representative example for XE subject to 36 month, 60,000 mile agreement',
    representativeExampleTermsAndConditions: 'Ejemplo de arrendamiento no vinculante con facturación de millaje dispuesto para Jaguar Bank, sucursal de FCA Bank Spain en condiciones válidas y actualizadas. Más. Costos de transferencia y admisión. Las cuotas mensuales y el monto total incluyen el seguro GAP Plus + de AXA (colisión diferencial). Esto no está sujeto al IVA. Todos los demás precios incluyen 21'
  }
};