"use strict";

var _interopRequireDefault = require("@babel/runtime/helpers/interopRequireDefault");
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports["default"] = void 0;
var _taggedTemplateLiteral2 = _interopRequireDefault(require("@babel/runtime/helpers/taggedTemplateLiteral"));
var _styledComponents = _interopRequireDefault(require("styled-components"));
var _theme = _interopRequireDefault(require("../../theme"));
var _templateObject;
var PageTitle = _styledComponents["default"].h1.withConfig({
  displayName: "PageTitle",
  componentId: "sc-j6j4d4-0"
})(["font:32px/40px 'AstonMartinFlare-Regular';text-align:center;margin:0 0 64px;text-transform:uppercase;color:#121212;", ";"], _theme["default"].max.large(_templateObject || (_templateObject = (0, _taggedTemplateLiteral2["default"])(["\n    font: 26px/36px 'AstonMartinFlare-Regular';\n    margin-bottom: 40px;\n  "]))));
var _default = exports["default"] = PageTitle;