"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.formatCombinedValues = void 0;
/**
 * Summary: Formats emissions combined info.
 * @param {string} nedcValue
 * @param {string} wltdValue
 * @return string combined text.
 */
var formatCombinedValues = exports.formatCombinedValues = function formatCombinedValues(nedcValue, wltdValue) {
  var nedcCombinedText = nedcValue && "".concat(nedcValue);
  var wltpCombinedText = wltdValue && "".concat(wltdValue);
  return nedcCombinedText && wltpCombinedText ? [nedcCombinedText, wltpCombinedText].join(', ') : nedcCombinedText || wltpCombinedText;
};