"use strict";

var _interopRequireDefault = require("@babel/runtime/helpers/interopRequireDefault");
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.newArray = void 0;
var _toConsumableArray2 = _interopRequireDefault(require("@babel/runtime/helpers/toConsumableArray"));
var _without = _interopRequireDefault(require("ramda/src/without"));
/* eslint-disable import/prefer-default-export */
var newArray = exports.newArray = function newArray() {
  var currentArr = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : [];
  var value = arguments.length > 1 ? arguments[1] : undefined;
  if (currentArr.some(function (i) {
    return i === value;
  })) {
    return (0, _without["default"])([value], currentArr);
  }
  return [].concat((0, _toConsumableArray2["default"])(currentArr), [value]);
};