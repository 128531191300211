"use strict";

var _interopRequireDefault = require("@babel/runtime/helpers/interopRequireDefault");
var _typeof = require("@babel/runtime/helpers/typeof");
Object.defineProperty(exports, "__esModule", {
  value: true
});
Object.defineProperty(exports, "actions", {
  enumerable: true,
  get: function get() {
    return _reducer.actions;
  }
});
exports.availableToAdministration = void 0;
Object.defineProperty(exports, "component", {
  enumerable: true,
  get: function get() {
    return _ModelMatrixSearch["default"];
  }
});
Object.defineProperty(exports, "config", {
  enumerable: true,
  get: function get() {
    return _config["default"];
  }
});
Object.defineProperty(exports, "constants", {
  enumerable: true,
  get: function get() {
    return _reducer.constants;
  }
});
Object.defineProperty(exports, "defaultConfig", {
  enumerable: true,
  get: function get() {
    return _defaultConfig["default"];
  }
});
exports.description = void 0;
Object.defineProperty(exports, "effects", {
  enumerable: true,
  get: function get() {
    return _effects["default"];
  }
});
exports.id = exports.icon = void 0;
Object.defineProperty(exports, "initialState", {
  enumerable: true,
  get: function get() {
    return _reducer.initialState;
  }
});
exports.name = void 0;
Object.defineProperty(exports, "reducer", {
  enumerable: true,
  get: function get() {
    return _reducer.reducer;
  }
});
Object.defineProperty(exports, "reducerId", {
  enumerable: true,
  get: function get() {
    return _reducer.id;
  }
});
exports.submodules = void 0;
var _Icon = _interopRequireDefault(require("../Search/Icon"));
var Filters = _interopRequireWildcard(require("../../linked-modules/Filters"));
var _reducer = require("./reducer");
var _ModelMatrixSearch = _interopRequireDefault(require("./ModelMatrixSearch"));
var _effects = _interopRequireDefault(require("./effects"));
var _config = _interopRequireDefault(require("../Search/config"));
var _defaultConfig = _interopRequireDefault(require("./defaultConfig"));
function _getRequireWildcardCache(e) { if ("function" != typeof WeakMap) return null; var r = new WeakMap(), t = new WeakMap(); return (_getRequireWildcardCache = function _getRequireWildcardCache(e) { return e ? t : r; })(e); }
function _interopRequireWildcard(e, r) { if (!r && e && e.__esModule) return e; if (null === e || "object" != _typeof(e) && "function" != typeof e) return { "default": e }; var t = _getRequireWildcardCache(r); if (t && t.has(e)) return t.get(e); var n = { __proto__: null }, a = Object.defineProperty && Object.getOwnPropertyDescriptor; for (var u in e) if ("default" !== u && {}.hasOwnProperty.call(e, u)) { var i = a ? Object.getOwnPropertyDescriptor(e, u) : null; i && (i.get || i.set) ? Object.defineProperty(n, u, i) : n[u] = e[u]; } return n["default"] = e, t && t.set(e, n), n; }
var name = exports.name = 'ModelMatrixSearch';
var id = exports.id = 'ModelMatrixSearch';
var availableToAdministration = exports.availableToAdministration = true;
var description = exports.description = 'Search Vehicles Page';
var icon = exports.icon = _Icon["default"];
var submodules = exports.submodules = [Filters];