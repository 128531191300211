"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.getWarrantyLabel = void 0;
// Logic for Poland whereby an extra translation key is required for 'Years'
// when warranty is between 2 and 4 years
var getWarrantyLabel = exports.getWarrantyLabel = function getWarrantyLabel(remainingWarrantyYears, warrantyLabelDefault, warrantyLabel2to4) {
  return warrantyLabel2to4 && remainingWarrantyYears > 1 && remainingWarrantyYears < 5 ? "".concat(remainingWarrantyYears, " ").concat(warrantyLabel2to4) : "".concat(remainingWarrantyYears, " ").concat(warrantyLabelDefault);
};