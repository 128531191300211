"use strict";

var _interopRequireDefault = require("@babel/runtime/helpers/interopRequireDefault");
Object.defineProperty(exports, "__esModule", {
  value: true
});
Object.defineProperty(exports, "actions", {
  enumerable: true,
  get: function get() {
    return _reducer.actions;
  }
});
Object.defineProperty(exports, "component", {
  enumerable: true,
  get: function get() {
    return _CompareGrid["default"];
  }
});
Object.defineProperty(exports, "constants", {
  enumerable: true,
  get: function get() {
    return _reducer.constants;
  }
});
exports.id = exports.description = void 0;
Object.defineProperty(exports, "initialState", {
  enumerable: true,
  get: function get() {
    return _reducer.initialState;
  }
});
exports.name = void 0;
Object.defineProperty(exports, "reducer", {
  enumerable: true,
  get: function get() {
    return _reducer.reducer;
  }
});
Object.defineProperty(exports, "reducerId", {
  enumerable: true,
  get: function get() {
    return _reducer.id;
  }
});
var _reducer = require("./reducer");
var _CompareGrid = _interopRequireDefault(require("./CompareGrid"));
var name = exports.name = 'RRMCCompareGrid';
var id = exports.id = 'RRMCCompareGrid';
var description = exports.description = 'Compare Page';