"use strict";

var _interopRequireDefault = require("@babel/runtime/helpers/interopRequireDefault");
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.matchGroup = exports.initialState = exports.id = exports.constants = exports.actions = void 0;
exports.reducer = reducer;
var _toConsumableArray2 = _interopRequireDefault(require("@babel/runtime/helpers/toConsumableArray"));
var _defineProperty2 = _interopRequireDefault(require("@babel/runtime/helpers/defineProperty"));
var _reducerMacros = require("../../utilities/reducerMacros");
function ownKeys(e, r) { var t = Object.keys(e); if (Object.getOwnPropertySymbols) { var o = Object.getOwnPropertySymbols(e); r && (o = o.filter(function (r) { return Object.getOwnPropertyDescriptor(e, r).enumerable; })), t.push.apply(t, o); } return t; }
function _objectSpread(e) { for (var r = 1; r < arguments.length; r++) { var t = null != arguments[r] ? arguments[r] : {}; r % 2 ? ownKeys(Object(t), !0).forEach(function (r) { (0, _defineProperty2["default"])(e, r, t[r]); }) : Object.getOwnPropertyDescriptors ? Object.defineProperties(e, Object.getOwnPropertyDescriptors(t)) : ownKeys(Object(t)).forEach(function (r) { Object.defineProperty(e, r, Object.getOwnPropertyDescriptor(t, r)); }); } return e; }
var modulePrefix = 'MOD_MATRIX_SEARCH';
var constants = exports.constants = (0, _reducerMacros.moduleConstants)(modulePrefix, ['GET_INVENTORY_GROUP', 'GET_INVENTORY_GROUP_SUCCESS', 'GET_MODEL_GROUPS', 'GET_MODEL_GROUPS_SUCCESS', 'UPDATE_MODEL_COUNTS', 'GET_FINANCE', 'GET_FINANCE_SUCCESS', 'CONVERT_PRICES', 'CONVERT_PRICES_SUCCESS']);
var id = exports.id = 'MatrixSearch';

// @TODO: The models have accents and trailing white spaces in some cases, the modelsGroups do not
// so need to normalize the string to perform lookups
// we need to get the ModelGroups end point to return proper model name data :-(
var normalize = function normalize(str) {
  return str.normalize('NFD').replace(/[\u0300-\u036f]/g, '').trim();
};
var actions = exports.actions = {
  getInventoryGroup: (0, _reducerMacros.moduleActionWithArgs)(constants.GET_INVENTORY_GROUP, id, function (payload) {
    return payload;
  }),
  getInventoryGroupSuccess: (0, _reducerMacros.moduleActionWithArgs)(constants.GET_INVENTORY_GROUP_SUCCESS, id, function (config) {
    return config;
  }),
  getModelGroups: (0, _reducerMacros.moduleAction)(constants.GET_MODEL_GROUPS, id),
  getModelGroupsSuccess: (0, _reducerMacros.moduleActionWithArgs)(constants.GET_MODEL_GROUPS_SUCCESS, id, function (payload) {
    return payload;
  }),
  updateModelCounts: (0, _reducerMacros.moduleActionWithArgs)(constants.UPDATE_MODEL_COUNTS, id, function (payload) {
    return payload;
  }),
  getFinance: (0, _reducerMacros.moduleActionWithArgs)(constants.GET_FINANCE, id, function (payload) {
    return payload;
  }),
  getFinanceSuccess: (0, _reducerMacros.moduleActionWithArgs)(constants.GET_FINANCE_SUCCESS, id, function (payload) {
    return payload;
  }),
  convertPrices: (0, _reducerMacros.moduleAction)(constants.CONVERT_PRICES, id),
  convertPricesSuccess: (0, _reducerMacros.moduleActionWithArgs)(constants.CONVERT_PRICES_SUCCESS, id, function (payload) {
    return payload;
  })
};
var initialState = exports.initialState = {
  modelGroups: [],
  inventoryGroup: [],
  modelGroupsWithPrices: [],
  error: null
};
var sortGroups = function sortGroups(a, b) {
  // order can be undefined so put these last
  var aOrder = a.position || 10000;
  var bOrder = b.position || 10000;
  return aOrder - bOrder;
};
var matchGroup = exports.matchGroup = function matchGroup(g, i) {
  return g.model === i.model || g.modelGroupName && i.model && normalize(g.modelGroupName.toLowerCase()) === normalize(i.model.toLowerCase());
};
var mergeInventoryAndModelGroups = function mergeInventoryAndModelGroups(inventory, stateGroups) {
  var vehiclesfromInventory = inventory.map(function (i) {
    var fromState = stateGroups.find(function (g) {
      return matchGroup(g, i);
    }) || {};
    return _objectSpread(_objectSpread({}, fromState), {}, {
      model: i.model,
      minimumPrice: i.minimumPrice,
      currency: i.currency,
      financeVehicleId: i.financeVehicleId,
      originalPrice: i.originalPrice,
      modelDisplay: i.modelDisplay
    });
  });
  var vehiclesNotInInventory = stateGroups.filter(function (g) {
    return !inventory.find(function (i) {
      return matchGroup(g, i);
    });
  });
  return [].concat((0, _toConsumableArray2["default"])(vehiclesfromInventory), (0, _toConsumableArray2["default"])(vehiclesNotInInventory)).sort(sortGroups);
};
var mergeFinanceAndModelGroups = function mergeFinanceAndModelGroups(finance, modelGroups) {
  var modelForFinance = modelGroups.find(function (g) {
    return matchGroup(g, finance);
  });
  return [].concat((0, _toConsumableArray2["default"])(modelGroups.filter(function (g) {
    return g !== modelForFinance;
  })), [_objectSpread(_objectSpread({}, modelForFinance), {}, {
    finance: finance.finance
  })]).sort(sortGroups);
};
function reducer() {
  var state = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : initialState;
  var action = arguments.length > 1 ? arguments[1] : undefined;
  switch (action.type) {
    case constants.GET_MODEL_GROUPS_SUCCESS:
      {
        return _objectSpread(_objectSpread({}, state), action.payload);
      }
    case constants.GET_INVENTORY_GROUP_SUCCESS:
      {
        return _objectSpread(_objectSpread({}, state), {}, {
          inventoryGroup: action.payload
        });
      }
    case constants.GET_FINANCE_SUCCESS:
      {
        var merged = mergeFinanceAndModelGroups(action.payload, state.modelGroupsWithPrices);
        return _objectSpread(_objectSpread({}, state), {}, {
          inventoryGroup: merged
        });
      }
    case constants.CONVERT_PRICES_SUCCESS:
      {
        return _objectSpread(_objectSpread({}, state), {}, {
          inventoryGroup: mergeInventoryAndModelGroups(action.payload, state.inventoryGroup)
        });
      }
    case constants.GET_INVENTORY_GROUP_FAILURE:
    case constants.GET_MODEL_GROUPS_FAILURE:
    default:
      return state;
  }
}