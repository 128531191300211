"use strict";

var _interopRequireDefault = require("@babel/runtime/helpers/interopRequireDefault");
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.constants = exports.actions = void 0;
exports.reducer = reducer;
var _defineProperty2 = _interopRequireDefault(require("@babel/runtime/helpers/defineProperty"));
function ownKeys(e, r) { var t = Object.keys(e); if (Object.getOwnPropertySymbols) { var o = Object.getOwnPropertySymbols(e); r && (o = o.filter(function (r) { return Object.getOwnPropertyDescriptor(e, r).enumerable; })), t.push.apply(t, o); } return t; }
function _objectSpread(e) { for (var r = 1; r < arguments.length; r++) { var t = null != arguments[r] ? arguments[r] : {}; r % 2 ? ownKeys(Object(t), !0).forEach(function (r) { (0, _defineProperty2["default"])(e, r, t[r]); }) : Object.getOwnPropertyDescriptors ? Object.defineProperties(e, Object.getOwnPropertyDescriptors(t)) : ownKeys(Object(t)).forEach(function (r) { Object.defineProperty(e, r, Object.getOwnPropertyDescriptor(t, r)); }); } return e; }
var constants = exports.constants = {
  printVehicleInfo: 'PRINT_VEHICLE_INFO',
  printEmissionInfo: 'PRINT_EMISSION_INFO',
  printingSuccessful: 'PRINTING_SUCCESSFUL',
  printingFailure: 'PRINTING_FAILURE'
};
var actions = exports.actions = {
  printVehicleInfo: function printVehicleInfo(vehicle, translations, config) {
    return {
      type: constants.printVehicleInfo,
      payload: {
        vehicle: vehicle,
        translations: translations,
        config: config
      }
    };
  },
  printEmissionInfo: function printEmissionInfo(vehicle) {
    return {
      type: constants.printEmissionInfo,
      payload: vehicle
    };
  },
  printingSuccessful: function printingSuccessful() {
    return {
      type: constants.printingSuccessful
    };
  },
  printingFailure: function printingFailure() {
    return {
      type: constants.printingFailure
    };
  }
};
var initialState = {
  isPrinting: false,
  error: false
};
function reducer() {
  var state = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : initialState;
  var action = arguments.length > 1 ? arguments[1] : undefined;
  var type = action.type;
  switch (type) {
    case constants.printVehicleInfo:
      return _objectSpread(_objectSpread({}, state), {}, {
        isPrinting: true,
        error: false
      });
    case constants.printEmissionInfo:
      return _objectSpread(_objectSpread({}, state), {}, {
        isPrinting: true,
        error: false
      });
    case constants.printingSuccessful:
      return _objectSpread(_objectSpread({}, state), {}, {
        isPrinting: false,
        error: false
      });
    case constants.printingFailure:
      return {
        isPrinting: false,
        error: true
      };
    default:
      return state;
  }
}