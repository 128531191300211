"use strict";

var _interopRequireDefault = require("@babel/runtime/helpers/interopRequireDefault");
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.modulePrefix = exports.initialState = exports.id = exports.constants = exports.actions = void 0;
exports.reducer = reducer;
var _defineProperty2 = _interopRequireDefault(require("@babel/runtime/helpers/defineProperty"));
var _reducerMacros = require("../../utilities/reducerMacros");
var _validation = require("../../helpers/validation");
function ownKeys(e, r) { var t = Object.keys(e); if (Object.getOwnPropertySymbols) { var o = Object.getOwnPropertySymbols(e); r && (o = o.filter(function (r) { return Object.getOwnPropertyDescriptor(e, r).enumerable; })), t.push.apply(t, o); } return t; }
function _objectSpread(e) { for (var r = 1; r < arguments.length; r++) { var t = null != arguments[r] ? arguments[r] : {}; r % 2 ? ownKeys(Object(t), !0).forEach(function (r) { (0, _defineProperty2["default"])(e, r, t[r]); }) : Object.getOwnPropertyDescriptors ? Object.defineProperties(e, Object.getOwnPropertyDescriptors(t)) : ownKeys(Object(t)).forEach(function (r) { Object.defineProperty(e, r, Object.getOwnPropertyDescriptor(t, r)); }); } return e; }
var modulePrefix = exports.modulePrefix = 'MOD_TEST_DRIVE';
var constants = exports.constants = (0, _reducerMacros.moduleConstants)(modulePrefix, ['GET_VEHICLE_INFO', 'GET_VEHICLE_INFO_SUCCESS', 'GET_VEHICLE_INFO_FAILURE', 'SET_FORM_VALUE_FROM_DROPDOWN', 'SET_BOOKING_FORM_VALUE', 'CONTINUE_AS_GUEST', 'GUEST_DETAILS_SUBMITTED', 'LOGIN_AND_CONFIRM', 'LOGIN_SUCCESS', 'LOGIN_FAILED', 'SOMETHING_WENT_WRONG', 'USER_LOGGED_IN', 'SEND_TEST_DRIVE_LEAD_SUCCESS', 'SAVE_TEST_DRIVE_SUCCESS', 'CONFIRM_DATE_AND_TIME', 'CONFIRM_REQUEST', 'TOGGLE_CALENDAR', 'INTERESTED_CLICKED', 'RADIO_GROUP_MODE_CHANGED', 'RESET', 'CHANGE_CONTEXT']);
var id = exports.id = 'TestDrive';
var selectDateModeOptionsList = [{
  value: 'AnyDate'
}, {
  value: 'SpecificDate'
}];
var selectTimeModeOptionsList = [{
  label: 'Any',
  value: 'Any'
}, {
  label: 'Morning',
  value: 'Morning'
}, {
  label: 'Afternoon',
  value: 'Afternoon'
}];
var actions = exports.actions = {
  getVehicleInfo: (0, _reducerMacros.moduleActionWithArgs)(constants.GET_VEHICLE_INFO, id, function (vehicleId) {
    return {
      vehicleId: vehicleId
    };
  }),
  getVehicleInfoSuccess: (0, _reducerMacros.moduleActionWithArgs)(constants.GET_VEHICLE_INFO_SUCCESS, id, function (config) {
    return config;
  }),
  getVehicleInfoFailure: (0, _reducerMacros.moduleActionWithArgs)(constants.GET_VEHICLE_INFO_FAILURE, id, function (error) {
    return error;
  }),
  setFormValueFromDropdown: (0, _reducerMacros.moduleActionWithArgs)(constants.SET_FORM_VALUE_FROM_DROPDOWN, id, function (selected, translations) {
    return {
      selected: selected,
      translations: translations
    };
  }),
  setBookingFormValue: (0, _reducerMacros.moduleActionWithArgs)(constants.SET_BOOKING_FORM_VALUE, id, function (key, value, translations, validation) {
    return {
      key: key,
      value: value,
      translations: translations,
      validation: validation
    };
  }),
  continueAsGuest: (0, _reducerMacros.moduleAction)(constants.CONTINUE_AS_GUEST, id),
  guestDetailsSubmitted: (0, _reducerMacros.moduleActionWithArgs)(constants.GUEST_DETAILS_SUBMITTED, id, function (guestDetails, newAccount) {
    return {
      guestDetails: guestDetails,
      newAccount: newAccount
    };
  }),
  toggleCalendar: (0, _reducerMacros.moduleAction)(constants.TOGGLE_CALENDAR, id),
  setSelectDateMode: (0, _reducerMacros.moduleActionWithArgs)(constants.SET_SELECT_DATE_MODE, id, function (selectedValue) {
    return {
      selectedValue: selectedValue
    };
  }),
  loginAndConfirm: (0, _reducerMacros.moduleActionWithArgs)(constants.LOGIN_AND_CONFIRM, id, function (loginDetails) {
    return _objectSpread({}, loginDetails);
  }),
  loginSuccess: (0, _reducerMacros.moduleAction)(constants.LOGIN_SUCCESS, id),
  loginFailed: (0, _reducerMacros.moduleActionWithArgs)(constants.LOGIN_FAILED, id, function (payload) {
    return _objectSpread({}, payload);
  }),
  userLoggedIn: (0, _reducerMacros.moduleAction)(constants.USER_LOGGED_IN, id),
  somethingWentWrong: (0, _reducerMacros.moduleAction)(constants.SOMETHING_WENT_WRONG, id),
  sendTestDriveLeadSuccess: (0, _reducerMacros.moduleActionWithArgs)(constants.SEND_TEST_DRIVE_LEAD_SUCCESS, id, function (userCreated, newUserEmail) {
    return {
      userCreated: userCreated,
      newUserEmail: newUserEmail
    };
  }),
  saveTestDriveSuccess: (0, _reducerMacros.moduleActionWithArgs)(constants.SAVE_TEST_DRIVE_SUCCESS, id, function (guestDetails) {
    return {
      guestDetails: guestDetails
    };
  }),
  confirmDateAndTime: (0, _reducerMacros.moduleAction)(constants.CONFIRM_DATE_AND_TIME, id),
  confirmRequest: (0, _reducerMacros.moduleActionWithArgs)(constants.CONFIRM_REQUEST, id, function (payload) {
    return payload;
  }),
  setSelectRadioGroupMode: (0, _reducerMacros.moduleActionWithArgs)(constants.RADIO_GROUP_MODE_CHANGED, id, function (key, value) {
    return {
      key: key,
      value: value
    };
  }),
  interestedClicked: (0, _reducerMacros.moduleAction)(constants.INTERESTED_CLICKED, id),
  reset: (0, _reducerMacros.moduleAction)(constants.RESET, id),
  changeContext: (0, _reducerMacros.moduleActionWithArgs)(constants.CHANGE_CONTEXT, id, function (payload) {
    return payload;
  })
};
function validate(form, translations) {
  return {
    title: (0, _validation.required)(form.title, translations),
    firstname: (0, _validation.required)(form.firstname, translations),
    lastname: (0, _validation.required)(form.lastname, translations),
    email: (0, _validation.validEmail)(form.email, translations),
    emailConfirm: (0, _validation.valueMatchesRequired)(form.email, form.emailConfirm, translations),
    phone: (0, _validation.validPhone)(form.phone, translations),
    postcode: (0, _validation.required)(form.postcode, translations),
    accountPassword: (0, _validation.meetsPasswordPolicy)(form.accountPassword, translations, false),
    accountPasswordConfirm: (0, _validation.valueMatches)(form.accountPassword, form.accountPasswordConfirm, translations.formValidationPasswordMatch),
    termsAndConditionsNotChecked: !form.termsAndConditionsAccept ? [translations.testDriveUserDetailsTermsAndConditionsNotChecked] : undefined
  };
}
var initialState = exports.initialState = {
  testDriveConfig: null,
  error: null,
  context: 'DateAndTime',
  open: false,
  selectDateModeOptions: selectDateModeOptionsList,
  selectDateMode: selectDateModeOptionsList[0],
  selectTimeModeOptions: selectTimeModeOptionsList,
  selectTimeMode: selectTimeModeOptionsList[0],
  selectedDateMode: 'AnyDate',
  selectedTimeMode: 'Any',
  disableCalendar: true,
  bookingForm: {
    interestedChecked: false,
    termsAndConditionsAccept: false
  },
  bookingFormError: {},
  somethingWentWrong: false,
  userCreated: false,
  newUserEmail: null
};
function reducer() {
  var state = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : initialState;
  var action = arguments.length > 1 ? arguments[1] : undefined;
  switch (action.type) {
    case constants.RESET:
      return _objectSpread(_objectSpread({}, initialState), {}, {
        error: state.error
      });
    case constants.GET_VEHICLE_INFO_SUCCESS:
      {
        var testDriveConfig = action.payload.testDriveConfig;
        return _objectSpread(_objectSpread({}, state), {}, {
          testDriveConfig: testDriveConfig
        });
      }
    case constants.GET_VEHICLE_INFO_FAILURE:
      return _objectSpread(_objectSpread({}, state), {}, {
        error: action.payload
      });
    case constants.SET_FORM_VALUE_FROM_DROPDOWN:
      {
        var _action$payload = action.payload,
          selected = _action$payload.selected,
          translations = _action$payload.translations;
        var bookingForm = _objectSpread(_objectSpread({}, state.bookingForm), {}, (0, _defineProperty2["default"])({}, selected.keyValue, selected.value));
        var errors = validate(bookingForm, translations);
        return _objectSpread(_objectSpread({}, state), {}, {
          bookingForm: bookingForm,
          bookingFormError: _objectSpread(_objectSpread({}, state.bookingFormError), {}, (0, _defineProperty2["default"])({}, selected.keyValue, errors[selected.keyValue]))
        });
      }
    case constants.SET_BOOKING_FORM_VALUE:
      {
        var _action$payload2 = action.payload,
          key = _action$payload2.key,
          value = _action$payload2.value,
          _translations = _action$payload2.translations,
          validation = _action$payload2.validation;
        var _bookingForm = {};
        if (key === 'date') {
          var toUTC = Date.UTC(value.getFullYear(), value.getMonth(), value.getDate());
          var getTimezoneOffset = value.getTimezoneOffset() * 60000;
          var getEpoch = toUTC + getTimezoneOffset;
          _bookingForm = _objectSpread(_objectSpread({}, state.bookingForm), {}, (0, _defineProperty2["default"])({}, key, getEpoch));
        } else {
          _bookingForm = _objectSpread(_objectSpread({}, state.bookingForm), {}, (0, _defineProperty2["default"])({}, key, value));
        }
        var bookingFormError = _objectSpread({}, state.bookingFormError);
        if (validation) {
          var _errors = validate(_bookingForm, _translations);
          bookingFormError[key] = _errors[key];
        }
        return _objectSpread(_objectSpread({}, state), {}, {
          bookingForm: _bookingForm,
          bookingFormError: bookingFormError
        });
      }
    case constants.RADIO_GROUP_MODE_CHANGED:
      {
        var _action$payload3 = action.payload,
          _key = _action$payload3.key,
          _value = _action$payload3.value;
        var newBookingForm = _objectSpread({}, state.bookingForm);
        if (!state.bookingForm.date && _key === 'selectedDateMode' && _value === 'SpecificDate') {
          newBookingForm = _objectSpread(_objectSpread({}, state.bookingForm), {}, {
            date: new Date()
          });
        }
        return _objectSpread(_objectSpread({}, state), {}, (0, _defineProperty2["default"])((0, _defineProperty2["default"])({
          open: false,
          disableCalendar: _key === 'selectedDateMode' ? _value === 'AnyDate' : state.disableCalendar
        }, _key, _value), "bookingForm", newBookingForm));
      }
    case constants.CONTINUE_AS_GUEST:
      return _objectSpread(_objectSpread({}, state), {}, {
        context: 'UserDetails'
      });
    case constants.GUEST_DETAILS_SUBMITTED:
      {
        return _objectSpread(_objectSpread({}, state), {}, {
          guestDetails: action.payload.guestDetails
        });
      }
    case constants.TOGGLE_CALENDAR:
      return _objectSpread(_objectSpread({}, state), {}, {
        open: !state.open,
        disableCalendar: state.selectedDateMode === 'AnyDate'
      });
    case constants.CONFIRM_DATE_AND_TIME:
      return _objectSpread(_objectSpread({}, state), {}, {
        context: 'Login'
      });
    case constants.CONFIRM_REQUEST:
      {
        var _bookingFormError = validate(action.payload.bookingForm, action.payload.translations);
        var valid = Object.values(_bookingFormError).filter(function (val) {
          return val !== undefined;
        }).length === 0;
        return _objectSpread(_objectSpread({}, state), {}, {
          bookingFormError: _bookingFormError,
          context: valid ? 'Summary' : state.context
        });
      }
    case constants.LOGIN_SUCCESS:
      {
        return _objectSpread(_objectSpread({}, state), {}, {
          loginFailed: undefined
        });
      }
    case constants.SEND_TEST_DRIVE_LEAD_SUCCESS:
      {
        return _objectSpread(_objectSpread({}, state), {}, {
          userCreated: action.payload.userCreated,
          newUserEmail: action.payload.newUserEmail,
          context: 'Summary'
        });
      }
    case constants.LOGIN_FAILED:
      {
        return _objectSpread(_objectSpread({}, state), {}, {
          loginFailed: action.payload.reason
        });
      }
    case constants.SOMETHING_WENT_WRONG:
      {
        return _objectSpread(_objectSpread({}, state), {}, {
          context: 'DateAndTime',
          somethingWentWrong: true
        });
      }
    case constants.INTERESTED_CLICKED:
      {
        return _objectSpread(_objectSpread({}, state), {}, {
          bookingForm: _objectSpread(_objectSpread({}, state.bookingForm), {}, {
            interestedChecked: !state.bookingForm.interestedChecked
          })
        });
      }
    case constants.CHANGE_CONTEXT:
      {
        return _objectSpread(_objectSpread({}, state), {}, {
          context: action.payload
        });
      }
    default:
      return state;
  }
}