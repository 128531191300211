"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.constants = exports.actions = void 0;
var _reducerMacros = require("../../utilities/reducerMacros");
var modulePrefix = 'MOD_DEALER_CHECKER';
var constants = exports.constants = (0, _reducerMacros.moduleConstants)(modulePrefix, ['GET_DEALER_INFO', 'GET_DEALER_INFO_SUCCESS', 'GET_DEALER_INFO_FAILED']);
var actions = exports.actions = {
  getDealerInfo: function getDealerInfo(payload) {
    return {
      type: constants.GET_DEALER_INFO,
      payload: payload
    };
  },
  getDealerInfoSuccess: function getDealerInfoSuccess(payload) {
    return {
      type: constants.GET_DEALER_INFO_SUCCESS,
      payload: payload
    };
  },
  getDealerInfoFailed: function getDealerInfoFailed() {
    return {
      type: constants.GET_DEALER_INFO_FAILED
    };
  }
};