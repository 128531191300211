/* eslint-disable no-unused-vars */
/* eslint-disable import/no-unresolved, import/extensions  */
import { createStore, applyMiddleware } from 'redux';
import createSagaMiddleware from 'redux-saga';
import { composeWithDevTools } from 'redux-devtools-extension/developmentOnly';
import { modules, shared, pods } from 'cms-modules';

import reducer from './reducer';
import sagas from './effects';

import {
  loadCustomerAuth,
  loadCustomerDetails,
  loadCustomerShortlist,
  loadCustomerCompare,
  loadVehicleValuation,
  loadLocale,
  loadCustomerDealerCompare,
  loadCustomerDealerShortlist,
} from './localStorage';

import settings from '../settings';

import persistCustomerState from './storeSubscriber';

// Could make this configurable
const persistedState = {
  shared: {
    customerLogin: {
      authenticationDetails: loadCustomerAuth(),
      customerDetails: loadCustomerDetails(),
    },
    shortlist: {
      vehicles: loadCustomerShortlist() || [],
      ...(loadCustomerDealerShortlist() || {}),
    },
    compare: {
      vehicles: loadCustomerCompare() || [],
      ...(loadCustomerDealerCompare() || {}),
    },
    vehicleValuations: {
      valuation: loadVehicleValuation(),
    },
  },
  config: {
    config: { pages: [], global: [] },
    settings: {
      ...settings,
      baseUrl: settings.baseUrl,
      imsModelGroupsBaseUrl: settings.imsModelGroupsBaseUrl,
      placeholderBrandImageUrl: settings.placeholderBrandImageUrl,
      vehiclesShortlistServicePath: settings.vehiclesShortlistServicePath,
      testDrivesServicePath: settings.testDrivesServicePath,
      myDealsServicePath: settings.myDealsServicePath,
      inventorySearchServicePath: settings.inventorySearchServicePath,
      avlCustomersServicePath: settings.avlCustomersServicePath,
      useLegacyApiUrls: settings.useLegacyApiUrls,
      appendLocaleQueryString: settings.appendLocaleQueryString,
      imsLeadsHostName: settings.imsLeadsHostName,
      imsApacLeadsHostName: settings.imsApacLeadsHostName,
      inventoryStatus: settings.getInventoryStatus,
      financeStatus: settings.getFinanceStatus,
      postsServicePath: settings.postsServicePath,
      thirdPartyLeadsBaseUrl: settings.thirdPartyLeadsBaseUrl,
      enquiryFormPostMessageUrl: settings.enquiryFormPostMessageUrl,
      leadsApiUrl: settings.leadsApiUrl,
      imsApiClientBaseUrl: settings.imsApiClientBaseUrl,
      locale: loadLocale(),
    },
    initialised: false,
  },
};

const sagaMiddleware = createSagaMiddleware();

// persistedState: Hydrates store with persisted state from localStorage
const store = createStore(
  reducer,
  persistedState,
  composeWithDevTools(applyMiddleware(sagaMiddleware)),
);

store.subscribe(() => {
  const {
    customerLogin: { customerDetails, authenticationDetails },
    shortlist: { vehicles: shortlistVehicles, ...dealerShortlist },
    compare: { vehicles: compareVehicles, ...dealerCompare },
    vehicleValuations: { valuation },
  } = store.getState().shared;

  const { locale } = store.getState().config.settings;

  persistCustomerState({
    customerDetails,
    authenticationDetails,
    customerShortlist: shortlistVehicles,
    customerCompare: compareVehicles,
    vehicleValuation: valuation,
    locale,
    dealerShortlist,
    dealerCompare,
  });
});

sagas.forEach(saga => sagaMiddleware.run(saga));

// Ideally we should only run the effects for loaded Modules - right now we run them all
const unique = items => [...new Set(items)];
const modulesWithEffects = Object.values(modules).filter(m => !!m.effects);
// Filtering effects by reducerId as these are now shared across brands.
unique(modulesWithEffects.map(({ reducerId, id }) => reducerId || id)).forEach(
  id => {
    const m = modulesWithEffects.find(mod => (mod.reducerId || mod.id) === id);
    sagaMiddleware.run(m.effects);
  },
);

// Pods with self contained data fetching and usage also have their own sagas
// so they need to to be run through sagaMiddleware
Object.entries(pods.podReducers)
  .filter(podReducer => !!podReducer[1].effects)
  .forEach(podReducer => sagaMiddleware.run(podReducer[1].effects));

// Loading from Object.values(shared) was not loading all shared effects.
// The Object.values(shared) function was not returning all the effects in the 'shared' collection.
// Switching to Object.keys(shared) brute force loads all effects from their key.
Object.keys(shared).forEach(
  key => shared[key].effects && sagaMiddleware.run(shared[key].effects),
);

export default store;
