"use strict";

var _interopRequireDefault = require("@babel/runtime/helpers/interopRequireDefault");
Object.defineProperty(exports, "__esModule", {
  value: true
});
Object.defineProperty(exports, "actions", {
  enumerable: true,
  get: function get() {
    return _reducer.actions;
  }
});
exports.availableToAdministration = void 0;
Object.defineProperty(exports, "component", {
  enumerable: true,
  get: function get() {
    return _TestDrive["default"];
  }
});
Object.defineProperty(exports, "config", {
  enumerable: true,
  get: function get() {
    return _config["default"];
  }
});
Object.defineProperty(exports, "constants", {
  enumerable: true,
  get: function get() {
    return _reducer.constants;
  }
});
Object.defineProperty(exports, "defaultConfig", {
  enumerable: true,
  get: function get() {
    return _defaultConfig["default"];
  }
});
exports.description = void 0;
Object.defineProperty(exports, "effects", {
  enumerable: true,
  get: function get() {
    return _effects["default"];
  }
});
exports.id = exports.icon = void 0;
Object.defineProperty(exports, "initialState", {
  enumerable: true,
  get: function get() {
    return _reducer.initialState;
  }
});
exports.name = void 0;
Object.defineProperty(exports, "reducer", {
  enumerable: true,
  get: function get() {
    return _reducer.reducer;
  }
});
Object.defineProperty(exports, "reducerId", {
  enumerable: true,
  get: function get() {
    return _reducer.id;
  }
});
var _Icon = _interopRequireDefault(require("../../modules/TestDrive/Icon"));
var _reducer = require("../../modules/TestDrive/reducer");
var _TestDrive = _interopRequireDefault(require("./TestDrive"));
var _effects = _interopRequireDefault(require("../../modules/TestDrive/effects"));
var _config = _interopRequireDefault(require("../../modules/TestDrive/config"));
var _defaultConfig = _interopRequireDefault(require("../../modules/TestDrive/defaultConfig"));
var name = exports.name = 'Test Drive';
var id = exports.id = 'TestDrive';
var availableToAdministration = exports.availableToAdministration = false;
var description = exports.description = 'A module that takes the user through the booking of a test drive process for a vehicle';
var icon = exports.icon = _Icon["default"];