"use strict";

var _interopRequireDefault = require("@babel/runtime/helpers/interopRequireDefault");
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.availableToAdministration = void 0;
Object.defineProperty(exports, "component", {
  enumerable: true,
  get: function get() {
    return _VehicleGallery["default"];
  }
});
Object.defineProperty(exports, "config", {
  enumerable: true,
  get: function get() {
    return _config["default"];
  }
});
exports.name = exports.id = void 0;
var _VehicleGallery = _interopRequireDefault(require("../../components/VehicleGallery"));
var _config = _interopRequireDefault(require("./config"));
var name = exports.name = 'Vehicle Gallery';
var id = exports.id = 'VehicleGallery';
var availableToAdministration = exports.availableToAdministration = false;