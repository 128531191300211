// @flow
/* eslint-disable import/prefer-default-export */
export const sortOptions = {
  priceAsc: {
    field: 'fullPrice',
    order: 'asc',
    value: 'priceAsc',
  },
  priceDesc: {
    field: 'fullPrice',
    order: 'desc',
    value: 'priceDesc',
  },
  distanceAsc: {
    field: 'distance',
    order: 'asc',
    value: 'distanceAsc',
  },
  mileageAsc: {
    field: 'odometer',
    order: 'asc',
    value: 'mileageAsc',
  },
  mileageDesc: {
    field: 'odometer',
    order: 'desc',
    value: 'mileageDesc',
  },
  ageAsc: {
    field: 'age',
    order: 'asc',
    value: 'ageAsc',
  },
  ageDesc: {
    field: 'age',
    order: 'desc',
    value: 'ageDesc',
  },
};
