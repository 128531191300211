"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
Object.defineProperty(exports, "Email", {
  enumerable: true,
  get: function get() {
    return _Email.Email;
  }
});
Object.defineProperty(exports, "Facebook", {
  enumerable: true,
  get: function get() {
    return _Facebook.Facebook;
  }
});
Object.defineProperty(exports, "Twitter", {
  enumerable: true,
  get: function get() {
    return _Twitter.Twitter;
  }
});
Object.defineProperty(exports, "Whatsapp", {
  enumerable: true,
  get: function get() {
    return _Whatsapp.Whatsapp;
  }
});
var _Facebook = require("./Facebook");
var _Twitter = require("./Twitter");
var _Whatsapp = require("./Whatsapp");
var _Email = require("./Email");