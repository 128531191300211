"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.constants = exports.actions = void 0;
var constants = exports.constants = {
  SET_MARKET_SELECTION: 'SET_MARKET_SELECTION'
};
var actions = exports.actions = {
  setMarketSelection: function setMarketSelection(payload) {
    return {
      type: constants.SET_MARKET_SELECTION,
      payload: payload
    };
  }
};