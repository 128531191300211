"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports["default"] = _default;
function _default() {
  return {
    headingFont: {
      kerning: 1,
      fontSize: 20,
      transform: 'uppercase'
    },
    bodyFont: {
      kerning: 1,
      fontSize: 14,
      transform: 'uppercase'
    },
    overlayVisible: true,
    backgroundColour: {
      label: '#000000',
      value: '#000000'
    },
    backgroundOpacity: 0.8,
    videoFile: {
      label: '',
      value: ''
    },
    preview: true,
    playButtonVisible: true,
    translations: {
      heading: 'placeholder',
      subheading: 'placeholder',
      body: 'placeholder',
      footer: 'placeholder'
    }
  };
}