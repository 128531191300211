"use strict";

var _interopRequireDefault = require("@babel/runtime/helpers/interopRequireDefault");
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.availableToAdministration = void 0;
Object.defineProperty(exports, "component", {
  enumerable: true,
  get: function get() {
    return _AstonMartinFooter["default"];
  }
});
exports.name = exports.id = exports.description = void 0;
var _AstonMartinFooter = _interopRequireDefault(require("./AstonMartinFooter"));
var name = exports.name = 'Aston Martin Footer';
var id = exports.id = 'AstonMartinFooter';
var availableToAdministration = exports.availableToAdministration = true;
var description = exports.description = 'AstonMartinFooter Module';