"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.localiseNumber = exports.localiseCurrency = exports.convertMilesToKm = exports.converKmToMiles = void 0;
var localiseCurrency = exports.localiseCurrency = function localiseCurrency(price, locale, currencyType, fractionDigits) {
  return price !== null && price !== undefined && price.toLocaleString(locale.replace('_', '-'), {
    style: 'currency',
    currency: currencyType || 'EUR',
    minimumFractionDigits: fractionDigits,
    maximumFractionDigits: fractionDigits
  });
};
var localiseNumber = exports.localiseNumber = function localiseNumber(number, locale, fractionDigits) {
  return number !== null && number !== undefined && number.toLocaleString(locale.replace('_', '-'), {
    minimumFractionDigits: fractionDigits,
    maximumFractionDigits: fractionDigits
  });
};
var conversionFactor = 0.621371;
var converKmToMiles = exports.converKmToMiles = function converKmToMiles(km) {
  return km * conversionFactor;
};
var convertMilesToKm = exports.convertMilesToKm = function convertMilesToKm(miles) {
  return miles / conversionFactor;
};