"use strict";

var _interopRequireDefault = require("@babel/runtime/helpers/interopRequireDefault");
Object.defineProperty(exports, "__esModule", {
  value: true
});
Object.defineProperty(exports, "component", {
  enumerable: true,
  get: function get() {
    return _GenesisFooter["default"];
  }
});
exports.id = exports.description = void 0;
var _GenesisFooter = _interopRequireDefault(require("./GenesisFooter"));
var id = exports.id = 'GenesisFooter';
var description = exports.description = 'GenesisFooter Module';