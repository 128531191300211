"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.constants = exports.actions = void 0;
var constants = exports.constants = {
  changeLanguage: 'CHANGE_LANGUAGE'
};
var actions = exports.actions = {
  changeLanguage: function changeLanguage(language) {
    return {
      type: constants.changeLanguage,
      payload: language
    };
  }
};