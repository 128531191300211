"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports["default"] = void 0;
var _config = require("../../helpers/config");
var config = (0, _config.Config)('Test Drive', {
  id: 'test-drive'
}, (0, _config.Slice)('Date & Time', {
  id: 'date-time'
}));
var _default = exports["default"] = config;