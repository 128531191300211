"use strict";

var _interopRequireDefault = require("@babel/runtime/helpers/interopRequireDefault");
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports["default"] = void 0;
var _styledComponents = _interopRequireDefault(require("styled-components"));
var InactiveFilter = _styledComponents["default"].div.withConfig({
  displayName: "InactiveFilter",
  componentId: "sc-iudo92-0"
})(["align-items:center;color:#121212;display:flex;font:14px/20px 'AstonMartinSans-Regular';justify-content:center;margin-top:8px;text-align:center;"]);
var _default = exports["default"] = InactiveFilter;