"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.displayKiaEmissionsConsumption = exports.displayKiaElectricRange = void 0;
var displayKiaEmissionsConsumption = exports.displayKiaEmissionsConsumption = function displayKiaEmissionsConsumption(vehicle, translations) {
  var fuel = vehicle.fuel,
    engineDetails = vehicle.engineDetails,
    emissions = vehicle.emissions,
    consumption = vehicle.consumption,
    energyEfficiencyRating = vehicle.energyEfficiencyRating;
  switch (fuel) {
    case 'Plug-in Hybrid – (Benzin/Elektro)':
    case 'Plug-in Hybrid– (Diesel/Elektro)':
      if (engineDetails && engineDetails.weightedCombinedFuelConsumption !== null && engineDetails.weightedCombinedCo2Emission !== null && emissions && emissions.co2ClassWeightedCombined !== null && emissions.co2ClassDischaredBattery !== null && consumption && consumption.wltpWeightedCombinedConsumptionDischargedBattery !== null && consumption.wltpPowerConsumptionWeightedCombined !== null && consumption.wltpBatteryRange !== null) {
        return "".concat(translations.weightedCombinedFuelConsumptionLabel, " ").concat(engineDetails.weightedCombinedFuelConsumption, " l/100 km; ").concat(translations.powerConsumptionCombinedLabel, " ").concat(consumption.wltpPowerConsumptionWeightedCombined, " kWh/100 km; ").concat(translations.co2EmissionsCombinedLabel, " ").concat(engineDetails.weightedCombinedCo2Emission, " g/km; ").concat(translations.co2ClassCombinedLabel, " ").concat(emissions.co2ClassWeightedCombined, ". ").concat(translations.fuelConsumptionWithDischargedBatteryLabel, " ").concat(consumption.wltpWeightedCombinedConsumptionDischargedBattery, " l/100 km; ").concat(translations.co2ClassWithDischargedBatteryLabel, " ").concat(emissions.co2ClassDischaredBattery, ".");
      }
      break;
    case 'Strom':
      if (engineDetails && consumption && consumption.wltpPowerConsumptionCombined !== null && emissions && emissions.wltpEmissionsCombined !== null && energyEfficiencyRating !== null && consumption.wltpBatteryRange !== null) {
        return "".concat(translations.combinedPowerLabel, " ").concat(consumption.wltpPowerConsumptionCombined, " kWh/100 km; ").concat(translations.combinedCO2EmissionsLabel, " ").concat(emissions.wltpEmissionsCombined, " g/km; ").concat(translations.co2ClassLabel, " ").concat(energyEfficiencyRating, ".");
      }
      break;
    default:
      if (emissions && emissions.wltpEmissionsCombined !== null && consumption && consumption.wltpConsumptionCombined !== null && energyEfficiencyRating !== null) {
        return "".concat(translations.fuelConsumptionLabel, " ").concat(consumption.wltpConsumptionCombined, " l/100 km; ").concat(translations.combinedCO2EmissionsLabel, " ").concat(emissions.wltpEmissionsCombined, " g/km; ").concat(translations.co2ClassLabel, " ").concat(energyEfficiencyRating, ".");
      }
  }
  return null;
};
var displayKiaElectricRange = exports.displayKiaElectricRange = function displayKiaElectricRange(vehicle, translations) {
  var fuel = vehicle.fuel,
    engineDetails = vehicle.engineDetails,
    emissions = vehicle.emissions,
    consumption = vehicle.consumption,
    energyEfficiencyRating = vehicle.energyEfficiencyRating;
  switch (fuel) {
    case 'Plug-in Hybrid – (Benzin/Elektro)':
    case 'Plug-in Hybrid– (Diesel/Elektro)':
      if (engineDetails && engineDetails.weightedCombinedFuelConsumption !== null && engineDetails.weightedCombinedCo2Emission !== null && emissions && emissions.co2ClassWeightedCombined !== null && emissions.co2ClassDischaredBattery !== null && consumption && consumption.wltpWeightedCombinedConsumptionDischargedBattery !== null && consumption.wltpPowerConsumptionWeightedCombined !== null && consumption.wltpBatteryRange !== null) {
        return "".concat(translations.electricRangeHybridLabel, " ").concat(consumption.wltpBatteryRange, " km.");
      }
      break;
    case 'Strom':
      if (engineDetails && consumption && consumption.wltpPowerConsumptionCombined !== null && emissions && emissions.wltpEmissionsCombined !== null && energyEfficiencyRating !== null && consumption.wltpBatteryRange !== null) {
        return "".concat(translations.electricRangeLabel, " ").concat(consumption.wltpBatteryRange, " km.");
      }
      break;
  }
  return null;
};