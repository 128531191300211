"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports["default"] = void 0;
var _react = require("react");
var useIsWindows = function useIsWindows() {
  return (0, _react.useMemo)(function () {
    return navigator.userAgent.includes('Windows');
  }, []);
};
var _default = exports["default"] = useIsWindows;