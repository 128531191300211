"use strict";

var _interopRequireDefault = require("@babel/runtime/helpers/interopRequireDefault");
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.fileDownload = exports.defaultConfig = exports.blobContentConfig = void 0;
exports.get = get;
exports.httpClientIntercept = httpClientIntercept;
exports.multipartFormConfig = exports.localeConfigSerializer = void 0;
exports.post = post;
exports.put = put;
exports.remove = remove;
var _defineProperty2 = _interopRequireDefault(require("@babel/runtime/helpers/defineProperty"));
var _axios = _interopRequireDefault(require("axios"));
var _querystringEs = _interopRequireDefault(require("querystring-es3"));
var _compose = _interopRequireDefault(require("ramda/src/compose"));
var _reduxSaga = require("redux-saga");
var _error = require("./error");
function ownKeys(e, r) { var t = Object.keys(e); if (Object.getOwnPropertySymbols) { var o = Object.getOwnPropertySymbols(e); r && (o = o.filter(function (r) { return Object.getOwnPropertyDescriptor(e, r).enumerable; })), t.push.apply(t, o); } return t; }
function _objectSpread(e) { for (var r = 1; r < arguments.length; r++) { var t = null != arguments[r] ? arguments[r] : {}; r % 2 ? ownKeys(Object(t), !0).forEach(function (r) { (0, _defineProperty2["default"])(e, r, t[r]); }) : Object.getOwnPropertyDescriptors ? Object.defineProperties(e, Object.getOwnPropertyDescriptors(t)) : ownKeys(Object(t)).forEach(function (r) { Object.defineProperty(e, r, Object.getOwnPropertyDescriptor(t, r)); }); } return e; } /* eslint-env browser */
var axiosRequestErrorMap = {
  put: _error.errorType.Update,
  get: _error.errorType.Load,
  "delete": _error.errorType.Delete,
  post: _error.errorType.Create
};
var CancelToken = _axios["default"].CancelToken;
var defaultConfig = exports.defaultConfig = function defaultConfig(_ref) {
  var token = _ref.token;
  return {
    responseType: 'json',
    headers: token ? {
      Authorization: "Bearer ".concat(token)
    } : {}
  };
};
var blobContentConfig = exports.blobContentConfig = function blobContentConfig(token) {
  return _objectSpread(_objectSpread({}, defaultConfig({
    token: token
  })), {}, {
    responseType: 'blob'
  });
};
var localeConfigSerializer = exports.localeConfigSerializer = function localeConfigSerializer(locale, additionalParams) {
  return {
    paramsSerializer: function paramsSerializer(params) {
      return _querystringEs["default"].stringify(params);
    },
    params: _objectSpread(_objectSpread({}, locale && {
      languageCode: locale
    }), additionalParams)
  };
};
var multipartFormConfig = exports.multipartFormConfig = function multipartFormConfig(token) {
  var postConfig = defaultConfig({
    token: token
  });
  return _objectSpread(_objectSpread({}, postConfig), {}, {
    headers: _objectSpread(_objectSpread({}, postConfig.headers), {
      'content-type': 'multipart/form-data'
    })
  });
};
function get(options) {
  var url = options.url,
    _options$config = options.config,
    config = _options$config === void 0 ? {} : _options$config,
    token = options.token;
  return _axios["default"].get(url, _objectSpread(_objectSpread({}, defaultConfig({
    token: token
  })), config));
}
function post(options) {
  var url = options.url,
    _options$config2 = options.config,
    config = _options$config2 === void 0 ? {} : _options$config2,
    data = options.data,
    token = options.token;
  var cancelTokenSource = CancelToken.source();
  var promise = _axios["default"].post(url, data, _objectSpread(_objectSpread({}, defaultConfig({
    token: token
  })), {}, {
    cancelToken: cancelTokenSource.token
  }, config));
  promise[_reduxSaga.CANCEL] = function () {
    return cancelTokenSource.cancel();
  };
  return promise;
}
function put(options) {
  var url = options.url,
    _options$config3 = options.config,
    config = _options$config3 === void 0 ? {} : _options$config3,
    data = options.data,
    token = options.token;
  return _axios["default"].put(url, data, _objectSpread(_objectSpread({}, defaultConfig({
    token: token
  })), config));
}
function remove(options) {
  var url = options.url,
    _options$config4 = options.config,
    config = _options$config4 === void 0 ? {} : _options$config4,
    data = options.data,
    token = options.token;
  return _axios["default"]["delete"](url, _objectSpread(_objectSpread({}, defaultConfig({
    token: token
  })), {}, {
    data: data
  }, config));
}
var extractLongFileName = function extractLongFileName(headers) {
  return headers['content-disposition'].split(' ')[1];
};
var pretifyLongFileName = function pretifyLongFileName(longFileName) {
  return longFileName ? longFileName.replace('filename=', '').replace(';', '') : 'download.csv';
};
var fileNameFromHeaders = (0, _compose["default"])(pretifyLongFileName, extractLongFileName);
var blobFactory = function blobFactory(headers, data) {
  return new Blob([data], {
    type: headers['content-type'] || 'application/octet-stream'
  });
};
var downloadWithHiddenAnchorTag = function downloadWithHiddenAnchorTag(blob, fileName) {
  var blobURL = window.URL.createObjectURL(blob);
  var tempLink = document.createElement('a');
  tempLink.style.display = 'none';
  tempLink.href = blobURL;
  tempLink.setAttribute('download', fileName);
  if (typeof tempLink.download === 'undefined') {
    tempLink.setAttribute('target', '_blank');
  }
  window.document.body.appendChild(tempLink);
  tempLink.click();
  window.document.body.removeChild(tempLink);
  window.URL.revokeObjectURL(blobURL);
};
var fileDownload = exports.fileDownload = function fileDownload(_ref2) {
  var headers = _ref2.headers,
    data = _ref2.data;
  var fileName = fileNameFromHeaders(headers);
  var blob = blobFactory(headers, data);
  return navigator.msSaveBlob ? navigator.msSaveBlob(blob, fileName) : downloadWithHiddenAnchorTag(blob, fileName);
};
function httpClientIntercept(next) {
  _axios["default"].interceptors.response.use(function (response) {
    return response;
  }, function (error) {
    var config = error.config,
      response = error.response;
    // eslint-disable-next-line no-console
    console.warn('[HTTP ERROR INTERCEPTOR]:', error);
    next(response);
    return Promise.reject(_objectSpread(_objectSpread({
      message: error.message
    }, config && {
      type: axiosRequestErrorMap[error.config && error.config.method.toLowerCase()]
    }), response && {
      status: response.status,
      data: response.data
    }));
  });
}