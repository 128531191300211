import { constants } from '../actions/indexedPages';

const initialState = { content: [] };

export default function reducer(state = initialState, action) {
  switch (action.type) {
    case constants.LoadIndexedPages:
      return {
        ...state,
        content: action.payload,
      };
    default:
      return state;
  }
}
