"use strict";

var _interopRequireDefault = require("@babel/runtime/helpers/interopRequireDefault");
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports["default"] = void 0;
var _config = require("../../helpers/config");
var _SectionIcons = _interopRequireDefault(require("../../helpers/SectionIcons"));
var config = (0, _config.Config)('Brick', {
  id: 'Brick'
}, (0, _config.ListSlice)('Content', {
  id: 'items',
  max: 4,
  sectionLabel: 'Brick item',
  buttonLabel: 'Add new brick item',
  icon: _SectionIcons["default"].GridItem
}, (0, _config.Image)('Image', 'image'), (0, _config.TextArea)('Headline', 'headlineText'), (0, _config.TextArea)('Body Text', 'descriptionText')), (0, _config.Slice)('Styling', {
  id: 'styling'
}, (0, _config.Section)('Global', {
  icon: _SectionIcons["default"].TopTier
}, (0, _config.Colour)('Background', 'brickBackgroundColour'), (0, _config.Font)('Header text', 'brickItemHeaderFont'), (0, _config.Font)('Description text', 'brickItemDescriptionFont'), (0, _config.Toggle)('Border', 'brickPartitionBorderActive'), (0, _config.Colour)('Border Colour', 'brickPartitionBorderColour'))));
var _default = exports["default"] = config;