"use strict";

var _interopRequireDefault = require("@babel/runtime/helpers/interopRequireDefault");
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports["default"] = void 0;
var _config = require("../../helpers/config");
var _SectionIcons = _interopRequireDefault(require("../../helpers/SectionIcons"));
var config = (0, _config.Config)('CookiePolicy', {
  id: 'CookiePolicy'
}, (0, _config.Slice)('Layout', {
  id: 'layout'
}, (0, _config.Section)('Global', {
  icon: _SectionIcons["default"].TopTier
}, (0, _config.Align)('Alignment', 'alignment'))), (0, _config.Slice)('Content', {
  id: 'content'
}, (0, _config.Section)('Cookie Policy', {
  icon: _SectionIcons["default"].MidTier
}, (0, _config.TextArea)('Cookie policy text', 'bodyText'))), (0, _config.Slice)('Styling', {
  id: 'styling'
}, (0, _config.Section)('Styling', {
  icon: _SectionIcons["default"].TopTier
}, (0, _config.Colour)('Background Colour', 'backgroundColour'), (0, _config.Font)('Body text', 'cookiesBodyFont'))));
var _default = exports["default"] = config;