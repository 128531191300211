"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.errorType = void 0;
/* eslint-disable import/prefer-default-export */
var errorType = exports.errorType = {
  Load: 'LOAD',
  Create: 'CREATE',
  Delete: 'DELETE',
  Update: 'UPDATE'
};