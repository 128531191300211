"use strict";

var _interopRequireDefault = require("@babel/runtime/helpers/interopRequireDefault");
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports["default"] = void 0;
var _styledComponents = _interopRequireDefault(require("styled-components"));
var _default = exports["default"] = _styledComponents["default"].div.withConfig({
  displayName: "Wrapper",
  componentId: "sc-fflq05-0"
})(["display:flex;position:relative;word-break:break-all;flex-direction:", ";flex:1 0 25%;", ";", ";", ";"], function (props) {
  return props.flexDirection || 'column';
}, function (_ref) {
  var border = _ref.border;
  return border && border.map(function (val) {
    return "border-".concat(val, ": 1px solid;");
  }).join(' ');
}, function (_ref2) {
  var borderColour = _ref2.borderColour;
  return borderColour && "border-color: ".concat(borderColour.value, ";");
}, function (_ref3) {
  var backgroundColour = _ref3.backgroundColour;
  return backgroundColour && "background-color: ".concat(backgroundColour.value);
});