"use strict";

var _interopRequireDefault = require("@babel/runtime/helpers/interopRequireDefault");
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.initialState = exports.id = exports.constants = exports.actions = void 0;
exports.reducer = reducer;
var _defineProperty2 = _interopRequireDefault(require("@babel/runtime/helpers/defineProperty"));
var _toConsumableArray2 = _interopRequireDefault(require("@babel/runtime/helpers/toConsumableArray"));
var _reducerMacros = require("../../utilities/reducerMacros");
function ownKeys(e, r) { var t = Object.keys(e); if (Object.getOwnPropertySymbols) { var o = Object.getOwnPropertySymbols(e); r && (o = o.filter(function (r) { return Object.getOwnPropertyDescriptor(e, r).enumerable; })), t.push.apply(t, o); } return t; }
function _objectSpread(e) { for (var r = 1; r < arguments.length; r++) { var t = null != arguments[r] ? arguments[r] : {}; r % 2 ? ownKeys(Object(t), !0).forEach(function (r) { (0, _defineProperty2["default"])(e, r, t[r]); }) : Object.getOwnPropertyDescriptors ? Object.defineProperties(e, Object.getOwnPropertyDescriptors(t)) : ownKeys(Object(t)).forEach(function (r) { Object.defineProperty(e, r, Object.getOwnPropertyDescriptor(t, r)); }); } return e; }
var modulePrefix = 'MOD_SEARCH_RESULTS';
var constants = exports.constants = (0, _reducerMacros.moduleConstants)(modulePrefix, ['SHOW_ALL', 'GET_INVENTORY', 'GET_INVENTORY_SUCCESS', 'INIT', 'GET_FINANCE_SUCCESS', 'GET_DEALER', 'GET_DEALER_SUCCESS', 'CLEAR_ALL', 'CHANGE_PAGE', 'LOAD_FINANCE', 'CANCEL_LOADING', 'CLEAR_LOADED']);
var id = exports.id = 'SearchResults';
var actions = exports.actions = {
  init: (0, _reducerMacros.moduleAction)(constants.INIT, id),
  showAll: (0, _reducerMacros.moduleActionWithArgs)(constants.SHOW_ALL, id, function (payload) {
    return payload;
  }),
  getInventory: (0, _reducerMacros.moduleActionWithArgs)(constants.GET_INVENTORY, id, function (payload) {
    return payload;
  }),
  getInventorySuccess: (0, _reducerMacros.moduleActionWithArgs)(constants.GET_INVENTORY_SUCCESS, id, function (config) {
    return config;
  }),
  getFinanceSuccess: (0, _reducerMacros.moduleActionWithArgs)(constants.GET_FINANCE_SUCCESS, id, function (payload) {
    return payload;
  }),
  getDealer: (0, _reducerMacros.moduleActionWithArgs)(constants.GET_DEALER, id, function (dealerId) {
    return dealerId;
  }),
  getDealerSuccess: (0, _reducerMacros.moduleActionWithArgs)(constants.GET_DEALER_SUCCESS, id, function (dealer) {
    return dealer;
  }),
  changePage: (0, _reducerMacros.moduleActionWithArgs)(constants.CHANGE_PAGE, id, function (payload) {
    return payload;
  }),
  loadFinance: (0, _reducerMacros.moduleActionWithArgs)(constants.LOAD_FINANCE, id, function (payload) {
    return payload;
  }),
  cancelLoading: (0, _reducerMacros.moduleActionWithArgs)(constants.CANCEL_LOADING, id, function (payload) {
    return payload;
  }),
  clearLoaded: (0, _reducerMacros.moduleAction)(constants.CLEAR_LOADED, id)
};
var replaceIfExists = function replaceIfExists(vehicles, vehicle) {
  return (0, _toConsumableArray2["default"])(vehicles.map(function (v) {
    return v.id === vehicle.id ? vehicle : v;
  }));
};
var initialState = exports.initialState = {
  infiniteScroll: false,
  loading: false,
  searchResults: {},
  pageSize: 9,
  currentPage: 1,
  dealer: {}
};
function reducer() {
  var state = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : initialState;
  var action = arguments.length > 1 ? arguments[1] : undefined;
  switch (action.type) {
    case constants.SHOW_ALL:
      {
        return _objectSpread(_objectSpread({}, state), {}, {
          infiniteScroll: true
        });
      }
    case constants.CLEAR_LOADED:
      {
        return _objectSpread(_objectSpread({}, state), {}, {
          searchResults: {},
          totalResults: null,
          infiniteScroll: false,
          currentPage: 1,
          loading: false
        });
      }
    case constants.CANCEL_LOADING:
      {
        var resetToFirstPage = action.payload;
        return _objectSpread(_objectSpread({}, state), {}, {
          loading: false,
          infiniteScroll: false
        }, resetToFirstPage && {
          currentPage: 1
        });
      }
    case constants.GET_INVENTORY:
      {
        return _objectSpread(_objectSpread({}, state), {}, {
          loading: true,
          filters: action.payload.filters
        });
      }
    case constants.CHANGE_PAGE:
      {
        return _objectSpread(_objectSpread({}, state), {}, {
          currentPage: action.payload,
          loading: false
        });
      }
    case constants.GET_INVENTORY_SUCCESS:
      {
        var _action$payload = action.payload,
          pageNumber = _action$payload.pageNumber,
          contents = _action$payload.contents,
          total = _action$payload.total;
        return _objectSpread(_objectSpread({}, state), {}, {
          loading: false,
          searchResults: _objectSpread(_objectSpread({}, state.searchResults), {}, (0, _defineProperty2["default"])({}, pageNumber, contents)),
          totalResults: total,
          currentPage: pageNumber
        });
      }
    case constants.GET_FINANCE_SUCCESS:
      {
        var _action$payload2 = action.payload,
          vehicle = _action$payload2.vehicle,
          _pageNumber = _action$payload2.pageNumber;
        return _objectSpread(_objectSpread({}, state), {}, {
          searchResults: _objectSpread(_objectSpread({}, state.searchResults), {}, (0, _defineProperty2["default"])({}, _pageNumber, replaceIfExists(state.searchResults[_pageNumber] || [], vehicle)))
        });
      }
    case constants.GET_DEALER_SUCCESS:
      {
        return _objectSpread(_objectSpread({}, state), {}, {
          dealer: action.payload
        });
      }
    default:
      return state;
  }
}