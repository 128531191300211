"use strict";

var _interopRequireDefault = require("@babel/runtime/helpers/interopRequireDefault");
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.Button = void 0;
var _taggedTemplateLiteral2 = _interopRequireDefault(require("@babel/runtime/helpers/taggedTemplateLiteral"));
var _styledComponents = _interopRequireDefault(require("styled-components"));
var _Genesis = _interopRequireDefault(require("../../../customThemes/Genesis"));
var _templateObject;
var Button = exports.Button = _styledComponents["default"].button.withConfig({
  displayName: "Button",
  componentId: "sc-9651vo-0"
})(["align-items:center;background-color:#111;border:1px solid #fff;color:#fff;cursor:pointer;display:flex;flex-shrink:0;font-family:'GenesisSansTextGlobal-Regular';font-size:18px;height:56px;justify-content:center;text-align:center;width:290px;box-shadow:inset 0 0 0 0 #fff;transition:ease-out 0.4s;", " &:hover{border:2px solid #fff;color:#000;box-shadow:inset 600px 0 0 0 #fff;}"], _Genesis["default"].max.medium(_templateObject || (_templateObject = (0, _taggedTemplateLiteral2["default"])(["\n    width: 100%;\n  "]))));