"use strict";

var _interopRequireDefault = require("@babel/runtime/helpers/interopRequireDefault");
var _typeof = require("@babel/runtime/helpers/typeof");
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.initialState = exports.constants = exports.actions = void 0;
exports.reducer = reducer;
var _defineProperty2 = _interopRequireDefault(require("@babel/runtime/helpers/defineProperty"));
var localStorageManager = _interopRequireWildcard(require("../../helpers/localStorage"));
function _getRequireWildcardCache(e) { if ("function" != typeof WeakMap) return null; var r = new WeakMap(), t = new WeakMap(); return (_getRequireWildcardCache = function _getRequireWildcardCache(e) { return e ? t : r; })(e); }
function _interopRequireWildcard(e, r) { if (!r && e && e.__esModule) return e; if (null === e || "object" != _typeof(e) && "function" != typeof e) return { "default": e }; var t = _getRequireWildcardCache(r); if (t && t.has(e)) return t.get(e); var n = { __proto__: null }, a = Object.defineProperty && Object.getOwnPropertyDescriptor; for (var u in e) if ("default" !== u && {}.hasOwnProperty.call(e, u)) { var i = a ? Object.getOwnPropertyDescriptor(e, u) : null; i && (i.get || i.set) ? Object.defineProperty(n, u, i) : n[u] = e[u]; } return n["default"] = e, t && t.set(e, n), n; }
function ownKeys(e, r) { var t = Object.keys(e); if (Object.getOwnPropertySymbols) { var o = Object.getOwnPropertySymbols(e); r && (o = o.filter(function (r) { return Object.getOwnPropertyDescriptor(e, r).enumerable; })), t.push.apply(t, o); } return t; }
function _objectSpread(e) { for (var r = 1; r < arguments.length; r++) { var t = null != arguments[r] ? arguments[r] : {}; r % 2 ? ownKeys(Object(t), !0).forEach(function (r) { (0, _defineProperty2["default"])(e, r, t[r]); }) : Object.getOwnPropertyDescriptors ? Object.defineProperties(e, Object.getOwnPropertyDescriptors(t)) : ownKeys(Object(t)).forEach(function (r) { Object.defineProperty(e, r, Object.getOwnPropertyDescriptor(t, r)); }); } return e; }
var constants = exports.constants = {
  INIT: 'INIT',
  UPDATE_SESSION_PREFERENCES: 'UPDATE_SESSION_PREFERENCES',
  UPDATE_MULTIPLE_SESSION_PREFERENCES: 'UPDATE_MULTIPLE_SESSION_PREFERENCES'
};
var actions = exports.actions = {
  updateSessionPreferences: function updateSessionPreferences(key, value) {
    return {
      type: constants.UPDATE_SESSION_PREFERENCES,
      payload: {
        key: key,
        value: value
      }
    };
  },
  updateMultipleSessionPreferences: function updateMultipleSessionPreferences(preferences) {
    return {
      type: constants.UPDATE_MULTIPLE_SESSION_PREFERENCES,
      payload: preferences
    };
  }
};
var initialState = exports.initialState = {
  error: null,
  cookiesAccepted: false,
  searchSort: null,
  language: null,
  infiniteScroll: false,
  disableCurrencyPopup: false,
  currency: null,
  marketSelected: false,
  location: {},
  isHeroVisible: false
};
function reducer() {
  var state = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : initialState;
  var action = arguments.length > 1 ? arguments[1] : undefined;
  var payload = action.payload,
    type = action.type;
  switch (type) {
    case constants.UPDATE_SESSION_PREFERENCES:
      if (payload.value && payload.value.locationTown && payload.value.userCountry) {
        var _payload$value = payload.value,
          locationTown = _payload$value.locationTown,
          userCountry = _payload$value.userCountry;
        localStorageManager.saveKey('userCity', locationTown);
        localStorageManager.saveKey('userCountryCode', userCountry);
      }
      return _objectSpread(_objectSpread({}, state), {}, (0, _defineProperty2["default"])({}, payload.key, payload.value));
    case constants.UPDATE_MULTIPLE_SESSION_PREFERENCES:
      return _objectSpread(_objectSpread({}, state), payload.reduce(function (acc, curr) {
        return _objectSpread(_objectSpread({}, acc), {}, (0, _defineProperty2["default"])({}, curr.key, curr.value));
      }, {}));
    default:
      return state;
  }
}