"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.getName = exports.chunkArrayByNumber = void 0;
var getName = exports.getName = function getName(_ref) {
  var name = _ref.name;
  return name !== undefined ? name : null;
};
var chunkArrayByNumber = exports.chunkArrayByNumber = function chunkArrayByNumber(arr, number) {
  var size = Math.ceil(arr.length / number);
  return Array.from({
    length: number
  }, function (v, i) {
    return arr.slice(i * size, i * size + size);
  });
};