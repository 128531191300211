"use strict";

var _interopRequireDefault = require("@babel/runtime/helpers/interopRequireDefault");
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports["default"] = void 0;
var _slicedToArray2 = _interopRequireDefault(require("@babel/runtime/helpers/slicedToArray"));
var _react = require("react");
var translateMonthName = function translateMonthName(month, locale) {
  var dateOptions = {
    month: 'long'
  };
  var _locale$split = locale.split('_'),
    _locale$split2 = (0, _slicedToArray2["default"])(_locale$split, 2),
    firstChars = _locale$split2[0],
    lastChars = _locale$split2[1];
  var language = "".concat(firstChars, "-").concat(lastChars.toUpperCase());

  // Any year and day will do as we only required a month value to be returned
  return new Date(2019, month, 1).toLocaleDateString(language, dateOptions);
};
var useTranslateMonthName = function useTranslateMonthName(month, locale) {
  return (0, _react.useMemo)(function () {
    return month ? translateMonthName(month, locale) : undefined;
  }, [month, locale]);
};
var _default = exports["default"] = useTranslateMonthName;