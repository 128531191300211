"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports["default"] = defaultConfig;
function defaultConfig() {
  return {
    translations: {
      distanceDisplayText: 'Distance up to {VALUE} km',
      ageDisplayText: 'Age up to {VALUE} years',
      odometerDisplayText: 'Mileage up to {VALUE}',
      fuelConsumptionText: 'Up to {VALUE} l/100km'
    }
  };
}