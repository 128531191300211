"use strict";

var _interopRequireDefault = require("@babel/runtime/helpers/interopRequireDefault");
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.availableToAdministration = void 0;
Object.defineProperty(exports, "component", {
  enumerable: true,
  get: function get() {
    return _BentleyLocationOverlay["default"];
  }
});
exports.name = exports.id = void 0;
var _BentleyLocationOverlay = _interopRequireDefault(require("./BentleyLocationOverlay"));
var name = exports.name = 'BentleyLocationOverlay';
var id = exports.id = 'BentleyLocationOverlay';
var availableToAdministration = exports.availableToAdministration = false;