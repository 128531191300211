"use strict";

var _interopRequireDefault = require("@babel/runtime/helpers/interopRequireDefault");
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.constants = exports.actions = void 0;
exports.reducer = reducer;
var _toConsumableArray2 = _interopRequireDefault(require("@babel/runtime/helpers/toConsumableArray"));
var _defineProperty2 = _interopRequireDefault(require("@babel/runtime/helpers/defineProperty"));
var _reducerMacros = require("../../utilities/reducerMacros");
function ownKeys(e, r) { var t = Object.keys(e); if (Object.getOwnPropertySymbols) { var o = Object.getOwnPropertySymbols(e); r && (o = o.filter(function (r) { return Object.getOwnPropertyDescriptor(e, r).enumerable; })), t.push.apply(t, o); } return t; }
function _objectSpread(e) { for (var r = 1; r < arguments.length; r++) { var t = null != arguments[r] ? arguments[r] : {}; r % 2 ? ownKeys(Object(t), !0).forEach(function (r) { (0, _defineProperty2["default"])(e, r, t[r]); }) : Object.getOwnPropertyDescriptors ? Object.defineProperties(e, Object.getOwnPropertyDescriptors(t)) : ownKeys(Object(t)).forEach(function (r) { Object.defineProperty(e, r, Object.getOwnPropertyDescriptor(t, r)); }); } return e; }
// todo move to another file
var id = 'Enquiry';
var modulePrefix = 'MOD_ENQUIRY';
var constants = exports.constants = (0, _reducerMacros.moduleConstants)(modulePrefix, ['ADDRESS_SUGGESTIONS_REQUESTED', 'ADDRESS_SUGGESTIONS_RECEIVED', 'ADDRESS_SUGGESTIONS_FAILURE', 'ADDRESS_SELECTED', 'GET_LANGUAGE_OPTIONS', 'GET_LANGUAGE_OPTIONS_SUCCESS', 'SUBMIT_THIRD_PARTY_LEAD', 'GET_VIN_VALIDITY', 'GET_VIN_VALIDITY_SUCCESS', 'GET_VIN_VALIDITY_FAILURE', 'RECAPTCHA_VALIDATION_FAILED']);
var actions = exports.actions = {
  addressSuggestionsRequested: (0, _reducerMacros.moduleActionWithArgs)(constants.ADDRESS_SUGGESTIONS_REQUESTED, id, function (addressPart) {
    return addressPart;
  }),
  addressSuggestionsReceived: (0, _reducerMacros.moduleActionWithArgs)(constants.ADDRESS_SUGGESTIONS_RECEIVED, id, function (suggestions) {
    return suggestions;
  }),
  addressSelected: (0, _reducerMacros.moduleAction)(constants.ADDRESS_SELECTED, id, function (suggestions) {
    return suggestions;
  }),
  addressSuggestionsFailed: (0, _reducerMacros.moduleAction)(constants.ADDRESS_SUGGESTIONS_FAILURE, id, function (suggestions) {
    return suggestions;
  }),
  getLanguageOptions: (0, _reducerMacros.moduleAction)(constants.GET_LANGUAGE_OPTIONS, id),
  getLanguageOptionsSuccess: (0, _reducerMacros.moduleActionWithArgs)(constants.GET_LANGUAGE_OPTIONS_SUCCESS, id, function (languageOptions) {
    return languageOptions;
  }),
  submitThirdPartyLead: (0, _reducerMacros.moduleActionWithArgs)(constants.SUBMIT_THIRD_PARTY_LEAD, id, function (lead) {
    return lead;
  }),
  getVinValidity: (0, _reducerMacros.moduleActionWithArgs)(constants.GET_VIN_VALIDITY, id, function (payload) {
    return payload;
  }),
  getVinValiditySuccess: (0, _reducerMacros.moduleActionWithArgs)(constants.GET_VIN_VALIDITY_SUCCESS, id, function (payload) {
    return payload;
  }),
  getVinValidityFailure: (0, _reducerMacros.moduleActionWithArgs)(constants.GET_VIN_VALIDITY_FAILURE, id, function (payload) {
    return payload;
  }),
  submitRecaptchaValidationFailure: (0, _reducerMacros.moduleAction)(constants.RECAPTCHA_VALIDATION_FAILED, id)
};
function reducer(state, action) {
  switch (action.type) {
    case constants.ADDRESS_SUGGESTIONS_REQUESTED:
      return _objectSpread(_objectSpread({}, state), {}, {
        suggestionRequestStatus: 'loading'
      });
    case constants.ADDRESS_SUGGESTIONS_RECEIVED:
      return _objectSpread(_objectSpread({}, state), {}, {
        addressSuggestions: (0, _toConsumableArray2["default"])(action.payload),
        suggestionRequestStatus: 'loaded'
      });
    case constants.ADDRESS_SELECTED:
      return _objectSpread(_objectSpread({}, state), {}, {
        addressSuggestions: [],
        suggestionRequestStatus: 'idle'
      });
    case constants.ADDRESS_SUGGESTIONS_FAILURE:
      return _objectSpread(_objectSpread({}, state), {}, {
        addressSuggestions: [],
        suggestionRequestStatus: 'failure'
      });
    case constants.GET_LANGUAGE_OPTIONS_SUCCESS:
      return _objectSpread(_objectSpread({}, state), {}, {
        languageOptions: action.payload
      });
    case constants.GET_VIN_VALIDITY_SUCCESS:
      return _objectSpread(_objectSpread({}, state), {}, {
        vinValidity: action.payload
      });
    case constants.GET_VIN_VALIDITY_FAILURE:
      return _objectSpread(_objectSpread({}, state), {}, {
        vinValidity: action.payload
      });
    case constants.RECAPTCHA_VALIDATION_FAILED:
      {
        return _objectSpread(_objectSpread({}, state), {}, {
          submissionStatus: 'RecaptchaValidationFailed'
        });
      }
    default:
      return state;
  }
}