"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.useSortResults = exports["default"] = void 0;
var _reactRedux = require("react-redux");
var _filters = require("../shared/filters");
var _sessionPreferences = require("../shared/sessionPreferences");
var useSortResults = exports.useSortResults = function useSortResults(_ref) {
  var sortOptions = _ref.sortOptions;
  var dispatch = (0, _reactRedux.useDispatch)();
  var onSortChange = function onSortChange(payload, sortField) {
    dispatch(_filters.actions.updateFilters(payload, ''));
    dispatch(_sessionPreferences.actions.updateSessionPreferences('searchSort', sortOptions[sortField]));
  };
  return {
    onSortChange: onSortChange
  };
};
var _default = exports["default"] = useSortResults;