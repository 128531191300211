"use strict";

var _interopRequireDefault = require("@babel/runtime/helpers/interopRequireDefault");
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.mountComponent = exports.moduleConstants = exports.moduleActionWithArgs = exports.moduleAction = void 0;
var _defineProperty2 = _interopRequireDefault(require("@babel/runtime/helpers/defineProperty"));
function ownKeys(e, r) { var t = Object.keys(e); if (Object.getOwnPropertySymbols) { var o = Object.getOwnPropertySymbols(e); r && (o = o.filter(function (r) { return Object.getOwnPropertyDescriptor(e, r).enumerable; })), t.push.apply(t, o); } return t; }
function _objectSpread(e) { for (var r = 1; r < arguments.length; r++) { var t = null != arguments[r] ? arguments[r] : {}; r % 2 ? ownKeys(Object(t), !0).forEach(function (r) { (0, _defineProperty2["default"])(e, r, t[r]); }) : Object.getOwnPropertyDescriptors ? Object.defineProperties(e, Object.getOwnPropertyDescriptors(t)) : ownKeys(Object(t)).forEach(function (r) { Object.defineProperty(e, r, Object.getOwnPropertyDescriptor(t, r)); }); } return e; }
var moduleConstants = exports.moduleConstants = function moduleConstants(modulePrefix, keys) {
  return keys.reduce(function (constants, key) {
    return _objectSpread(_objectSpread({}, constants), {}, (0, _defineProperty2["default"])({}, key, "".concat(modulePrefix, "_").concat(key)));
  }, {});
};
var moduleAction = exports.moduleAction = function moduleAction(type, mod) {
  return function (page, instance) {
    return {
      forModuleInstance: true,
      type: type,
      module: {
        id: mod,
        page: page,
        instance: instance
      }
    };
  };
};
var moduleActionWithArgs = exports.moduleActionWithArgs = function moduleActionWithArgs(type, mod, callback) {
  return function (page, instance) {
    for (var _len = arguments.length, rest = new Array(_len > 2 ? _len - 2 : 0), _key = 2; _key < _len; _key++) {
      rest[_key - 2] = arguments[_key];
    }
    return _objectSpread({
      payload: callback.apply(void 0, rest)
    }, moduleAction(type, mod)(page, instance));
  };
};
var mountComponent = exports.mountComponent = function mountComponent(moduleConfig) {
  return {
    forModuleInstance: true,
    type: 'CMS_COMPONENTS_MOUNT',
    module: moduleConfig
  };
};