"use strict";

var _interopRequireDefault = require("@babel/runtime/helpers/interopRequireDefault");
Object.defineProperty(exports, "__esModule", {
  value: true
});
Object.defineProperty(exports, "actions", {
  enumerable: true,
  get: function get() {
    return _reducer.actions;
  }
});
exports.availableToAdministration = void 0;
Object.defineProperty(exports, "component", {
  enumerable: true,
  get: function get() {
    return _VDP["default"];
  }
});
Object.defineProperty(exports, "config", {
  enumerable: true,
  get: function get() {
    return _config["default"];
  }
});
Object.defineProperty(exports, "constants", {
  enumerable: true,
  get: function get() {
    return _reducer.constants;
  }
});
Object.defineProperty(exports, "defaultConfig", {
  enumerable: true,
  get: function get() {
    return _defaultConfig["default"];
  }
});
exports.description = void 0;
Object.defineProperty(exports, "effects", {
  enumerable: true,
  get: function get() {
    return _effects["default"];
  }
});
exports.id = exports.icon = exports.getIndexContent = void 0;
Object.defineProperty(exports, "initialState", {
  enumerable: true,
  get: function get() {
    return _reducer.initialState;
  }
});
exports.name = void 0;
Object.defineProperty(exports, "reducer", {
  enumerable: true,
  get: function get() {
    return _reducer.reducer;
  }
});
Object.defineProperty(exports, "reducerId", {
  enumerable: true,
  get: function get() {
    return _reducer.id;
  }
});
exports.searchable = void 0;
var _Icon = _interopRequireDefault(require("../../VDP/Icon"));
var _VDP = _interopRequireDefault(require("./VDP"));
var _effects = _interopRequireDefault(require("../../VDP/effects"));
var _config = _interopRequireDefault(require("../../VDP/config"));
var _defaultConfig = _interopRequireDefault(require("../../VDP/defaultConfig"));
var _reducer = require("../../VDP/reducer");
var name = exports.name = 'VDP';
var id = exports.id = 'AstonMartinVDP';
var availableToAdministration = exports.availableToAdministration = false;
var description = exports.description = 'Vehicle Details Page';
var icon = exports.icon = _Icon["default"];
var searchable = exports.searchable = true;
var getIndexContent = exports.getIndexContent = function getIndexContent(_ref) {
  var _ref$config = _ref.config,
    _ref$config2 = _ref$config === void 0 ? {} : _ref$config,
    _ref$config2$translat = _ref$config2.translations,
    translations = _ref$config2$translat === void 0 ? {} : _ref$config2$translat,
    relativePath = _ref.relativePath,
    instanceId = _ref.instanceId;
  return [{
    title: translations.vdpAccessoriesHeader,
    content: translations.vdpAccessoriesSubHeader
  }, {
    title: translations.vdpMyDealHeader,
    content: translations.vdpGalleryAndMyDealBottomText
  }].map(function (_ref2, index) {
    var title = _ref2.title,
      content = _ref2.content;
    return {
      key: "".concat(instanceId, "-").concat(index),
      title: title,
      content: content,
      link: relativePath
    };
  }).filter(function (_ref3) {
    var title = _ref3.title,
      content = _ref3.content;
    return !!title || !!content;
  });
};