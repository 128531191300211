"use strict";

var _interopRequireDefault = require("@babel/runtime/helpers/interopRequireDefault");
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.useAnimatedCarouselState = exports["default"] = exports.actions = exports.CarouselConstants = void 0;
var _defineProperty2 = _interopRequireDefault(require("@babel/runtime/helpers/defineProperty"));
var _react = require("react");
var _types = require("./types");
function ownKeys(e, r) { var t = Object.keys(e); if (Object.getOwnPropertySymbols) { var o = Object.getOwnPropertySymbols(e); r && (o = o.filter(function (r) { return Object.getOwnPropertyDescriptor(e, r).enumerable; })), t.push.apply(t, o); } return t; }
function _objectSpread(e) { for (var r = 1; r < arguments.length; r++) { var t = null != arguments[r] ? arguments[r] : {}; r % 2 ? ownKeys(Object(t), !0).forEach(function (r) { (0, _defineProperty2["default"])(e, r, t[r]); }) : Object.getOwnPropertyDescriptors ? Object.defineProperties(e, Object.getOwnPropertyDescriptors(t)) : ownKeys(Object(t)).forEach(function (r) { Object.defineProperty(e, r, Object.getOwnPropertyDescriptor(t, r)); }); } return e; }
// remove any when ts/linter accept enums and <custom>

var actions = exports.actions = {
  reset: function reset() {
    return {
      type: _types.types.RESET
    };
  },
  setDragging: function setDragging(value) {
    return {
      type: _types.types.SET_DRAGGING,
      payload: {
        value: value
      }
    };
  },
  setWidth: function setWidth(value) {
    return {
      type: _types.types.SET_WIDTH,
      payload: {
        value: value
      }
    };
  },
  moveX: function moveX(value) {
    return {
      type: _types.types.MOVE_X,
      payload: {
        value: value
      }
    };
  },
  setCurrentPos: function setCurrentPos(value) {
    return {
      type: _types.types.SET_CURRENT_POS,
      payload: {
        value: value
      }
    };
  },
  updateClientWidth: function updateClientWidth(value) {
    return {
      type: _types.types.UPDATE_CLIENT_WIDTH,
      payload: {
        value: value
      }
    };
  },
  hoverStartNext: function hoverStartNext() {
    return {
      type: _types.types.HOVER_START_NEXT,
      payload: undefined
    };
  },
  hoverEndNext: function hoverEndNext() {
    return {
      type: _types.types.HOVER_END_NEXT,
      payload: undefined
    };
  },
  hoverEndPrev: function hoverEndPrev() {
    return {
      type: _types.types.HOVER_END_PREV,
      payload: undefined
    };
  },
  hoverStartPrev: function hoverStartPrev() {
    return {
      type: _types.types.HOVER_START_PREV,
      payload: undefined
    };
  },
  setCursorVariant: function setCursorVariant(value) {
    return {
      type: _types.types.SET_CURSOR_VARIANT,
      payload: {
        cursorVariant: value
      }
    };
  }
};
var CarouselConstants = exports.CarouselConstants = {
  PERCENTAGE: 0.1,
  //Percentage to move carousel on hover actions
  INITIAL_MARGIN: 48 // Initial left margin
};
var animatedCarouselReducer = function animatedCarouselReducer(state, action) {
  switch (action.type) {
    case _types.types.SET_DRAGGING:
      {
        return _objectSpread(_objectSpread({}, state), {}, {
          isDragging: action.payload.value
        });
      }
    case _types.types.SET_WIDTH:
      {
        return _objectSpread(_objectSpread({}, state), {}, {
          width: action.payload.value,
          posXNew: 0,
          isDragging: false,
          cursor: 'pointer',
          selectedVariant: undefined,
          currentPos: 0
        });
      }
    case _types.types.MOVE_X:
      {
        var x = state.posXNew + action.payload.value;
        var newPos = x >= 0 // go to the init of container
        ? 0 : Math.abs(x) >= state.width // got to the end of container
        ? -state.width : state.posXNew + action.payload.value;
        return _objectSpread(_objectSpread({}, state), {}, {
          posXNew: newPos,
          selectedVariant: 'click',
          cursor: 'pointer'
        });
      }
    case _types.types.SET_CURRENT_POS:
      {
        return _objectSpread(_objectSpread({}, state), {}, {
          currentPos: action.payload.value
        });
      }
    case _types.types.UPDATE_CLIENT_WIDTH:
      {
        return _objectSpread(_objectSpread({}, state), {}, {
          clientWidth: action.payload.value
        });
      }
    case _types.types.HOVER_START_PREV:
      {
        if (state.isDragging || state.currentPos >= 0) {
          return state;
        }
        return _objectSpread(_objectSpread({}, state), {}, {
          cursor: 'pointer',
          posXNew: state.currentPos + state.clientWidth * CarouselConstants.PERCENTAGE,
          selectedVariant: 'hoverStart',
          cursorVariant: 'hoverPrev'
        });
      }
    case _types.types.HOVER_END_PREV:
      {
        if (state.isDragging || state.currentPos === 0 || Math.abs(state.currentPos) < state.clientWidth * CarouselConstants.PERCENTAGE) {
          return state;
        }
        return _objectSpread(_objectSpread({}, state), {}, {
          cursor: 'pointer',
          posXNew: state.currentPos + state.clientWidth * -CarouselConstants.PERCENTAGE,
          selectedVariant: 'hoverEnd',
          cursorVariant: 'default'
        });
      }
    case _types.types.HOVER_START_NEXT:
      {
        if (state.isDragging || Math.abs(state.currentPos) === state.width) {
          return state;
        }
        return _objectSpread(_objectSpread({}, state), {}, {
          cursor: 'pointer',
          posXNew: state.currentPos + state.clientWidth * -CarouselConstants.PERCENTAGE,
          selectedVariant: 'hoverStart',
          cursorVariant: 'hoverNext'
        });
      }
    case _types.types.HOVER_END_NEXT:
      {
        //No more items to show
        if (state.isDragging || Math.abs(state.posXNew) === state.width) {
          return state;
        }
        return _objectSpread(_objectSpread({}, state), {}, {
          cursor: 'pointer',
          posXNew: state.posXNew + state.clientWidth * CarouselConstants.PERCENTAGE,
          selectedVariant: 'hoverEnd',
          cursorVariant: 'default'
        });
      }
    case _types.types.SET_CURSOR_VARIANT:
      {
        return _objectSpread(_objectSpread({}, state), {}, {
          cursorVariant: action.payload.value
        });
      }
    case _types.types.RESET:
      {
        return init();
      }
  }
  return state;
};
var init = function init() {
  return {
    width: 0,
    posXNew: 0,
    isDragging: false,
    cursor: 'pointer',
    selectedVariant: undefined,
    currentPos: 0,
    clientWidth: 0,
    cursorVariant: 'default'
  };
};
var useAnimatedCarouselState = exports.useAnimatedCarouselState = function useAnimatedCarouselState() {
  return (0, _react.useReducer)(animatedCarouselReducer, {
    currentPos: 0,
    cursor: 'pointer',
    isDragging: false,
    posXNew: 0,
    selectedVariant: undefined,
    width: 0,
    clientWidth: 0,
    cursorVariant: 'default'
  }, init);
};
var _default = exports["default"] = useAnimatedCarouselState;