"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.getIpLongitude = exports.getIpLatitude = exports.getIpCountryName = exports.getIpCountryCode = exports.getIpCity = void 0;
var _sessionStorage = require("../../helpers/sessionStorage");
var getIpCountryCode = exports.getIpCountryCode = function getIpCountryCode() {
  return (0, _sessionStorage.getKey)('CO');
};
var getIpCountryName = exports.getIpCountryName = function getIpCountryName() {
  return (0, _sessionStorage.getKey)('CN');
};
var getIpLatitude = exports.getIpLatitude = function getIpLatitude() {
  return (0, _sessionStorage.getKey)('LT');
};
var getIpLongitude = exports.getIpLongitude = function getIpLongitude() {
  return (0, _sessionStorage.getKey)('LG');
};
var getIpCity = exports.getIpCity = function getIpCity() {
  return (0, _sessionStorage.getKey)('UC');
};