"use strict";

var _interopRequireDefault = require("@babel/runtime/helpers/interopRequireDefault");
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.constants = exports.actions = void 0;
exports.reducer = reducer;
var _defineProperty2 = _interopRequireDefault(require("@babel/runtime/helpers/defineProperty"));
var _router = require("../../actions/router");
function ownKeys(e, r) { var t = Object.keys(e); if (Object.getOwnPropertySymbols) { var o = Object.getOwnPropertySymbols(e); r && (o = o.filter(function (r) { return Object.getOwnPropertyDescriptor(e, r).enumerable; })), t.push.apply(t, o); } return t; }
function _objectSpread(e) { for (var r = 1; r < arguments.length; r++) { var t = null != arguments[r] ? arguments[r] : {}; r % 2 ? ownKeys(Object(t), !0).forEach(function (r) { (0, _defineProperty2["default"])(e, r, t[r]); }) : Object.getOwnPropertyDescriptors ? Object.defineProperties(e, Object.getOwnPropertyDescriptors(t)) : ownKeys(Object(t)).forEach(function (r) { Object.defineProperty(e, r, Object.getOwnPropertyDescriptor(t, r)); }); } return e; }
var constants = exports.constants = {
  TOGGLE_ENQUIRE_DRAWER: 'TOGGLE_DRAWER',
  TOGGLE_FINANCE_DRAWER: 'TOGGLE_FINANCE_DRAWER'
};
var actions = exports.actions = {
  toggleEnquireDrawer: function toggleEnquireDrawer(payload) {
    return {
      type: constants.TOGGLE_ENQUIRE_DRAWER,
      payload: payload
    };
  },
  toggleFinanceDrawer: function toggleFinanceDrawer(payload) {
    return {
      type: constants.TOGGLE_FINANCE_DRAWER,
      payload: payload
    };
  }
};
var initialState = {
  enquireDrawer: false,
  financeDrawer: false,
  currentVehicle: null
};
function reducer() {
  var state = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : initialState;
  var _ref = arguments.length > 1 ? arguments[1] : undefined,
    type = _ref.type,
    payload = _ref.payload;
  switch (type) {
    case constants.TOGGLE_ENQUIRE_DRAWER:
      return _objectSpread(_objectSpread({}, state), {}, {
        enquireDrawer: !state.enquireDrawer,
        currentVehicle: !state.enquireDrawer ? payload : null
      });
    case constants.TOGGLE_FINANCE_DRAWER:
      return _objectSpread(_objectSpread({}, state), {}, {
        financeDrawer: !state.financeDrawer
      }, payload ? {
        currentVehicle: payload
      } : {});
    case _router.constants.urlChangeNavigation:
      return _objectSpread(_objectSpread({}, state), {}, {
        enquireDrawer: false,
        financeDrawer: false,
        currentVehicle: null
      });
    default:
      return state;
  }
}