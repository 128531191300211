"use strict";

var _interopRequireDefault = require("@babel/runtime/helpers/interopRequireDefault");
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.isTokenValid = exports.initialState = exports.getToken = exports.getCustomerDetails = exports.constants = exports.actions = void 0;
exports.reducer = reducer;
exports.selectors = void 0;
var _defineProperty2 = _interopRequireDefault(require("@babel/runtime/helpers/defineProperty"));
var _reducerMacros = require("../../utilities/reducerMacros");
function ownKeys(e, r) { var t = Object.keys(e); if (Object.getOwnPropertySymbols) { var o = Object.getOwnPropertySymbols(e); r && (o = o.filter(function (r) { return Object.getOwnPropertyDescriptor(e, r).enumerable; })), t.push.apply(t, o); } return t; }
function _objectSpread(e) { for (var r = 1; r < arguments.length; r++) { var t = null != arguments[r] ? arguments[r] : {}; r % 2 ? ownKeys(Object(t), !0).forEach(function (r) { (0, _defineProperty2["default"])(e, r, t[r]); }) : Object.getOwnPropertyDescriptors ? Object.defineProperties(e, Object.getOwnPropertyDescriptors(t)) : ownKeys(Object(t)).forEach(function (r) { Object.defineProperty(e, r, Object.getOwnPropertyDescriptor(t, r)); }); } return e; }
var modulePrefix = 'MOD_CUSTOMER_LOGIN';
var id = 'Login';
var constants = exports.constants = (0, _reducerMacros.moduleConstants)(modulePrefix, ['CREATE_ACCOUNT', 'LOGIN', 'LOGIN_SUCCESS', 'LOGIN_FAILURE', 'CREATE_CUSTOMER_SUCCESS', 'CREATE_CUSTOMER_FAILURE', 'GET_CUSTOMER_SUCCESS', 'GET_CUSTOMER_FAILURE', 'SAVE_USER_DETAILS', 'SAVE_USER_DETAILS_SUCCESS', 'SAVE_USER_DETAILS_FAILURE', 'CHANGE_PASSWORD', 'CHANGE_PASSWORD_SUCCESS', 'CHANGE_PASSWORD_FAILURE', 'DELETE_ACCOUNT', 'DELETE_ACCOUNT_SUCCESS', 'DELETE_ACCOUNT_FAILURE', 'SIGN_OUT']);
var MILLISECONDS_IN_MINUTE = 60000;
var OFFSET = 10 * MILLISECONDS_IN_MINUTE;
var actions = exports.actions = {
  postCreateAccount: (0, _reducerMacros.moduleActionWithArgs)(constants.CREATE_ACCOUNT, id, function (payload) {
    return _objectSpread({}, payload);
  }),
  postLogin: (0, _reducerMacros.moduleActionWithArgs)(constants.LOGIN, id, function (payload) {
    return _objectSpread({}, payload);
  }),
  postLoginSuccess: (0, _reducerMacros.moduleActionWithArgs)(constants.LOGIN_SUCCESS, id, function (payload) {
    return _objectSpread({}, payload);
  }),
  postLoginFailure: (0, _reducerMacros.moduleActionWithArgs)(constants.LOGIN_FAILURE, id, function (payload) {
    return _objectSpread({}, payload);
  }),
  createCustomerAccountSuccess: (0, _reducerMacros.moduleActionWithArgs)(constants.CREATE_CUSTOMER_SUCCESS, id, function (payload) {
    return _objectSpread({}, payload);
  }),
  createCustomerAccountFailure: (0, _reducerMacros.moduleActionWithArgs)(constants.CREATE_CUSTOMER_FAILURE, id, function (failMessage) {
    return _objectSpread({}, failMessage);
  }),
  postGetCustomerSuccess: (0, _reducerMacros.moduleActionWithArgs)(constants.GET_CUSTOMER_SUCCESS, id, function (payload) {
    return _objectSpread({}, payload);
  }),
  postGetCustomerFailure: (0, _reducerMacros.moduleActionWithArgs)(constants.GET_CUSTOMER_FAILURE, id, function (failMessage) {
    return _objectSpread({}, failMessage);
  }),
  saveUserDetails: (0, _reducerMacros.moduleActionWithArgs)(constants.SAVE_USER_DETAILS, id, function (details) {
    return _objectSpread({}, details);
  }),
  saveUserDetailsSuccess: (0, _reducerMacros.moduleActionWithArgs)(constants.SAVE_USER_DETAILS_SUCCESS, id, function (successMessage) {
    return _objectSpread({}, successMessage);
  }),
  saveUserDetailsFailure: (0, _reducerMacros.moduleActionWithArgs)(constants.SAVE_USER_DETAILS_FAILURE, id, function (failMessage) {
    return _objectSpread({}, failMessage);
  }),
  changePassword: (0, _reducerMacros.moduleActionWithArgs)(constants.CHANGE_PASSWORD, id, function (passwords) {
    return _objectSpread({}, passwords);
  }),
  changePasswordSuccess: (0, _reducerMacros.moduleActionWithArgs)(constants.CHANGE_PASSWORD_SUCCESS, id, function (successMessage) {
    return _objectSpread({}, successMessage);
  }),
  changePasswordFailure: (0, _reducerMacros.moduleActionWithArgs)(constants.CHANGE_PASSWORD_FAILURE, id, function (failMessage) {
    return _objectSpread({}, failMessage);
  }),
  deleteAccount: (0, _reducerMacros.moduleActionWithArgs)(constants.DELETE_ACCOUNT, id, function (password) {
    return _objectSpread({}, password);
  }),
  deleteAccountSuccess: (0, _reducerMacros.moduleAction)(constants.DELETE_ACCOUNT_SUCCESS, id),
  deleteAccountFailure: (0, _reducerMacros.moduleActionWithArgs)(constants.DELETE_ACCOUNT_FAILURE, id, function (failMessage) {
    return _objectSpread({}, failMessage);
  }),
  signOut: (0, _reducerMacros.moduleAction)(constants.SIGN_OUT, id)
};
var initialState = exports.initialState = {
  // @TODO: authenticationDetails will be renamed
  authenticationDetails: null,
  customerDetails: null
};
function reducer() {
  var state = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : initialState;
  var action = arguments.length > 1 ? arguments[1] : undefined;
  var payload = action.payload,
    type = action.type;
  switch (type) {
    case constants.LOGIN_SUCCESS:
      {
        return _objectSpread(_objectSpread({}, state), {}, {
          authenticationDetails: payload.auth
        });
      }
    case constants.LOGIN_FAILURE:
      {
        return _objectSpread(_objectSpread({}, state), {}, {
          authenticationDetails: null
        });
      }
    case constants.GET_CUSTOMER_SUCCESS:
      {
        return _objectSpread(_objectSpread({}, state), {}, {
          customerDetails: payload.customerDetails
        });
      }
    case constants.DELETE_ACCOUNT_SUCCESS:
    case constants.SIGN_OUT:
      {
        return _objectSpread(_objectSpread({}, state), {}, {
          customerDetails: null,
          authenticationDetails: null
        });
      }
    default:
      return state;
  }
}

// selectors
var getToken = exports.getToken = function getToken(state) {
  return (state.customerLogin.authenticationDetails || {}).accessToken;
};
var getCustomerDetails = exports.getCustomerDetails = function getCustomerDetails(state) {
  return state.customerLogin.customerDetails;
};
var isTokenValid = exports.isTokenValid = function isTokenValid(state) {
  return Date.parse((state.customerLogin.authenticationDetails || {}).expiresAt) > Date.now() + OFFSET;
};
var tokenExists = function tokenExists(state) {
  return !!state.customerLogin.authenticationDetails;
};
var selectors = exports.selectors = {
  getToken: getToken,
  getCustomerDetails: getCustomerDetails,
  isTokenValid: isTokenValid,
  tokenExists: tokenExists
};