"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.transformRangeProps = exports.transformCheckboxProps = exports.getInitialRangeValues = exports.getFullRangeValues = exports.getAgeInitialRangeValues = exports.getAgeFullRangeValues = exports.distanceOptions = exports.calculateYearRegistered = exports.calculateAge = void 0;
var formatCheckboxProps = function formatCheckboxProps(availableFilters, selectedFilters, id, filter) {
  return {
    isDisabled: !availableFilters.includes(filter) && !selectedFilters.includes(filter),
    isChecked: selectedFilters.includes(filter),
    value: filter,
    id: id
  };
};
var formatFilters = function formatFilters(filters, id) {
  var _filters$allFilters = filters.allFilters,
    allFilters = _filters$allFilters === void 0 ? {} : _filters$allFilters,
    _filters$availableFil = filters.availableFilters,
    availableFilters = _filters$availableFil === void 0 ? {} : _filters$availableFil,
    _filters$selectedFilt = filters.selectedFilters,
    selectedFilters = _filters$selectedFilt === void 0 ? {} : _filters$selectedFilt;
  var available = availableFilters[id] || [];
  var selected = selectedFilters[id] || [];
  var all = allFilters[id] || [];
  return all.map(function (filter) {
    return formatCheckboxProps(available, selected, id, filter);
  });
};

// round ranges to nearest step value for slider compatibility
var getInitialRangeValues = exports.getInitialRangeValues = function getInitialRangeValues(step) {
  var range = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : {
    min: 0,
    max: 0
  };
  return [Math.round(range.min / step) * step, Math.round(range.max / step) * step];
};

// round ranges to nearest step value for slider compatibility
var getFullRangeValues = exports.getFullRangeValues = function getFullRangeValues(step) {
  var range = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : {
    min: 0,
    max: 0
  };
  return {
    min: Math.round(range.min / step) * step,
    max: Math.round(range.max / step) * step
  };
};
var calculateAge = exports.calculateAge = function calculateAge(manufacturedYear) {
  return new Date().getFullYear() - manufacturedYear;
};
var calculateYearRegistered = exports.calculateYearRegistered = function calculateYearRegistered(age) {
  return new Date().getFullYear() - age;
};

// get age from registration year
var getAgeFullRangeValues = exports.getAgeFullRangeValues = function getAgeFullRangeValues(step) {
  var range = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : {
    min: 0,
    max: 0
  };
  return {
    min: calculateAge(range.max),
    max: calculateAge(range.min)
  };
};

// get age from registration year
var getAgeInitialRangeValues = exports.getAgeInitialRangeValues = function getAgeInitialRangeValues(step) {
  var range = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : {
    min: 0,
    max: 0
  };
  return [calculateAge(range.max), calculateAge(range.min)];
};

// Transforms required for the api
// The api request is filters?Model=XF&Model=XSF
// The api response is "models": ["XF", "XSF"]
var transformCheckboxProps = exports.transformCheckboxProps = function transformCheckboxProps(allFilters, availableFilters, selectedFilters) {
  return {
    models: {
      titleKey: 'searchSubHeaderModel',
      checkboxes: formatFilters({
        allFilters: allFilters,
        availableFilters: allFilters,
        selectedFilters: selectedFilters
      }, 'models'),
      id: 'models'
    },
    variants: {
      titleKey: 'searchSubHeaderVariant',
      checkboxes: formatFilters({
        allFilters: allFilters,
        availableFilters: availableFilters,
        selectedFilters: selectedFilters
      }, 'variants'),
      id: 'variants'
    },
    vehicleTypes: {
      checkboxes: formatFilters({
        allFilters: allFilters,
        availableFilters: availableFilters,
        selectedFilters: selectedFilters
      }, 'vehicleTypes'),
      id: 'vehicleTypes'
    },
    transmissions: {
      titleKey: 'searchSubHeaderTransmission',
      checkboxes: formatFilters({
        allFilters: allFilters,
        availableFilters: availableFilters,
        selectedFilters: selectedFilters
      }, 'transmissionTypes'),
      id: 'transmissionTypes'
    },
    engineSizes: {
      titleKey: 'searchSubHeaderEngineSize',
      checkboxes: formatFilters({
        allFilters: allFilters,
        availableFilters: availableFilters,
        selectedFilters: selectedFilters
      }, 'engineSizes'),
      id: 'engineSizes'
    },
    engine: {
      titleKey: 'searchSubHeaderEngine',
      checkboxes: formatFilters({
        allFilters: allFilters,
        availableFilters: availableFilters,
        selectedFilters: selectedFilters
      }, 'fuelTypes'),
      id: 'fuelTypes'
    },
    bodyStyles: {
      checkboxes: formatFilters({
        allFilters: allFilters,
        availableFilters: availableFilters,
        selectedFilters: selectedFilters
      }, 'bodystyles'),
      id: 'bodystyles'
    },
    exteriorColours: {
      titleKey: 'searchSubHeaderExteriorColours',
      checkboxes: formatFilters({
        allFilters: allFilters,
        availableFilters: availableFilters,
        selectedFilters: selectedFilters
      }, 'colours'),
      id: 'colours'
    },
    interiorShades: {
      titleKey: 'searchSubHeaderInteriorShades',
      checkboxes: formatFilters({
        allFilters: allFilters,
        availableFilters: availableFilters,
        selectedFilters: selectedFilters
      }, 'interiorShades'),
      id: 'interiorShades'
    },
    features: {
      checkboxes: formatFilters({
        allFilters: allFilters,
        availableFilters: availableFilters,
        selectedFilters: selectedFilters
      }, 'equipment'),
      id: 'equipment'
    }
  };
};

// Transforms required for the api
// The api request is filters?MaxMileage=50444&MinMileage=1444
// The api response is "mileageRange": { "max": 50000, "min": 1500 }
var transformRangeProps = exports.transformRangeProps = function transformRangeProps(rangeFilters) {
  return {
    mileageRange: {
      id: 'mileageRange',
      name: ['MinMileage', 'MaxMileage'],
      step: 500,
      defaultValues: getInitialRangeValues(500, rangeFilters.mileageRange),
      fullRangeValues: getFullRangeValues(500, rangeFilters.mileageRange)
    },
    yearRange: {
      id: 'yearRange',
      name: ['MaxYear', 'MinYear'],
      step: 1,
      defaultValues: getAgeInitialRangeValues(1, rangeFilters.yearRange),
      fullRangeValues: getAgeFullRangeValues(1, rangeFilters.yearRange)
    },
    priceRange: {
      id: 'priceRange',
      name: ['MaxPrice', 'MinPrice'],
      step: 100,
      defaultValues: getInitialRangeValues(100, rangeFilters.priceRange),
      fullRangeValues: getFullRangeValues(100, rangeFilters.priceRange)
    },
    monthlyPaymentRange: {
      id: 'monthlyPaymentRange',
      name: ['MaxMonthlyPayment', 'MinMonthlyPayment'],
      step: 100,
      defaultValues: getInitialRangeValues(100, rangeFilters.monthlyPaymentRange || {}),
      fullRangeValues: getFullRangeValues(100, rangeFilters.monthlyPaymentRange || {})
    }
  };
};
var distanceOptions = exports.distanceOptions = function distanceOptions(translations) {
  return [{
    label: translations.searchDistanceNationwide,
    value: 'all'
  }, {
    label: '10 KM',
    value: 10
  }, {
    label: '20 KM',
    value: 20
  }, {
    label: '50 KM',
    value: 50
  }, {
    label: '100 KM',
    value: 100
  }];
};