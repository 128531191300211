"use strict";

var _interopRequireDefault = require("@babel/runtime/helpers/interopRequireDefault");
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.matchesPathname = exports.bandWithConfigSelector = void 0;
var _defineProperty2 = _interopRequireDefault(require("@babel/runtime/helpers/defineProperty"));
var _lensPath = _interopRequireDefault(require("ramda/src/lensPath"));
var _view = _interopRequireDefault(require("ramda/src/view"));
function ownKeys(e, r) { var t = Object.keys(e); if (Object.getOwnPropertySymbols) { var o = Object.getOwnPropertySymbols(e); r && (o = o.filter(function (r) { return Object.getOwnPropertyDescriptor(e, r).enumerable; })), t.push.apply(t, o); } return t; }
function _objectSpread(e) { for (var r = 1; r < arguments.length; r++) { var t = null != arguments[r] ? arguments[r] : {}; r % 2 ? ownKeys(Object(t), !0).forEach(function (r) { (0, _defineProperty2["default"])(e, r, t[r]); }) : Object.getOwnPropertyDescriptors ? Object.defineProperties(e, Object.getOwnPropertyDescriptors(t)) : ownKeys(Object(t)).forEach(function (r) { Object.defineProperty(e, r, Object.getOwnPropertyDescriptor(t, r)); }); } return e; }
var matchesPathname = exports.matchesPathname = function matchesPathname(selector, hashRouting) {
  return RegExp(selector.regex).test(hashRouting ? window.location.hash : window.location.pathname);
};
var matchReduxPath = function matchReduxPath(selector, state) {
  return RegExp(selector.valueCheck).test((0, _view["default"])((0, _lensPath["default"])(selector.reduxPath.split('.')), state));
};
var bandWithConfigSelector = exports.bandWithConfigSelector = function bandWithConfigSelector(band, hashRouting, state) {
  var configProp = (band.configSelector || []).find(function (selector) {
    return selector.reduxPath ? matchReduxPath(selector, state) : matchesPathname(selector, hashRouting);
  }) || {
    prop: 'config'
  };
  return _objectSpread(_objectSpread({}, band), {}, {
    config: band[configProp.prop]
  });
};