"use strict";

var _interopRequireDefault = require("@babel/runtime/helpers/interopRequireDefault");
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports["default"] = void 0;
var _slicedToArray2 = _interopRequireDefault(require("@babel/runtime/helpers/slicedToArray"));
var _react = require("react");
var _reactRedux = require("react-redux");
var _enquiry = require("../../actions/enquiry");
var _settings = require("../../shared/selectors/settings");
var _vehicle = require("../../helpers/vehicle");
var _default = exports["default"] = function _default(_ref) {
  var _ref$config = _ref.config,
    redirect = _ref$config.redirect,
    properties = _ref$config.properties,
    template = _ref$config.template;
  var dispatch = (0, _reactRedux.useDispatch)();
  var vehicle = (0, _reactRedux.useSelector)(function (state) {
    return state.vehicleInContext;
  });
  var locale = (0, _reactRedux.useSelector)(_settings.getLocale);
  var path = (0, _reactRedux.useSelector)(function (state) {
    return state.router.path;
  });
  var hashRouting = (0, _reactRedux.useSelector)(function (state) {
    return state.config.settings.hashRouting;
  });
  var hash = (0, _reactRedux.useSelector)(function (state) {
    return state.router.hash;
  });
  var _split$reverse = (hashRouting ? hash : path).split('/').reverse(),
    _split$reverse2 = (0, _slicedToArray2["default"])(_split$reverse, 1),
    vehicleId = _split$reverse2[0];
  (0, _react.useEffect)(function () {
    dispatch(_enquiry.actions.getEnquiryVehicleInfo(vehicleId));
  }, []);
  (0, _react.useEffect)(function () {
    if (vehicle && properties) {
      var formattedArgs = (0, _vehicle.urlTemplateFormatter)(properties, vehicle, locale, template);
      window.open("".concat(redirect, "/").concat(formattedArgs), '_blank');
      window.history.go(-1);
      return function () {
        dispatch(_enquiry.actions.clearEnquiryVehicleInfo());
      };
    }
    return function () {};
  }, [vehicle]);
  return null;
};