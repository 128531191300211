"use strict";

var _interopRequireDefault = require("@babel/runtime/helpers/interopRequireDefault");
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports["default"] = void 0;
var _styledComponents = _interopRequireDefault(require("styled-components"));
var TableRow = _styledComponents["default"].tr.withConfig({
  displayName: "TableRow",
  componentId: "sc-1x7waoz-0"
})(["font-size:", ";&:last-child{td{border-bottom:", ";}}"], function (_ref) {
  var fontSize = _ref.fontSize;
  return fontSize ? "".concat(fontSize, "px") : '20px';
}, function (_ref2) {
  var hideBottomBorder = _ref2.hideBottomBorder;
  return hideBottomBorder ? 'none' : '0.5px solid #151515';
});
var _default = exports["default"] = TableRow;