"use strict";

var _interopRequireDefault = require("@babel/runtime/helpers/interopRequireDefault");
var _typeof = require("@babel/runtime/helpers/typeof");
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.podReducers = exports["default"] = void 0;
var _objectWithoutProperties2 = _interopRequireDefault(require("@babel/runtime/helpers/objectWithoutProperties"));
var _Text = _interopRequireDefault(require("./Text"));
var _Image = _interopRequireDefault(require("./Image"));
var _TextImage = _interopRequireDefault(require("./TextImage"));
var _TextCTA = _interopRequireDefault(require("./TextCTA"));
var _ImageTitle = _interopRequireDefault(require("./ImageTitle"));
var _Brick = _interopRequireDefault(require("./Brick"));
var _Spacer = _interopRequireDefault(require("./Spacer"));
var _IconGrid = _interopRequireDefault(require("./IconGrid"));
var _Video = _interopRequireDefault(require("./Video"));
var _HeroCarousel = _interopRequireDefault(require("./HeroCarousel"));
var _VehicleSpecifications = _interopRequireDefault(require("./VehicleSpecifications"));
var _VehicleCarousel = _interopRequireDefault(require("./VehicleCarousel"));
var _VehicleEquipment = _interopRequireDefault(require("./VehicleEquipment"));
var _QuickContact = _interopRequireDefault(require("./QuickContact"));
var _Heading = _interopRequireDefault(require("./Heading"));
var _VehicleRetailerInfo = _interopRequireDefault(require("./VehicleRetailerInfo"));
var _Header = _interopRequireDefault(require("./Header"));
var _Footer = _interopRequireDefault(require("./Footer"));
var _Filters = _interopRequireDefault(require("./Filters"));
var _BlogPost = _interopRequireDefault(require("./BlogPost"));
var _CookiePolicy = _interopRequireDefault(require("./CookiePolicy"));
var _EnquiryRedirect = _interopRequireDefault(require("./EnquiryRedirect"));
var _Form = _interopRequireDefault(require("./Form"));
var _FeatureInventoryRange = _interopRequireDefault(require("./FeatureInventoryRange"));
var _SearchResults = _interopRequireDefault(require("./SearchResults"));
var _PostSummary = _interopRequireDefault(require("./PostSummary"));
var ModelSearchPod = _interopRequireWildcard(require("./ModelSearch"));
var FeaturedVehiclePod = _interopRequireWildcard(require("./FeaturedVehicle"));
var DealerLocatorPod = _interopRequireWildcard(require("./DealerLocator"));
function _getRequireWildcardCache(e) { if ("function" != typeof WeakMap) return null; var r = new WeakMap(), t = new WeakMap(); return (_getRequireWildcardCache = function _getRequireWildcardCache(e) { return e ? t : r; })(e); }
function _interopRequireWildcard(e, r) { if (!r && e && e.__esModule) return e; if (null === e || "object" != _typeof(e) && "function" != typeof e) return { "default": e }; var t = _getRequireWildcardCache(r); if (t && t.has(e)) return t.get(e); var n = { __proto__: null }, a = Object.defineProperty && Object.getOwnPropertyDescriptor; for (var u in e) if ("default" !== u && {}.hasOwnProperty.call(e, u)) { var i = a ? Object.getOwnPropertyDescriptor(e, u) : null; i && (i.get || i.set) ? Object.defineProperty(n, u, i) : n[u] = e[u]; } return n["default"] = e, t && t.set(e, n), n; }
var ModelSearch = ModelSearchPod["default"],
  modelSearchReducer = (0, _objectWithoutProperties2["default"])(ModelSearchPod, ["default"]);
var FeaturedVehicle = FeaturedVehiclePod["default"],
  featuredVehicleReducer = (0, _objectWithoutProperties2["default"])(FeaturedVehiclePod, ["default"]);
var DealerLocator = DealerLocatorPod["default"],
  dealerLocatorReducer = (0, _objectWithoutProperties2["default"])(DealerLocatorPod, ["default"]);
var pods = {
  Text: _Text["default"],
  Image: _Image["default"],
  TextImage: _TextImage["default"],
  TextCTA: _TextCTA["default"],
  ImageTitle: _ImageTitle["default"],
  Brick: _Brick["default"],
  Spacer: _Spacer["default"],
  IconGrid: _IconGrid["default"],
  Video: _Video["default"],
  HeroCarousel: _HeroCarousel["default"],
  VehicleSpecifications: _VehicleSpecifications["default"],
  VehicleCarousel: _VehicleCarousel["default"],
  VehicleEquipment: _VehicleEquipment["default"],
  QuickContact: _QuickContact["default"],
  Heading: _Heading["default"],
  VehicleRetailerInfo: _VehicleRetailerInfo["default"],
  ModelSearch: ModelSearch,
  PostSummary: _PostSummary["default"],
  Header: _Header["default"],
  Footer: _Footer["default"],
  Filters: _Filters["default"],
  BlogPost: _BlogPost["default"],
  FeatureInventoryRange: _FeatureInventoryRange["default"],
  CookiePolicy: _CookiePolicy["default"],
  EnquiryRedirect: _EnquiryRedirect["default"],
  FeaturedVehicle: FeaturedVehicle,
  Form: _Form["default"],
  SearchResults: _SearchResults["default"],
  DealerLocator: DealerLocator
};
var podReducers = exports.podReducers = {
  modelSearchReducer: modelSearchReducer,
  featuredVehicleReducer: featuredVehicleReducer,
  dealerLocatorReducer: dealerLocatorReducer
};
var _default = exports["default"] = pods;