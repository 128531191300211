"use strict";

var _interopRequireDefault = require("@babel/runtime/helpers/interopRequireDefault");
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.availableToAdministration = void 0;
Object.defineProperty(exports, "component", {
  enumerable: true,
  get: function get() {
    return _TextWithImageGrid["default"];
  }
});
exports.searchable = exports.name = exports.id = exports.description = void 0;
var _TextWithImageGrid = _interopRequireDefault(require("./TextWithImageGrid"));
var name = exports.name = 'Text with image grid';
var id = exports.id = 'TextWithImageGrid';
var availableToAdministration = exports.availableToAdministration = true;
var description = exports.description = 'Text with image grid';
var searchable = exports.searchable = true;