"use strict";

var _interopRequireDefault = require("@babel/runtime/helpers/interopRequireDefault");
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.vehiclePropFormatter = exports.vehicleDataFormatter = exports.vanityUrlFormatter = exports.urlTemplateFormatter = exports.translateTemplateWithVehicleData = exports.fromMpgToLitresPer100ToString = exports.fromMpgToLitresPer100 = exports.formatEquipmentColours = exports.dashIfNull = void 0;
var _defineProperty2 = _interopRequireDefault(require("@babel/runtime/helpers/defineProperty"));
var _path = _interopRequireDefault(require("ramda/src/path"));
var _propOr = _interopRequireDefault(require("ramda/src/propOr"));
var _dates = require("../shared/localisation/dates");
var _numbers = require("../shared/localisation/numbers");
var _translateFromTemplate = require("../shared/localisation/translateFromTemplate");
var _Rating = require("./Rating");
var _Emission = require("./Emission");
function ownKeys(e, r) { var t = Object.keys(e); if (Object.getOwnPropertySymbols) { var o = Object.getOwnPropertySymbols(e); r && (o = o.filter(function (r) { return Object.getOwnPropertyDescriptor(e, r).enumerable; })), t.push.apply(t, o); } return t; }
function _objectSpread(e) { for (var r = 1; r < arguments.length; r++) { var t = null != arguments[r] ? arguments[r] : {}; r % 2 ? ownKeys(Object(t), !0).forEach(function (r) { (0, _defineProperty2["default"])(e, r, t[r]); }) : Object.getOwnPropertyDescriptors ? Object.defineProperties(e, Object.getOwnPropertyDescriptors(t)) : ownKeys(Object(t)).forEach(function (r) { Object.defineProperty(e, r, Object.getOwnPropertyDescriptor(t, r)); }); } return e; }
var should = function should(condition, vehicle) {
  var value = condition && (0, _path["default"])(condition.prop.split('.'), vehicle);
  return value && condition["in"].includes(value);
};
var formatters = {
  integer: function integer(d, locale) {
    return (0, _numbers.localiseNumber)(d, locale, 0);
  },
  decimal: function decimal(d, locale) {
    return (0, _numbers.localiseNumber)(d, locale, 2);
  },
  date: function date(d, locale, _ref) {
    var dateFormat = _ref.dateFormat;
    return d && (0, _dates.localeFormattedDate)(d, locale, dateFormat || {
      month: '2-digit',
      year: 'numeric'
    });
  },
  capitalise: function capitalise(d) {
    return d && d.charAt(0).toUpperCase() + d.slice(1).toLowerCase();
  },
  capitaliseEveryWord: function capitaliseEveryWord(d) {
    return d.toLowerCase().split(' ').map(function (word) {
      return word.charAt(0).toUpperCase() + word.substring(1);
    }).join(' ');
  },
  endstring: function endstring(d, _, _ref2) {
    var length = _ref2.length;
    return d && d.slice(-length);
  },
  price: function price(d, locale, _ref3) {
    var currency = _ref3.currency,
      _ref3$decimalPlaces = _ref3.decimalPlaces,
      decimalPlaces = _ref3$decimalPlaces === void 0 ? 0 : _ref3$decimalPlaces;
    return (0, _numbers.localiseCurrency)(d, locale, currency, decimalPlaces);
  },
  priceWithoutCurrency: function priceWithoutCurrency(d, locale, _ref4) {
    var currency = _ref4.currency,
      _ref4$decimalPlaces = _ref4.decimalPlaces,
      decimalPlaces = _ref4$decimalPlaces === void 0 ? 0 : _ref4$decimalPlaces;
    var value = (0, _numbers.localiseCurrency)(d, locale, currency, decimalPlaces);
    return value.replace(/[^0-9\\.\\,-]+/g, '').trim();
  },
  unit: function unit(d, locale, _ref5, vehicle) {
    var _unit = _ref5.unit,
      showZero = _ref5.showZero;
    return (d || d === 0 && showZero && should(showZero, vehicle)) && "".concat((0, _numbers.localiseNumber)(d, locale)).concat(_unit);
  },
  rating: function rating(d, _, _ref6) {
    var max = _ref6.max,
      baseUrl = _ref6.baseUrl,
      fill = _ref6.fill,
      half = _ref6.half,
      empty = _ref6.empty;
    return d && (0, _Rating.Rating)({
      rating: d,
      max: max,
      baseUrl: baseUrl,
      fill: fill,
      half: half,
      empty: empty
    });
  },
  concat: function concat(d, locale, requiredData, vehicle) {
    return (
      // eslint-disable-next-line no-use-before-define
      d && "".concat(d, " ").concat(vehicleDataFormatter(requiredData, vehicle, locale))
    );
  },
  join: function join(d, _, _ref7) {
    var separator = _ref7.separator;
    return d.join(separator);
  },
  emissions: function emissions(d, _, __, vehicle) {
    return vehicle.specification.engineDetails.emissionsClass && (0, _Emission.Emissions)(vehicle);
  },
  kwToHp: function kwToHp(d) {
    return Math.round(d * 1.35962);
  }
};
var vehiclePropFormatter = exports.vehiclePropFormatter = function vehiclePropFormatter(data, vehicle, locale) {
  var value = (0, _path["default"])(data.prop.split('.'), vehicle);
  var formatter = (0, _propOr["default"])(function (d) {
    return d;
  }, data.format, formatters);
  return should(data.not, vehicle) ? null : formatter(value, locale, data.formatArgs || {}, vehicle);
};
var vehicleDataFormatter = exports.vehicleDataFormatter = function vehicleDataFormatter(requiredData, vehicle, locale) {
  var separator = arguments.length > 3 && arguments[3] !== undefined ? arguments[3] : ' ';
  return requiredData.props.map(function (p) {
    return vehiclePropFormatter(p, vehicle, locale);
  }).filter(Boolean).join(separator);
};
var urlTemplateFormatter = exports.urlTemplateFormatter = function urlTemplateFormatter(properties, vehicle, locale, template) {
  return properties.reduce(function (url, prop) {
    var value = encodeURIComponent(vehiclePropFormatter(prop, vehicle, locale));
    var regexGlobal = new RegExp("{".concat(prop.prop, "}"), 'g');
    return url.replace(regexGlobal, value);
  }, template);
};
var vanityUrlFormatter = exports.vanityUrlFormatter = function vanityUrlFormatter(config, vehicle, locale) {
  return encodeURI(vehicleDataFormatter(config.vdpUrlMetaInfo, vehicle, locale, '-').replace(/\s/g, '-').replace(/%/g, 'pc').replace(/#/g, '') // uri encoding of % throws error with history object trying to parse the path if refresh the page, so manual intervention happening here
  );
};
var translateTemplateWithVehicleData = exports.translateTemplateWithVehicleData = function translateTemplateWithVehicleData(translationKey, dataProps, vehicle, translations) {
  return (0, _translateFromTemplate.translateFromTemplate)(translationKey, dataProps.reduce(function (acc, data) {
    return _objectSpread(_objectSpread({}, acc), {}, (0, _defineProperty2["default"])({}, data.key, vehiclePropFormatter(data, vehicle, vehicle.locale)));
  }, {}), translations);
};
var dashIfNull = exports.dashIfNull = function dashIfNull(value) {
  return value || '-';
};

// Converts MPG (Miles per Gallon) to Litres Per 100 Kilometers.
var fromMpgToLitresPer100 = exports.fromMpgToLitresPer100 = function fromMpgToLitresPer100(mpgValue) {
  var LitresPerGallon = 4.54609188;
  var KmPerMiles = 1.609344;
  var ratio = LitresPerGallon / KmPerMiles * 100;
  return Math.round(ratio / mpgValue * 10) / 10;
};
var fromMpgToLitresPer100ToString = exports.fromMpgToLitresPer100ToString = function fromMpgToLitresPer100ToString(value) {
  return "".concat(dashIfNull(value ? fromMpgToLitresPer100(Number(value)) : undefined), " l/100km");
};
var formatEquipmentColours = exports.formatEquipmentColours = function formatEquipmentColours(vehicle, translations) {
  return [vehicle.specification.trim ? (0, _translateFromTemplate.translateFromTemplate)('trimColour', {
    COLOUR: "".concat(vehicle.specification.trim)
  }, translations) : undefined, vehicle.headlineColour ? (0, _translateFromTemplate.translateFromTemplate)('headlineColour', {
    COLOUR: "".concat(vehicle.headlineColour)
  }, translations) : undefined].filter(Boolean);
};