"use strict";

var _interopRequireDefault = require("@babel/runtime/helpers/interopRequireDefault");
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.availableToAdministration = void 0;
Object.defineProperty(exports, "component", {
  enumerable: true,
  get: function get() {
    return _ModelSearch["default"];
  }
});
exports.name = exports.id = exports.description = void 0;
var _ModelSearch = _interopRequireDefault(require("./ModelSearch"));
var name = exports.name = 'RollsRoyceSearch';
var id = exports.id = 'RollsRoyceSearch';
var availableToAdministration = exports.availableToAdministration = false;
var description = exports.description = 'Rolls Royce Search';