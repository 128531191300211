"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.getIpLocatorServiceUrl = exports.getGlobalStyling = void 0;
var getGlobalStyling = exports.getGlobalStyling = function getGlobalStyling(state) {
  return state.config.config.global.globalStyling;
};

/** Gets service api url that gets IP information */
var getIpLocatorServiceUrl = exports.getIpLocatorServiceUrl = function getIpLocatorServiceUrl(state) {
  return state.config.settings.ipInformationServiceUrl;
};