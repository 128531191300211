"use strict";

var _interopRequireDefault = require("@babel/runtime/helpers/interopRequireDefault");
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.useCompareVehicle = void 0;
var _reactRedux = require("react-redux");
var _compare = require("../shared/compare");
var _vehicleStore = require("../shared/selectors/vehicleStore");
var _converters = _interopRequireDefault(require("../helpers/converters"));
/**
 * Hook to handle comparison functionality for a specific vehicle.
 * Uses global state.
 */
var useCompareVehicle = exports.useCompareVehicle = function useCompareVehicle(props) {
  var vehicleId = props.vehicleId;
  var dispatch = (0, _reactRedux.useDispatch)();
  var featureFlags = (0, _reactRedux.useSelector)(function (state) {
    return state.flags;
  });
  var shared = (0, _reactRedux.useSelector)(function (state) {
    return state.shared;
  });

  // Gets from state all selected vehicles for comparison.
  var selectedVehicles = (0, _vehicleStore.getCompareList)(featureFlags)(shared);

  /**
   * Checks whether a vehicle is selected for comparison or not.
   */
  var isCompared = selectedVehicles.some(function (vehicle) {
    return Number(vehicle.id) === Number(vehicleId);
  });

  /**
   * Adds or removes a vehicle from comparison list.
   */
  var toggleCompare = function toggleCompare(vehicle) {
    return dispatch(_compare.actions.toggleVehicle((0, _converters["default"])(vehicle)));
  };
  return {
    isCompared: isCompared,
    toggleCompare: toggleCompare
  };
};