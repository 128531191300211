"use strict";

var _interopRequireDefault = require("@babel/runtime/helpers/interopRequireDefault");
Object.defineProperty(exports, "__esModule", {
  value: true
});
Object.defineProperty(exports, "component", {
  enumerable: true,
  get: function get() {
    return _CookiePolicy["default"];
  }
});
exports.required = exports.position = exports.name = exports.id = exports.global = exports.description = void 0;
var _CookiePolicy = _interopRequireDefault(require("./CookiePolicy"));
var name = exports.name = 'AstonMartinCookiePolicy';
var id = exports.id = 'AstonMartinCookiePolicy';
var description = exports.description = 'Add Cookies Policy to inform users about the use of cookies by a website or an app.';
var global = exports.global = true;
var required = exports.required = false;
var position = exports.position = 1;