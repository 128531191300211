"use strict";

var _interopRequireDefault = require("@babel/runtime/helpers/interopRequireDefault");
Object.defineProperty(exports, "__esModule", {
  value: true
});
Object.defineProperty(exports, "component", {
  enumerable: true,
  get: function get() {
    return _GenesisSubNav["default"];
  }
});
exports.name = exports.id = void 0;
var _GenesisSubNav = _interopRequireDefault(require("./GenesisSubNav"));
var name = exports.name = 'GenesisSubNav';
var id = exports.id = 'GenesisSubNav';