"use strict";

var _interopRequireDefault = require("@babel/runtime/helpers/interopRequireDefault");
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports["default"] = void 0;
var _styledComponents = _interopRequireDefault(require("styled-components"));
var InactiveFilter = _styledComponents["default"].div.withConfig({
  displayName: "InactiveFilter",
  componentId: "sc-1xak9e0-0"
})(["display:flex;align-items:center;justify-content:center;font-family:Bentley-Light;font-size:16px;font-size:14px;text-align:center;text-transform:none;padding:10px;"]);
var _default = exports["default"] = InactiveFilter;