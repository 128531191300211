"use strict";

var _interopRequireDefault = require("@babel/runtime/helpers/interopRequireDefault");
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.availableToAdministration = void 0;
Object.defineProperty(exports, "component", {
  enumerable: true,
  get: function get() {
    return _Shortlist["default"];
  }
});
exports.name = exports.id = exports.description = void 0;
var _Shortlist = _interopRequireDefault(require("./Shortlist"));
var name = exports.name = 'GenesisShortlistGrid';
var id = exports.id = 'GenesisShortlistGrid';
var availableToAdministration = exports.availableToAdministration = false;
var description = exports.description = 'A module that displays the shortlisted vehicles of the user';