"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports["default"] = void 0;
var _react = require("react");
var useIsTouchscreen = function useIsTouchscreen() {
  return (0, _react.useMemo)(function () {
    return 'ontouchstart' in document.documentElement;
  }, []);
};
var _default = exports["default"] = useIsTouchscreen;