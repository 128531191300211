"use strict";

var _interopRequireDefault = require("@babel/runtime/helpers/interopRequireDefault");
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.fontStyleOverride = exports.buttonStyleOverride = void 0;
var _fallbackFonts = _interopRequireDefault(require("./fallbackFonts"));
var fontStyleOverride = exports.fontStyleOverride = function fontStyleOverride() {
  var _ref = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : {},
    typeface = _ref.typeface,
    fontSize = _ref.fontSize,
    kerning = _ref.kerning,
    transform = _ref.transform,
    colour = _ref.colour,
    lineHeight = _ref.lineHeight,
    alignment = _ref.alignment,
    padding = _ref.padding,
    textDecoration = _ref.textDecoration,
    margin = _ref.margin;
  return [typeface && "font-family: ".concat(typeface.value, ",").concat(_fallbackFonts["default"], ";"), fontSize && "font-size: ".concat(fontSize, "px;"), kerning !== undefined && "letter-spacing: ".concat(kerning, "px;"), transform && "text-transform: ".concat(transform, ";"), colour && "color: ".concat(colour.value, ";"), lineHeight && "line-height: ".concat(lineHeight, "px;"), alignment && "text-align: ".concat(alignment, ";"), padding && padding.top && "padding-top: ".concat(padding.top, "px;"), padding && padding.bottom && "padding-bottom: ".concat(padding.bottom, "px;"), padding && padding.left && "padding-left: ".concat(padding.left, "px;"), padding && padding.right && "padding-right: ".concat(padding.right, "px;"), textDecoration && "text-decoration: ".concat(textDecoration), margin && margin.top && "margin-top: ".concat(margin.top, ";"), margin && margin.bottom && "margin-bottom: ".concat(margin.bottom, ";"), margin && margin.left && "margin-left: ".concat(margin.left, ";"), margin && margin.right && "margin-right: ".concat(margin.right, ";")].filter(Boolean).join('');
};
var buttonStyleOverride = exports.buttonStyleOverride = function buttonStyleOverride(font, backgroundColour, hoverBackgroundColour, buttonSize, hoverColour, border, fullBorder) {
  return "\n    ".concat(fontStyleOverride(font), "\n    ").concat([buttonSize && buttonSize.width && "width: ".concat(buttonSize.width, ";"), buttonSize && buttonSize.height && "height: ".concat(buttonSize.height, "!important;"), buttonSize && buttonSize.minWidth && "min-width: ".concat(buttonSize.minWidth, ";"), buttonSize && buttonSize.padding && "padding: ".concat(buttonSize.padding, ";"), buttonSize && buttonSize.fontSize && "font-size: ".concat(buttonSize.fontSize, "px;"), buttonSize && buttonSize.minHeight && "min-height: ".concat(buttonSize.minHeight, ";"), backgroundColour && "background-color: ".concat(backgroundColour.value, ";"), border && border.radius && "border-radius: ".concat(border.radius, ";"), border && "border: 1px solid ".concat(border.value, ";"), fullBorder && "border: ".concat(fullBorder, ";")].filter(Boolean).join(''), "\n\n    svg > rect:not([fill='none']) {\n      ").concat(font && font.colour && "fill: ".concat(font.colour.value), ";\n    }\n    svg > path:not([fill]) {\n      ").concat(font && font.colour && "fill: ".concat(font.colour.value), ";\n    }\n    &:hover {\n      ").concat([hoverBackgroundColour && "background-color: ".concat(hoverBackgroundColour.value, ";"), hoverColour && "color: ".concat(hoverColour.value, ";")].filter(Boolean).join(''), "\n    }\n  ");
};