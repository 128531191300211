"use strict";

var _interopRequireDefault = require("@babel/runtime/helpers/interopRequireDefault");
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.ClickWrapper = void 0;
var _styledComponents = _interopRequireDefault(require("styled-components"));
var ClickWrapper = exports.ClickWrapper = _styledComponents["default"].div.withConfig({
  displayName: "ClickWrapper",
  componentId: "sc-147za0b-0"
})(["cursor:", ";min-height:1px;max-height:", ";display:flex;align-items:center;overflow:hidden;"], function (_ref) {
  var pointer = _ref.pointer;
  return pointer ? 'pointer' : 'default';
}, function (_ref2) {
  var fullScreen = _ref2.fullScreen;
  return fullScreen ? '100vh' : '600px';
});