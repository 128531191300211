"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports["default"] = void 0;
var _config = require("../../../helpers/config");
var config = (0, _config.Config)('GenesisRetailerLocationSearch', {
  id: 'retailer-location-search'
});
var _default = exports["default"] = config;