"use strict";

var _interopRequireDefault = require("@babel/runtime/helpers/interopRequireDefault");
var _typeof = require("@babel/runtime/helpers/typeof");
Object.defineProperty(exports, "__esModule", {
  value: true
});
Object.defineProperty(exports, "CookiePolicyContent", {
  enumerable: true,
  get: function get() {
    return _CookiePolicy.CookiePolicyContent;
  }
});
Object.defineProperty(exports, "CookiePolicyWrapper", {
  enumerable: true,
  get: function get() {
    return _CookiePolicy.CookiePolicyWrapper;
  }
});
Object.defineProperty(exports, "GlobalStyling", {
  enumerable: true,
  get: function get() {
    return _GlobalStyling["default"];
  }
});
exports.components = exports.bands = exports.actions = void 0;
Object.defineProperty(exports, "config", {
  enumerable: true,
  get: function get() {
    return _config["default"];
  }
});
exports.modules = exports.linkedModules = exports.formFields = void 0;
Object.defineProperty(exports, "mountComponent", {
  enumerable: true,
  get: function get() {
    return _reducerMacros.mountComponent;
  }
});
Object.defineProperty(exports, "normalisers", {
  enumerable: true,
  get: function get() {
    return _normalisation["default"];
  }
});
exports.pods = exports.pathBuilders = void 0;
Object.defineProperty(exports, "renderer", {
  enumerable: true,
  get: function get() {
    return _renderer["default"];
  }
});
exports.shared = void 0;
var _defineProperty2 = _interopRequireDefault(require("@babel/runtime/helpers/defineProperty"));
var formFields = _interopRequireWildcard(require("./pods/components/formFields"));
exports.formFields = formFields;
var genericModules = _interopRequireWildcard(require("./modules"));
var JaguarModules = _interopRequireWildcard(require("./modules.jaguar"));
var linkedModules = _interopRequireWildcard(require("./linked-modules"));
exports.linkedModules = linkedModules;
var bands = _interopRequireWildcard(require("./bands"));
exports.bands = bands;
var pods = _interopRequireWildcard(require("./pods"));
exports.pods = pods;
var _renderer = _interopRequireDefault(require("./renderer"));
var _CookiePolicy = require("./pods/CookiePolicy/CookiePolicy");
var _GlobalStyling = _interopRequireDefault(require("./components/GlobalStyling"));
var pathBuilders = _interopRequireWildcard(require("./path-builders"));
exports.pathBuilders = pathBuilders;
var _config = _interopRequireDefault(require("./helpers/config"));
var _normalisation = _interopRequireDefault(require("./normalisation"));
var _actions = _interopRequireWildcard(require("./actions"));
exports.actions = _actions;
var _shared = _interopRequireWildcard(require("./shared"));
exports.shared = _shared;
var _components = _interopRequireWildcard(require("./components"));
exports.components = _components;
var _reducerMacros = require("./utilities/reducerMacros");
function _getRequireWildcardCache(e) { if ("function" != typeof WeakMap) return null; var r = new WeakMap(), t = new WeakMap(); return (_getRequireWildcardCache = function _getRequireWildcardCache(e) { return e ? t : r; })(e); }
function _interopRequireWildcard(e, r) { if (!r && e && e.__esModule) return e; if (null === e || "object" != _typeof(e) && "function" != typeof e) return { "default": e }; var t = _getRequireWildcardCache(r); if (t && t.has(e)) return t.get(e); var n = { __proto__: null }, a = Object.defineProperty && Object.getOwnPropertyDescriptor; for (var u in e) if ("default" !== u && {}.hasOwnProperty.call(e, u)) { var i = a ? Object.getOwnPropertyDescriptor(e, u) : null; i && (i.get || i.set) ? Object.defineProperty(n, u, i) : n[u] = e[u]; } return n["default"] = e, t && t.set(e, n), n; }
function ownKeys(e, r) { var t = Object.keys(e); if (Object.getOwnPropertySymbols) { var o = Object.getOwnPropertySymbols(e); r && (o = o.filter(function (r) { return Object.getOwnPropertyDescriptor(e, r).enumerable; })), t.push.apply(t, o); } return t; }
function _objectSpread(e) { for (var r = 1; r < arguments.length; r++) { var t = null != arguments[r] ? arguments[r] : {}; r % 2 ? ownKeys(Object(t), !0).forEach(function (r) { (0, _defineProperty2["default"])(e, r, t[r]); }) : Object.getOwnPropertyDescriptors ? Object.defineProperties(e, Object.getOwnPropertyDescriptors(t)) : ownKeys(Object(t)).forEach(function (r) { Object.defineProperty(e, r, Object.getOwnPropertyDescriptor(t, r)); }); } return e; } /* eslint-disable import/prefer-default-export */
var modules = exports.modules = _objectSpread(_objectSpread(_objectSpread({}, genericModules), JaguarModules), bands);