"use strict";

var _interopRequireDefault = require("@babel/runtime/helpers/interopRequireDefault");
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports["default"] = void 0;
var _defineProperty2 = _interopRequireDefault(require("@babel/runtime/helpers/defineProperty"));
var _reactRedux = require("react-redux");
var _filters = require("../shared/filters");
var _sessionPreferences = require("../shared/sessionPreferences");
var _StickySearchBar = _interopRequireDefault(require("../components/StickySearchBar"));
function ownKeys(e, r) { var t = Object.keys(e); if (Object.getOwnPropertySymbols) { var o = Object.getOwnPropertySymbols(e); r && (o = o.filter(function (r) { return Object.getOwnPropertyDescriptor(e, r).enumerable; })), t.push.apply(t, o); } return t; }
function _objectSpread(e) { for (var r = 1; r < arguments.length; r++) { var t = null != arguments[r] ? arguments[r] : {}; r % 2 ? ownKeys(Object(t), !0).forEach(function (r) { (0, _defineProperty2["default"])(e, r, t[r]); }) : Object.getOwnPropertyDescriptors ? Object.defineProperties(e, Object.getOwnPropertyDescriptors(t)) : ownKeys(Object(t)).forEach(function (r) { Object.defineProperty(e, r, Object.getOwnPropertyDescriptor(t, r)); }); } return e; }
var mapStateToProps = function mapStateToProps(_ref) {
  var shared = _ref.shared;
  return {
    filterSearchUrl: shared.searchFilter.filterSearchUrl,
    maxDistance: shared.filters.selectedFilters && shared.filters.availableFilterValues && (parseInt(shared.filters.selectedFilters.maxDistance, 10) || shared.filters.availableFilterValues.maxDistance[0]),
    location: shared.sessionPreferences.location || {},
    distanceOptions: function distanceOptions(translations) {
      return shared.filters.availableFilterValues && shared.filters.availableFilterValues.maxDistance.map(function (f) {
        return f.translationKey && _objectSpread(_objectSpread({}, f), {}, {
          label: translations[f.translationKey]
        }) || f;
      }) || [];
    }
  };
};
var mapDispatchToProps = function mapDispatchToProps(dispatch, ownProps) {
  return {
    resetFilters: function resetFilters() {
      if (ownProps.onReset) {
        ownProps.onReset();
      }
      dispatch(_filters.actions.resetFilters());
      dispatch(_filters.actions.initialiseFilters());
    },
    handleLocationChange: function handleLocationChange(_ref2) {
      var latitude = _ref2.latitude,
        longitude = _ref2.longitude,
        locationTown = _ref2.locationTown;
      dispatch(_sessionPreferences.actions.updateSessionPreferences('location', {
        latitude: latitude,
        longitude: longitude,
        locationTown: locationTown
      }));
    },
    handleMaxDistanceChange: function handleMaxDistanceChange(maxDistance) {
      return dispatch(_filters.actions.updateFilters([{
        key: 'maxDistance',
        value: maxDistance
      }]));
    },
    buttonPress: function buttonPress() {
      dispatch(_filters.actions.searchWithFilters());
      dispatch(_sessionPreferences.actions.updateSessionPreferences('resetSearchResultsPageNumber', true));
    }
  };
};
var _default = exports["default"] = (0, _reactRedux.connect)(mapStateToProps, mapDispatchToProps)(_StickySearchBar["default"]);