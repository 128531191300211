"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.sortDropdownListOptions = void 0;
var _settings = require("../shared/sortOptions/settings");
var sortDropdownListOptions = exports.sortDropdownListOptions = function sortDropdownListOptions(translations) {
  var sortByPriceAsc = translations.sortByPriceAsc,
    sortByPriceDesc = translations.sortByPriceDesc,
    sortByMileageAsc = translations.sortByMileageAsc,
    sortByMileageDesc = translations.sortByMileageDesc,
    sortByAgeAsc = translations.sortByAgeAsc,
    sortByAgeDesc = translations.sortByAgeDesc,
    sortByDistance = translations.sortByDistance;
  return [{
    label: sortByPriceAsc,
    value: _settings.sortOptions.priceAsc.value
  }, {
    label: sortByPriceDesc,
    value: _settings.sortOptions.priceDesc.value
  }, {
    label: sortByMileageAsc,
    value: _settings.sortOptions.mileageAsc.value
  }, {
    label: sortByMileageDesc,
    value: _settings.sortOptions.mileageDesc.value
  }, {
    label: sortByAgeAsc,
    value: _settings.sortOptions.ageAsc.value
  }, {
    label: sortByAgeDesc,
    value: _settings.sortOptions.ageDesc.value
  }, {
    label: sortByDistance,
    value: _settings.sortOptions.distanceAsc.value
  }].filter(function (_ref) {
    var label = _ref.label;
    return label && label.trim();
  });
};