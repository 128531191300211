"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.useLocationHook = useLocationHook;
var _reactRedux = require("react-redux");
var _filters = require("../../shared/filters");
var _sessionPreferences = require("../../shared/sessionPreferences");
var _useRegionsHook2 = require("../../shared/hooks/useRegionsHook");
function useLocationHook() {
  var dispatch = (0, _reactRedux.useDispatch)();
  var selectedFilters = (0, _reactRedux.useSelector)(function (state) {
    return state.shared.sessionPreferences.location || {};
  });
  var _useRegionsHook = (0, _useRegionsHook2.useRegionsHook)(),
    setRegionFilters = _useRegionsHook.setRegionFilters;
  var updateLocationFilter = function updateLocationFilter(latitude, longitude, locationTown, userCountry) {
    var locationFilters = {
      latitude: latitude,
      longitude: longitude,
      locationTown: locationTown,
      userCountry: userCountry
    };
    if (!locationTown) delete locationFilters.locationTown;
    return dispatch(_sessionPreferences.actions.updateSessionPreferences('location', locationFilters));
  };
  var onButtonClick = function onButtonClick() {
    setRegionFilters(selectedFilters.userCountry);
    dispatch(_filters.actions.searchWithFilters());
  };
  return {
    onButtonClick: onButtonClick,
    updateLocationFilter: updateLocationFilter,
    latitude: selectedFilters.latitude,
    longitude: selectedFilters.longitude
  };
}