"use strict";

var _interopRequireDefault = require("@babel/runtime/helpers/interopRequireDefault");
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.initialState = exports.id = exports.constants = exports.actions = void 0;
exports.reducer = reducer;
var _defineProperty2 = _interopRequireDefault(require("@babel/runtime/helpers/defineProperty"));
var _reducerMacros = require("../../utilities/reducerMacros");
function ownKeys(e, r) { var t = Object.keys(e); if (Object.getOwnPropertySymbols) { var o = Object.getOwnPropertySymbols(e); r && (o = o.filter(function (r) { return Object.getOwnPropertyDescriptor(e, r).enumerable; })), t.push.apply(t, o); } return t; }
function _objectSpread(e) { for (var r = 1; r < arguments.length; r++) { var t = null != arguments[r] ? arguments[r] : {}; r % 2 ? ownKeys(Object(t), !0).forEach(function (r) { (0, _defineProperty2["default"])(e, r, t[r]); }) : Object.getOwnPropertyDescriptors ? Object.defineProperties(e, Object.getOwnPropertyDescriptors(t)) : ownKeys(Object(t)).forEach(function (r) { Object.defineProperty(e, r, Object.getOwnPropertyDescriptor(t, r)); }); } return e; }
var modulePrefix = 'MOD_RANGE';
var constants = exports.constants = (0, _reducerMacros.moduleConstants)(modulePrefix, ['INIT', 'GET_MODEL_GROUPS', 'GET_MODEL_GROUPS_SUCCESS', 'GET_MODEL_GROUPS_FAILURE']);
var id = exports.id = 'Range';
var actions = exports.actions = {
  init: (0, _reducerMacros.moduleAction)(constants.INIT, id),
  getModelGroups: (0, _reducerMacros.moduleActionWithArgs)(constants.GET_MODEL_GROUPS, id, function (payload) {
    return payload;
  }),
  getModelGroupsSuccess: (0, _reducerMacros.moduleActionWithArgs)(constants.GET_MODEL_GROUPS_SUCCESS, id, function (payload) {
    return payload;
  })
};
var initialState = exports.initialState = {
  error: null,
  modelGroups: []
};
function reducer() {
  var state = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : initialState;
  var action = arguments.length > 1 ? arguments[1] : undefined;
  switch (action.type) {
    case constants.GET_MODEL_GROUPS_SUCCESS:
      return _objectSpread(_objectSpread(_objectSpread({}, state), action.payload), {}, {
        error: null
      });
    case constants.GET_MODEL_GROUPS_FAILURE:
      return _objectSpread(_objectSpread({}, state), {}, {
        error: action.payload
      });
    default:
      return state;
  }
}