import { normalisers } from 'cms-modules';
import { constants } from '../actions/config';

const { normalisePages, normaliseGlobal } = normalisers;

export default function reducer(state = {}, action) {
  switch (action.type) {
    case constants.CONFIG_LOAD: {
      const {
        global: { modules: globalModules, ...globalConfig },
        pages,
        linked,
      } = action.payload;
      const {
        entities: {
          pages: pageEntities,
          bands: bandEntities,
          pods: podEntities,
        },
      } = normalisePages(pages);
      const {
        entities: { bands: globalBandEntities, pods: globalPodEntities },
        result: site,
      } = normaliseGlobal(globalModules);
      return {
        ...state,
        site,
        pages: pageEntities,
        bands: {
          ...bandEntities,
          ...globalBandEntities,
        },
        pods: { ...podEntities, ...globalPodEntities },
        linked: {
          ...linked,
        },
        config: {
          ...state.config,
          global: { ...globalConfig },
        },
      };
    }
    case 'CHANGE_TERRITORY':
      return {
        ...state,
        initialised: false,
      };
    case constants.INITIALISED:
      return {
        ...state,
        initialised: true,
      };
    case constants.SETTINGS_LOAD:
      return {
        ...state,
        settings: {
          ...state.settings,
          ...action.payload,
        },
      };

    default:
      return state;
  }
}
