"use strict";

var _interopRequireDefault = require("@babel/runtime/helpers/interopRequireDefault");
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.initialState = exports.id = exports.constants = exports.actions = void 0;
exports.reducer = reducer;
var _defineProperty2 = _interopRequireDefault(require("@babel/runtime/helpers/defineProperty"));
var _toConsumableArray2 = _interopRequireDefault(require("@babel/runtime/helpers/toConsumableArray"));
var _reducerMacros = require("../../utilities/reducerMacros");
function ownKeys(e, r) { var t = Object.keys(e); if (Object.getOwnPropertySymbols) { var o = Object.getOwnPropertySymbols(e); r && (o = o.filter(function (r) { return Object.getOwnPropertyDescriptor(e, r).enumerable; })), t.push.apply(t, o); } return t; }
function _objectSpread(e) { for (var r = 1; r < arguments.length; r++) { var t = null != arguments[r] ? arguments[r] : {}; r % 2 ? ownKeys(Object(t), !0).forEach(function (r) { (0, _defineProperty2["default"])(e, r, t[r]); }) : Object.getOwnPropertyDescriptors ? Object.defineProperties(e, Object.getOwnPropertyDescriptors(t)) : ownKeys(Object(t)).forEach(function (r) { Object.defineProperty(e, r, Object.getOwnPropertyDescriptor(t, r)); }); } return e; }
var modulePrefix = 'MOD_BUDGET_SEARCH';
var constants = exports.constants = (0, _reducerMacros.moduleConstants)(modulePrefix, ['INIT', 'GET_BUDGET_SEARCH_GROUPS', 'GET_BUDGET_SEARCH_GROUPS_SUCCESS', 'GET_BUDGET_SEARCH_RANGE', 'GET_BUDGET_SEARCH_RANGE_SUCCESS', 'GET_AFFORDABLE_MODEL_FINANCE_SUCCESS', 'GET_SUGGESTED_MODEL_FINANCE_SUCCESS']);
var id = exports.id = 'BudgetSearch';
var actions = exports.actions = {
  init: (0, _reducerMacros.moduleAction)(constants.INIT, id),
  getBudgetSearchGroups: (0, _reducerMacros.moduleActionWithArgs)(constants.GET_BUDGET_SEARCH_GROUPS, id, function (payload) {
    return payload;
  }),
  getBudgetSearchGroupsSuccess: (0, _reducerMacros.moduleActionWithArgs)(constants.GET_BUDGET_SEARCH_GROUPS_SUCCESS, id, function (config) {
    return config;
  }),
  getBudgetSearchRange: (0, _reducerMacros.moduleActionWithArgs)(constants.GET_BUDGET_SEARCH_RANGE, id, function (payload) {
    return payload;
  }),
  getBudgetSearchRangeSuccess: (0, _reducerMacros.moduleActionWithArgs)(constants.GET_BUDGET_SEARCH_RANGE_SUCCESS, id, function (config) {
    return config;
  }),
  getAffordableModelFinanceSuccess: (0, _reducerMacros.moduleActionWithArgs)(constants.GET_AFFORDABLE_MODEL_FINANCE_SUCCESS, id, function (payload) {
    return payload;
  }),
  getSuggestedModelFinanceSuccess: (0, _reducerMacros.moduleActionWithArgs)(constants.GET_SUGGESTED_MODEL_FINANCE_SUCCESS, id, function (payload) {
    return payload;
  })
};
var replaceIfExists = function replaceIfExists(groups, group) {
  return (0, _toConsumableArray2["default"])(groups.map(function (g) {
    return g.model === group.model ? group : g;
  }));
};
var initialState = exports.initialState = {
  error: null,
  affordableModels: null,
  suggestedModels: null,
  suggestedBudgetIncrease: 10,
  priceRangeLabel: null,
  fullRangeOptions: null,
  searching: true
};
function reducer() {
  var state = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : initialState;
  var action = arguments.length > 1 ? arguments[1] : undefined;
  switch (action.type) {
    case constants.GET_BUDGET_SEARCH_GROUPS:
      return _objectSpread(_objectSpread({}, state), {}, {
        searching: true
      });
    case constants.GET_BUDGET_SEARCH_RANGE_SUCCESS:
      return _objectSpread(_objectSpread({}, state), action.payload);
    case constants.GET_BUDGET_SEARCH_GROUPS_SUCCESS:
      return _objectSpread(_objectSpread(_objectSpread({}, state), action.payload), {}, {
        searching: false
      });
    case constants.GET_AFFORDABLE_MODEL_FINANCE_SUCCESS:
      {
        var group = action.payload.group;
        return _objectSpread(_objectSpread({}, state), {}, {
          affordableModels: replaceIfExists(state.affordableModels, group)
        });
      }
    case constants.GET_SUGGESTED_MODEL_FINANCE_SUCCESS:
      {
        var _group = action.payload.group;
        return _objectSpread(_objectSpread({}, state), {}, {
          suggestedModels: replaceIfExists(state.suggestedModels, _group)
        });
      }
    default:
      return state;
  }
}