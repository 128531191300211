"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports["default"] = void 0;
var _config = require("../../helpers/config");
var config = (0, _config.Config)('50-50 grid', {
  id: 'FiftyFifty'
}, (0, _config.Slice)('Layout', {
  id: 'layout'
}, (0, _config.Section)('50-50 item', {}, (0, _config.AlignLeftRight)('Image Alignment', 'imageAlign'), (0, _config.Pixels)('Padding Vertical', 'paddingVertical'), (0, _config.Pixels)('Padding Horizontal', 'paddingHorizontal'))), (0, _config.Slice)('Content', {
  id: 'content'
}, (0, _config.Section)('50-50 item', {}, (0, _config.Image)('Image', 'selectedImage'), (0, _config.TextArea)('Headline', 'headlineText'), (0, _config.TextArea)('Body text', 'bodyText'), (0, _config.Link)('Button', 'buttonText'))), (0, _config.Slice)('Styling', {
  id: 'styling'
}, (0, _config.Section)('Global', {}, (0, _config.Colour)('Background Colour', 'backgroundColour'), (0, _config.Font)('Header Text', 'headlineFont'), (0, _config.Font)('Body Text', 'bodyFont'), (0, _config.Font)('Button Text', 'buttonFont'), (0, _config.Colour)('Button Colour', 'buttonBackgroundColour'), (0, _config.Colour)('Button Hover Colour', 'buttonHover'))));
var _default = exports["default"] = config;