"use strict";

var _interopRequireDefault = require("@babel/runtime/helpers/interopRequireDefault");
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports["default"] = void 0;
var _config = require("../../helpers/config");
var _SectionIcons = _interopRequireDefault(require("../../helpers/SectionIcons"));
var config = (0, _config.Config)('PageTitle', {
  id: 'PageTitle'
}, (0, _config.Slice)('Layout', {
  id: 'layout'
}, (0, _config.Section)('Global', {
  icon: _SectionIcons["default"].SideCarousel
}, (0, _config.Align)('Alignment', 'alignment'))), (0, _config.Slice)('Content', {
  id: 'content'
}, (0, _config.Section)('Global', {
  icon: _SectionIcons["default"].TopTier
}, (0, _config.TextArea)('Page Title', 'pageTitle'), (0, _config.TextArea)('Page Subtitle', 'pageSubTitle'))), (0, _config.Slice)('Styling', {
  id: 'styling'
}, (0, _config.Section)('Global', {
  icon: _SectionIcons["default"].TopTier
}, (0, _config.Font)('Page Title', 'pageTitleFont'), (0, _config.Font)('Page SubTitle', 'pageSubTitleFont'), (0, _config.Colour)('Background', 'backgroundColour'))));
var _default = exports["default"] = config;