"use strict";

var _interopRequireDefault = require("@babel/runtime/helpers/interopRequireDefault");
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.availableToAdministration = void 0;
Object.defineProperty(exports, "component", {
  enumerable: true,
  get: function get() {
    return _VehiclePerformance["default"];
  }
});
Object.defineProperty(exports, "config", {
  enumerable: true,
  get: function get() {
    return _config["default"];
  }
});
exports.name = exports.id = void 0;
var _VehiclePerformance = _interopRequireDefault(require("./VehiclePerformance"));
var _config = _interopRequireDefault(require("./config"));
var name = exports.name = 'Vehicle Performance';
var id = exports.id = 'VehiclePerformance';
var availableToAdministration = exports.availableToAdministration = false;