"use strict";

var _interopRequireDefault = require("@babel/runtime/helpers/interopRequireDefault");
Object.defineProperty(exports, "__esModule", {
  value: true
});
Object.defineProperty(exports, "actions", {
  enumerable: true,
  get: function get() {
    return _reducer.actions;
  }
});
exports.availableToAdministration = void 0;
Object.defineProperty(exports, "component", {
  enumerable: true,
  get: function get() {
    return _ModelSearch["default"];
  }
});
Object.defineProperty(exports, "constants", {
  enumerable: true,
  get: function get() {
    return _reducer.constants;
  }
});
exports.description = void 0;
Object.defineProperty(exports, "effects", {
  enumerable: true,
  get: function get() {
    return _effects["default"];
  }
});
exports.name = exports.id = void 0;
Object.defineProperty(exports, "reducer", {
  enumerable: true,
  get: function get() {
    return _reducer.reducer;
  }
});
var _ModelSearch = _interopRequireDefault(require("./ModelSearch"));
var _effects = _interopRequireDefault(require("./effects"));
var _reducer = require("../../../pods/ModelSearch/reducer");
var name = exports.name = 'AstonSiteSearch';
var id = exports.id = 'AstonSiteSearch';
var availableToAdministration = exports.availableToAdministration = false;
var description = exports.description = 'Aston Site Search';