"use strict";

var _interopRequireDefault = require("@babel/runtime/helpers/interopRequireDefault");
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports["default"] = void 0;
var _taggedTemplateLiteral2 = _interopRequireDefault(require("@babel/runtime/helpers/taggedTemplateLiteral"));
var _styledComponents = _interopRequireDefault(require("styled-components"));
var _theme = _interopRequireDefault(require("../../../theme"));
var _templateObject;
var _default = exports["default"] = _styledComponents["default"].div.withConfig({
  displayName: "Image",
  componentId: "sc-1spr4ay-0"
})(["width:100%;background-image:url(", ");background-size:", ";background-repeat:no-repeat;background-position:center;min-height:", "px;", ";"], function (props) {
  return props.src;
}, function (props) {
  return props.backgroundSize || 'cover';
}, function (props) {
  return props.imageHeight || 300;
}, _theme["default"].max.medium(_templateObject || (_templateObject = (0, _taggedTemplateLiteral2["default"])(["\n    padding-bottom: 75%;\n  "]))));