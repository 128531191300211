"use strict";

var _interopRequireDefault = require("@babel/runtime/helpers/interopRequireDefault");
Object.defineProperty(exports, "__esModule", {
  value: true
});
Object.defineProperty(exports, "actions", {
  enumerable: true,
  get: function get() {
    return _reducer.actions;
  }
});
exports.availableToAdministration = void 0;
Object.defineProperty(exports, "component", {
  enumerable: true,
  get: function get() {
    return _Search["default"];
  }
});
Object.defineProperty(exports, "config", {
  enumerable: true,
  get: function get() {
    return _config["default"];
  }
});
Object.defineProperty(exports, "constants", {
  enumerable: true,
  get: function get() {
    return _reducer.constants;
  }
});
Object.defineProperty(exports, "defaultConfig", {
  enumerable: true,
  get: function get() {
    return _defaultConfig["default"];
  }
});
exports.description = void 0;
Object.defineProperty(exports, "effects", {
  enumerable: true,
  get: function get() {
    return _effects["default"];
  }
});
exports.id = exports.icon = void 0;
Object.defineProperty(exports, "initialState", {
  enumerable: true,
  get: function get() {
    return _reducer.initialState;
  }
});
exports.name = void 0;
Object.defineProperty(exports, "reducer", {
  enumerable: true,
  get: function get() {
    return _reducer.reducer;
  }
});
Object.defineProperty(exports, "reducerId", {
  enumerable: true,
  get: function get() {
    return _reducer.id;
  }
});
var _Icon = _interopRequireDefault(require("../../modules/Search/Icon"));
var _reducer = require("../../modules/Search/reducer");
var _Search = _interopRequireDefault(require("./Search"));
var _effects = _interopRequireDefault(require("../../modules/Search/effects"));
var _config = _interopRequireDefault(require("../../modules/Search/config"));
var _defaultConfig = _interopRequireDefault(require("../../modules/Search/defaultConfig"));
var name = exports.name = 'Search';
var id = exports.id = 'Search';
var availableToAdministration = exports.availableToAdministration = false;
var description = exports.description = 'Search Vehicles Page';
var icon = exports.icon = _Icon["default"];