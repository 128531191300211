"use strict";

var _interopRequireDefault = require("@babel/runtime/helpers/interopRequireDefault");
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.availableToAdministration = void 0;
Object.defineProperty(exports, "component", {
  enumerable: true,
  get: function get() {
    return _SubNav["default"];
  }
});
Object.defineProperty(exports, "config", {
  enumerable: true,
  get: function get() {
    return _config["default"];
  }
});
Object.defineProperty(exports, "defaultConfig", {
  enumerable: true,
  get: function get() {
    return _defaultConfig["default"];
  }
});
exports.name = exports.id = void 0;
var _SubNav = _interopRequireDefault(require("./SubNav"));
var _config = _interopRequireDefault(require("./config"));
var _defaultConfig = _interopRequireDefault(require("./defaultConfig"));
var name = exports.name = 'SubNav';
var id = exports.id = 'SubNav';
var availableToAdministration = exports.availableToAdministration = false;