"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports["default"] = void 0;
var parseYouTubeId = function parseYouTubeId(url) {
  // eslint-disable-next-line
  var pattern = /(?:youtube\.com\/.*[?&]v=|youtu\.be\/|youtube\.com\/embed\/|youtube\.com\/v\/|youtube\.com\/.*[\/\?&]v=|youtube\.com\/.*[\/\?&]vi=)([^"&?\/\s]{11})/;
  var match = url.match(pattern);
  return match ? match[1] : null;
};
var _default = exports["default"] = parseYouTubeId;