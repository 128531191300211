"use strict";

var _interopRequireDefault = require("@babel/runtime/helpers/interopRequireDefault");
var _typeof = require("@babel/runtime/helpers/typeof");
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports["default"] = void 0;
var _slicedToArray2 = _interopRequireDefault(require("@babel/runtime/helpers/slicedToArray"));
var _toConsumableArray2 = _interopRequireDefault(require("@babel/runtime/helpers/toConsumableArray"));
var _defineProperty2 = _interopRequireDefault(require("@babel/runtime/helpers/defineProperty"));
var _reactRedux = require("react-redux");
var _router = require("../actions/router");
var _settings = require("../shared/selectors/settings");
var _ = require("..");
var bandModules = _interopRequireWildcard(require("../bands"));
function _getRequireWildcardCache(e) { if ("function" != typeof WeakMap) return null; var r = new WeakMap(), t = new WeakMap(); return (_getRequireWildcardCache = function _getRequireWildcardCache(e) { return e ? t : r; })(e); }
function _interopRequireWildcard(e, r) { if (!r && e && e.__esModule) return e; if (null === e || "object" != _typeof(e) && "function" != typeof e) return { "default": e }; var t = _getRequireWildcardCache(r); if (t && t.has(e)) return t.get(e); var n = { __proto__: null }, a = Object.defineProperty && Object.getOwnPropertyDescriptor; for (var u in e) if ("default" !== u && {}.hasOwnProperty.call(e, u)) { var i = a ? Object.getOwnPropertyDescriptor(e, u) : null; i && (i.get || i.set) ? Object.defineProperty(n, u, i) : n[u] = e[u]; } return n["default"] = e, t && t.set(e, n), n; }
function ownKeys(e, r) { var t = Object.keys(e); if (Object.getOwnPropertySymbols) { var o = Object.getOwnPropertySymbols(e); r && (o = o.filter(function (r) { return Object.getOwnPropertyDescriptor(e, r).enumerable; })), t.push.apply(t, o); } return t; }
function _objectSpread(e) { for (var r = 1; r < arguments.length; r++) { var t = null != arguments[r] ? arguments[r] : {}; r % 2 ? ownKeys(Object(t), !0).forEach(function (r) { (0, _defineProperty2["default"])(e, r, t[r]); }) : Object.getOwnPropertyDescriptors ? Object.defineProperties(e, Object.getOwnPropertyDescriptors(t)) : ownKeys(Object(t)).forEach(function (r) { Object.defineProperty(e, r, Object.getOwnPropertyDescriptor(t, r)); }); } return e; }
var navigate = _router.actions.navigate;
var bindActionToInstance = function bindActionToInstance(_ref, dispatch, action) {
  var page = _ref.page,
    instance = _ref.instance;
  return function () {
    for (var _len = arguments.length, args = new Array(_len), _key = 0; _key < _len; _key++) {
      args[_key] = arguments[_key];
    }
    return dispatch(action.apply(void 0, [page, instance].concat(args)));
  };
};
var bindActionsToInstance = function bindActionsToInstance(moduleConfig, actions, dispatch) {
  return Object.keys(actions).reduce(function (newActions, name) {
    return _objectSpread(_objectSpread({}, newActions), {}, (0, _defineProperty2["default"])({}, name, bindActionToInstance(moduleConfig, dispatch, actions[name])));
  }, {});
};
var bindPathByModule = function bindPathByModule(pages, bands) {
  return function (moduleId) {
    var band = bands.find(function (m) {
      return m.id === moduleId || m.name === moduleId;
    });
    var _ref2 = pages.find(function (page) {
        return page.modules.includes(band.instanceId);
      }) || {
        relativePath: ''
      },
      relativePath = _ref2.relativePath;
    return relativePath;
  };
};
var bindNavigateIfInternal = function bindNavigateIfInternal(dispatch) {
  return function (href) {
    if (!href.match(/^http/)) {
      dispatch(navigate(href));
      return true;
    }
    return false;
  };
};
var useCompiledModule = function useCompiledModule(id, index, path) {
  var allModules = [].concat((0, _toConsumableArray2["default"])(Object.values(_.modules)), (0, _toConsumableArray2["default"])(Object.values(bandModules)));
  var _allModules$find = allModules.find(function (m) {
      return m.id === id;
    }),
    ReactComponent = _allModules$find.component,
    _allModules$find$acti = _allModules$find.actions,
    actions = _allModules$find$acti === void 0 ? {} : _allModules$find$acti;
  var dispatch = (0, _reactRedux.useDispatch)();
  // we dont want to key in state by the locale as this may change!
  var _path$replace$split = path.replace(/^\/[a-z]{2}_[a-z]{2}\//, '/').split('/'),
    _path$replace$split2 = (0, _slicedToArray2["default"])(_path$replace$split, 2),
    page = _path$replace$split2[1];
  var pagePath = "/".concat(page);
  var moduleConfig = {
    id: id,
    instance: index,
    page: pagePath
  };
  var moduleState = (0, _reactRedux.useSelector)(function (state) {
    return state.pages && state.pages[pagePath] && state.pages[pagePath][index];
  }) || {};
  var bands = (0, _reactRedux.useSelector)(function (state) {
    return state.config.bands;
  });
  var pages = (0, _reactRedux.useSelector)(function (state) {
    return state.config.pages;
  });
  var boundActions = bindActionsToInstance(moduleConfig, actions, dispatch);
  var shared = (0, _reactRedux.useSelector)(function (state) {
    return state.shared;
  });
  var router = (0, _reactRedux.useSelector)(function (state) {
    return state.router;
  });
  var vehicleInContext = (0, _reactRedux.useSelector)(function (state) {
    return state.vehicleInContext;
  });
  var marketInfo = (0, _reactRedux.useSelector)(function (state) {
    return (0, _settings.getAVLConfig)(state);
  });
  var history = (0, _reactRedux.useSelector)(function (state) {
    return state.router.history;
  });
  var linkedModules = (0, _reactRedux.useSelector)(function (state) {
    return state.config.linked;
  });
  var _useSelector = (0, _reactRedux.useSelector)(function (state) {
      return state.config.config.global;
    }),
    featureFlags = _useSelector.featureFlags,
    finance = _useSelector.finance,
    socialShare = _useSelector.socialShare,
    maps = _useSelector.maps,
    analytics = _useSelector.analytics;
  var hashRouting = (0, _reactRedux.useSelector)(function (state) {
    return state.config.settings.hashRouting;
  });
  var pathByModule = bindPathByModule(Object.values(pages), Object.values(bands));
  var navigateIfInternal = bindNavigateIfInternal(dispatch);
  return {
    ReactComponent: ReactComponent,
    moduleConfig: moduleConfig,
    shared: shared,
    moduleState: moduleState,
    boundActions: boundActions,
    router: router,
    marketInfo: marketInfo,
    vehicleInContext: vehicleInContext,
    history: history,
    linkedModules: linkedModules,
    featureFlags: featureFlags,
    maps: maps,
    analytics: analytics,
    finance: finance,
    socialShare: socialShare,
    pathByModule: pathByModule,
    navigateIfInternal: navigateIfInternal,
    dispatch: dispatch,
    hashRouting: hashRouting
  };
};
var _default = exports["default"] = useCompiledModule;