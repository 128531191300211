"use strict";

var _interopRequireDefault = require("@babel/runtime/helpers/interopRequireDefault");
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.availableToAdministration = void 0;
Object.defineProperty(exports, "component", {
  enumerable: true,
  get: function get() {
    return _FooterStickyBar["default"];
  }
});
exports.required = exports.position = exports.name = exports.id = exports.global = exports.description = void 0;
var _FooterStickyBar = _interopRequireDefault(require("./FooterStickyBar"));
var name = exports.name = 'FooterStickyBar';
var id = exports.id = 'FooterStickyBar';
var availableToAdministration = exports.availableToAdministration = false;
var description = exports.description = 'Add a sticky bar at the bottom of the page. Acts as a container for other elements.';
var global = exports.global = true;
var required = exports.required = false;
var position = exports.position = 1;