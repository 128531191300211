"use strict";

var _interopRequireDefault = require("@babel/runtime/helpers/interopRequireDefault");
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.useFeaturedVehicle = useFeaturedVehicle;
var _react = require("react");
var _reactRedux = require("react-redux");
var _Templates = _interopRequireDefault(require("./Templates"));
var _reducer = require("./reducer");
var _vehicle = require("../../helpers/vehicle");
var _helpers = require("../../shared/currencyConversion/helpers");
var _numbers = require("../../shared/localisation/numbers");
var _compare = require("../../shared/compare");
var _shortlist = require("../../shared/shortlist");
var _router = require("../../actions/router");
function useFeaturedVehicle(template, vehicleId, translations) {
  var Template = _Templates["default"][template];
  var dispatch = (0, _reactRedux.useDispatch)();
  var _useSelector = (0, _reactRedux.useSelector)(function (state) {
      return {
        vehicle: state.featuredVehicles[vehicleId],
        currency: state.shared.sessionPreferences.currency,
        locale: state.config.settings.locale,
        shared: state.shared,
        globalStyling: state.config.config.global.globalStyling
      };
    }),
    vehicle = _useSelector.vehicle,
    currency = _useSelector.currency,
    locale = _useSelector.locale,
    shared = _useSelector.shared,
    globalStyling = _useSelector.globalStyling;
  (0, _react.useEffect)(function () {
    dispatch(_reducer.actions.getFeaturedVehicle(vehicleId));
  }, []);
  var generateSubTitle = function generateSubTitle(subtitleData) {
    if (subtitleData) {
      return (0, _vehicle.vehicleDataFormatter)(subtitleData, vehicle, locale, ' | ');
    }
    return (vehicle.powerOutput ? [vehicle.powerOutput, vehicle.specification.transmission, vehicle.specification.fuelType] : [vehicle.specification.transmission, vehicle.specification.fuelType]).filter(Boolean).join(' | ');
  };
  var priceNoDigits = function priceNoDigits(priceValue, rates, priceCurrency) {
    var exchangeValue = (0, _helpers.convertSecondaryPriceCurrency)(priceValue, rates && rates, priceCurrency, currency || priceCurrency);
    return (0, _numbers.localiseCurrency)(exchangeValue, locale, currency || priceCurrency, 0) || translations.notAvailableLabel;
  };
  var mileageNoDigits = function mileageNoDigits(reading) {
    return (0, _numbers.localiseNumber)(reading, locale, 0) || translations.notAvailableLabel;
  };
  var compareVehicle = function compareVehicle() {
    return dispatch(_compare.actions.toggleVehicle(vehicle));
  };
  var shortlistVehicle = function shortlistVehicle() {
    return dispatch(_shortlist.actions.toggleVehicle(vehicle));
  };
  var onMoreInfoClick = function onMoreInfoClick(id) {
    return dispatch(_router.actions.navigate("VDP/".concat(id)));
  };
  var onEnquiryClick = function onEnquiryClick(id) {
    return dispatch(_router.actions.navigate("enquiry/".concat(id)));
  };
  return {
    Template: Template,
    vehicle: vehicle,
    locale: locale,
    shared: shared,
    globalStyling: globalStyling,
    priceNoDigits: priceNoDigits,
    mileageNoDigits: mileageNoDigits,
    generateSubTitle: generateSubTitle,
    compareVehicle: compareVehicle,
    shortlistVehicle: shortlistVehicle,
    onMoreInfoClick: onMoreInfoClick,
    onEnquiryClick: onEnquiryClick
  };
}