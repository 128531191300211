import { differenceInHours } from 'date-fns';
// eslint-disable-next-line
import { storageProviderCustomisations } from './storageProviderCustomisations';
// eslint-disable-next-line
const log = (key, error) => console.warn(`[${key}]: ${error}`);

// @TODO: rename authenticationDetails!!!
const keys = {
  customerAuth: 'authenticationDetails',
  customer: 'customerDetails',
  shortlist: 'customerShortlist',
  compare: 'customerCompare',
  vehicleValuation: 'vehicleValuation',
  locale: 'locale',
  dealerShortlist: 'customerDealerShortlist',
  dealerCompare: 'customerDealerCompare',
};

function callMiddleware(mode, key, value) {
  if (typeof window.storageMiddleware !== 'function') {
    return { handled: false, value: null };
  }
  try {
    return window.storageMiddleware(mode, key, value);
  } catch (error) {
    //  log(`mode: ${mode}, key: ${key}, value: ${value}`, error);
    return { handled: false, value: null };
  }
}

function tryMiddleware(mode, key, value) {
  const middlewareResult = callMiddleware(mode, key, value);
  if (mode === 'load') {
    return middlewareResult;
  }
  return middlewareResult.handled;
}

function load(key) {
  const middlewareResult = tryMiddleware('load', key);
  if (middlewareResult.handled) {
    return JSON.parse(middlewareResult.value);
  }

  try {
    return JSON.parse(localStorage.getItem(key));
  } catch (error) {
    log(key, error);
    return null;
  }
}

function save(key, data) {
  if (tryMiddleware('save', key, JSON.stringify(data))) {
    return;
  }

  try {
    localStorage.setItem(key, JSON.stringify(data));
  } catch (error) {
    log(key, error);
  }
}

function clear(key) {
  if (tryMiddleware('save', key)) {
    return;
  }

  try {
    localStorage.removeItem(key);
  } catch (error) {
    log(key, error);
  }
}

// Customer auth
export const loadCustomerAuth = () => load(keys.customerAuth);
export const saveCustomerAuth = token => save(keys.customerAuth, token);
export const clearCustomerAuth = () => clear(keys.customerAuth);

// Customer details
export const loadCustomerDetails = () => load(keys.customer);
export const saveCustomerDetails = customer => save(keys.customer, customer);
export const clearCustomerDetails = () => clear(keys.customer);

// Customer shortlist
export const loadCustomerShortlist = () => load(keys.shortlist);
export const saveCustomerShortlist = shortlist =>
  save(keys.shortlist, shortlist);
export const clearCustomerShortlist = () => clear(keys.shortlist);

// Customer compare
export const loadCustomerCompare = () => load(keys.compare);
export const saveCustomerCompare = compare => save(keys.compare, compare);
export const clearCustomerCompare = () => clear(keys.compare);

export const saveVehicleValuation = valuation =>
  save(keys.vehicleValuation, valuation);
export const clearVehicleValuation = () => clear(keys.vehicleValuation);
// vehicle valuation
export const loadVehicleValuation = () => {
  const valuation = load(keys.vehicleValuation);
  const resultValuation =
    valuation &&
    valuation.created &&
    differenceInHours(new Date(), valuation.created) < 48
      ? valuation
      : null;

  if (!resultValuation) {
    clearVehicleValuation();
  }
  return resultValuation;
};

// Locale
export const loadLocale = () => load(keys.locale);
export const saveLocale = locale => save(keys.locale, locale);
export const clearLocale = () => clear(keys.locale);

export const loadCustomerDealerShortlist = () => load(keys.dealerShortlist);
export const saveCustomerDealerShortlist = dealerShortlist =>
  save(keys.dealerShortlist, dealerShortlist);
export const clearCustomerDealerShortlist = () => clear(keys.dealerShortlist);

export const loadCustomerDealerCompare = () => load(keys.dealerCompare);
export const saveCustomerDealerCompare = dealerCompare =>
  save(keys.dealerCompare, dealerCompare);
export const clearCustomerDealerCompare = () => clear(keys.dealerCompare);
