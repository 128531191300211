"use strict";

var _interopRequireDefault = require("@babel/runtime/helpers/interopRequireDefault");
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.localisations = void 0;
var _es = _interopRequireDefault(require("react-phone-input-2/lang/es.json"));
var _de = _interopRequireDefault(require("react-phone-input-2/lang/de.json"));
var _ru = _interopRequireDefault(require("react-phone-input-2/lang/ru.json"));
var _fr = _interopRequireDefault(require("react-phone-input-2/lang/fr.json"));
var _jp = _interopRequireDefault(require("react-phone-input-2/lang/jp.json"));
var _cn = _interopRequireDefault(require("react-phone-input-2/lang/cn.json"));
var _pt = _interopRequireDefault(require("react-phone-input-2/lang/pt.json"));
var _it = _interopRequireDefault(require("react-phone-input-2/lang/it.json"));
var _ir = _interopRequireDefault(require("react-phone-input-2/lang/ir.json"));
var _ar = _interopRequireDefault(require("react-phone-input-2/lang/ar.json"));
var localisations = exports.localisations = {
  es: _es["default"],
  de: _de["default"],
  ru: _ru["default"],
  fr: _fr["default"],
  jp: _jp["default"],
  cn: _cn["default"],
  pt: _pt["default"],
  it: _it["default"],
  ir: _ir["default"],
  ar: _ar["default"]
};