"use strict";

var _interopRequireDefault = require("@babel/runtime/helpers/interopRequireDefault");
Object.defineProperty(exports, "__esModule", {
  value: true
});
Object.defineProperty(exports, "component", {
  enumerable: true,
  get: function get() {
    return _AstonMartinSubNav["default"];
  }
});
exports.name = exports.id = void 0;
var _AstonMartinSubNav = _interopRequireDefault(require("./AstonMartinSubNav"));
var name = exports.name = 'AstonMartinSubNav';
var id = exports.id = 'AstonMartinSubNav';