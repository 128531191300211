"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports["default"] = _default;
function _default() {
  return {
    headline: 'Finance Explained',
    backgroundColour: {
      label: 'grey',
      value: '#f0f0f0'
    }
  };
}