"use strict";

var _interopRequireDefault = require("@babel/runtime/helpers/interopRequireDefault");
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.adjustPriceRangeFilterValues = void 0;
exports["default"] = applyModelVariantLogic;
exports.removeNonNumericDistance = exports.queryFromHistory = exports.parseFiltersToQueryString = exports.parseFilter = exports.filterKeysWithArrays = void 0;
var _slicedToArray2 = _interopRequireDefault(require("@babel/runtime/helpers/slicedToArray"));
var _defineProperty2 = _interopRequireDefault(require("@babel/runtime/helpers/defineProperty"));
var _omit = _interopRequireDefault(require("ramda/src/omit"));
function ownKeys(e, r) { var t = Object.keys(e); if (Object.getOwnPropertySymbols) { var o = Object.getOwnPropertySymbols(e); r && (o = o.filter(function (r) { return Object.getOwnPropertyDescriptor(e, r).enumerable; })), t.push.apply(t, o); } return t; }
function _objectSpread(e) { for (var r = 1; r < arguments.length; r++) { var t = null != arguments[r] ? arguments[r] : {}; r % 2 ? ownKeys(Object(t), !0).forEach(function (r) { (0, _defineProperty2["default"])(e, r, t[r]); }) : Object.getOwnPropertyDescriptors ? Object.defineProperties(e, Object.getOwnPropertyDescriptors(t)) : ownKeys(Object(t)).forEach(function (r) { Object.defineProperty(e, r, Object.getOwnPropertyDescriptor(t, r)); }); } return e; }
var filterKeysWithArrays = exports.filterKeysWithArrays = ['model', 'variant', 'modelVariants', 'subregions', 'exteriorColours', 'interiorShades', 'bodystyle', 'fuelType', 'transmission', 'engine', 'cylinders', 'veneer'];
function applyModelVariantLogic(filtersObject) {
  var keys = Object.keys(filtersObject);
  if (keys.find(function (k) {
    return k === 'variant' || k === 'modelVariants';
  }) && !keys.find(function (k) {
    return k === 'model';
  })) {
    return (0, _omit["default"])(['variant', 'modelVariants'], filtersObject);
  }
  return filtersObject;
}
var isNumeric = function isNumeric(num) {
  return !Number.isNaN(Number(num));
};
var removeNonNumericDistance = exports.removeNonNumericDistance = function removeNonNumericDistance(filtersObject) {
  var entries = Object.entries(filtersObject);
  var numericalDistance = entries.find(function (e) {
    return e[0] === 'maxDistance' && isNumeric(e[1]);
  });
  return numericalDistance ? filtersObject : (0, _omit["default"])(['maxDistance'], filtersObject);
};
var convertToPounds = function convertToPounds(value, exchange) {
  if (value === 0) {
    return value;
  }
  return Math.round(value * exchange.rates.GBP);
};

// AVL3-2528 - Temporary fix to handle fluctuating currency conversion rates.
// Adds a 1% buffer to price filter values.
// - Updated to 1.5%
var adjustPriceRangeFilterValues = exports.adjustPriceRangeFilterValues = function adjustPriceRangeFilterValues(filtersObject, exchange) {
  return Object.keys(filtersObject).reduce(function (acc, key) {
    if (key === 'minDefaultPrice' || key === 'maxDefaultPrice') {
      var priceValue = Number(filtersObject[key]);
      var convertedGBPValue = exchange ? convertToPounds(priceValue, exchange) : priceValue;
      var coef = key === 'minDefaultPrice' ? -1 : 1;
      var adjustedValue = Math.round(convertedGBPValue + convertedGBPValue / 66.666 * coef);
      return _objectSpread(_objectSpread({}, acc), {}, (0, _defineProperty2["default"])({}, key, adjustedValue));
    }
    return acc;
  }, filtersObject);
};
var queryFromHistory = exports.queryFromHistory = function queryFromHistory(history, hashRouting) {
  var query;
  // this this may be the cause of crazy whit with no ?
  if (hashRouting) {
    var _history$location$has = history.location.hash.split('?');
    var _history$location$has2 = (0, _slicedToArray2["default"])(_history$location$has, 2);
    query = _history$location$has2[1];
  } else {
    query = history.location.search.substring(1);
  }
  return query;
};

/**
 * Converts an object into query string parameters.
 * @param {object} params Object to be converted.
 * @returns A string that represents query string parameters.
 */
var parseFiltersToQueryString = exports.parseFiltersToQueryString = function parseFiltersToQueryString(params) {
  return Object.keys(params).map(function (key) {
    return "".concat(encodeURIComponent(key), "=").concat(encodeURIComponent(params[key].toString()));
  }).join('&');
};

/**
 * Converts a comma separated string into an array.
 * @param {object} originalFilters contains all filters values gathered from UI.
 * @param {string} filterKey Filter name to find what property should be parsed into an array.
 * @returns Altered filters value with parsed filter value.
 */
var parseFilter = exports.parseFilter = function parseFilter(originalFilters, filterKey) {
  return originalFilters[filterKey] && !Array.isArray(originalFilters[filterKey]) ? _objectSpread(_objectSpread({}, originalFilters), {}, (0, _defineProperty2["default"])({}, filterKey, originalFilters[filterKey].split(','))) : originalFilters;
};