"use strict";

var _interopRequireDefault = require("@babel/runtime/helpers/interopRequireDefault");
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports["default"] = useExterior;
var _slicedToArray2 = _interopRequireDefault(require("@babel/runtime/helpers/slicedToArray"));
var _react = require("react");
var _hammerjs = _interopRequireDefault(require("hammerjs"));
var _createModal = _interopRequireDefault(require("./createModal"));
function useExterior(player, spinner, _ref) {
  var url = _ref.url,
    viewPoints = _ref.viewPoints;
  var _useState = (0, _react.useState)(false),
    _useState2 = (0, _slicedToArray2["default"])(_useState, 2),
    loaded = _useState2[0],
    setLoaded = _useState2[1];
  var video = document.createElement('video');
  var canvas = document.createElement('canvas');
  canvas.id = 'exterior-canvas';
  var aspectRatio = 1 / (9 / 16);
  var target;
  var videoStep;
  var scale = 1;
  var direction = 1;
  var increment = 0.05;
  var playing;
  var pause;
  var duration;
  var updateVelocity = function updateVelocity() {
    return 0.05;
  };
  var slowDown = function slowDown() {
    var threshold = 0.01;
    var dVelocity = 0.5;
    if (Math.abs(increment) <= threshold) {
      playing = clearInterval(playing);
      updateVelocity = function updateVelocity() {
        return 0;
      };
      return 0;
    }
    return increment * dVelocity;
  };
  var doPause = function doPause() {
    updateVelocity = slowDown;
  };
  var spin = function spin(velocity) {
    var spinIncrement = Math.abs(velocity) * videoStep * 50;
    return function () {
      return Math.min(spinIncrement, 1);
    };
  };
  var getViewpoints = function getViewpoints(now) {
    return viewPoints.filter(function (_ref2) {
      var start = _ref2.start,
        end = _ref2.end;
      return start < now && now < end;
    });
  };
  var getCoordinates = function getCoordinates(_ref3, now) {
    var start = _ref3.start,
      end = _ref3.end,
      points = _ref3.points;
    var offset = now - start;
    var videoDuration = end - start;
    var interval = videoDuration / (points.length - 1);
    var index = Math.floor(offset / interval);
    var _points$index = points[index],
      x1 = _points$index.x,
      y1 = _points$index.y;
    var _ref4 = points[index + 1] || {
        x: x1,
        y: y1
      },
      x2 = _ref4.x,
      y2 = _ref4.y;
    var delta = offset % interval / interval;
    return {
      x: x1 + (x2 - x1) * delta,
      y: y1 + (y2 - y1) * delta
    };
  };
  var drawViewpoint = function drawViewpoint(xp, yp) {
    var x = xp * canvas.width;
    var y = yp * canvas.height;
    target.beginPath();
    target.strokeStyle = '#ff5d6f';
    target.arc(x, y, 15 * scale, 0, 2 * Math.PI);
    target.stroke();
    target.beginPath();
    target.fillStyle = '#ff5d6f';
    target.arc(x, y, 12 * scale, 0, 2 * Math.PI);
    target.fill();
  };
  var getClicked = function getClicked(vps, _ref5) {
    var clickX = _ref5.x,
      clickY = _ref5.y;
    return vps.find(function (vp) {
      var _getCoordinates = getCoordinates(vp, video.currentTime),
        x = _getCoordinates.x,
        y = _getCoordinates.y;
      var offX = Math.abs(clickX - x * canvas.width);
      var offY = Math.abs(clickY - y * canvas.height);
      return offX <= 15 * scale && offY <= 15 * scale;
    });
  };
  var drawFrame = function drawFrame() {
    target.drawImage(video, 0, 0, canvas.width, canvas.height);
    var vps = getViewpoints(video.currentTime);
    vps.map(function (vp) {
      return getCoordinates(vp, video.currentTime);
    }).map(function (_ref6) {
      var x = _ref6.x,
        y = _ref6.y;
      return drawViewpoint(x * scale, y * scale);
    });
  };
  var maintainSpeed = function maintainSpeed() {
    var currentTime = video.currentTime + increment * direction;
    video.currentTime = (currentTime + video.duration) % video.duration;
  };
  var updateFrame = function updateFrame() {
    drawFrame();
    maintainSpeed();
    increment = updateVelocity();
  };
  var createElements = function createElements() {
    video.src = url;
    video.loop = true;
    video.muted = true;
    video.autoplay = true;
    video.height = player.current.clientWidth / aspectRatio;
    video.width = player.current.clientWidth;
    video.onloadeddata = function () {
      video.pause();
      // eslint-disable-next-line prefer-destructuring
      duration = video.duration;
      video.currentTime = video.duration;
      videoStep = duration / (canvas.width * 0.5);
      updateVelocity = function updateVelocity() {
        return videoStep * 0.0001;
      }; // Change this value to 1.5 to have the 360view continue autoplaying on load.
      setLoaded(true);
      player.current.classList.add('visible');
      document.getElementById('360-player-spinner').style.display = 'none';
    };
    canvas.width = player.current.clientWidth;
    canvas.height = player.current.clientWidth / aspectRatio;
    canvas.addEventListener('click', function (event) {
      var vps = getViewpoints(video.currentTime);
      var vp = getClicked(vps, {
        x: event.offsetX,
        y: event.offsetY
      });
      if (vp) {
        (0, _createModal["default"])(vp, canvas);
      }
    });
    player.current.appendChild(video);
    player.current.appendChild(canvas);
    target = canvas.getContext('2d');
  };
  var initialiseTouchGestures = function initialiseTouchGestures() {
    var hm = new _hammerjs["default"].Manager(player.current);
    hm.add(new _hammerjs["default"].Pan({
      threshold: 20
    }));
    hm.on('panstart', function () {
      if (!playing) {
        playing = setInterval(updateFrame, 100);
      }
    });
    hm.on('panmove', function () {
      pause = pause && clearTimeout(pause);
      pause = setTimeout(doPause, 150);
    });
    hm.on('panend', function () {
      updateVelocity = slowDown;
    });
    hm.on('panleft', function (event) {
      direction = 1;
      updateVelocity = spin(event.velocity);
    });
    hm.on('panright', function (event) {
      direction = -1;
      updateVelocity = spin(event.velocity);
    });
  };
  var resizePlayer = function resizePlayer() {
    video.height = player.current.clientWidth / aspectRatio;
    video.width = player.current.clientWidth;
    canvas.width = player.current.clientWidth;
    canvas.height = player.current.clientWidth / aspectRatio;
  };
  var initialisePlayer = function initialisePlayer() {
    initialiseTouchGestures();
    createElements();
  };
  (0, _react.useEffect)(function () {
    if (!loaded) {
      window.addEventListener('resize', resizePlayer);
      initialisePlayer();
    }
  }, [loaded]);
}