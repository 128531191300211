"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.roundRangeValues = exports.getClosestDivisableStep = exports.bhpToPsConverter = void 0;
var getClosestDivisableStep = exports.getClosestDivisableStep = function getClosestDivisableStep(minRangeValue, maxRangeValue, step) {
  var difference = maxRangeValue - minRangeValue;
  var floorQuotient = Math.floor(difference / step);
  return step + (difference - step * floorQuotient) / floorQuotient;
};
var bhpToPsConverter = exports.bhpToPsConverter = function bhpToPsConverter(bhp) {
  return Math.round(bhp * 1.01387);
};
var roundRangeValues = exports.roundRangeValues = function roundRangeValues(minRangeValue, maxRangeValue, minValue, maxValue, step) {
  if (!step) {
    return [minValue, maxValue];
  }
  var floorMin = Math.round(minValue / step) * step;
  var min = floorMin;
  var max = Math.min(Math.round(maxValue / step) * step, maxRangeValue);
  return [min, max];
};