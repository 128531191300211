"use strict";

var _interopRequireDefault = require("@babel/runtime/helpers/interopRequireDefault");
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.constants = exports.actions = void 0;
exports.reducer = reducer;
var _defineProperty2 = _interopRequireDefault(require("@babel/runtime/helpers/defineProperty"));
var _filters = require("../../shared/filters");
function ownKeys(e, r) { var t = Object.keys(e); if (Object.getOwnPropertySymbols) { var o = Object.getOwnPropertySymbols(e); r && (o = o.filter(function (r) { return Object.getOwnPropertyDescriptor(e, r).enumerable; })), t.push.apply(t, o); } return t; }
function _objectSpread(e) { for (var r = 1; r < arguments.length; r++) { var t = null != arguments[r] ? arguments[r] : {}; r % 2 ? ownKeys(Object(t), !0).forEach(function (r) { (0, _defineProperty2["default"])(e, r, t[r]); }) : Object.getOwnPropertyDescriptors ? Object.defineProperties(e, Object.getOwnPropertyDescriptors(t)) : ownKeys(Object(t)).forEach(function (r) { Object.defineProperty(e, r, Object.getOwnPropertyDescriptor(t, r)); }); } return e; }
var constants = exports.constants = {
  getModelGroups: 'GET_MODEL_GROUPS',
  getAstonModelGroups: 'GET_ASTON_MODEL_GROUPS',
  getGenesisModelGroups: 'GET_GENESIS_MODEL_GROUPS',
  getModelGroupsSuccess: 'GET_MODEL_GROUPS_SUCCESS',
  getModelGroupsFailure: 'GET_MODEL_GROUPS_FAILURE',
  clearState: 'CLEAR_STATE'
};
var actions = exports.actions = {
  getModelGroups: function getModelGroups() {
    return {
      type: constants.getModelGroups
    };
  },
  getAstonModelGroups: function getAstonModelGroups() {
    return {
      type: constants.getAstonModelGroups
    };
  },
  getGenesisModelGroups: function getGenesisModelGroups() {
    return {
      type: constants.getGenesisModelGroups
    };
  },
  getModelGroupsSuccess: function getModelGroupsSuccess(payload) {
    return {
      type: constants.getModelGroupsSuccess,
      payload: payload
    };
  },
  getModelGroupsFailure: function getModelGroupsFailure() {
    return {
      type: constants.getModelGroupsFailure
    };
  },
  clearState: function clearState() {
    return {
      type: constants.clearState
    };
  }
};
var initialState = {
  modelGroups: [],
  loaded: false
};
function reducer() {
  var state = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : initialState;
  var action = arguments.length > 1 ? arguments[1] : undefined;
  var payload = action.payload;
  switch (action.type) {
    case constants.getModelGroupsSuccess:
      return _objectSpread(_objectSpread({}, state), {}, {
        modelGroups: payload
      });
    case _filters.constants.LOAD_FILTERS_SUCCESS:
      return _objectSpread(_objectSpread({}, state), {}, {
        loaded: true
      });
    case constants.clearState:
      return initialState;
    default:
      return state;
  }
}