"use strict";

var _interopRequireDefault = require("@babel/runtime/helpers/interopRequireDefault");
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.initialState = exports.id = exports.constants = exports.actions = void 0;
exports.reducer = reducer;
var _defineProperty2 = _interopRequireDefault(require("@babel/runtime/helpers/defineProperty"));
var _toConsumableArray2 = _interopRequireDefault(require("@babel/runtime/helpers/toConsumableArray"));
var _reducerMacros = require("../../utilities/reducerMacros");
function ownKeys(e, r) { var t = Object.keys(e); if (Object.getOwnPropertySymbols) { var o = Object.getOwnPropertySymbols(e); r && (o = o.filter(function (r) { return Object.getOwnPropertyDescriptor(e, r).enumerable; })), t.push.apply(t, o); } return t; }
function _objectSpread(e) { for (var r = 1; r < arguments.length; r++) { var t = null != arguments[r] ? arguments[r] : {}; r % 2 ? ownKeys(Object(t), !0).forEach(function (r) { (0, _defineProperty2["default"])(e, r, t[r]); }) : Object.getOwnPropertyDescriptors ? Object.defineProperties(e, Object.getOwnPropertyDescriptors(t)) : ownKeys(Object(t)).forEach(function (r) { Object.defineProperty(e, r, Object.getOwnPropertyDescriptor(t, r)); }); } return e; }
var modulePrefix = 'MOD_SEARCH';
var constants = exports.constants = (0, _reducerMacros.moduleConstants)(modulePrefix, ['GET_INVENTORY_GROUP', 'GET_INVENTORY_GROUP_SUCCESS', 'GET_MODEL_GROUPS_AND_INVENTORY', 'GET_MODEL_GROUPS_AND_INVENTORY_SUCCESS', 'GET_FINANCE_SUCCESS', 'RESET_FILTERS_FAILURE', 'INIT']);
var id = exports.id = 'Search';

// @TODO: The models have accents and trailing white spaces in some cases, the modelsGroups do not
// so need to normalize the string to perform lookups
// we need to get the ModelGroups end point to return proper model name data :-(
var normalize = function normalize(str) {
  return str.normalize('NFD').replace(/[\u0300-\u036f]/g, '').trim();
};
var modelLookup = function modelLookup(modelGroups, model) {
  return modelGroups.find(function (m) {
    return normalize(m.name.toLowerCase()) === normalize(model.toLowerCase());
  }) || {};
};
var transform = function transform(lookupModels, placeholdingImage) {
  return function (_ref) {
    var model = _ref.model,
      modelDisplay = _ref.modelDisplay,
      count = _ref.count,
      minimumPrice = _ref.minimumPrice,
      finance = _ref.finance,
      financeLoaded = _ref.financeLoaded;
    var m = modelLookup(lookupModels, model);
    return {
      model: model,
      modelDisplay: modelDisplay,
      count: count,
      minimumPrice: minimumPrice,
      finance: finance,
      financeLoaded: financeLoaded,
      position: m.position || 0,
      imageUrl: m.imageUrl || placeholdingImage,
      modelSubTitle: m.modelSubTitle
    };
  };
};
var mergeAndSortInventoryGroup = function mergeAndSortInventoryGroup(inventoryGroup, modelGroups, placeholdingImage) {
  return inventoryGroup.map(transform(modelGroups, placeholdingImage)).sort(function (a, b) {
    return a.position - b.position;
  });
};
var actions = exports.actions = {
  getInventoryGroup: (0, _reducerMacros.moduleActionWithArgs)(constants.GET_INVENTORY_GROUP, id, function (payload) {
    return payload;
  }),
  getInventoryGroupSuccess: (0, _reducerMacros.moduleActionWithArgs)(constants.GET_INVENTORY_GROUP_SUCCESS, id, function (config) {
    return config;
  }),
  getModelGroupsAndInventory: (0, _reducerMacros.moduleActionWithArgs)(constants.GET_MODEL_GROUPS_AND_INVENTORY, id, function (payload) {
    return payload;
  }),
  getModelGroupsAndInventorySuccess: (0, _reducerMacros.moduleActionWithArgs)(constants.GET_MODEL_GROUPS_AND_INVENTORY_SUCCESS, id, function (payload) {
    return payload;
  }),
  getFinanceSuccess: (0, _reducerMacros.moduleActionWithArgs)(constants.GET_FINANCE_SUCCESS, id, function (payload) {
    return payload;
  }),
  init: (0, _reducerMacros.moduleAction)(constants.INIT, id)
};
var replaceIfExists = function replaceIfExists(groups, group) {
  return (0, _toConsumableArray2["default"])(groups.map(function (g) {
    return g.model === group.model ? group : g;
  }));
};
var initialState = exports.initialState = {
  searchConfig: {
    inventoryGroup: [],
    modelGroups: [],
    placeholdingImage: ''
  }
};
function reducer() {
  var state = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : initialState;
  var action = arguments.length > 1 ? arguments[1] : undefined;
  switch (action.type) {
    case constants.GET_INVENTORY_GROUP_SUCCESS:
      {
        var _action$payload = action.payload,
          inventoryGroup = _action$payload.inventoryGroup,
          placeholdingImage = _action$payload.placeholdingImage;
        var mergedInventoryGroup = mergeAndSortInventoryGroup(inventoryGroup, state.searchConfig.modelGroups, placeholdingImage);
        return _objectSpread(_objectSpread({}, state), {}, {
          searchConfig: _objectSpread(_objectSpread({}, state.searchConfig), {}, {
            inventoryGroup: mergedInventoryGroup
          })
        });
      }
    case constants.GET_MODEL_GROUPS_AND_INVENTORY_SUCCESS:
      {
        var _action$payload2 = action.payload,
          modelGroups = _action$payload2.modelGroups,
          _inventoryGroup = _action$payload2.inventoryGroup,
          _placeholdingImage = _action$payload2.placeholdingImage,
          modelSubTitle = _action$payload2.modelSubTitle;
        var _mergedInventoryGroup = mergeAndSortInventoryGroup(_inventoryGroup, modelGroups, _placeholdingImage, modelSubTitle);
        return _objectSpread(_objectSpread({}, state), {}, {
          searchConfig: _objectSpread(_objectSpread({}, state.searchConfig), {}, {
            modelGroups: modelGroups,
            inventoryGroup: _mergedInventoryGroup
          })
        });
      }
    case constants.GET_INVENTORY_GROUP_FAILURE:
    case constants.GET_MODEL_GROUPS_AND_INVENTORY_FAILURE:
    case constants.GET_FINANCE_SUCCESS:
      {
        var _action$payload3 = action.payload,
          group = _action$payload3.group,
          _placeholdingImage2 = _action$payload3.placeholdingImage;
        var _inventoryGroup2 = replaceIfExists(state.searchConfig.inventoryGroup, group);
        var _mergedInventoryGroup2 = mergeAndSortInventoryGroup(_inventoryGroup2, state.searchConfig.modelGroups, _placeholdingImage2);
        return _objectSpread(_objectSpread({}, state), {}, {
          searchConfig: _objectSpread(_objectSpread({}, state.searchConfig), {}, {
            inventoryGroup: _mergedInventoryGroup2
          })
        });
      }
    default:
      return state;
  }
}