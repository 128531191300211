"use strict";

var _interopRequireDefault = require("@babel/runtime/helpers/interopRequireDefault");
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports["default"] = _default;
var _slicedToArray2 = _interopRequireDefault(require("@babel/runtime/helpers/slicedToArray"));
var _react = require("react");
function _default(selectedValues) {
  var _useState = (0, _react.useState)({
      minValue: selectedValues.min,
      maxValue: selectedValues.max
    }),
    _useState2 = (0, _slicedToArray2["default"])(_useState, 2),
    selectedSliderValues = _useState2[0],
    setSelectedSliderValues = _useState2[1];
  var updateSliderValues = function updateSliderValues(values) {
    var _values = (0, _slicedToArray2["default"])(values, 2),
      minValue = _values[0],
      maxValue = _values[1];
    setSelectedSliderValues({
      minValue: minValue,
      maxValue: maxValue
    });
  };
  (0, _react.useEffect)(function () {
    setSelectedSliderValues({
      minValue: selectedValues.min,
      maxValue: selectedValues.max
    });
  }, [selectedValues]);
  return {
    updateSliderValues: updateSliderValues,
    selectedSliderValues: selectedSliderValues
  };
}