"use strict";

var _interopRequireDefault = require("@babel/runtime/helpers/interopRequireDefault");
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.Suggestion = exports.LocationSuggestions = void 0;
var _styledComponents = _interopRequireDefault(require("styled-components"));
var LocationSuggestions = exports.LocationSuggestions = _styledComponents["default"].div.withConfig({
  displayName: "LocationBar__LocationSuggestions",
  componentId: "sc-9pbx0b-0"
})(["position:absolute;width:100%;", ";", ";background-color:#ffffff;border:1px solid #d5d5d5;box-sizing:border-box;z-index:10;"], function (_ref) {
  var position = _ref.position;
  return position ? position === 'below' ? 'top: 55px' : 'bottom: 55px' : '';
}, function (_ref2) {
  var top = _ref2.top;
  return top && "top: ".concat(top);
});
var Suggestion = exports.Suggestion = _styledComponents["default"].div.withConfig({
  displayName: "LocationBar__Suggestion",
  componentId: "sc-9pbx0b-1"
})(["font-size:12px;padding:5px;border-bottom:1px solid #d5d5d5;cursor:pointer;outline:none;color:#000;", ";&:hover{background-color:#d5d5d5;}&:last-child{border-bottom:none;}"], function (_ref3) {
  var inFocus = _ref3.inFocus;
  return inFocus && 'background-color: #d5d5d5;';
});