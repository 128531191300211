"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports["default"] = void 0;
var _default = exports["default"] = {
  createApiScriptElement: function createApiScriptElement(tenantId, locale, stage, apiSrc) {
    var apiScriptTag = document.createElement('script');
    var onLoadScript = "epaas.api.initialize(\n                            {\n                              tenant: '".concat(tenantId, "',\n                              locale:'").concat(locale, "',\n                            });");
    apiScriptTag.setAttribute('src', apiSrc);
    apiScriptTag.setAttribute('onload', onLoadScript);
    apiScriptTag.setAttribute('id', 'ePaaS-Script');
    apiScriptTag.setAttribute('type', 'text/javascript');
    return apiScriptTag;
  },
  cookieDrawerOpenScriptElement: function cookieDrawerOpenScriptElement() {
    var drawerLoader = document.createElement('script');
    drawerLoader.setAttribute('type', 'text/javascript');
    drawerLoader.setAttribute('id', 'consentDrawerLoader');
    drawerLoader.text = "\n                              function showDrawer(){\n                                var showDisclaimer = epaas.api.showDisclaimer();\n                                if (showDisclaimer || location.search.indexOf('forcedrawer') > -1) {\n                                  epaas.api.consentdrawer.then(consentDrawerBundle =>\n                                  consentDrawerBundle.showConsentDrawer());\n                                }\n                                (window.epaas.api ||window).removeEventListener('consentcontroller.api.initialized',showDrawer);\n                              }\n\n                              if(!window.epaas){\n                                window.addEventListener('consentcontroller.api.initialized', showDrawer);\n                              }else{\n                                showDrawer();\n                              }";
    return drawerLoader;
  },
  createStyleTagForConsentController: function createStyleTagForConsentController(linkFontUrl, textFontUrl) {
    var styleTag = document.createElement('style');
    styleTag.setAttribute('id', 'stylesForePaaS');
    var styleInnerHTML = "@font-face {\n                        font-family: epaasLinkFont;\n                        src: url('".concat(linkFontUrl, "');\n                        }\n                      @font-face {\n                        font-family: epaasTextFont;\n                        src: url('").concat(textFontUrl, "');\n                        }");
    styleTag.innerHTML = styleInnerHTML;
    return styleTag;
  },
  createStyleTagForConsentOveridesController: function createStyleTagForConsentOveridesController() {
    var styleTag = document.createElement('style');
    styleTag.setAttribute('id', 'stylesForePaaSOverides');
    var styleInnerHTML = ".epaas{cursor: default}.epaas button, .epaas .checkbox label,.epaas .expander,.epaas a, .epaas .category-header__switch .slider, .epaas .expander-symbol, .epaas .overview-category-name{cursor: pointer!important}";
    styleTag.innerHTML = styleInnerHTML;
    return styleTag;
  },
  createGoogleTagsScriptElementsIfUsageAllowed: function createGoogleTagsScriptElementsIfUsageAllowed(gtmId, processingName) {
    if (window.epaas && !window.analyticsConsent && window.epaas.api.isUsageAllowed(processingName)) {
      var gtmTagScript = document.createElement('script');
      gtmTagScript.setAttribute('type', 'text/javascript');
      gtmTagScript.setAttribute('key', "gtm-".concat(gtmId));
      gtmTagScript.setAttribute('src', "https://www.googletagmanager.com/gtm.js?id=".concat(gtmId));
      var gtmInitScript = document.createElement('script');
      gtmInitScript.setAttribute('type', 'text/javascript');
      var gtmInitContent = "window.dataLayer = window.dataLayer || [];\n      window.dataLayer.push({'gtm.start': new Date().getTime(),event:'gtm.js'})";
      gtmInitScript.text = gtmInitContent;
      var head = document.getElementsByTagName('head')[0];
      head.insertBefore(gtmTagScript, head.firstChild);
      head.insertBefore(gtmInitScript, head.firstChild);
      window.analyticsConsent = true;
    }
  }
};