/* eslint-disable import/no-unresolved, import/extensions  */
import { actions } from 'cms-modules';

const {
  enquiry: { constants: enquiryConstants },
} = actions;

const initialState = null;

export default function reducer(state = initialState, action) {
  switch (action.type) {
    case enquiryConstants.GET_ENQUIRY_VEHICLE_INFO_SUCCESS: {
      return {
        id: action.payload.id,
        name: action.payload.name || '',
        description: action.payload.description || '',
        retailerInformation: action.payload.retailerInformation || {
          id: action.payload.retailerId,
        },
        specification: action.payload.specification,
        variant: action.payload.variant || action.payload.modelVariant,
        price: action.payload.price,
        modelGroupName: action.payload.modelGroupName || '',
        registration: action.payload.registration || '',
        registrationPlate: action.payload.registrationPlate || '',
        locale: action.payload.locale || '',
        exterior: action.payload.exterior || '',
        vin: action.payload.vin,
        enquiryType: action.payload.enquiryType,
        modixVehicleId: action.payload.modixVehicleId || '',
        modelCode: action.payload.modelCode,
        modelTypeCode: action.payload.modelTypeCode,
        modelYear: action.payload.modelYear,
        approved: action.payload.approved,
      };
    }
    case enquiryConstants.CLEAR_ENQUIRY_VEHICLE_INFO: {
      return null;
    }

    default:
      return state;
  }
}
