"use strict";

var _interopRequireDefault = require("@babel/runtime/helpers/interopRequireDefault");
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.mediaProps = exports.createElement = void 0;
var _defineProperty2 = _interopRequireDefault(require("@babel/runtime/helpers/defineProperty"));
var _taggedTemplateLiteral2 = _interopRequireDefault(require("@babel/runtime/helpers/taggedTemplateLiteral"));
var _styledComponents = _interopRequireDefault(require("styled-components"));
var _path = _interopRequireDefault(require("ramda/src/path"));
var _theme = _interopRequireDefault(require("../../theme"));
var _templateObject, _templateObject2, _templateObject3, _templateObject4, _templateObject5, _templateObject6;
function ownKeys(e, r) { var t = Object.keys(e); if (Object.getOwnPropertySymbols) { var o = Object.getOwnPropertySymbols(e); r && (o = o.filter(function (r) { return Object.getOwnPropertyDescriptor(e, r).enumerable; })), t.push.apply(t, o); } return t; }
function _objectSpread(e) { for (var r = 1; r < arguments.length; r++) { var t = null != arguments[r] ? arguments[r] : {}; r % 2 ? ownKeys(Object(t), !0).forEach(function (r) { (0, _defineProperty2["default"])(e, r, t[r]); }) : Object.getOwnPropertyDescriptors ? Object.defineProperties(e, Object.getOwnPropertyDescriptors(t)) : ownKeys(Object(t)).forEach(function (r) { Object.defineProperty(e, r, Object.getOwnPropertyDescriptor(t, r)); }); } return e; }
var screenSizeOptions = {
  max: {
    small: 'maxSmallMobileStyleOverride',
    medium: 'maxMediumMobileStyleOverride',
    large: 'maxLargeMobileStyleOverride'
  },
  min: {
    small: 'minSmallMobileStyleOverride',
    medium: 'minMediumMobileStyleOverride',
    large: 'minLargeMobileStyleOverride'
  }
};
var createElement = exports.createElement = function createElement(element) {
  return _styledComponents["default"][element].attrs(function () {
    return {
      className: 'global'
    };
  }).withConfig({
    displayName: "styledComponentFactory",
    componentId: "sc-fgrpai-0"
  })(["&&&{", ";", ";", ";", ";", ";", ";", ";}"], function (_ref) {
    var styleOverride = _ref.styleOverride;
    return styleOverride && styleOverride();
  }, _theme["default"].max.medium(_templateObject || (_templateObject = (0, _taggedTemplateLiteral2["default"])(["", ";"])), function (_ref2) {
    var maxMediumMobileStyleOverride = _ref2.maxMediumMobileStyleOverride;
    return maxMediumMobileStyleOverride;
  }), _theme["default"].max.small(_templateObject2 || (_templateObject2 = (0, _taggedTemplateLiteral2["default"])(["", ";"])), function (_ref3) {
    var maxSmallMobileStyleOverride = _ref3.maxSmallMobileStyleOverride;
    return maxSmallMobileStyleOverride;
  }), _theme["default"].max.large(_templateObject3 || (_templateObject3 = (0, _taggedTemplateLiteral2["default"])(["", ";"])), function (_ref4) {
    var maxLargeMobileStyleOverride = _ref4.maxLargeMobileStyleOverride;
    return maxLargeMobileStyleOverride;
  }), _theme["default"].min.medium(_templateObject4 || (_templateObject4 = (0, _taggedTemplateLiteral2["default"])(["", ";"])), function (_ref5) {
    var minMediumMobileStyleOverride = _ref5.minMediumMobileStyleOverride;
    return minMediumMobileStyleOverride;
  }), _theme["default"].min.small(_templateObject5 || (_templateObject5 = (0, _taggedTemplateLiteral2["default"])(["", ";"])), function (_ref6) {
    var minSmallMobileStyleOverride = _ref6.minSmallMobileStyleOverride;
    return minSmallMobileStyleOverride;
  }), _theme["default"].min.large(_templateObject6 || (_templateObject6 = (0, _taggedTemplateLiteral2["default"])(["", ";"])), function (_ref7) {
    var minLargeMobileStyleOverride = _ref7.minLargeMobileStyleOverride;
    return minLargeMobileStyleOverride;
  }));
};
var mediaProps = exports.mediaProps = function mediaProps() {
  var mobileStyleOverride = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : [];
  return mobileStyleOverride.reduce(function (ac, _ref8) {
    var queryPath = _ref8.queryPath,
      template = _ref8.template;
    return _objectSpread(_objectSpread({}, ac), {}, (0, _defineProperty2["default"])({}, (0, _path["default"])(queryPath.split('.'), screenSizeOptions), template));
  }, {});
};