"use strict";

var _interopRequireDefault = require("@babel/runtime/helpers/interopRequireDefault");
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.availableToAdministration = void 0;
Object.defineProperty(exports, "component", {
  enumerable: true,
  get: function get() {
    return _VehicleOverview["default"];
  }
});
Object.defineProperty(exports, "config", {
  enumerable: true,
  get: function get() {
    return _config["default"];
  }
});
Object.defineProperty(exports, "defaultConfig", {
  enumerable: true,
  get: function get() {
    return _defaultConfig["default"];
  }
});
exports.searchable = exports.name = exports.id = exports.icon = exports.getIndexContent = exports.description = void 0;
var _Icon = _interopRequireDefault(require("./Icon"));
var _VehicleOverview = _interopRequireDefault(require("./VehicleOverview"));
var _config = _interopRequireDefault(require("./config"));
var _defaultConfig = _interopRequireDefault(require("./defaultConfig"));
var name = exports.name = 'Vehicle Overview';
var id = exports.id = 'VehicleOverview';
var availableToAdministration = exports.availableToAdministration = true;
var description = exports.description = 'Vehicle Overview Module';
var icon = exports.icon = _Icon["default"];
var searchable = exports.searchable = true;
var getIndexContent = exports.getIndexContent = function getIndexContent(_ref) {
  var _ref$config = _ref.config,
    _ref$config2 = _ref$config === void 0 ? {} : _ref$config,
    _ref$config2$translat = _ref$config2.translations,
    translations = _ref$config2$translat === void 0 ? {} : _ref$config2$translat,
    relativePath = _ref.relativePath,
    instanceId = _ref.instanceId;
  return [{
    title: translations.vdpAccessoriesHeader,
    content: translations.vdpAccessoriesSubHeader
  }, {
    title: translations.vdpMyDealHeader,
    content: translations.vdpGalleryAndMyDealBottomText
  }].map(function (_ref2, index) {
    var title = _ref2.title,
      content = _ref2.content;
    return {
      key: "".concat(instanceId, "-").concat(index),
      title: title,
      content: content,
      link: relativePath
    };
  }).filter(function (_ref3) {
    var title = _ref3.title,
      content = _ref3.content;
    return !!title || !!content;
  });
};