"use strict";

var _interopRequireDefault = require("@babel/runtime/helpers/interopRequireDefault");
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports["default"] = void 0;
var _config = require("../../helpers/config");
var _SectionIcons = _interopRequireDefault(require("../../helpers/SectionIcons"));
var config = (0, _config.Config)('Linked Test', {
  id: 'LinkedTest',
  linked: true
}, (0, _config.Slice)('Content', {
  id: 'content'
}, (0, _config.Section)('Filter options', {
  icon: _SectionIcons["default"].TopTier
}, (0, _config.DraggableListWithToggle)('filterfilter', 'filterOptions'))));
var _default = exports["default"] = config;