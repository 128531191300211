"use strict";

var _interopRequireDefault = require("@babel/runtime/helpers/interopRequireDefault");
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports["default"] = ConsentController;
var _react = require("react");
var _reactRedux = require("react-redux");
var _router = require("../../actions/router");
var _epaasScripts = _interopRequireDefault(require("./epaasScripts"));
function ConsentController(props) {
  var dispatch = (0, _reactRedux.useDispatch)();
  var _props$config$ePaaS = props.config.ePaaS,
    apiSrc = _props$config$ePaaS.apiSrc,
    tenantId = _props$config$ePaaS.tenantId,
    stage = _props$config$ePaaS.stage;
  var _props$config$ePaaSFo = props.config.ePaaSFonts,
    ePaasLinkFontUrl = _props$config$ePaaSFo.ePaasLinkFontUrl,
    ePaasTextFontUrl = _props$config$ePaaSFo.ePaasTextFontUrl;
  var policyPageIdentifier = props.config.policyLink.urlRoute;
  var locale = props.shared.sessionPreferences.language;
  var gtmId = (0, _reactRedux.useSelector)(function (state) {
    return state.config.config.global.analytics.googleTagManagerIds[0];
  });
  var googleAnalyticsProcessingName = 'GoogleAnalytics';
  var head = document.getElementsByTagName('head')[0];
  var apiScriptElement = _epaasScripts["default"].createApiScriptElement(tenantId, locale, stage, apiSrc);
  var drawerLoader = _epaasScripts["default"].cookieDrawerOpenScriptElement();
  var fontStyleTag = _epaasScripts["default"].createStyleTagForConsentController(ePaasLinkFontUrl, ePaasTextFontUrl);
  var consentStyleOverides = _epaasScripts["default"].createStyleTagForConsentOveridesController();

  // override the default ePaaS link navigation in cookie drawer
  var policyPageRoute = "".concat(props.router.basePath).concat(props.shared.sessionPreferences.language, "/").concat(policyPageIdentifier, "/");
  var navigateToPolicyPage = function navigateToPolicyPage() {
    dispatch(_router.actions.navigate(policyPageRoute));
    //  force drawer suppression on Policy Page
    window.location.reload(false);
  };
  window.setInterval(
  // eslint-disable-next-line func-names
  function (id, processing) {
    _epaasScripts["default"].createGoogleTagsScriptElementsIfUsageAllowed(id, processing);
  }, 2000, gtmId, googleAnalyticsProcessingName);
  var cursorOverides = function cursorOverides() {
    setTimeout(function () {
      if (document.querySelector('epaas-consent-drawer-shell')) {
        var consentRoot = document.querySelector('epaas-consent-drawer-shell').shadowRoot;
        var consentBody = consentRoot.querySelector('html');
        if (!document.getElementById('stylesForePaaSOverides')) consentBody.insertBefore(consentStyleOverides, consentBody.lastChild);
        document.querySelector('html').removeAttribute('style');
      }
      if (document.querySelector('epaas-policy-page-shell')) {
        var policyRoot = document.querySelector('epaas-policy-page-shell').shadowRoot;
        var policyBody = policyRoot.querySelector('html');
        if (!document.getElementById('stylesForePaaSOverides')) policyBody.insertBefore(consentStyleOverides, policyBody.lastChild);
        document.querySelector('html').removeAttribute('style');
      }
    }, 2000);
  };

  // inject scripts at correct position in document head (can't use react-helmet for this)
  (0, _react.useEffect)(function () {
    // override default cookie drawer styling
    if (!document.getElementById('StylesForePaaS')) head.insertBefore(fontStyleTag, head.lastChild);
    if (!document.getElementById('consentDrawerLoader')) head.insertBefore(drawerLoader, head.lastChild);
    if (document.body.getAttribute('ePaasPolicyClickHandlerCreated') !== 'true') {
      window.addEventListener('consentcontroller.api.showPolicyPageClicked', navigateToPolicyPage);
      window.addEventListener('consentcontroller.api.drawershown', cursorOverides);
      window.addEventListener('consentcontroller.api.policyPageRendered', cursorOverides);
      document.body.setAttribute('ePaasPolicyClickHandlerCreated', 'true');
    }
    if (!document.getElementById('ePaaS-Script')) head.insertBefore(apiScriptElement, head.firstChild);
  }, []); // Only run on first load

  return null;
}