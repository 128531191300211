"use strict";

var _interopRequireDefault = require("@babel/runtime/helpers/interopRequireDefault");
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.initialState = exports.constants = exports.actions = void 0;
exports.reducer = reducer;
var _toConsumableArray2 = _interopRequireDefault(require("@babel/runtime/helpers/toConsumableArray"));
var _defineProperty2 = _interopRequireDefault(require("@babel/runtime/helpers/defineProperty"));
var _helper = require("./helper");
function ownKeys(e, r) { var t = Object.keys(e); if (Object.getOwnPropertySymbols) { var o = Object.getOwnPropertySymbols(e); r && (o = o.filter(function (r) { return Object.getOwnPropertyDescriptor(e, r).enumerable; })), t.push.apply(t, o); } return t; }
function _objectSpread(e) { for (var r = 1; r < arguments.length; r++) { var t = null != arguments[r] ? arguments[r] : {}; r % 2 ? ownKeys(Object(t), !0).forEach(function (r) { (0, _defineProperty2["default"])(e, r, t[r]); }) : Object.getOwnPropertyDescriptors ? Object.defineProperties(e, Object.getOwnPropertyDescriptors(t)) : ownKeys(Object(t)).forEach(function (r) { Object.defineProperty(e, r, Object.getOwnPropertyDescriptor(t, r)); }); } return e; }
var constants = exports.constants = {
  TOGGLE_CHECKBOX_FILTER: 'SearchFilters:ToggleCheckbox',
  SELECT_MULTIPLE_CHECKBOX_FILTERS: 'SearchFilters:SelectMultipleCheckboxes',
  CLEAR_CHECKBOX_SECTION: 'SearchFilters:ClearCheckboxSection',
  CHANGE_RANGE_FILTER: 'SearchFilters:ChangeRangeFilter',
  CHANGE_LOCATION: 'SearchFilters:ChangeLocation',
  CHANGE_MAX_DISTANCE: 'SearchFilters:MaxDistance',
  RESET_FILTERS: 'SearchFilters:ResetFilters',
  GET_SEARCH_FILTERS: 'SearchFilters:GetAvailableFilters',
  GET_SEARCH_FILTERS_SUCCESS: 'SearchFilters:GetAvailableFiltersSuccess',
  GET_ALL_SEARCH_FILTERS: 'SearchFilters:GetAllFilters',
  GET_ALL_SEARCH_FILTERS_SUCCESS: 'SearchFilters:GetAllFiltersSuccess',
  FILTER_BY_MODEL_RANGE: 'SearchFilters:FilterByModelRange',
  REMOVE_RANGE_FILTER: 'SearchFilters:RemoveRangeFilter',
  UPDATE_QUERY: 'SearchFilters:UpdateQuery'
};
var actions = exports.actions = {
  toggleCheckboxFilter: function toggleCheckboxFilter(payload) {
    return {
      type: constants.TOGGLE_CHECKBOX_FILTER,
      payload: payload
    };
  },
  selectMultipleCheckboxes: function selectMultipleCheckboxes(payload) {
    return {
      type: constants.SELECT_MULTIPLE_CHECKBOX_FILTERS,
      payload: payload
    };
  },
  clearCheckboxSection: function clearCheckboxSection(payload) {
    return {
      type: constants.CLEAR_CHECKBOX_SECTION,
      payload: payload
    };
  },
  changeRangeFilter: function changeRangeFilter(payload) {
    return {
      type: constants.CHANGE_RANGE_FILTER,
      payload: payload
    };
  },
  changeLocation: function changeLocation(payload) {
    return {
      type: constants.CHANGE_LOCATION,
      payload: payload
    };
  },
  changeMaxDistance: function changeMaxDistance(payload) {
    return {
      type: constants.CHANGE_MAX_DISTANCE,
      payload: payload
    };
  },
  resetFilters: function resetFilters(payload) {
    return {
      type: constants.RESET_FILTERS,
      payload: payload
    };
  },
  resetRetailer: function resetRetailer(payload) {
    return {
      type: constants.RESET_RETAILER,
      payload: payload
    };
  },
  getSearchFilters: function getSearchFilters(payload) {
    return {
      type: constants.GET_SEARCH_FILTERS,
      payload: payload
    };
  },
  getSearchFiltersSuccess: function getSearchFiltersSuccess(payload) {
    return {
      type: constants.GET_SEARCH_FILTERS_SUCCESS,
      payload: payload
    };
  },
  getAllSearchFilters: function getAllSearchFilters(payload) {
    return {
      type: constants.GET_ALL_SEARCH_FILTERS,
      payload: payload
    };
  },
  getAllSearchFiltersSuccess: function getAllSearchFiltersSuccess(payload) {
    return {
      type: constants.GET_ALL_SEARCH_FILTERS_SUCCESS,
      payload: payload
    };
  },
  filterByModelRange: function filterByModelRange(payload) {
    return {
      type: constants.FILTER_BY_MODEL_RANGE,
      payload: payload
    };
  },
  removeRangeFilter: function removeRangeFilter(payload) {
    return {
      type: constants.REMOVE_RANGE_FILTER,
      payload: payload
    };
  },
  updateQuery: function updateQuery() {
    return {
      type: constants.UPDATE_QUERY
    };
  }
};
var initialState = exports.initialState = {
  selectedCheckboxFilters: {
    models: [],
    variants: [],
    engine: [],
    engineSizes: [],
    transmissions: [],
    bodyStyles: [],
    exteriorColours: [],
    interiorShades: [],
    features: []
  },
  selectedRangeFilters: {},
  location: {},
  searchFilters: {
    loaded: false,
    loadingAllFilters: false,
    loadingAvailableFilters: false,
    allFilters: {},
    availableFilters: {}
  },
  searchUrl: '',
  modelSearchUrl: '',
  filterSearchUrl: '',
  retailer: '',
  resetRangeCount: 0
};
function reducer() {
  var state = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : initialState;
  var action = arguments.length > 1 ? arguments[1] : undefined;
  var payload = action.payload,
    type = action.type;
  var selectedCheckboxFilters = state.selectedCheckboxFilters;
  switch (type) {
    case constants.FILTER_BY_RETAILER:
      return _objectSpread(_objectSpread({}, initialState), {}, {
        retailer: payload
      });
    case constants.SELECT_MULTIPLE_CHECKBOX_FILTERS:
      {
        var section = payload.section,
          values = payload.values;
        var selected = (0, _toConsumableArray2["default"])(values);
        var updatedFilters = _objectSpread(_objectSpread({}, selectedCheckboxFilters), {}, (0, _defineProperty2["default"])({}, section, selected));
        return _objectSpread(_objectSpread({}, state), {}, {
          selectedCheckboxFilters: updatedFilters,
          searchUrl: _objectSpread(_objectSpread({}, state.searchUrl), {}, {
            checkboxUrl: (0, _helper.generateCheckboxFilterUrl)(updatedFilters)
          }),
          modelSearchUrl: _objectSpread(_objectSpread({}, state.modelSearchUrl), {}, {
            checkboxUrl: (0, _helper.generateCheckboxFilterUrl)(_objectSpread(_objectSpread({}, updatedFilters), {}, {
              models: []
            }))
          }),
          filterSearchUrl: _objectSpread(_objectSpread({}, state.filterSearchUrl), {}, {
            checkboxUrl: (0, _helper.generateCheckboxFilterUrl)({
              models: updatedFilters.models
            })
          })
        });
      }
    case constants.FILTER_BY_MODEL_RANGE:
    case constants.TOGGLE_CHECKBOX_FILTER:
      {
        var _section = payload.section,
          filter = payload.filter;
        var filterGroup = selectedCheckboxFilters[_section] || [];
        var isSelected = filterGroup.includes(filter);
        var _selected = isSelected ? filterGroup.filter(function (f) {
          return f !== filter;
        }) : [].concat((0, _toConsumableArray2["default"])(filterGroup), [filter]);
        var _updatedFilters = _objectSpread(_objectSpread({}, selectedCheckboxFilters), {}, (0, _defineProperty2["default"])({}, _section, _selected));
        return _objectSpread(_objectSpread({}, state), {}, {
          selectedCheckboxFilters: _updatedFilters,
          searchUrl: _objectSpread(_objectSpread({}, state.searchUrl), {}, {
            checkboxUrl: (0, _helper.generateCheckboxFilterUrl)(_updatedFilters)
          }),
          modelSearchUrl: _objectSpread(_objectSpread({}, state.modelSearchUrl), {}, {
            checkboxUrl: (0, _helper.generateCheckboxFilterUrl)(_objectSpread(_objectSpread({}, _updatedFilters), {}, {
              models: []
            }))
          }),
          filterSearchUrl: _objectSpread(_objectSpread({}, state.filterSearchUrl), {}, {
            checkboxUrl: (0, _helper.generateCheckboxFilterUrl)({
              models: _updatedFilters.models
            })
          })
        });
      }
    case constants.CLEAR_CHECKBOX_SECTION:
      {
        var _section2 = payload.section;
        var _updatedFilters2 = _objectSpread(_objectSpread({}, state.selectedCheckboxFilters), {}, (0, _defineProperty2["default"])({}, _section2, []));
        var checkboxUrl = (0, _helper.generateCheckboxFilterUrl)(_updatedFilters2);
        var filterCheckBoxUrl = (0, _helper.generateCheckboxFilterUrl)({
          models: _updatedFilters2.models
        });
        return _objectSpread(_objectSpread({}, state), {}, {
          selectedCheckboxFilters: _updatedFilters2,
          searchUrl: _objectSpread(_objectSpread({}, state.searchUrl), {}, {
            checkboxUrl: checkboxUrl
          }),
          modelSearchUrl: _objectSpread(_objectSpread({}, state.modelSearchUrl), {}, {
            checkboxUrl: checkboxUrl
          }),
          filterSearchUrl: _objectSpread(_objectSpread({}, state.filterSearchUrl), {}, {
            checkboxUrl: filterCheckBoxUrl
          })
        });
      }
    case constants.CHANGE_RANGE_FILTER:
      {
        var id = payload.id,
          _values = payload.values;
        var selectedRanges = _objectSpread(_objectSpread({}, state.selectedRangeFilters), {}, (0, _defineProperty2["default"])({}, id, {
          min: _values[0],
          max: _values[1],
          active: true
        }));
        return _objectSpread(_objectSpread({}, state), {}, {
          selectedRangeFilters: selectedRanges,
          searchUrl: _objectSpread(_objectSpread({}, state.searchUrl), {}, {
            rangeUrl: (0, _helper.generateRangeUrl)(selectedRanges)
          }),
          modelSearchUrl: _objectSpread(_objectSpread({}, state.modelSearchUrl), {}, {
            rangeUrl: (0, _helper.generateRangeUrl)(selectedRanges)
          }),
          filterSearchUrl: _objectSpread(_objectSpread({}, state.filterSearchUrl), {}, {
            rangeUrl: (0, _helper.generateRangeUrl)(selectedRanges)
          })
        });
      }
    case constants.REMOVE_RANGE_FILTER:
      {
        var _id = payload.id;
        var _selectedRanges = _objectSpread(_objectSpread({}, state.selectedRangeFilters), {}, (0, _defineProperty2["default"])({}, _id, _objectSpread(_objectSpread({}, state.selectedRangeFilters[_id]), {}, {
          active: false
        })));
        return _objectSpread(_objectSpread({}, state), state.selectedRangeFilters[_id] ? {
          selectedRangeFilters: _selectedRanges,
          searchUrl: _objectSpread(_objectSpread({}, state.searchUrl), {}, {
            rangeUrl: (0, _helper.generateRangeUrl)(_selectedRanges)
          }),
          modelSearchUrl: _objectSpread(_objectSpread({}, state.modelSearchUrl), {}, {
            rangeUrl: (0, _helper.generateRangeUrl)(_selectedRanges)
          }),
          filterSearchUrl: _objectSpread(_objectSpread({}, state.filterSearchUrl), {}, {
            rangeUrl: (0, _helper.generateRangeUrl)(_selectedRanges)
          })
        } : {});
      }
    case constants.CHANGE_LOCATION:
      {
        var location = payload.location;
        return _objectSpread(_objectSpread({}, state), {}, {
          location: location,
          searchUrl: _objectSpread(_objectSpread({}, state.searchUrl), {}, {
            locationUrl: (0, _helper.generateLocationUrl)(location)
          }),
          modelSearchUrl: _objectSpread(_objectSpread({}, state.modelSearchUrl), {}, {
            locationUrl: (0, _helper.generateLocationUrl)(location)
          }),
          filterSearchUrl: _objectSpread(_objectSpread({}, state.filterSearchUrl), {}, {
            rangeUrl: (0, _helper.generateLocationUrl)(location)
          })
        });
      }
    case constants.CHANGE_MAX_DISTANCE:
      {
        var maxDistance = payload.maxDistance;
        return _objectSpread(_objectSpread({}, state), {}, {
          maxDistance: maxDistance,
          searchUrl: _objectSpread(_objectSpread({}, state.searchUrl), {}, {
            maxDistanceUrl: (0, _helper.generateMaxDistanceUrl)(maxDistance)
          }),
          modelSearchUrl: _objectSpread(_objectSpread({}, state.modelSearchUrl), {}, {
            maxDistanceUrl: (0, _helper.generateMaxDistanceUrl)(maxDistance)
          }),
          filterSearchUrl: _objectSpread(_objectSpread({}, state.filterSearchUrl), {}, {
            maxDistanceUrl: (0, _helper.generateMaxDistanceUrl)(maxDistance)
          })
        });
      }
    case constants.RESET_FILTERS:
      {
        var allFilters = state.searchFilters.allFilters;
        return _objectSpread(_objectSpread({}, initialState), {}, {
          searchFilters: _objectSpread(_objectSpread({}, state.searchFilters), {}, {
            availableFilters: allFilters,
            resetRangeCount: Math.random()
          })
        });
      }
    case constants.GET_SEARCH_FILTERS:
      {
        return _objectSpread(_objectSpread({}, state), {}, {
          searchFilters: _objectSpread(_objectSpread({}, state.searchFilters), {}, {
            loadingAvailableFilters: true
          })
        });
      }
    case constants.GET_SEARCH_FILTERS_SUCCESS:
      {
        var filters = action.payload.filters;
        return _objectSpread(_objectSpread({}, state), {}, {
          searchFilters: _objectSpread(_objectSpread({}, state.searchFilters), {}, {
            availableFilters: filters,
            loadingAvailableFilters: false
          })
        });
      }
    case constants.GET_ALL_SEARCH_FILTERS:
      {
        return _objectSpread(_objectSpread({}, state), {}, {
          searchFilters: _objectSpread(_objectSpread({}, state.searchFilters), {}, {
            loadingAllFilters: true
          })
        });
      }
    case constants.GET_ALL_SEARCH_FILTERS_SUCCESS:
      {
        var _allFilters = action.payload.allFilters;
        return _objectSpread(_objectSpread({}, state), {}, {
          searchFilters: {
            allFilters: _allFilters,
            availableFilters: _allFilters,
            loadingAllFilters: false,
            loaded: true
          }
        });
      }
    default:
      return state;
  }
}