"use strict";

var _interopRequireDefault = require("@babel/runtime/helpers/interopRequireDefault");
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.initialState = exports.id = exports.constants = exports.actions = void 0;
exports.reducer = reducer;
var _slicedToArray2 = _interopRequireDefault(require("@babel/runtime/helpers/slicedToArray"));
var _defineProperty2 = _interopRequireDefault(require("@babel/runtime/helpers/defineProperty"));
var _reducerMacros = require("../../utilities/reducerMacros");
function ownKeys(e, r) { var t = Object.keys(e); if (Object.getOwnPropertySymbols) { var o = Object.getOwnPropertySymbols(e); r && (o = o.filter(function (r) { return Object.getOwnPropertyDescriptor(e, r).enumerable; })), t.push.apply(t, o); } return t; }
function _objectSpread(e) { for (var r = 1; r < arguments.length; r++) { var t = null != arguments[r] ? arguments[r] : {}; r % 2 ? ownKeys(Object(t), !0).forEach(function (r) { (0, _defineProperty2["default"])(e, r, t[r]); }) : Object.getOwnPropertyDescriptors ? Object.defineProperties(e, Object.getOwnPropertyDescriptors(t)) : ownKeys(Object(t)).forEach(function (r) { Object.defineProperty(e, r, Object.getOwnPropertyDescriptor(t, r)); }); } return e; }
var modulePrefix = 'MAP_SEARCH';
var constants = exports.constants = (0, _reducerMacros.moduleConstants)(modulePrefix, ['GET_RETAILERS', 'GET_RETAILERS_SUCCESS']);
var id = exports.id = 'RetailerLocationSearch';
var actions = exports.actions = {
  getRetailers: (0, _reducerMacros.moduleActionWithArgs)(constants.GET_RETAILERS, id, function (payload) {
    return payload;
  }),
  getRetailersSuccess: (0, _reducerMacros.moduleActionWithArgs)(constants.GET_RETAILERS_SUCCESS, id, function (payload) {
    return payload;
  }),
  getLocation: (0, _reducerMacros.moduleActionWithArgs)(constants.GET_LOCATION, id, function (payload) {
    return payload;
  })
};
var initialState = exports.initialState = {
  retailers: []
};
function reducer() {
  var state = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : initialState;
  var action = arguments.length > 1 ? arguments[1] : undefined;
  switch (action.type) {
    case constants.GET_RETAILERS:
      {
        var _ref = action.payload || {},
          lat = _ref.lat,
          lng = _ref.lng;
        return _objectSpread(_objectSpread({}, state), {}, {
          loadingNearest: !!(lat && lng)
        });
      }
    case constants.GET_RETAILERS_SUCCESS:
      {
        var _action$payload = action.payload,
          retailers = _action$payload.retailers,
          _action$payload$centr = (0, _slicedToArray2["default"])(_action$payload.centreLocation.coordinates, 2),
          _lat = _action$payload$centr[0],
          _lng = _action$payload$centr[1];
        var formattedRetailers = retailers.map(function (retailer) {
          return _objectSpread(_objectSpread({}, retailer), {}, {
            location: {
              lat: retailer.location.coordinates[0],
              lng: retailer.location.coordinates[1]
            }
          });
        });
        return _objectSpread(_objectSpread({}, state), {}, {
          retailers: formattedRetailers,
          centreLocation: {
            lat: _lat,
            lng: _lng
          },
          loadingNearest: false
        });
      }
    default:
      return state;
  }
}