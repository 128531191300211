"use strict";

var _interopRequireDefault = require("@babel/runtime/helpers/interopRequireDefault");
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.availableToAdministration = void 0;
Object.defineProperty(exports, "component", {
  enumerable: true,
  get: function get() {
    return _ImageTitle["default"];
  }
});
Object.defineProperty(exports, "config", {
  enumerable: true,
  get: function get() {
    return _config["default"];
  }
});
Object.defineProperty(exports, "defaultConfig", {
  enumerable: true,
  get: function get() {
    return _defaultConfig["default"];
  }
});
exports.searchable = exports.name = exports.id = exports.icon = exports.getIndexContent = exports.description = void 0;
var _Icon = _interopRequireDefault(require("./Icon"));
var _ImageTitle = _interopRequireDefault(require("./ImageTitle"));
var _config = _interopRequireDefault(require("./config"));
var _defaultConfig = _interopRequireDefault(require("./defaultConfig"));
var name = exports.name = 'Image title';
var id = exports.id = 'ImageTitle';
var availableToAdministration = exports.availableToAdministration = true;
var description = exports.description = 'Full screen image with header overlay';
var icon = exports.icon = _Icon["default"];
var searchable = exports.searchable = true;
var getIndexContent = exports.getIndexContent = function getIndexContent(_ref) {
  var _ref$config = _ref.config,
    _ref$config2 = _ref$config === void 0 ? {} : _ref$config,
    _ref$config2$translat = _ref$config2.translations,
    translations = _ref$config2$translat === void 0 ? {} : _ref$config2$translat;
  return [{
    title: translations.headlineText,
    content: translations.bodyText
  }];
};