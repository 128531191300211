"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports["default"] = _default;
function _default() {
  return {
    translations: {
      pageTitle: 'Title Placeholder',
      pageSubTitle: 'Subtitle Placeholder'
    },
    pageTitleFont: {
      fontSize: 16,
      kerning: 0.4,
      transform: 'uppercase',
      height: '10px'
    },
    pageSubTitleFont: {
      fontSize: 12,
      kerning: 0.4,
      transform: 'uppercase',
      height: '10px'
    },
    alignment: 'center'
  };
}