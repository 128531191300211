"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports["default"] = void 0;
var _config = require("../../helpers/config");
var config = (0, _config.Config)('Image title', {
  id: 'imagetitle'
}, (0, _config.Slice)('Content', {
  id: 'content'
}, (0, _config.Section)('Global', {}, (0, _config.Image)('Image', 'selectedImage'), (0, _config.TextArea)('Header', 'headlineText'), (0, _config.TextArea)('Description', 'subHeading'))), (0, _config.Slice)('Styling', {
  id: 'styling'
}, (0, _config.Section)('Global', {}, (0, _config.Colour)('Background Colour', 'backgroundColour'), (0, _config.Font)('Header', 'headlineFont'), (0, _config.Font)('Description', 'bodyFont'))));
var _default = exports["default"] = config;