"use strict";

var _interopRequireDefault = require("@babel/runtime/helpers/interopRequireDefault");
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.availableToAdministration = void 0;
Object.defineProperty(exports, "component", {
  enumerable: true,
  get: function get() {
    return _ConsentController["default"];
  }
});
Object.defineProperty(exports, "config", {
  enumerable: true,
  get: function get() {
    return _config["default"];
  }
});
Object.defineProperty(exports, "defaultConfig", {
  enumerable: true,
  get: function get() {
    return _defaultConfig["default"];
  }
});
exports.required = exports.position = exports.name = exports.id = exports.icon = exports.global = exports.description = void 0;
var _Icon = _interopRequireDefault(require("./Icon"));
var _ConsentController = _interopRequireDefault(require("./ConsentController"));
var _config = _interopRequireDefault(require("./config"));
var _defaultConfig = _interopRequireDefault(require("./defaultConfig"));
var name = exports.name = 'ConsentController';
var id = exports.id = 'ConsentController';
var availableToAdministration = exports.availableToAdministration = false;
var description = exports.description = 'For sites using BMW ePaaS only. Inject the ePaaS script into the document head.';
var icon = exports.icon = _Icon["default"];
var global = exports.global = true;
var required = exports.required = false;
var position = exports.position = 1;