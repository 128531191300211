"use strict";

var _interopRequireDefault = require("@babel/runtime/helpers/interopRequireDefault");
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.Subtitle = exports.Header = void 0;
var _taggedTemplateLiteral2 = _interopRequireDefault(require("@babel/runtime/helpers/taggedTemplateLiteral"));
var _styledComponents = _interopRequireDefault(require("styled-components"));
var _Genesis = _interopRequireDefault(require("../../customThemes/Genesis"));
var _templateObject, _templateObject2;
var Header = exports.Header = _styledComponents["default"].h1.withConfig({
  displayName: "Titles__Header",
  componentId: "sc-18ts70v-0"
})(["color:#fff;text-align:center;font-family:'GenesisSansHeadGlobal-Light';font-size:40px;line-height:40px;font-weight:300;margin-bottom:0;", ";"], _Genesis["default"].max.medium(_templateObject || (_templateObject = (0, _taggedTemplateLiteral2["default"])(["\n    font-size: 20px;\n  "]))));
var Subtitle = exports.Subtitle = _styledComponents["default"].h2.withConfig({
  displayName: "Titles__Subtitle",
  componentId: "sc-18ts70v-1"
})(["color:#fff;text-align:center;font-family:'GenesisSansHeadGlobal-Light';font-weight:300;font-size:18px;line-height:14px;", ";"], _Genesis["default"].max.medium(_templateObject2 || (_templateObject2 = (0, _taggedTemplateLiteral2["default"])(["\n    font-size: 14px;\n  "]))));