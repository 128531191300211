"use strict";

var _interopRequireDefault = require("@babel/runtime/helpers/interopRequireDefault");
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports["default"] = void 0;
var _styledComponents = _interopRequireDefault(require("styled-components"));
var _default = exports["default"] = _styledComponents["default"].div.withConfig({
  displayName: "FieldError",
  componentId: "sc-p11hh2-0"
})(["color:#bf0c0c;text-align:left;"]);