"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports["default"] = void 0;
var _reactRedux = require("react-redux");
var _router = require("../actions/router");
var navigate = _router.actions.navigate;
var useNavigation = function useNavigation() {
  var dispatch = (0, _reactRedux.useDispatch)();
  var isExternalUrl = function isExternalUrl(url) {
    return /^https?:\/\//.test(url);
  };
  var getTargetUrl = function getTargetUrl(url) {
    return isExternalUrl(url) ? '_blank' : '_self';
  };
  var navigateInternalUrl = function navigateInternalUrl(url) {
    var isInternal = !isExternalUrl(url);
    if (isInternal) {
      dispatch(navigate(url));
    }
    return isInternal;
  };
  var navigateTo = function navigateTo(url) {
    dispatch(navigate(url));
  };
  return {
    navigateInternalUrl: navigateInternalUrl,
    navigateTo: navigateTo,
    isExternalUrl: isExternalUrl,
    getTargetUrl: getTargetUrl
  };
};
var _default = exports["default"] = useNavigation;