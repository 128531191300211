"use strict";

var _interopRequireDefault = require("@babel/runtime/helpers/interopRequireDefault");
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.constants = exports.actions = void 0;
exports.reducer = reducer;
var _defineProperty2 = _interopRequireDefault(require("@babel/runtime/helpers/defineProperty"));
function ownKeys(e, r) { var t = Object.keys(e); if (Object.getOwnPropertySymbols) { var o = Object.getOwnPropertySymbols(e); r && (o = o.filter(function (r) { return Object.getOwnPropertyDescriptor(e, r).enumerable; })), t.push.apply(t, o); } return t; }
function _objectSpread(e) { for (var r = 1; r < arguments.length; r++) { var t = null != arguments[r] ? arguments[r] : {}; r % 2 ? ownKeys(Object(t), !0).forEach(function (r) { (0, _defineProperty2["default"])(e, r, t[r]); }) : Object.getOwnPropertyDescriptors ? Object.defineProperties(e, Object.getOwnPropertyDescriptors(t)) : ownKeys(Object(t)).forEach(function (r) { Object.defineProperty(e, r, Object.getOwnPropertyDescriptor(t, r)); }); } return e; }
var constants = exports.constants = {
  LOAD_REPRESENTATIVE_EXAMPLE: 'LOAD_REPRESENTATIVE_EXAMPLE',
  LOAD_REPRESENTATIVE_EXAMPLE_SUCCESS: 'LOAD_REPRESENTATIVE_EXAMPLE_SUCCESS',
  LOAD_FINANCE_OPTIONS: 'LOAD_FINANCE_OPTIONS',
  LOAD_FINANCE_OPTIONS_SUCCESS: 'LOAD_FINANCE_OPTIONS_SUCCESS',
  LOAD_FINANCE_PRODUCT: 'LOAD_FINANCE_PRODUCT',
  LOAD_FINANCE_PRODUCT_SUCCESS: 'LOAD_FINANCE_PRODUCT_SUCCESS',
  RESET_FINANCE_PRODUCT: 'RESET_FINANCE_PRODUCT'
};
var actions = exports.actions = {
  loadRepresentativeExample: function loadRepresentativeExample() {
    return {
      type: constants.LOAD_REPRESENTATIVE_EXAMPLE
    };
  },
  loadRepresentativeExampleSuccess: function loadRepresentativeExampleSuccess(representativeExample) {
    return {
      type: constants.LOAD_REPRESENTATIVE_EXAMPLE_SUCCESS,
      payload: representativeExample
    };
  },
  loadFinanceOptions: function loadFinanceOptions(payload) {
    return {
      type: constants.LOAD_FINANCE_OPTIONS,
      payload: payload
    };
  },
  loadFinanceOptionsSuccess: function loadFinanceOptionsSuccess(payload) {
    return {
      type: constants.LOAD_FINANCE_OPTIONS_SUCCESS,
      payload: payload
    };
  },
  loadFinanceProduct: function loadFinanceProduct(payload) {
    return {
      type: constants.LOAD_FINANCE_PRODUCT,
      payload: payload
    };
  },
  loadFinanceProductSuccess: function loadFinanceProductSuccess(payload) {
    return {
      type: constants.LOAD_FINANCE_PRODUCT_SUCCESS,
      payload: payload
    };
  },
  resetFinanceProduct: function resetFinanceProduct(payload) {
    return {
      type: constants.RESET_FINANCE_PRODUCT,
      payload: payload
    };
  },
  setAstonMartinFinanceValues: function setAstonMartinFinanceValues(payload) {
    return {
      type: constants.SET_ASTON_MARTIN_FINANCE_VALUES,
      payload: payload
    };
  }
};
var initialState = {
  representativeExample: null,
  financeOptions: {},
  financeProductExample: {}
};
var transformMarketFinanceOptions = function transformMarketFinanceOptions(options) {
  return options.filter(function (o) {
    return !!o.financeType;
  }).reduce(function (acc, option) {
    var financeType = option.financeType,
      durationOptions = option.durationOptions,
      downpaymentRange = option.downpaymentRange;
    return _objectSpread(_objectSpread({}, acc), {}, (0, _defineProperty2["default"])({}, financeType, {
      durationOptions: durationOptions,
      downpaymentRange: downpaymentRange
    }));
  }, {});
};
function reducer() {
  var state = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : initialState;
  var action = arguments.length > 1 ? arguments[1] : undefined;
  var payload = action.payload,
    type = action.type;
  switch (type) {
    case constants.LOAD_REPRESENTATIVE_EXAMPLE_SUCCESS:
      return _objectSpread(_objectSpread({}, state), {}, {
        representativeExample: payload
      });
    case constants.LOAD_FINANCE_OPTIONS_SUCCESS:
      return _objectSpread(_objectSpread({}, state), {}, {
        financeOptions: _objectSpread(_objectSpread({}, state.financeOptions), transformMarketFinanceOptions(payload.data))
      });
    case constants.LOAD_FINANCE_PRODUCT_SUCCESS:
      return _objectSpread(_objectSpread({}, state), {}, {
        financeProductExample: _objectSpread(_objectSpread({}, state.financeProductExample), {}, (0, _defineProperty2["default"])({}, payload.type, payload.data))
      });
    case constants.RESET_FINANCE_PRODUCT:
      return _objectSpread(_objectSpread({}, state), {}, {
        financeProductExample: _objectSpread(_objectSpread({}, state.financeProductExample), {}, (0, _defineProperty2["default"])({}, payload.type, null))
      });
    default:
      return state;
  }
}