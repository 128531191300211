"use strict";

var _interopRequireDefault = require("@babel/runtime/helpers/interopRequireDefault");
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.handleVehiclePrice = exports.convertVehiclesPriceCurrency = exports.convertVehiclePriceCurrency = exports.convertSecondaryPriceCurrency = exports.convertPriceCurrency = void 0;
var _defineProperty2 = _interopRequireDefault(require("@babel/runtime/helpers/defineProperty"));
var _numbers = require("../localisation/numbers");
var _translateFromTemplate = require("../localisation/translateFromTemplate");
function ownKeys(e, r) { var t = Object.keys(e); if (Object.getOwnPropertySymbols) { var o = Object.getOwnPropertySymbols(e); r && (o = o.filter(function (r) { return Object.getOwnPropertyDescriptor(e, r).enumerable; })), t.push.apply(t, o); } return t; }
function _objectSpread(e) { for (var r = 1; r < arguments.length; r++) { var t = null != arguments[r] ? arguments[r] : {}; r % 2 ? ownKeys(Object(t), !0).forEach(function (r) { (0, _defineProperty2["default"])(e, r, t[r]); }) : Object.getOwnPropertyDescriptors ? Object.defineProperties(e, Object.getOwnPropertyDescriptors(t)) : ownKeys(Object(t)).forEach(function (r) { Object.defineProperty(e, r, Object.getOwnPropertyDescriptor(t, r)); }); } return e; }
var convertVehiclePriceCurrency = exports.convertVehiclePriceCurrency = function convertVehiclePriceCurrency(vehicle, exchangeRates, currency) {
  if (!vehicle || vehicle.price && vehicle.price.value === 0) {
    return vehicle;
  }
  var originalValue = vehicle.price.originalValue || vehicle.price.value;
  var originalCurrency = vehicle.price.originalCurrency || vehicle.price.currency;
  var value = originalValue;
  if (currency !== originalCurrency) {
    var newBaseRate = exchangeRates[vehicle.price.currency];
    value = Math.ceil(vehicle.price.value / newBaseRate);
  }
  var price = _objectSpread(_objectSpread({}, vehicle.price), {}, {
    value: value,
    currency: currency,
    originalValue: originalValue,
    originalCurrency: originalCurrency
  });
  return _objectSpread(_objectSpread({}, vehicle), {}, {
    price: price
  });
};
var convertVehiclesPriceCurrency = exports.convertVehiclesPriceCurrency = function convertVehiclesPriceCurrency(vehicles, exchangeRates, currency) {
  return vehicles.map(function (v) {
    return convertVehiclePriceCurrency(v, exchangeRates, currency);
  });
};
var convertSecondaryPriceCurrency = exports.convertSecondaryPriceCurrency = function convertSecondaryPriceCurrency() {
  var value = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : 0;
  var exchangeRates = arguments.length > 1 ? arguments[1] : undefined;
  var exchangeCurrency = arguments.length > 2 ? arguments[2] : undefined;
  var incomingCurrency = arguments.length > 3 ? arguments[3] : undefined;
  if (value === 0) {
    return value;
  }
  var exchangeValue = value;
  if (exchangeCurrency !== incomingCurrency && exchangeRates && exchangeCurrency) {
    var newBaseRate = exchangeRates[incomingCurrency];
    exchangeValue = Math.round(value * newBaseRate);
  }
  return exchangeValue;
};
var convertPriceCurrency = exports.convertPriceCurrency = function convertPriceCurrency(value, exchangeRates, exchangeCurrency, incomingCurrency) {
  var step = arguments.length > 4 && arguments[4] !== undefined ? arguments[4] : 1;
  var type = arguments.length > 5 && arguments[5] !== undefined ? arguments[5] : 'max';
  if (value === 0) {
    return value;
  }
  var roundFunc = function roundFunc(val) {
    return type === 'max' ? Math.ceil(val) : Math.floor(val);
  };
  if (exchangeCurrency !== incomingCurrency && exchangeRates && exchangeCurrency) {
    var newBaseRate = exchangeRates[incomingCurrency];
    return roundFunc(value / newBaseRate / step) * step;
  }
  return roundFunc(value / step) * step;
};
var handleVehiclePrice = exports.handleVehiclePrice = function handleVehiclePrice(price, options) {
  var translations = options.translations,
    retailerCountryCode = options.retailerCountryCode,
    handleZeroPriceAsText = options.handleZeroPriceAsText,
    translationsSold = options.translationsSold,
    priceSpecial = options.priceSpecial,
    vehiclePrice = options.vehiclePrice,
    zeroPriceText = options.zeroPriceText,
    currencyType = options.currencyType,
    locale = options.locale,
    fractionDigits = options.fractionDigits;
  if (handleZeroPriceAsText && vehiclePrice === 0) {
    return priceSpecial === 'SOLD' && translationsSold || zeroPriceText || '-';
  }
  if (translations && translations.priceIncludesVat) {
    var PRICE = price ? (0, _numbers.localiseCurrency)(price, locale, currencyType, fractionDigits || 0) : '-';
    var VALUE = retailerCountryCode === 'BE' ? translations.vatRateBelgium : retailerCountryCode === 'LU' ? translations.vatRateLuxembourg : '';
    return (0, _translateFromTemplate.translateFromTemplate)('priceIncludesVat', {
      PRICE: PRICE,
      VALUE: VALUE
    }, translations);
  }
  return price ? (0, _numbers.localiseCurrency)(price, locale, currencyType, fractionDigits || 0) : '-';
};