"use strict";

var _interopRequireDefault = require("@babel/runtime/helpers/interopRequireDefault");
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.availableToAdministration = void 0;
Object.defineProperty(exports, "component", {
  enumerable: true,
  get: function get() {
    return _ConsentPolicy["default"];
  }
});
exports.name = exports.id = exports.description = void 0;
var _ConsentPolicy = _interopRequireDefault(require("./ConsentPolicy"));
var name = exports.name = 'ConsentPolicy';
var id = exports.id = 'ConsentPolicy';
var availableToAdministration = exports.availableToAdministration = false;
var description = exports.description = 'ConsentPolicy';