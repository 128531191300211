"use strict";

var _interopRequireDefault = require("@babel/runtime/helpers/interopRequireDefault");
var _typeof = require("@babel/runtime/helpers/typeof");
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.availableToAdministration = void 0;
Object.defineProperty(exports, "component", {
  enumerable: true,
  get: function get() {
    return _FiftyFifty["default"];
  }
});
Object.defineProperty(exports, "config", {
  enumerable: true,
  get: function get() {
    return _config["default"];
  }
});
Object.defineProperty(exports, "defaultConfig", {
  enumerable: true,
  get: function get() {
    return _defaultConfig["default"];
  }
});
exports.submodules = exports.searchable = exports.name = exports.id = exports.icon = exports.getIndexContent = exports.description = void 0;
var _Icon = _interopRequireDefault(require("./Icon"));
var Filters = _interopRequireWildcard(require("../../linked-modules/Filters"));
var _FiftyFifty = _interopRequireDefault(require("./FiftyFifty"));
var _config = _interopRequireDefault(require("./config"));
var _defaultConfig = _interopRequireDefault(require("./defaultConfig"));
function _getRequireWildcardCache(e) { if ("function" != typeof WeakMap) return null; var r = new WeakMap(), t = new WeakMap(); return (_getRequireWildcardCache = function _getRequireWildcardCache(e) { return e ? t : r; })(e); }
function _interopRequireWildcard(e, r) { if (!r && e && e.__esModule) return e; if (null === e || "object" != _typeof(e) && "function" != typeof e) return { "default": e }; var t = _getRequireWildcardCache(r); if (t && t.has(e)) return t.get(e); var n = { __proto__: null }, a = Object.defineProperty && Object.getOwnPropertyDescriptor; for (var u in e) if ("default" !== u && {}.hasOwnProperty.call(e, u)) { var i = a ? Object.getOwnPropertyDescriptor(e, u) : null; i && (i.get || i.set) ? Object.defineProperty(n, u, i) : n[u] = e[u]; } return n["default"] = e, t && t.set(e, n), n; }
var name = exports.name = 'FiftyFifty';
var id = exports.id = 'FiftyFifty';
var availableToAdministration = exports.availableToAdministration = true;
var description = exports.description = 'Customisable image and text';
var icon = exports.icon = _Icon["default"];
var searchable = exports.searchable = true;
var getIndexContent = exports.getIndexContent = function getIndexContent(_ref) {
  var _ref$config = _ref.config,
    _ref$config2 = _ref$config === void 0 ? {} : _ref$config,
    _ref$config2$translat = _ref$config2.translations,
    translations = _ref$config2$translat === void 0 ? {} : _ref$config2$translat,
    relativePath = _ref.relativePath,
    instanceId = _ref.instanceId;
  return [{
    title: translations.headlineText,
    content: translations.bodyText
  }].map(function (_ref2, index) {
    var title = _ref2.title,
      content = _ref2.content;
    return {
      key: "".concat(instanceId, "-").concat(index),
      title: title,
      content: content,
      link: relativePath
    };
  }).filter(function (_ref3) {
    var title = _ref3.title,
      content = _ref3.content;
    return !!title || !!content;
  });
};
var submodules = exports.submodules = [Filters];