export const constants = {
  LoadIndexedPages: 'LoadIndexedPages',
};

export const actions = {
  loadIndexedPages: indexedPages => ({
    type: constants.LoadIndexedPages,
    payload: indexedPages,
  }),
};
