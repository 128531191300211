"use strict";

var _interopRequireDefault = require("@babel/runtime/helpers/interopRequireDefault");
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.initialState = exports.constants = exports.actions = void 0;
exports.reducer = reducer;
var _defineProperty2 = _interopRequireDefault(require("@babel/runtime/helpers/defineProperty"));
var _toConsumableArray2 = _interopRequireDefault(require("@babel/runtime/helpers/toConsumableArray"));
var _reducerMacros = require("../../utilities/reducerMacros");
var _helpers = require("../currencyConversion/helpers");
function ownKeys(e, r) { var t = Object.keys(e); if (Object.getOwnPropertySymbols) { var o = Object.getOwnPropertySymbols(e); r && (o = o.filter(function (r) { return Object.getOwnPropertyDescriptor(e, r).enumerable; })), t.push.apply(t, o); } return t; }
function _objectSpread(e) { for (var r = 1; r < arguments.length; r++) { var t = null != arguments[r] ? arguments[r] : {}; r % 2 ? ownKeys(Object(t), !0).forEach(function (r) { (0, _defineProperty2["default"])(e, r, t[r]); }) : Object.getOwnPropertyDescriptors ? Object.defineProperties(e, Object.getOwnPropertyDescriptors(t)) : ownKeys(Object(t)).forEach(function (r) { Object.defineProperty(e, r, Object.getOwnPropertyDescriptor(t, r)); }); } return e; }
var modulePrefix = 'MOD_SHORTLIST_SHARED';
var constants = exports.constants = (0, _reducerMacros.moduleConstants)(modulePrefix, ['TOGGLE_VEHICLE', 'TOGGLE_VEHICLE_SUCCESS', 'GET_VEHICLES', 'GET_VEHICLES_SUCCESS', 'SAVE_SHORTLISTED_VEHICLES', 'SAVE_SHORTLISTED_VEHICLES_SUCCESS', 'SAVE_SHORTLISTED_VEHICLES_FAILURE', 'UPDATE_FINANCE_SUCCESS', 'UPDATE_VEHICLE_PRICES']);
var actions = exports.actions = {
  toggleVehicle: function toggleVehicle(payload) {
    return {
      type: constants.TOGGLE_VEHICLE,
      payload: payload
    };
  },
  toggleVehicleSuccess: function toggleVehicleSuccess(payload) {
    return {
      type: constants.TOGGLE_VEHICLE_SUCCESS,
      payload: payload
    };
  },
  getVehicles: function getVehicles() {
    return {
      type: constants.GET_VEHICLES
    };
  },
  getVehiclesSuccess: function getVehiclesSuccess(payload) {
    return {
      type: constants.GET_VEHICLES_SUCCESS,
      payload: payload
    };
  },
  saveShorlistedVehicles: function saveShorlistedVehicles() {
    return {
      type: constants.SAVE_SHORTLISTED_VEHICLES
    };
  },
  saveShorlistedVehiclesSuccess: function saveShorlistedVehiclesSuccess(payload) {
    return {
      type: constants.SAVE_SHORTLISTED_VEHICLES_SUCCESS,
      payload: payload
    };
  },
  saveShorlistedVehiclesFailure: function saveShorlistedVehiclesFailure(error) {
    return {
      type: constants.SAVE_SHORTLISTED_VEHICLES_FAILURE,
      error: error
    };
  },
  updateFinanceSuccess: function updateFinanceSuccess(payload) {
    return {
      type: constants.UPDATE_FINANCE_SUCCESS,
      payload: payload
    };
  },
  updateVehiclePrices: function updateVehiclePrices(payload) {
    return {
      type: constants.UPDATE_VEHICLE_PRICES,
      payload: payload
    };
  }
};
var initialState = exports.initialState = {
  vehicles: []
};
var patch = function patch(vehicles, vehicle) {
  return (0, _toConsumableArray2["default"])(vehicles.find(function (v) {
    return v.id === vehicle.id;
  }) ? (0, _toConsumableArray2["default"])(vehicles.filter(function (v) {
    return v.id !== vehicle.id;
  })) : [].concat((0, _toConsumableArray2["default"])(vehicles), [vehicle]));
};
var replaceIfExists = function replaceIfExists(vehicles, vehicle) {
  return (0, _toConsumableArray2["default"])(vehicles.map(function (v) {
    return v.id === vehicle.id ? vehicle : v;
  }));
};
var updateVehicleCurrencies = function updateVehicleCurrencies(state, action) {
  var payload = action.payload;
  var exchangeRates = payload.exchangeRates,
    currency = payload.currency;
  var vehiclesToUpdate = state[payload.statePath] || state.vehicles;
  var vehicles = (0, _helpers.convertVehiclesPriceCurrency)(vehiclesToUpdate, exchangeRates[currency].rates, currency);
  return _objectSpread(_objectSpread({}, state), {}, {
    vehicles: vehicles
  });
};
function reducer() {
  var state = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : initialState;
  var action = arguments.length > 1 ? arguments[1] : undefined;
  var payload = action.payload,
    type = action.type;
  switch (type) {
    case constants.GET_VEHICLES_SUCCESS:
      return _objectSpread(_objectSpread({}, state), {}, (0, _defineProperty2["default"])((0, _defineProperty2["default"])({}, payload.statePath, payload.vehicles), "placeholdingImage", payload.placeholdingImage));
    case constants.TOGGLE_VEHICLE_SUCCESS:
      {
        return _objectSpread(_objectSpread({}, state), {}, (0, _defineProperty2["default"])((0, _defineProperty2["default"])({}, payload.statePath, patch(state[payload.statePath] || [], payload.vehicle, payload.placeholdingImage)), "placeholdingImage", payload.placeholdingImage));
      }
    case constants.SAVE_SHORTLISTED_VEHICLES_SUCCESS:
      return _objectSpread(_objectSpread({}, state), {}, (0, _defineProperty2["default"])({}, payload.statePath, payload.vehicles));
    case constants.SAVE_SHORTLISTED_VEHICLES_FAILURE:
      return _objectSpread(_objectSpread({}, state), {}, {
        error: payload
      });
    case constants.UPDATE_FINANCE_SUCCESS:
      return _objectSpread(_objectSpread({}, state), {}, (0, _defineProperty2["default"])({}, payload.statePath, replaceIfExists(state.vehicles, payload.vehicle)));
    case constants.UPDATE_VEHICLE_PRICES:
      return updateVehicleCurrencies(state, action);
    default:
      return state;
  }
}