"use strict";

var _interopRequireDefault = require("@babel/runtime/helpers/interopRequireDefault");
Object.defineProperty(exports, "__esModule", {
  value: true
});
Object.defineProperty(exports, "actions", {
  enumerable: true,
  get: function get() {
    return _actions.actions;
  }
});
Object.defineProperty(exports, "component", {
  enumerable: true,
  get: function get() {
    return _DataProvider["default"];
  }
});
Object.defineProperty(exports, "constants", {
  enumerable: true,
  get: function get() {
    return _actions.constants;
  }
});
exports.description = void 0;
Object.defineProperty(exports, "effects", {
  enumerable: true,
  get: function get() {
    return _effects["default"];
  }
});
Object.defineProperty(exports, "id", {
  enumerable: true,
  get: function get() {
    return _actions.id;
  }
});
Object.defineProperty(exports, "reducer", {
  enumerable: true,
  get: function get() {
    return _reducer["default"];
  }
});
var _DataProvider = _interopRequireDefault(require("./DataProvider"));
var _effects = _interopRequireDefault(require("./effects"));
var _reducer = _interopRequireDefault(require("./reducer"));
var _actions = require("./actions");
var description = exports.description = 'Data Provider Module';