"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports["default"] = _default;
function _default() {
  return {
    translations: {
      bodyText: 'Body Text Placeholder',
      buttonText: 'ACCEPT',
      policyLink: 'Policy UTL Placeholder'
    },
    policyLink: {
      url: 'www.mypolicy.com',
      label: 'URL',
      on: true
    },
    cookiesBodyFont: {
      fontSize: 14,
      kerning: 0.4,
      transform: 'uppercase',
      height: '10px'
    },
    active: 'true'
  };
}