"use strict";

var _interopRequireDefault = require("@babel/runtime/helpers/interopRequireDefault");
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.useIsInViewport = useIsInViewport;
var _slicedToArray2 = _interopRequireDefault(require("@babel/runtime/helpers/slicedToArray"));
var _react = require("react");
function useIsInViewport(ref, options) {
  var _useState = (0, _react.useState)(false),
    _useState2 = (0, _slicedToArray2["default"])(_useState, 2),
    isIntersecting = _useState2[0],
    setIsIntersecting = _useState2[1];
  var observer = (0, _react.useMemo)(function () {
    return new IntersectionObserver(function (_ref) {
      var _ref2 = (0, _slicedToArray2["default"])(_ref, 1),
        entry = _ref2[0];
      setIsIntersecting(entry.isIntersecting);
    }, options);
  }, []);
  (0, _react.useEffect)(function () {
    observer.observe(ref.current);
    return function () {
      observer.disconnect();
    };
  }, [ref, observer]);
  return isIntersecting;
}