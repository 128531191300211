"use strict";

var _interopRequireDefault = require("@babel/runtime/helpers/interopRequireDefault");
Object.defineProperty(exports, "__esModule", {
  value: true
});
Object.defineProperty(exports, "component", {
  enumerable: true,
  get: function get() {
    return _EmissionsSection["default"];
  }
});
exports.name = exports.id = void 0;
var _EmissionsSection = _interopRequireDefault(require("./EmissionsSection"));
var name = exports.name = 'EmissionsSection';
var id = exports.id = 'EmissionsSection';