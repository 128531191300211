"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.translateFromTemplate = void 0;
var translateFromTemplate = exports.translateFromTemplate = function translateFromTemplate(translationsKey, placeholdersAndValues, translations) {
  var template = translations[translationsKey];
  var replaceValue = function replaceValue(t, key) {
    return t.replace("{".concat(key, "}"), placeholdersAndValues[key] !== undefined && placeholdersAndValues[key] !== null ? placeholdersAndValues[key].toString() : '');
  };
  return template ? Object.keys(placeholdersAndValues).reduce(replaceValue, template) : '';
};