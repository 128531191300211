"use strict";

var _interopRequireDefault = require("@babel/runtime/helpers/interopRequireDefault");
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.initialState = exports.constants = exports.actions = void 0;
exports.reducer = reducer;
var _defineProperty2 = _interopRequireDefault(require("@babel/runtime/helpers/defineProperty"));
function ownKeys(e, r) { var t = Object.keys(e); if (Object.getOwnPropertySymbols) { var o = Object.getOwnPropertySymbols(e); r && (o = o.filter(function (r) { return Object.getOwnPropertyDescriptor(e, r).enumerable; })), t.push.apply(t, o); } return t; }
function _objectSpread(e) { for (var r = 1; r < arguments.length; r++) { var t = null != arguments[r] ? arguments[r] : {}; r % 2 ? ownKeys(Object(t), !0).forEach(function (r) { (0, _defineProperty2["default"])(e, r, t[r]); }) : Object.getOwnPropertyDescriptors ? Object.defineProperties(e, Object.getOwnPropertyDescriptors(t)) : ownKeys(Object(t)).forEach(function (r) { Object.defineProperty(e, r, Object.getOwnPropertyDescriptor(t, r)); }); } return e; }
var constants = exports.constants = {
  GET_EXCHANGE_RATES: 'GET_EXCHANGE_RATES',
  GET_EXCHANGE_RATES_SUCCESS: 'GET_EXCHANGE_RATES_SUCCESS',
  CLEAR_EXCHANGE_RATES: 'CLEAR_EXCHANGE_RATES'
};
var actions = exports.actions = {
  getExchangeRates: function getExchangeRates(payload) {
    return {
      type: constants.GET_EXCHANGE_RATES,
      payload: payload
    };
  },
  getExchangeRatesSuccess: function getExchangeRatesSuccess(payload) {
    return {
      type: constants.GET_EXCHANGE_RATES_SUCCESS,
      payload: payload
    };
  },
  clearExchangeRates: function clearExchangeRates() {
    return {
      type: constants.CLEAR_EXCHANGE_RATES
    };
  }
};
var initialState = exports.initialState = {
  exchangeRates: {}
};
function reducer() {
  var state = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : initialState;
  var action = arguments.length > 1 ? arguments[1] : undefined;
  var payload = action.payload,
    type = action.type;
  switch (type) {
    case constants.GET_EXCHANGE_RATES_SUCCESS:
      return _objectSpread(_objectSpread({}, state), {}, {
        exchangeRates: _objectSpread(_objectSpread({}, state.exchangeRates), {}, (0, _defineProperty2["default"])({}, payload.base, payload)),
        currency: payload.base
      });
    case constants.CLEAR_EXCHANGE_RATES:
      return _objectSpread(_objectSpread({}, state), {}, {
        exchangeRates: {}
      });
    default:
      return state;
  }
}