"use strict";

var _interopRequireDefault = require("@babel/runtime/helpers/interopRequireDefault");
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports["default"] = void 0;
var _styledComponents = _interopRequireDefault(require("styled-components"));
var _default = exports["default"] = _styledComponents["default"].div.withConfig({
  displayName: "Icon",
  componentId: "sc-1ww06pa-0"
})(["width:100%;background-image:url(", ");background-size:auto;background-repeat:no-repeat;background-position:", ";min-height:", "px;"], function (props) {
  return props.src;
}, function (props) {
  return props.imagePosition || 'center';
}, function (props) {
  return props.imageHeight || 300;
});