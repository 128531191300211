"use strict";

var _interopRequireDefault = require("@babel/runtime/helpers/interopRequireDefault");
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports["default"] = void 0;
var _config = require("../../helpers/config");
var _SectionIcons = _interopRequireDefault(require("../../helpers/SectionIcons"));
var config = (0, _config.Config)('Footer', {
  id: 'footer'
}, (0, _config.Slice)('Layout', {
  id: 'layout'
}, (0, _config.Section)('Top Tier', {
  icon: _SectionIcons["default"].TopTier
}, (0, _config.Pixels)('Height', 'topHeight'), (0, _config.Align)('Align', 'topTierAlign')), (0, _config.Section)('Middle Tier', {
  icon: _SectionIcons["default"].MidTier
}, (0, _config.Pixels)('Height', 'midHeight'), (0, _config.Align)('Align', 'midTierAlign')), (0, _config.Section)('Bottom Tier', {
  icon: _SectionIcons["default"].BottomTier
}, (0, _config.Pixels)('Height', 'bottomHeight'), (0, _config.Align)('Align', 'bottomTierAlign')), (0, _config.Section)('Global', {}, (0, _config.Toggle)('Sticky Footer?', 'stickyFooter'), (0, _config.Pixels)('Padding Vertical', 'paddingVertical'), (0, _config.Pixels)('Padding Horizontal', 'paddingHorizontal'))), (0, _config.Slice)('Content', {
  id: 'content'
}, (0, _config.Section)('Top Tier', {
  icon: _SectionIcons["default"].TopTier
}, (0, _config.Items)('PagesAndLinks', 'topItems')), (0, _config.Section)('Middle Tier', {
  icon: _SectionIcons["default"].MidTier
}, (0, _config.URL)('Facebook', 'urlFacebook'), (0, _config.URL)('Twitter', 'urlTwitter'), (0, _config.URL)('Instagram', 'urlInstagram'), (0, _config.URL)('YouTube', 'urlYouTube'), (0, _config.URL)('LinkedIn', 'urlLinkedIn')), (0, _config.Section)('Bottom Tier', {
  icon: _SectionIcons["default"].BottomTier
}, (0, _config.TextArea)('Custom Text', 'bottomText'), (0, _config.Link)('Link Text', 'bottomLinkText'), (0, _config.Toggle)('Show image', 'showBottomImage'))), (0, _config.Slice)('Styling', {
  id: 'styling'
}, (0, _config.Section)('Top Tier', {
  icon: _SectionIcons["default"].TopTier
}, (0, _config.Colour)('Background', 'topBGColour'), (0, _config.Font)('Link', 'topLinkFont'), (0, _config.Colour)('Link Hover', 'topLinkHoverColour'), (0, _config.Pixels)('Top Border Height', 'topTierBorderTopHeight', {
  max: 10
}), (0, _config.Colour)('Top Border Colour', 'topTierBorderTopColour')), (0, _config.Section)('Middle Tier', {
  icon: _SectionIcons["default"].MidTier
}, (0, _config.Colour)('Background', 'midBGColour'), (0, _config.Colour)('Social Media Icons', 'socialIconsColour'), (0, _config.Pixels)('Top Border Height', 'midTierBorderTopHeight', {
  max: 10
}), (0, _config.Colour)('Top Border Colour', 'midTierBorderTopColour')), (0, _config.Section)('Bottom Tier', {
  icon: _SectionIcons["default"].BottomTier
}, (0, _config.Colour)('Background', 'bottomBGColour'), (0, _config.Font)('Text Colour', 'bottomFont'), (0, _config.Font)('Bottom Link Text', 'bottomLinkFont'), (0, _config.Pixels)('Top Border Height', 'bottomTierBorderTopHeight', {
  max: 10
}), (0, _config.Colour)('Top Border Colour', 'bottomTierBorderTopColour'))));
var _default = exports["default"] = config;