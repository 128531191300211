"use strict";

var _interopRequireDefault = require("@babel/runtime/helpers/interopRequireDefault");
var _typeof = require("@babel/runtime/helpers/typeof");
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.TitleBar = exports.Title = exports.TextCol = exports.SummaryLink = exports.SubmitButton = exports.SubHeader = exports.StepOne = exports.SpinnerContainer = exports.Select = exports.RadioInput = exports.RadioContainer = exports.PaymentSummary = exports.PaymentRow = exports.PaymentButtons = exports.PageOverlay = exports.Label = exports.Input = exports.HeaderTwo = exports.Header = exports.FooterTopText = exports.FooterMainText = exports.FloatingFooter = exports.FinanceWrapper = exports.FinanceLogo = exports.Drawer = exports.Disclaimer = exports.DataValue = exports.DataRow = exports.DataLabel = exports.ButtonContainer = void 0;
var _taggedTemplateLiteral2 = _interopRequireDefault(require("@babel/runtime/helpers/taggedTemplateLiteral"));
var _styledComponents = _interopRequireWildcard(require("styled-components"));
var _theme = _interopRequireDefault(require("../../../theme"));
var _templateObject, _templateObject2, _templateObject3, _templateObject4, _templateObject5;
function _getRequireWildcardCache(e) { if ("function" != typeof WeakMap) return null; var r = new WeakMap(), t = new WeakMap(); return (_getRequireWildcardCache = function _getRequireWildcardCache(e) { return e ? t : r; })(e); }
function _interopRequireWildcard(e, r) { if (!r && e && e.__esModule) return e; if (null === e || "object" != _typeof(e) && "function" != typeof e) return { "default": e }; var t = _getRequireWildcardCache(r); if (t && t.has(e)) return t.get(e); var n = { __proto__: null }, a = Object.defineProperty && Object.getOwnPropertyDescriptor; for (var u in e) if ("default" !== u && {}.hasOwnProperty.call(e, u)) { var i = a ? Object.getOwnPropertyDescriptor(e, u) : null; i && (i.get || i.set) ? Object.defineProperty(n, u, i) : n[u] = e[u]; } return n["default"] = e, t && t.set(e, n), n; }
var PageOverlay = exports.PageOverlay = _styledComponents["default"].div.withConfig({
  displayName: "FinanceDrawerStyles__PageOverlay",
  componentId: "sc-1oa86qc-0"
})(["display:none;", ""], function (props) {
  return props.open && (0, _styledComponents.css)(["display:block;position:fixed;top:0;right:0;width:100%;height:100%;background:#111;opacity:0.4;z-index:100;"]);
});
var SpinnerContainer = exports.SpinnerContainer = _styledComponents["default"].div.withConfig({
  displayName: "FinanceDrawerStyles__SpinnerContainer",
  componentId: "sc-1oa86qc-1"
})(["display:flex;flex-direction:column;justify-content:center;align-items:center;height:100%;margin:50px;text-align:center;"]);
var Drawer = exports.Drawer = _styledComponents["default"].div.withConfig({
  displayName: "FinanceDrawerStyles__Drawer",
  componentId: "sc-1oa86qc-2"
})(["height:100%;background:#fff;position:fixed;top:0;right:0;width:820px;z-index:200;transform:translateX(100%);transition:transform 0.3s ease-out;display:flex;flex-direction:column;box-sizing:border-box;align-items:center;overflow-y:scroll;", " ", ""], _theme["default"].max.large(_templateObject || (_templateObject = (0, _taggedTemplateLiteral2["default"])(["\n    width: 100%;\n  "]))), function (props) {
  return props.open && (0, _styledComponents.css)(["transform:translateX(0);"]);
});
var FinanceLogo = exports.FinanceLogo = _styledComponents["default"].img.withConfig({
  displayName: "FinanceDrawerStyles__FinanceLogo",
  componentId: "sc-1oa86qc-3"
})(["align-self:flex-end;display:flex;margin-bottom:20px;max-width:196px;"]);
var Disclaimer = exports.Disclaimer = _styledComponents["default"].div.withConfig({
  displayName: "FinanceDrawerStyles__Disclaimer",
  componentId: "sc-1oa86qc-4"
})(["padding:20px 80px;display:flex;flex-direction:column;justify-content:center;font-size:16px;line-height:24px;border:1px solid #f1f1f1;", ""], _theme["default"].max.large(_templateObject2 || (_templateObject2 = (0, _taggedTemplateLiteral2["default"])(["\n    padding: 20px;\n  "]))));
var StepOne = exports.StepOne = _styledComponents["default"].div.withConfig({
  displayName: "FinanceDrawerStyles__StepOne",
  componentId: "sc-1oa86qc-5"
})(["display:flex;flex-direction:column;padding:30px 80px;", " width:100%;box-sizing:border-box;"], _theme["default"].max.large(_templateObject3 || (_templateObject3 = (0, _taggedTemplateLiteral2["default"])(["\n    padding: 20px;\n  "]))));
var TitleBar = exports.TitleBar = _styledComponents["default"].div.withConfig({
  displayName: "FinanceDrawerStyles__TitleBar",
  componentId: "sc-1oa86qc-6"
})(["display:flex;justify-content:space-between;align-items:center;width:100%;"]);
var Title = exports.Title = _styledComponents["default"].div.withConfig({
  displayName: "FinanceDrawerStyles__Title",
  componentId: "sc-1oa86qc-7"
})(["color:#202020;font-size:20.22px;font-weight:700;line-height:40px;border-bottom:2px solid #202020;"]);
var Header = exports.Header = _styledComponents["default"].div.withConfig({
  displayName: "FinanceDrawerStyles__Header",
  componentId: "sc-1oa86qc-8"
})(["color:#202020;font-size:25.7px;font-style:normal;font-weight:700;line-height:34.912px;margin-top:20px;"]);
var SubHeader = exports.SubHeader = _styledComponents["default"].div.withConfig({
  displayName: "FinanceDrawerStyles__SubHeader",
  componentId: "sc-1oa86qc-9"
})(["color:#202020;font-size:16px;line-height:24px;font-weight:300;"]);
var HeaderTwo = exports.HeaderTwo = _styledComponents["default"].div.withConfig({
  displayName: "FinanceDrawerStyles__HeaderTwo",
  componentId: "sc-1oa86qc-10"
})(["color:#202020;font-size:20.22px;font-style:normal;font-weight:700;line-height:28.742px;"]);
var Label = exports.Label = _styledComponents["default"].div.withConfig({
  displayName: "FinanceDrawerStyles__Label",
  componentId: "sc-1oa86qc-11"
})(["color:#202020;font-size:16px;font-weight:700;line-height:24px;margin-bottom:4px;"]);
var Select = exports.Select = _styledComponents["default"].select.withConfig({
  displayName: "FinanceDrawerStyles__Select",
  componentId: "sc-1oa86qc-12"
})(["font-family:'Roboto';margin-right:10px;min-width:250px;height:45px;width:350px;font-size:14px;line-height:40px;box-sizing:border-box;border:2px solid #6b6d70;padding-left:15px;padding-right:15px;color:#444444;background-color:#fff;margin-bottom:25px;&:focus{outline:none;}appearance:none;background-image:url('https://res.cloudinary.com/motortrak/image/upload/v1709656494/locator/lamborghini/icons/chevron-down.svg');background-repeat:no-repeat;background-position:right 12px top 51%;background-size:16px auto;"]);
var Input = exports.Input = _styledComponents["default"].input.withConfig({
  displayName: "FinanceDrawerStyles__Input",
  componentId: "sc-1oa86qc-13"
})(["display:flex;text-align:center;padding:13px 18px;align-items:center;border:2px solid #b5b5b5;font-size:16px;appearance:textfield;font-family:'Roboto';"]);
var PaymentButtons = exports.PaymentButtons = _styledComponents["default"].img.withConfig({
  displayName: "FinanceDrawerStyles__PaymentButtons",
  componentId: "sc-1oa86qc-14"
})(["cursor:pointer;display:flex;width:17px;padding:14px;justify-content:center;align-items:flex-start;border:2px solid #202020;touch-action:manipulation;"]);
var PaymentRow = exports.PaymentRow = _styledComponents["default"].div.withConfig({
  displayName: "FinanceDrawerStyles__PaymentRow",
  componentId: "sc-1oa86qc-15"
})(["width:350px;gap:6.5px;display:flex;flex-direction:row;justify-content:flex-start;align-items:center;margin-bottom:25px;"]);
var RadioContainer = exports.RadioContainer = _styledComponents["default"].div.withConfig({
  displayName: "FinanceDrawerStyles__RadioContainer",
  componentId: "sc-1oa86qc-16"
})(["display:flex;flex-direction:row;justify-content:flex-start;align-items:center;gap:8px;flex-wrap:wrap;max-width:400px;"]);
var ButtonContainer = exports.ButtonContainer = _styledComponents["default"].div.withConfig({
  displayName: "FinanceDrawerStyles__ButtonContainer",
  componentId: "sc-1oa86qc-17"
})(["cursor:pointer;display:flex;padding:12px 18px;justify-content:center;align-items:center;gap:10px;width:140px;border:2px solid #b5b5b5;", ""], function (_ref) {
  var active = _ref.active;
  return active && "  border: 2px solid #202020;";
});
var RadioInput = exports.RadioInput = _styledComponents["default"].input.withConfig({
  displayName: "FinanceDrawerStyles__RadioInput",
  componentId: "sc-1oa86qc-18"
})(["display:none;"]);
var PaymentSummary = exports.PaymentSummary = _styledComponents["default"].div.withConfig({
  displayName: "FinanceDrawerStyles__PaymentSummary",
  componentId: "sc-1oa86qc-19"
})(["display:flex;flex-direction:column;padding:30px 80px;width:100%;box-sizing:border-box;background:#f8f8f8;padding-bottom:160px;", " && > p{font-size:14px;margin-top:10px;}"], _theme["default"].max.large(_templateObject4 || (_templateObject4 = (0, _taggedTemplateLiteral2["default"])(["\n    padding: 20px;\n    padding-bottom: 200px;\n  "]))));
var FinanceWrapper = exports.FinanceWrapper = _styledComponents["default"].div.withConfig({
  displayName: "FinanceDrawerStyles__FinanceWrapper",
  componentId: "sc-1oa86qc-20"
})(["width:100%;display:flex;flex-direction:column;border:1px solid #9b9b9b;padding:20px;background:#fff;box-sizing:border-box;margin-bottom:20px;"]);
var DataRow = exports.DataRow = _styledComponents["default"].div.withConfig({
  displayName: "FinanceDrawerStyles__DataRow",
  componentId: "sc-1oa86qc-21"
})(["display:flex;padding:8px 0px;justify-content:space-between;align-items:center;flex-shrink:0;border-bottom:1px solid #b5b5b5;"]);
var DataLabel = exports.DataLabel = _styledComponents["default"].div.withConfig({
  displayName: "FinanceDrawerStyles__DataLabel",
  componentId: "sc-1oa86qc-22"
})(["color:#202020;font-size:16px;font-weight:400;line-height:24px;"]);
var DataValue = exports.DataValue = _styledComponents["default"].div.withConfig({
  displayName: "FinanceDrawerStyles__DataValue",
  componentId: "sc-1oa86qc-23"
})(["color:#202020;font-size:16px;font-weight:700;line-height:24px;"]);
var SubmitButton = exports.SubmitButton = _styledComponents["default"].button.withConfig({
  displayName: "FinanceDrawerStyles__SubmitButton",
  componentId: "sc-1oa86qc-24"
})(["cursor:pointer;display:inline-flex;padding:14px 28px;justify-content:center;align-items:center;border:2px solid #202020;background:transparent;white-space:nowrap;align-self:end;font-size:16px;color:#202020;font-family:Roboto;"]);
var FloatingFooter = exports.FloatingFooter = _styledComponents["default"].div.withConfig({
  displayName: "FinanceDrawerStyles__FloatingFooter",
  componentId: "sc-1oa86qc-25"
})(["display:flex;flex-direction:row;justify-content:space-between;padding:13px 60px;width:820px;box-sizing:border-box;background:#fff;position:fixed;bottom:0;right:0;background:#fff;z-index:200;align-items:center;", ""], _theme["default"].max.large(_templateObject5 || (_templateObject5 = (0, _taggedTemplateLiteral2["default"])(["\n    width: 100%;  \n    padding: 13px;\n  "]))));
var TextCol = exports.TextCol = _styledComponents["default"].div.withConfig({
  displayName: "FinanceDrawerStyles__TextCol",
  componentId: "sc-1oa86qc-26"
})(["display:flex;flex-direction:column;box-sizing:border-box;"]);
var FooterTopText = exports.FooterTopText = _styledComponents["default"].div.withConfig({
  displayName: "FinanceDrawerStyles__FooterTopText",
  componentId: "sc-1oa86qc-27"
})(["color:#202020;font-family:'Roboto';font-size:14px;font-style:normal;font-weight:400;"]);
var FooterMainText = exports.FooterMainText = _styledComponents["default"].div.withConfig({
  displayName: "FinanceDrawerStyles__FooterMainText",
  componentId: "sc-1oa86qc-28"
})(["color:#202020;font-family:'Roboto';font-size:16px;font-style:normal;font-weight:700;"]);
var SummaryLink = exports.SummaryLink = _styledComponents["default"].button.withConfig({
  displayName: "FinanceDrawerStyles__SummaryLink",
  componentId: "sc-1oa86qc-29"
})(["background:none;border:none;padding:0;margin:0;color:#202020;font-size:16px;font-weight:400;line-height:24px;text-decoration:underline;cursor:pointer;width:fit-content;"]);