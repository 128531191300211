"use strict";

var _interopRequireDefault = require("@babel/runtime/helpers/interopRequireDefault");
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.availableToAdministration = void 0;
Object.defineProperty(exports, "component", {
  enumerable: true,
  get: function get() {
    return _HeroCarousel["default"];
  }
});
Object.defineProperty(exports, "config", {
  enumerable: true,
  get: function get() {
    return _config["default"];
  }
});
Object.defineProperty(exports, "defaultConfig", {
  enumerable: true,
  get: function get() {
    return _defaultConfig["default"];
  }
});
exports.searchable = exports.name = exports.id = exports.icon = exports.getIndexContent = exports.description = void 0;
exports.template = template;
var _toConsumableArray2 = _interopRequireDefault(require("@babel/runtime/helpers/toConsumableArray"));
var _Icon = _interopRequireDefault(require("./Icon"));
var _HeroCarousel = _interopRequireDefault(require("./HeroCarousel"));
var _config = _interopRequireDefault(require("./config"));
var _defaultConfig = _interopRequireDefault(require("./defaultConfig"));
var name = exports.name = 'Hero Carousel';
var id = exports.id = 'HeroCarousel';
var availableToAdministration = exports.availableToAdministration = true;
var description = exports.description = 'Horizontal image slider (up to 8 slides)';
var icon = exports.icon = _Icon["default"];
function template() {
  return {
    items: {
      translations: {
        header: 'Placeholder',
        body: 'placeholder',
        topLink: 'Placeholder'
      },
      slideContentStyle: {
        label: 'slideContentOne',
        value: 'slideContentOne'
      },
      paginationStyle: {
        label: 'paginationOne',
        value: 'paginationOne'
      },
      align: 'left',
      topLink: {
        url: 'search',
        label: 'search',
        on: true
      },
      midLink: {
        url: 'search',
        label: 'search',
        on: false
      },
      bottomLink: {
        url: 'search',
        label: 'search',
        on: false
      },
      mediaContent: {
        label: 'placeholder',
        value: 'https://res.cloudinary.com/motortrak/image/upload/v1537798827/default-image_btd6xy.png'
      }
    }
  };
}
var searchable = exports.searchable = true;
var getIndexContent = exports.getIndexContent = function getIndexContent(_ref) {
  var _ref$config = _ref.config,
    _ref$config2 = _ref$config === void 0 ? {} : _ref$config,
    _ref$config2$items = _ref$config2.items,
    items = _ref$config2$items === void 0 ? [] : _ref$config2$items,
    relativePath = _ref.relativePath,
    instanceId = _ref.instanceId;
  return [].concat((0, _toConsumableArray2["default"])(items.map(function (_ref2) {
    var _ref2$translations = _ref2.translations,
      header = _ref2$translations.header,
      body = _ref2$translations.body;
    return {
      title: header,
      content: body
    };
  })), (0, _toConsumableArray2["default"])(items.map(function (_ref3) {
    var _ref3$translations = _ref3.translations,
      header = _ref3$translations.header,
      topLink = _ref3$translations.topLink;
    return {
      title: header,
      content: topLink
    };
  })), (0, _toConsumableArray2["default"])(items.map(function (_ref4) {
    var _ref4$translations = _ref4.translations,
      header = _ref4$translations.header,
      midLink = _ref4$translations.midLink;
    return {
      title: header,
      content: midLink
    };
  })), (0, _toConsumableArray2["default"])(items.map(function (_ref5) {
    var _ref5$translations = _ref5.translations,
      header = _ref5$translations.header,
      bottomLink = _ref5$translations.bottomLink;
    return {
      title: header,
      content: bottomLink
    };
  }))).map(function (_ref6, index) {
    var title = _ref6.title,
      content = _ref6.content;
    return {
      key: "".concat(instanceId, "-").concat(index),
      title: title,
      content: content,
      link: relativePath
    };
  }).filter(function (_ref7) {
    var title = _ref7.title,
      content = _ref7.content;
    return !!title || !!content;
  });
};